import React, {useEffect} from 'react';//useState, 
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Button } from 'react-bootstrap'
import * as actions from '../../../store/actions/companyPolicyAction'
import * as authActions from '../../../store/ducks/auth.duck'

export default function AcceptPolicy(props){
    const { user, hendleClose } = props
    const dispatch = useDispatch()
    const { letastPolicy, accept,success } = useSelector((state) => ({
        letastPolicy: state.companyPolicy.latest,
        accept: state.companyPolicy.accept,
        success: state.companyPolicy.success
    }), shallowEqual)
    // console.log('user', letastPolicy);
    useEffect(() => {
        if(user.vhUser){
            dispatch(actions.companyPolicyLatest())
        } else {
            dispatch(actions.companyPolicyLatestTenant(user.tenantID))
        }
        console.log("user changed", user);
    }, [user]);
    useEffect(()=>{
        console.log(letastPolicy);
    },[letastPolicy]);
    useEffect(()=>{
        if(success && accept){            
            console.log("Before doing request User One");
            dispatch(authActions.actions.requestUserOne(user.accessToken, user));
            hendleClose();       
        }
        console.log(accept, success);
    },[accept, success]);
    function onsubmit(){
        const params = {
            "CompanyPolicyID":letastPolicy.companyPolicyID, 
            "UserID":user.userID 
        }

        console.log("before accepting company policy");
        dispatch(actions.companyPolicyAccept(params))
    }

    return(
        <>
            {accept && accept.acceptedPolicyID && (
                <div role="alert" className="alert alert-success">
                    <div className="alert-text">You successfully accepted company policy...</div>
                </div>
            )}
            <div>
                {letastPolicy.policyContent}
                {/* <h5>Privacy Policy</h5>
                <p>Last updated: (add date)</p>
                <p>My Company (change this) ("us", "we", or "our") operates <a href="#">http://url.com</a> (change this) (the
            "Site"). This page informs you of our policies regarding the collection, use and disclosure of
            Personal Information we receive from users of the Site.</p>
                <p>We use your Personal Information only for providing and improving the Site. By using the Site, you
            agree to the collection and use of information in accordance with this policy.
            Information Collection And Use</p>
                <p>While using our Site, we may ask you to provide us with certain personally identifiable information
            that can be used to contact or identify you. Personally identifiable information may include, but is not
            limited to your name ("Personal Information").</p>
                <h5>Log Data</h5>
                <p>Like many site operators, we collect information that your browser sends whenever you visit our Site
            ("Log Data").</p>
                <p>This Log Data may include information such as your computer's Internet Protocol ("IP") address,
            browser type, browser version, the pages of our Site that you visit, the time and date of your visit,
            the time spent on those pages and other statistics.</p>
                <p>In addition, we may use third party services such as Google Analytics that collect, monitor and
            analyze this …</p> */}
            </div>
            
            <div style={{padding: '0 15px'}}>
                <div className="row mb-4">
                    <div className="col-sm-12 text-center">
                        <Button
                            variant="primary"
                            onClick={onsubmit}
                        >Accept</Button>
                    </div>
                </div>
            </div>
        </>
    )
}   