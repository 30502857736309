import React from 'react'
import { CustomTable } from './custom-table/CustomTable'

export function CustomAddCol(props){
    return(
        <>
        {props.defaultValues && <CustomTable 
            keys={props.keys} 
            tableName={props.tableName}
            columnName={props.columnName}
            onSelectChangeTableName={props.selectTableName}
            onSubmiHendle={props.onSubmiHendle}
            defaultValues={props.defaultValues}
            addNewRow={props.addNewRow}
            removeRow={props.removeRow}
            onCancleButton={props.onCancleButton}
            roles={props.roles}
            match={props.match}
            onTestMapping={props.onTestMapping}
            onCefitApprove={props.onCefitApprove}
            onRefreshMaping={props.onRefreshMaping}
        />}
        </>
    )
}