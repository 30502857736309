import React, {useState, useEffect, useMemo, Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';    
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as actions from "../../../../store/actions/tenantAction";
import * as industryActions from "../../../../store/actions/tenantIndustryAction";
import * as subActions from "../../../../store/actions/subscriptionsAction";
import {Form, Col, ListGroup, InputGroup, FormControl, Row} from 'react-bootstrap';
import BasicCustomerInForm from './BasicCustomerInForm';

export default function BasicCustomerInformation(props){
    const classes = useStyles();
    const { match } = props;
    const [item, setItem] = useState(props.item);
    const loader = document.getElementById('splash-screen');
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        setItem(props.item)
        if(props && props.item && props.item.countryID){
            dispatch(actions.getState(props.item && props.item.countryID))
        }
    }, [props.item]);
    
    const fakeUrl = ''
    const [logoImage, setLogoImage] = useState('');
    const Image = useMemo(() => {
        return {
            logoImage: props.item ? props.item.logoImage : fakeUrl,
            setLogoImage
        };
    }, [props.item]);
    const params = {
        "sortField": "tenantName",
        "sortDirection": "",
        "pageSize": 10,
        "pageNumber": 1,
        "searchText": ""
    }
    
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const {currentState, states, industry, country, subscriptions} = useSelector(
        (state) => ({ 
            industry: state.tenantIndustry.activeData.items,
            country: state.tenant.countries,
            subscriptions: state.subscriptions.data,
            states: state.tenant.cuntryState
        }), 
        shallowEqual
    );
    useEffect(() => {
        setIsLoading(true);
        dispatch(industryActions.getActiveTenantIndustries());
        dispatch(actions.getCountry());
        dispatch(subActions.fetchActiveSubscriptions());
        setIsLoading(false);
    }, [dispatch, isLoading]);
    const heandleChange = (e, type) => {
        
    }
    const saveInformation = (values, fileUrl) => {
        console.log("In SaveInformation");
        const paramsValues = editParams(values, fileUrl)
        if (values.tenantID) {
            loader.classList.remove('hidden')
            paramsValues['TenantID'] = parseInt(values.tenantID);
            dispatch(actions.updateTenantRequest(paramsValues));
            setTimeout(() => {
                dispatch(actions.getAllTenant(params))
            }, 2000)
        } else {
            const loader = document.getElementById('splash-screen');
            loader.classList.remove('hidden')
            dispatch(actions.addTenantRequest(paramsValues, history))
            setTimeout(() => {
                dispatch(actions.getAllTenant(params))
                //loader.classList.add('hidden')
            }, 10000)
        }
    };
    function editParams(values, fileUrl) {
        const params = {
            //"TenantID":parseInt(values.tenantID),
            "TenantName":values.tenantName,
            "SubscriptionID":parseInt(values.subscriptionID),
            "AllowAccount":parseInt(values.allowAccount),
            "Domain":`${values.domain}`,
            "emailDomain":`${values.emailDomain}`,
            "TenantIndustryID":parseInt(values.tenantIndustryID),
            "TenantIndustryLevelID":parseInt(values.tenantIndustryLevelID),
            "LevelSubTypeID":parseInt(values.levelSubTypeID),
            "LogoImage":fileUrl,
            "BusinessName":values.businessName,
            "BillingEmail":values.billingEmail,
            "Address1":values.address1,
            "Address2":values.address2,
            "CountryID":parseInt(values.countryID),
            "StateID":parseInt(values.stateID),
            "OtherState": values.otherState,
            "City":values.city,
            "TaxID":values.taxID,
            "SelfServiceBIPurchased":values.selfServiceBIPurchased == 'true' ? 1 : 0,
            "Active":values.active,
            "SuperUserID":parseInt(values.superUserID),
            "SuperFirstName":values.superFirstName,
            "SuperLastName":values.superLastName,
            "SuperPhoneNo":values.superPhoneNo,
            "SuperEmail":values.superEmail,
            "SponsarID":parseInt(values.sponsarUserID),
            "SponsarFirstName":values.sponsarFirstName,
            "SponsarLastName":values.sponsarLastName,
            "SponsarPhoneNo":values.sponsarPhoneNo,
            "SponsarEmail":values.sponsarEmail,
            "ZipCode": values.zipCode
        }
        return params;
    }
    const backToTenantList = () => {
        history.push(`/tenant`);
    };

    const backToTenantEdit = () => {
        setTimeout(() => {
            history.push(`/tenant/${item.tenantID}/edit`);
        },1000)
    };
    
    const heandleChangeSub = (value) => {
        setLoading(true);
        item.subscriptionID = value.subscriptionID;
        item.allowAccount = value.allowAccount;
        item.SelfServiceBIPurchased = value.SelfServiceBIPurchased;
        setLoading(false);
    }
    const changesLogoIages = (logoImage) => {
        Image.setLogoImage(...item, logoImage)
    }

    return(
        <>
            {item && <Fragment>
                <div className={classes.container}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className={classes.list}>
                                <div style={{paddingRight: 10, minWidth: 165}}>
                                    <h6 style={{margin: 0}}>Subscription Type:</h6>
                                </div>
                                <div>
                                    <p style={{margin: 0}}>{item.subscriptionType}</p>
                                </div>
                            </div>
                            <div className={classes.list}>
                                <div style={{paddingRight: 10, minWidth: 165}}>
                                    <h6 style={{margin: 0}}>Domain:</h6>
                                </div>
                                <div>
                                    <p style={{margin: 0}}>{item.domain.split(".")[0]}{process.env.REACT_APP_TENANT_DOMAIN_SUFFIX}</p>
                                </div>
                            </div>
                            <div className={classes.list}>
                                <div style={{paddingRight: 10, minWidth: 165}}>
                                    <h6 style={{margin: 0}}>Tenant ID:</h6>
                                </div>
                                <div>
                                    <p style={{margin: 0}}>{item.tenantGUID}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className={classes.list}>
                                <div style={{paddingRight: 10, minWidth: 165}}>
                                    <h6 style={{margin: 0}}>Tenant Industry Type:</h6>
                                </div>
                                <div>
                                    <p style={{margin: 0}}>{item.tenantIndustryType}</p>
                                </div>
                            </div>
                            <div className={classes.list}>
                                <div style={{paddingRight: 10, minWidth: 165}}>
                                    <h6 style={{margin: 0}}>Tenant Type:</h6>
                                </div>
                                <div>
                                    <p style={{margin: 0}}>{item.tenantType}</p>
                                </div>
                            </div>
                            <div className={classes.list}>
                                <div style={{paddingRight: 10, minWidth: 165}}>
                                    <h6 style={{margin: 0}}>Sub Type:</h6>
                                </div>
                                <div>
                                    <p style={{margin: 0}}>{item.subType}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {item && industry && country && states && subscriptions && <BasicCustomerInForm 
                    item={item} 
                    match={props.match}
                    backToTenantList={backToTenantList} 
                    saveInformation={saveInformation} 
                    industry={industry} 
                    country={country} 
                    states={states}
                    changesLogoIages={changesLogoIages}
                    heandleChangeSub={heandleChangeSub}
                    subscriptions={subscriptions}
                />}
            </Fragment>}
        </>
    )
}

const useStyles = makeStyles({
    container: {
        padding: 10,
        borderTop: 'solid 1px #dddddd'
    },
    list: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '8px 0px'
    },
    commonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    basicLable: {
        minWidth: 165,
    },
    profileContainer: {
        minWidth: 240,
    },
    profileImage: {
        width: 120,
        height: 120
    },
    uploadContainer: {
        width: 'calc(100% - 405px)',
    },
    inputContainer: {
        width: 'calc(100% - 165px)'
    }
})