import { takeEvery,  call, fork, put } from 'redux-saga/effects';//takeLatest, take,
import * as types from '../actions/types';
import * as action from '../actions/configureWorkspacesAction';
import { getConfigureWorkspaces, updateConfigureWorkspaces } from '../../crud/configureWorkspaces.curd';


function* getConfigureWorkspacesById({ payload }){
    try{
        const result = yield call(getConfigureWorkspaces, payload);
        yield put({
            type: types.FETCH_ConfigureWorkspaces_Success,
            payload: result.data.result
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getConfigureWorkspacesRequest(){
    yield takeEvery(types.FETCH_ConfigureWorkspaces, getConfigureWorkspacesById);
}

function* updateConfigureWorkspacesAll({ payload }){
    try{
        const result = yield call(updateConfigureWorkspaces, payload);
        yield put({
            type: types.UPDATE_ConfigureWorkspaces_Success,
            payload: result.data.result
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* updateConfigureWorkspacesRequest(){
    yield takeEvery(types.UPDATE_ConfigureWorkspaces, updateConfigureWorkspacesAll);
}

const ConfigureWorkspacesSaga = [
    fork(getConfigureWorkspacesRequest),
    fork(updateConfigureWorkspacesRequest)
];

export default ConfigureWorkspacesSaga;