import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import objectPath from "object-path";
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import Header from "../header/Header";
import SubHeader from "../sub-header/SubHeader";
import HeaderMobile from "../header/HeaderMobile";
import AsideLeft from "../aside/AsideLeft";
import ScrollTop from "../../../app/partials/layout/ScrollTop";
import HTMLClassService from "../HTMLClassService";
import LayoutConfig from "../LayoutConfig";
import CefitLayoutInitializer from "./CefitLayoutInitializer";
import QuickPanel from "../../../app/partials/layout/QuickPanel";
import KtContent from "../KtContent";
import Brand from '../brand/Brand'
import "../assets/Base.scss";
import * as actions from "../../../app/store/actions/modulesAction";

const htmlClassService = new HTMLClassService();

const CefitLayout = ({
  children,
  asideDisplay,
  subheaderDisplay,
  selfLayout,
  layoutConfig,
  contentContainerClasses
}) => {
  htmlClassService.setConfig(layoutConfig);
  const classes = useStyles()
  const dispatch = useDispatch()
  useEffect(() => {
      dispatch(actions.getModuls())
  }, [dispatch])
  // scroll to top after location changes
  // window.scrollTo(0, 0);

  const contentCssClasses = htmlClassService.classes.content.join(" ");
  const acceptCookies = localStorage.getItem('accept-cookies')
  const [cookies, setCookies] = useState(acceptCookies)
  const hendleAcceptcookies = () =>{
    localStorage.setItem('accept-cookies', true)
    setCookies(true)
  }
  //const menuConfig = getMenuItem().then(res => {return res})
  return selfLayout !== "blank" ? (
      <CefitLayoutInitializer
        //menuConfig={menuConfig}
        layoutConfig={LayoutConfig}
        htmlClassService={htmlClassService}
      >
        {/* <!-- begin:: Header Mobile --> */}
        <HeaderMobile />
        {/* <!-- end:: Header Mobile --> */}

        <div className="kt-grid kt-grid--hor kt-grid--root">
          {/* <!-- begin::Body --> */}<div>
              <Brand />
              <Header />
            </div>
            
            {subheaderDisplay && <SubHeader /> }
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            {/* <!-- begin:: Aside Left --> */}
            
            {asideDisplay && (
              <>
                <AsideLeft />
              </>
            )}
            {/* <!-- end:: Aside Left --> */}
            {/* start:: tenant Menu */}
                {/* <TenantMenu /> */}
            {/* ens:: tenant Menu */}
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              {/* <!-- begin:: Header READY --> */}

              {/* <!-- end:: Header --> */}

              {/* <!-- begin:: Content --> */}
              {/* <PerfectScrollbar 
                  options={{ 
                      wheelSpeed: 2, 
                      wheelPropagation: false 
                  }}
              > */}
                  <div
                      id="kt_content"
                      className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
                  >
                    {/* <!-- begin:: Content Head --> */}
                    {/* <!-- end:: Content Head --> */}

                    {/* <!-- begin:: Content Body --> */}
                    {/* TODO: add class to animate  kt-grid--animateContent-finished */}
                    <KtContent>
                      {children}
                    </KtContent>
                    {/*<!-- end:: Content Body -->*/}
                  </div>
              {/* </PerfectScrollbar> */}
              {/* <!-- end:: Content --> */}
              {/* <Footer /> */}
              {cookies === 'false' && <div className="accept-cookies">
                  <div>
                    <p style={{display: 'inline-block'}}>This website uses cookies to ensure you get the best experience on our website. What is cookie? Our Cookie Policy</p>
                    <Button
                        variant='contained' 
                        color="primary"
                        className={classes.btn}
                        onClick={hendleAcceptcookies}
                    >Understand</Button>
                  </div>
              </div>}
            </div>
          </div>
        </div>
        <QuickPanel />
        <ScrollTop />
      </CefitLayoutInitializer>
  ) : (
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <KtContent>
        {children}
      </KtContent>
    </div>
  );
}

const useStyles = makeStyles({
  btn: {
      whiteSpace: 'nowrap',
      padding: 12,
      borderRadius: 0,
      flex: '0 0 170px',
      marginLeft: 10
  }
})

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
    layoutConfig,
    selfLayout: objectPath.get(layoutConfig, "self.layout"),
    asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
    tenantDisplay: objectPath.get(layoutConfig, "tenant.self.display"),
    subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
    desktopHeaderDisplay: objectPath.get(
      layoutConfig,
      "header.self.fixed.desktop"
    ),
    contentContainerClasses: ""
});

export default connect(mapStateToProps)(CefitLayout);
