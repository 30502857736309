import React, { useState, Fragment, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector, shallowEqual,connect } from 'react-redux'
import * as actions from '../../../../store/actions/manageUserAction'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, Button, List, ListItem, ListItemText, ListItemIcon, Collapse } from '@material-ui/core';
import {ExpandLess , ExpandMore} from "@material-ui/icons";
import { Form } from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';   

function ManageUserAdd(props) {
    const loader = document.getElementById('splash-screen');
    const classes = useStyles();
    const dispatch = useDispatch();
    const { itemData, match, emailDomain } = props;
    const [items, setItem] = useState(null);
    const { user } = useSelector(
        (state) => ({user: state.manageUser}),
        shallowEqual
    );
    console.log("email domain: ",emailDomain);
    const [userHasEditTenantPermission, setUserHasEditTenantPermission] = useState(false);
    useEffect(()=>{
        var hasPermission = !!(props.user?.modules?.find(m=>m.moduleName==='Tenants' && m.permissions?.some(p=>p.permissionName === 'Create'||p.permissionName === 'Update')));
        
        setUserHasEditTenantPermission(hasPermission);
    },[]);
    const params = {
        "sortField":"",
        "sortDirection":"",
        "pageSize":10,
        "pageNumber":1,
        "searchText":""
    }
    useEffect(() => {
        setItem(user.userdata)
        setValidated(false);
    }, [user])
    // const item = React.useMemo(() => {
    //     return itemData
    // }, [itemData]);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [validated, setValidated] = useState(false);

    const [addRole, setAddRole] = useState({
        firstName: "",
        description: "",
        systemDefined: true,
    })
    const closeRow = () => {
        props.close();
    }
    const onAddSubmit = () => {
        addRole.firstName && props.onAdd(addRole);
        addRole.firstName && props.close();
    }
    const heandleChangeValue = (e, index) => {
        const {name, checked} = e.target;
        items.roles[index].isSelected = checked
        setItem({
            ...items
        });
    }
    const heandlephipiiChangeValue = (e) => {
        const {name, checked} = e.target;
        //items.phipiiAccess = checked;
        setItem({
            ...items,
            [name]: checked
        });
    }
    // const handleOnBlur = (e) => {
    //     const {value} = e.target;
    //     console.log(e);
    //     console.log(value);
    //     console.log(items['phoneNumber']);
    //     if(value.length !== 10){
    //         setIsPhoneValid(false);
    //         setValidated(true);
    //         setMessage("Invalid Phone Number");
    //         setLevel('danger');
    //         setToastVisible(true);
    //         setTimeout(() => {                
    //             setToastVisible(false);
    //         },5);
    //     }else{
    //         setIsPhoneValid(true);
    //         setValidated(true);           
    //     }
    // };
    const heandleChangeInput = (e) => {
        const {name, value} = e.target;
        setItem({
            ...items,
            [name]: value
        });
        ReactDOM.findDOMNode(document.getElementById('userEditorForm')).checkValidity();
    }
    // const [level, setLevel] = React.useState('')
    // const [message, setMessage] = React.useState('')
    // const [toastVisible, setToastVisible] = React.useState(false)  
    function onSubmitValue(event){
            event.preventDefault();
            event.stopPropagation();
        const form = event.currentTarget;
        console.log('on submit');
        if (form.checkValidity() === false) {
            console.log('checkvalidity === false');
        }else{
            if(items.userID > 0){
                dispatch(actions.updateManageUser(items))
                loader.classList.remove('hidden')
            } else {
                dispatch(actions.addManageUser(items))
                loader.classList.remove('hidden')
                setTimeout(() => {
                    if(match.params.id){
                        const data = {
                            params: params,
                            id: match.params.id
                        }
                        dispatch(actions.getManageUserTenantId(data));
                    } else {
                        dispatch(actions.getManageUser(params))
                    }  
                }, 2000)
            }
        }
        setValidated(true);
    }
    function heandleChangeAccess(e, current,parent, grandParent){
        const {checked} = e.target
        current.isSelected = checked;
        
        // if a child is selected,
        if(checked ){             
            //set parent and grand parents selected
            if(parent){// && !parent.isSelected
                parent.isSelected = checked;
                if(grandParent && !grandParent.isSelected){
                    grandParent.isSelected = checked;
                }
            }
            // set all of its child selected
            if(current.parent) { // current.parent is actually child of current
                current.parent.forEach(p=>{
                    p.isSelected = checked;
                    if(p.child){
                        p.child.forEach(c=>{
                            c.isSelected = checked;
                        })
                    }
                });
            }
            if(current.child){
                current.child.forEach(c=>{
                    c.isSelected = checked;
                })
            }
        }else{
            // if all siblings are unchecked uncheck parent as well
            if(parent){
                if(parent.parent && !parent.parent.some(p=>p.isSelected)){
                    parent.isSelected = checked;
                    
                }
                if(parent.child && !parent.child.some(p=>p.isSelected)){
                    parent.isSelected = checked;
                    if(grandParent.parent && !grandParent.parent.some(p=>p.isSelected)){
                        grandParent.isSelected = checked;
                    }
                }
            }

            // set all of its child deselected
            if(current.parent) { // current.parent is actually child of current
                current.parent.forEach(p=>{
                    p.isSelected = checked;
                    if(p.child){
                        p.child.forEach(c=>{
                            c.isSelected = checked;
                        })
                    }
                });
            }
            if(current.child){
                current.child.forEach(c=>{
                    c.isSelected = checked;
                })
            }            
        }
        setItem({
            ...items
        })
    }
    // const handleToggleOrganizationAccess = (e, value) => {
    //     const currentIndex = checked.indexOf(value);
    //     const newChecked = [...checked];
    
    //     if (currentIndex === -1) {
    //       newChecked.push(value);
    //     } else {
    //       newChecked.splice(currentIndex, 1);
    //     }
    
    //     setChecked(newChecked);
    //   };
    const [orgAccessChildListOpen, setOrgAccessChildListOpen] = useState({});
    const expandCollapseOrgAccListItem = (id)=>{
        setOrgAccessChildListOpen(prev => ({...prev, [id]: !prev[id]}));
    }
    const renderOrganizationsAccessList=(organizationAccess)=>{
        return <List className={classes.list} dense component="div" role="list">
            {organizationAccess.parent && organizationAccess.parent.map((value) => {
                const labelId = `organization-access-${value.organizationID}-label`;
                return (<Fragment key={`oap_${organizationAccess.organizationID}_${value.organizationID}`}>
                    <ListItem  role="listitem" button onClick={()=>expandCollapseOrgAccListItem(value.organizationID)}> 
                     
                    <ListItemIcon>
                        <Checkbox
                        checked={value.isSelected}
                        tabIndex={-1}
                        name="phiAccess" 
                        onChange={(e) => heandleChangeAccess(e, value, organizationAccess)}
                        onClick={(e) => e.stopPropagation()}
                        color="primary"
                        disabled={!userHasEditTenantPermission}
                        inputProps={{ 'aria-labelledby': labelId }}
                        />        
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={value.name} />                    
                    {value.child && value.child.length > 0 ? (orgAccessChildListOpen[value.organizationID] ? <ExpandLess /> : <ExpandMore />):""}
                    </ListItem>
                    <Collapse key={`oap_${value.organizationID}`} in={orgAccessChildListOpen[value.organizationID]} timeout="auto" unmountOnExit>
                        
                        <List key={`oap_${value.organizationID}`} component="div" disablePadding style={{paddingLeft: 20}}>
                        {value.child && value.child.map((oaChild) => {
                            
                            const oaChildlabelId = `organization-access-${value.organizationID}-label`;
                            return (                                
                            <ListItem key={`oac_${oaChild.organizationID}`} button className={classes.nested}>
                                <ListItemIcon>
                                <Checkbox
                                    checked={oaChild.isSelected}
                                    tabIndex={-1}
                                    name="phiAccess"
                                    color="primary"                                                
                                    onChange={(e) => heandleChangeAccess(e, oaChild, value, organizationAccess)}
                                    disabled={!userHasEditTenantPermission}
                                    inputProps={{ 'aria-labelledby': oaChildlabelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={oaChildlabelId} primary={oaChild.name} />
                            </ListItem>
                            )
                        })}
                        </List>
                    </Collapse>
                </Fragment>);
            })}
      </List>};
    

    return (
        <>
            {items && <Form id='userEditorForm' noValidate validated={validated} onSubmit={onSubmitValue}>
                <div className={classes.head}>
                    <h5 className={classes.title}>Profile</h5>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className={classes.commonContainer}>
                            
                            <div className={classes.inputContainer}>
                                <Form.Control
                                    name="firstName"
                                    placeholder="First Name"
                                    label="First Name"
                                    value={items.firstName ? items.firstName : ''}
                                    onChange={(e) => heandleChangeInput(e)}
                                    disabled={!userHasEditTenantPermission}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={classes.commonContainer}>
                            
                            <div className={classes.inputContainer}>
                                <Form.Control
                                    name="lastName"
                                    placeholder="Last Name"
                                    label="Last Name"
                                    value={items.lastName ? items.lastName : ''}
                                    onChange={(e) => heandleChangeInput(e)}
                                    disabled={!userHasEditTenantPermission}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-4">
                        <div className={classes.commonContainer}>
                            <div className={classes.inputContainer}>
                                <Form.Group controlId="phoneNumberValidation">
                                    <Form.Control
                                        name="phoneNumber"
                                        placeholder="Phone Number"
                                        label="Phone Number"
                                        value={items.phoneNumber ? items.phoneNumber : ''}
                                        onChange={(e) => heandleChangeInput(e)}
                                        // onBlur={handleOnBlur}
                                        disabled={!userHasEditTenantPermission}
                                        required
                                        type="text"
                                        pattern="\d{10}"
                                        minLength="10" maxLength="10" />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Phone Number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-4">
                        <div className={classes.commonContainer}>
                            <div className={classes.inputContainer}>
                                    <Form.Group controlId="emailValidation">
                                        <Form.Control
                                            name="email"
                                            placeholder={"email@"+emailDomain}
                                            label="email"
                                            value={items.email ? items.email : '@'+emailDomain}
                                            onChange={(e) => heandleChangeInput(e)}
                                            disabled={!userHasEditTenantPermission}
                                            required
                                            type="text"
                                            pattern={'.+@'+ emailDomain.replace('.','\\.')} />
                                            
                                        <Form.Control.Feedback type="invalid">
                                            Email must ends with {emailDomain}.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {/* {!emailDomain && <Form.Group controlId="emailValidation">
                                        <Form.Control
                                            name="email"
                                            placeholder={"email@"+emailDomain}
                                            label="email"
                                            value={items.email ? items.email : ''}
                                            onChange={(e) => heandleChangeInput(e)}
                                            disabled={!userHasEditTenantPermission}
                                            required
                                            type="text"
                                            pattern={'.+@'+ emailDomain.replace('.','\\.')} />
                                            
                                        <Form.Control.Feedback type="invalid">
                                            Email must ends with {emailDomain}.
                                        </Form.Control.Feedback>
                                        </Form.Group>
                                    } */}
                                

                            </div>
                        </div>
                    </div>
                   
                </div>
                <div className={classes.head}>
                    <h5 className={classes.title}>User Roles</h5>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                    {items && items.roles && items.roles.length > 0 && <FormGroup className={classes.formGroup} row>
                            <> 
                                {items.roles.map((pre, pi) => (
                                    <div key={pi} style={{paddingRight: 15}}>
                                        <FormControlLabel   
                                            className={classes.formLabel}
                                            key={pi}
                                            control={
                                                <Checkbox 
                                                    checked={pre.isSelected} 
                                                    name="isSelected" 
                                                    color="primary"
                                                    onChange={(e) => heandleChangeValue(e, pi)}
                                                    disabled={!userHasEditTenantPermission}
                                                />
                                            }
                                        />
                                        <label className={classes.formlLabelName}>{pre.roleName}</label>
                                    </div>
                                ))}
                            </>
                    </FormGroup>}
                    </div>
                </div>
                <div className={classes.head}>
                    <h5 className={`${classes.title} ${classes.inline}`} >PHI PII Access</h5> 
                    <FormControlLabel
                        className={classes.formLabel}
                        control = {<Checkbox
                                   checked={items.phipiiAccess || false }
                                   name="phipiiAccess"
                                   color="primary"                               
                                   onChange={(e) => heandlephipiiChangeValue(e)}
                                   disabled={!userHasEditTenantPermission}
                                />}
                        />
                </div>
                {/* <div className="row">
                    <div className="col-sm-6">
                        <div className={classes.commonContainer}>
                            
                            <div className={classes.inputContainer}>
                                <Form.Check
                                   type="Checkbox"
                                   label="phi pii Access"
                                   name="Check"
                                   className={classes.formLabel}
                                   checked={items.phipiiAccess}
                                   onChange={(e) => heandlephipiiChangeValue(e)}
                                   disabled={!userHasEditTenantPermission}
                                />
                            </div>
                        </div>
                    </div>
                    </div> */}
                {match.params.id && items.organizationsAccess && items.organizationsAccess.length > 0 &&
                <>
                    <div className={classes.head}>
                        <h5 className={classes.title}>Organisation Access</h5>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                {items.organizationsAccess.map((pre, pi) => (
                                    <div key={`oa_${pre.organizationID}`} style={{minWidth: '33.33336%', marginBottom: 10}}>
                                        
                                        <FormGroup className={classes.formGroup} row>
                                                <>
                                                    <div style={{paddingRight: 15, display: 'block', width: "100%"}}>
                                                        <FormControlLabel   
                                                            className={classes.formLabel}
                                                            control={
                                                                <Checkbox 
                                                                    checked={pre.isSelected} 
                                                                    name="phiAccess" 
                                                                    color="primary"
                                                                   onChange={(e) => heandleChangeAccess(e, pre)}
                                                                    disabled={!userHasEditTenantPermission}
                                                                />
                                                            }
                                                        />
                                                        <label className={classes.formlLabelName}>{pre.name}</label>
                                                    </div>
                                                    <div>{renderOrganizationsAccessList(pre)}</div>
                                                </>
                                        </FormGroup>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>}
                {userHasEditTenantPermission && (<div className="row">
                    <div className="col-sm-12 text-right">
                        <Button
                            variant="contained" 
                            color="primary"
                            type="submit"
                            // onClick={() => onSubmitValue()}
                        >
                            Submit
                        </Button>
                    </div>
                </div>)}
            </Form>}
            {/* <Toast
                                level={level}
                                message={message}
                                visible={toastVisible}
                            /> */}
        </>
    )
 }
const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
        padding: 0
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    },
    title:{
        color:'#0078d4',
        paddingBottom:10,
        paddingTop:10

    },
    inline: {
        display: 'inline-block',
    },
    dropdown: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        "&:hover, &:focus, &:active": {
            backgroundColor: '#ffffff !important',
            border: 'solid 1px #dddddd !important',
            color: '#333333 !important'
        }
    },
    dropdownMenu: {
        width: '100%'
    },
    treeRoot: {
        height: 240,
        flexGrow: 1,
        maxWidth: 400,
    },
    headLabelName: {
        fontWeight: 700
    }
})

const mapStateToProps = store => ({
    user: store.auth.user
  });
  
export default connect(mapStateToProps)(ManageUserAdd);