import axios from 'axios'
const getUrl = 'subscription/TenantConfiguration'
const updateUrl = 'subscription/createtenantconfiguration'
const workspaceUrl = 'workspace/getallworkspace'



export function getTenantConfigAllList() {
    return axios.get(getUrl);
}
export function updateTenantConfiguration(params) {
    return axios.post(updateUrl, params);
}
export function getworkspacereport(params) {
    return axios.post(workspaceUrl, params);
}
