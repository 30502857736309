import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import * as builder from "../ducks/builder";

const KtContent = ({ children, contentContainerClasses }) => {
  const match = useRouteMatch() || {};
  const animationEndClass = "kt-grid--animateContent-finished";
  const [cssClassesState, setCssClassesState] = useState([
    "kt-grid--animateContent",
    "kt-container",
    contentContainerClasses.split(" "),
    "kt-grid__item kt-grid__item--fluid",
    animationEndClass
  ]);

  useEffect(() => {
    // for animation start should toggle 'kt-grid--animateContent-finished' css class
    // TODO: change useMemo
    // let classes1 = 'kt_subheader'; 
    // var myEla = document.getElementById(classes1);
    // var wrapper = document.getElementById('kt_wrapper');
    // if (window.location.href.indexOf('tableData') > 0) {
    //     myEla.setAttribute("style", "display:none");
    //     wrapper.setAttribute('style', "padding-top: 64px")
    // }else {
    //     myEla.setAttribute("style", "display:inherit");
    //     wrapper.setAttribute('style', "padding-top: 100px")
    // }
    const fullClasses = [...cssClassesState];
    const startAnimation = fullClasses.filter(el => el !== animationEndClass);
    setCssClassesState(startAnimation);
    const timeOutId = setTimeout(() => {
      setCssClassesState(fullClasses);
    }, 200);

    return () => {
      clearTimeout(timeOutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.url]);

  return <div className={cssClassesState.join(" ")} style={{padding: 0}}>{children}</div>;
}

const mapStateToProps = store => ({
  contentContainerClasses: builder.selectors.getClasses(store, {
    path: "content_container",
    toString: true
  })
});

export default connect(mapStateToProps)(KtContent);


