import React, { useState, useEffect } from 'react'
import { 
    Paper,
    Button,
} from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import SVG from "react-inlinesvg";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
  PortletHeaderTitle
} from "../../../../partials/content/Portlet";
import * as actions from '../../../../store/actions/configureWorkspacesAction'
import ConfigureWorkspacesPage from './ConfigureWorkspacesPage'


const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export default function ConfigureWorkspacesBase(props){
    const {match, tenant} = props
    const classes = useStyles();
    const [items, setItems] = useState([])
    const dispatch = useDispatch();
    const { configureWorkspaces } = useSelector((state) => ({
        configureWorkspaces: state.configureWorkspaces.data
    }), shallowEqual)
    useEffect(() => {
        setItems(configureWorkspaces)
    }, [configureWorkspaces])
    useEffect(() => {
        if(match.params.id)
        dispatch(actions.getconfigureWorkspaces(match.params.id))
    }, [dispatch])
    return(
        <Paper className={classes.root}>
            <Portlet className={classes.portlet}>
                <PortletHeader
                    title={
                        <PortletHeaderTitle>
                            <SVG src={toAbsoluteUrl('/media/svg/icons/Layout/hourglass_full-24px.svg')} />
                            <span style={{color: '#000000', marginLeft: 10, fontWeight: 700}}>Configure Workspaces</span>
                        </PortletHeaderTitle>
                    }
                    headerClassName={classes.portletHead}
                >
                </PortletHeader>
                <PortletBody style={{position: 'relative', padding: '20px 10px 10px', maxHeight: 'calc( 100vh - 92px )', minHeight: 200, width: '100%'}} data-ktmenu-vertical="1">
                    {items && items.length > 0 && <ConfigureWorkspacesPage match={match} tenant={tenant} items={items} />}
                </PortletBody>
            </Portlet>
        </Paper>
    )
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    portlet: {
        marginBottom: 0
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    }
})