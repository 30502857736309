import React, {useEffect, useState} from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import BasicCustomerInformation from './edit-pages/BasicCustomerInformation';
import Organisation from './organisation'
import FileOperations from './fileOperations'
import Role from '../../home/admin/role'
import CompanyPolicy from '../../home/admin/company-policy'
import ConfigureWorkspaces from './configure-workspaces'
import ManageUser from '../../home/admin/users'
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions/tenantAction";


export default function EditTanentPage(props){
    const dispatch = useDispatch();
    const steps = props.match.params.id ? getSteps() : getCreateSteps();
    const [match, setMatch] = useState(props.match)
    const [item, setItem] = useState(props.item)
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(props.activeStep);
    const [completed, setCompleted] = React.useState(new Set());
    const [skipped, setSkipped] = React.useState(new Set());
    useEffect(() => {
        setItem(props.item)
        if(props.match.url !== match.url){
            setMatch(props.match)
            setActiveStep(props.activeStep)
            setCompleted(new Set())
            setSkipped(new Set())
        }
    }, [props]);
    useEffect(()=>{
        if(activeStep >=0){
            dispatch(actions.setActiveStep(activeStep));
        }
    },[dispatch, activeStep]);
    const handleStep = (step) => {

        setActiveStep(step);
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    // const totalSteps = () => {
    //     return props.match.params.id ? getSteps().length : getCreateSteps().length;
    // };
    
    // const skippedSteps = () => {
    //     return skipped.size;
    // };
    
    // const completedSteps = () => {
    //     return completed.size;
    // };
    
    // const allStepsCompleted = () => {
    //     return completedSteps() === totalSteps() - skippedSteps();
    // };
    
    // const isLastStep = () => {
    //     return activeStep === totalSteps() - 1;
    // };
    // const handleNext = () => {
    //     const newActiveStep =
    //       isLastStep() && !allStepsCompleted()
    //         ? 
    //           steps.findIndex((step, i) => !completed.has(i))
    //         : activeStep + 1;
    
    //     setActiveStep(newActiveStep);
    // };

    // const handleStepAdd = (step) => {
    //     setActiveStep(step);
    // }
    
    
    // function isStepComplete(step) {
    //     return completed.has(step);
    // }
    function getStepCreateContent(stepData, propsData, itemdata) {
        if(itemdata){
            if(itemdata && itemdata.tenantID) {
                if(stepData === 0){
                    stepData = 0
                } else if(stepData === 1){
                    stepData = 1
                } else {
                    stepData = 0
                }
            } else {
                stepData = 0
            }
            switch (stepData) {
                case 0:
                    return <BasicCustomerInformation match={propsData.match} item={itemdata}/>;
                case 1:
                    return <FileOperations match={propsData.match} tenant={itemdata} fileoperations={props.fileoperations} />;
                case 2:
                    return <ConfigureWorkspaces match={propsData.match} tenant={itemdata} />;
                case 3:
                    return <Organisation match={propsData.match} tenant={itemdata} organisation={props.organisation} />;
                case 4:
                    return <Role match={propsData.match} tenant={itemdata} />;
                case 5:
                    return <CompanyPolicy match={propsData.match} tenant={itemdata} />;
                default:
                    return 'Unknown step';
            }
        } else {
            return 'Unknown step';
        }
    }
    return(
        <>
            <div>
                <Stepper alternativeLabel activeStep={activeStep} connector={props.match.params.id ? <ColorlibConnector /> : <ColorlibConnectorAdd />}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        // const buttonProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps} editable={props.match.params.id}>
                                <StepLabel 
                                    {...labelProps}
                                    StepIconComponent={(e) => props.match.params.id ?  ColorlibStepIcon(e) : ColorAddlibStepIcon(e, item)}
                                    onClick={()=>handleStep(index)}
                                >
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </div>
            <div>
                <div className={classes.instructions}>
                    {props.match.params.id ? getStepContent(activeStep, props, item) : getStepCreateContent(activeStep, props, item, match)}
                </div>
            </div>
        </>
    )
}

function getSteps() {
    return [
        'Basic Customer Information', 
        'File Operations', 
        'Configure Workspaces', 
        'Organization Hierarchy', 
        'Manage Roles', 
        'Manage Users', 
        'Company Policy'
    ];
}

function getCreateSteps() {
    return [
        'Basic Customer Information', 
        'File Operations', 
        'Configure Workspaces', 
        'Organization Hierarchy', 
        'Manage Roles', 
        'Manage Users'
    ];
}
function getStepContent(step, props) {
    switch (step) {
        case 0:
            return <BasicCustomerInformation match={props.match} item={props.item}/>;
        case 1:
            return <FileOperations match={props.match} tenant={props.item} fileoperations={props.fileoperations} />;
        case 2:
            return <ConfigureWorkspaces match={props.match} tenant={props.item} />;
        case 3:
            return <Organisation match={props.match} tenant={props.item} organisation={props.organisation} />;
        case 4:
            return <Role match={props.match} tenant={props.item} />;
        case 5:
            return <ManageUser match={props.match} tenant={props.item} />;
        case 6:
            return <CompanyPolicy match={props.match} tenant={props.item} />;
        default:
            return 'Unknown step';
    }
}



function ColorlibStepIcon(props) {
    const classes = useStyles();
    const { active, completed } = props;
    const icons = {
        1: <i className="fas fa-info"></i>,
        2: <i className="fas fa-file-alt"></i>,
        3: <i className="fas fa-chalkboard-teacher"></i>,
        4: <i className="fas fa-sitemap"></i>,
        5: <i className="fas fa-people-carry"></i>,
        6: <i className="fas fa-user-cog"></i>,
        7: <i className="fas fa-balance-scale"></i>
    };
  
    return (
        <div
            className={clsx(classes.containerRoot, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

function ColorAddlibStepIcon(props, item) {
  const classes = useStyles();
  const stepIcon = {
      active: false,
      completed: false
  }
  if(item && item.tenantID) {
      if(props.icon === 1){
          stepIcon.completed = true
          stepIcon.active = false
      } else if(props.icon === 2){
          stepIcon.completed = false
          stepIcon.active = true
      } else {
          stepIcon.completed = false
          stepIcon.active = false
      }
  } else {
    if(props.icon === 1){
        stepIcon.completed = true
        stepIcon.active = false
    } else{
        stepIcon.completed = false
        stepIcon.active = false
    }
  }

  const icons = {
      1: <i className="fas fa-info"></i>,
      2: <i className="fas fa-file-alt"></i>,
      3: <i className="fas fa-chalkboard-teacher"></i>,
      4: <i className="fas fa-sitemap"></i>,
      5: <i className="fas fa-people-carry"></i>,
      6: <i className="fas fa-user-cog"></i>
  };

  return (
    <div
      className={clsx(classes.containerRootAdd, {
          [classes.active]: stepIcon.active,
          [classes.completed]: stepIcon.completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorAddlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
  props: PropTypes.object
};

const useStyles = makeStyles(theme => ({
    root: {
      width: "100%"
    },
    button: {
      marginRight: theme.spacing(1)
    },
    backButton: {
      marginRight: theme.spacing(1)
    },
    // completed: {
    //   display: "inline-block"
    // },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    containerRoot: {
        backgroundColor: '#0078d4',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerRootAdd: {
      backgroundColor: '#dddddd',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
  },
    active: {
        backgroundColor: '#ffffff',
        border: 'solid 2px #0078d4',
        color: '#0078d4'
    },
    completed: {
        backgroundColor: '#ffffff !important',
        border: 'solid 2px #0078d4',
        color: '#0078d4 !important'
    },
}));

const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundColor: '#8dcdff ',
      },
    },
    completed: {
      '& $line': {
        backgroundColor: '#8dcdff',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#0078d4',
      borderRadius: 1,
    },
})(StepConnector);

const ColorlibConnectorAdd = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#8dcdff ',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#dddddd',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#dddddd',
    borderRadius: 1,
  },
})(StepConnector);