import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as actions from '../../../store/actions/cefitAction';
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from '@material-ui/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TablePagination
} from '@material-ui/core';
import { EnhancedTableHead } from '../../../crud/SortGrid';
import { CefitItem } from './cefit-item/CefitItem';
import Toast from '../../../crud/Toast';
import { useCefitUiContext } from "../CefitUiContext";
import { PromptState } from 'msal/lib-commonjs/utils/Constants';

export function CefitTable(props) {
    const dispatch = useDispatch();
    const { currentState, deleteSuccess, cefitSuccess, cefitMassage, error, user } = useSelector(
        (state) => ({
            currentState: state.cefits,
            deleteSuccess: state.cefits.deleteSuccess,
            cefitSuccess: state.cefits.success,
            cefitMassage: state.cefits.message,
            error: state.cefits.error,
            user: state.auth.user
        }),
        shallowEqual
    );
    // const [userHasCreatePermission, setUserHasCreatePermission] = useState(false);
    // const [userHasUpdatePermission, setUserHasUpdatePermission] = useState(false);
    const [userHasDeletePermission, setUserHasDeletePermission] = useState(false);
    useEffect(() => {
        if (user) {
            // console.log(user.vhUser);
            if (user.vhUser) {
                // setUserHasCreatePermission(true);
                // setUserHasUpdatePermission(true);
                setUserHasDeletePermission(true);
            } else {
                var fileMappingModule = user.modules?.find(m => m.moduleName === 'FileMapping');
                if (fileMappingModule) {
                    // var hasCreatePermission = !!fileMappingModule.permissions.some(p => p.permissionName === 'Create');
                    // var hasUpdatePermission = !!fileMappingModule.permissions.some(p => p.permissionName === 'Update');
                    var hasDeletePermission = !!fileMappingModule.permissions.some(p => p.permissionName === 'Delete');
                    // setUserHasCreatePermission(hasCreatePermission);
                    // setUserHasUpdatePermission(hasUpdatePermission);
                    setUserHasDeletePermission(hasDeletePermission);
                } else {
                    // setUserHasCreatePermission(false);
                    // setUserHasUpdatePermission(false);
                    setUserHasDeletePermission(false);
                }
            }
        }
    }, [user]);
    const [params, setParams] = useState({
        "sortField": "fileName",
        "sortDirection": "asc",
        "pageSize": 10,
        "pageNumber": 1,
        "searchText": props.searchText
    });
    useEffect(()=>{
        setParams(prev=>({...prev, searchText: props.searchText}));
    }, [props.searchText]);
    const loader = document.getElementById('splash-screen');
    useEffect(() => {
        loader.classList.remove('hidden')
        dispatch(actions.fetchCefitList(params));
        setTimeout(() => {
            loader.classList.add('hidden')
        }, 2000)
    }, [dispatch, params, loader]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    useEffect(()=>{
        if(rowsPerPage){
            setParams(prev=>({
                ...prev,
                "pageSize": rowsPerPage
            }))
        }
    },[rowsPerPage]);

    const handleChangePage = (event, value) => {
        setAllItemRows([])
        const pageNumber = value + 1;
        setPage(value);
        setParams(prev => ({...prev, pageNumber }));
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setAllItemRows([]);
        const pageSize = event.target.value;
        setParams(prev => ({...prev, pageSize }));
    };
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('fileName');
    useEffect(() => {
        if (orderBy.length > 0) {
            setParams(prev => ({...prev, sortField: orderBy, sortDirection: order }));
            // console.log("handleRequestSort", order, orderBy);
        }
    }, [order, orderBy]);
    const handleRequestSort = (event, property) => {
        if (orderBy === '') {
            setOrder('desc');
            setOrderBy(property);
        } else {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
    };

    const classes = useStyles();
    let [allItemRows, setAllItemRows] = useState([])
    const cefitsUIContext = useCefitUiContext();
    const cefitsUIProps = useMemo(() => {
        return {
            ids: cefitsUIContext.ids,
            setIds: cefitsUIContext.setIds,
            queryParams: cefitsUIContext.queryParams,
            setQueryParams: cefitsUIContext.setQueryParams,
            openEditCefitPage: cefitsUIContext.openEditCefitPage,
            openDeleteCefitDialog: cefitsUIContext.openDeleteCefitDialog,
        };
    }, [cefitsUIContext]);


    const [level, setLevel] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [toastVisible, setToastVisible] = React.useState(false)
    const [count, setCount] = useState(0);
    const [items, setItems] = useState([])

    const { listLoading } = currentState;
    useEffect(() => {
        if (cefitSuccess) {
            setLevel('success')
            setMessage(cefitMassage)
            setToastVisible(cefitSuccess)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.reset())
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 5000)
        } else {
            setLevel('')
            setMessage('')
            setToastVisible(false)
        }
    }, [dispatch, cefitSuccess, cefitMassage])
    useEffect(() => {
        if (error) {
            setLevel('danger')
            setMessage(error)
            setToastVisible(true)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.reset())
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 5000)
        }
    }, [error])
    useEffect(() => {
        if (deleteSuccess) {
            dispatch(actions.fetchCefitList(params));
        }
    }, [deleteSuccess]);
    useEffect(() => {
        dispatch(actions.fetchCefitList(params));
    }, [dispatch]);

    function deleteCefit(item) {
        dispatch(actions.fetchDelete(item.id))
    }
    // const emptyRows = listLoading?.items && rowsPerPage - Math.min(rowsPerPage, listLoading.items.length - page * rowsPerPage);

    listLoading?.items && listLoading.items.forEach((item, i) => {
        const perItemRows = < CefitItem
        openEditCefitPage = { cefitsUIProps.openEditCefitPage }
        key = { i }
        item = { item }
        index = { i }
        onDelete = { deleteCefit }
        userHasDeletePermission = { userHasDeletePermission }
        />;
        allItemRows = allItemRows.concat(perItemRows);
    });
    
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    const [ieBrowser, setIeBrowser] = useState(isIE ? true : false);
    return ( <>
        {/* <PerfectScrollbar options={{ wheelSpeed: 2, wheelPropagation: false }}
                            style={{padding: 0, maxHeight: ieBrowser && 'calc( 100vh - 180px )'}}> */}
        <Table className = { classes.table } >
        <EnhancedTableHead classes = { classes }
            order = { order }
            headCells = { userHasDeletePermission ? headCells : headCellsWithoutDelete }
            orderBy = { orderBy }
            onRequestSort = { handleRequestSort }
            rowCount = { items ? items.length : 0 }
        /> 
        <TableBody >
        {allItemRows}
        {/* {emptyRows > 0 && (
            <TableRow style={{ height: 31 * emptyRows }}>
                <TableCell colSpan={6} />
            </TableRow>
        )} */}
    </TableBody>
</Table>
<TablePagination
    rowsPerPageOptions={[5, 10, 15, 20]}
    component="div"
    count={listLoading?.totalItems || 100}
    rowsPerPage={rowsPerPage}
    page={page}
    onChangePage={handleChangePage}
    onChangeRowsPerPage={handleChangeRowsPerPage}
/>
<Toast
    level={level}
    message={message}
    visible={toastVisible}
/>    
{/* </PerfectScrollbar>     */}
</>)
}

const headCells = [
    { id: 'inner_actions', numeric: true, disablePadding: false, align: 'left', label: 'ID' },
    { id: 'tenantID', numeric: false, disablePadding: false, align: 'left', label: 'Tenant ID' },
    { id: 'tenantName', numeric: false, disablePadding: false, align: 'left', label: 'Tenant' },
    { id: 'FileFormat', numeric: false, disablePadding: false, align: 'left', label: 'File Format' },
    { id: 'FileDataType', numeric: false, disablePadding: false, align: 'left', label: 'File Data Type' },
    { id: 'FileName', numeric: false, disablePadding: false, align: 'left', label: 'File Name' },
    { id: 'StagingTableName', numeric: false, disablePadding: false, align: 'left', label: 'Staging Table Name' },
    { id: 'StoredProcedureName', numeric: false, disablePadding: false, align: 'left', label: 'Stored Procedure Name' },
    { id: 'SubmittedOn', numeric: false, disablePadding: false, align: 'left', label: 'Submitted On' },
    { id: 'SubmittedBy', numeric: false, disablePadding: false, align: 'left', label: 'Submitted By' },
    { id: 'DeploymentStatus', numeric: false, disablePadding: false, align: 'left', label: 'Configuration Status' },
    { id: 'actions', numeric: false, disablePadding: false, align: 'right', label: 'View' },
    { id: 'actions2', numeric: false, disablePadding: false, align: 'right', label: 'Decline' },
];

const headCellsWithoutDelete = [
    { id: 'inner_actions', numeric: true, disablePadding: false, align: 'left', label: 'ID' },
    { id: 'tenantID', numeric: false, disablePadding: false, align: 'left', label: 'Tenant ID' },
    { id: 'tenantName', numeric: false, disablePadding: false, align: 'left', label: 'Tenant' },
    { id: 'FileFormat', numeric: false, disablePadding: false, align: 'left', label: 'File Format' },
    { id: 'FileDataType', numeric: false, disablePadding: false, align: 'left', label: 'File Data Type' },
    { id: 'FileName', numeric: false, disablePadding: false, align: 'left', label: 'File Name' },
    { id: 'StagingTableName', numeric: false, disablePadding: false, align: 'left', label: 'Staging Table Name' },
    { id: 'StoredProcedureName', numeric: false, disablePadding: false, align: 'left', label: 'Stored Procedure Name' },
    { id: 'SubmittedOn', numeric: false, disablePadding: false, align: 'left', label: 'Submitted On' },
    { id: 'SubmittedBy', numeric: false, disablePadding: false, align: 'left', label: 'Submitted By' },
    { id: 'DeploymentStatus', numeric: false, disablePadding: false, align: 'left', label: 'Configuration Status' },
    { id: 'actions', numeric: false, disablePadding: false, align: 'right', label: 'View' },
];

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',

    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        width: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    }
})