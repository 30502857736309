import * as types from '../actions/types';

const INITIAL_STATE = {
    listLoading: [],
    actionsLoading: false,
    cefitForEdit: undefined,
    updateResult: undefined,
    tableName: [],
    columnName: [],
    updateExcel: undefined,
    success: false,
    deleteSuccess: false,
    message: '',
    error: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.FETCH_CEFIT_LIST_SUCCESS:
            return {
                ...state,
                listLoading: action.payload
            }
        case types.FETCH_CEFIT_BY_ID_SUCCESS:
            const editData = action.payload;
            editData['file'] = ''
            return {
                ...state,
                cefitForEdit: editData
            }
        case types.FETCH_CEFIT_TABLE_NAME_SUCCESS:
            return {
                ...state,
                tableName: action.payload
            }
        case types.FETCH_CEFIT_COLUMN_NAME_SUCCESS:
            return {
                ...state,
                columnName: action.payload
            }
        case types.FETCH_CEFIT_UPDATE_EXCEL_SUCCESS:
            return {
                ...state,
                updateExcel: action.payload,
                success: true,
                message: 'File has been successfully uploaded..'
            }
        case types.FETCH_CEFIT_UPDATE_SUCCESS:
            return {
                ...state,
                updateResult: action.payload,
                success: true,
                message: 'CEFIT Config has been successfully updated..'
            }
        case types.FETCH_CEFIT_CREATE_SUCCESS:
            return {
                ...state,
                updateResult: action.payload,
                success: true,
                message: 'Cefit has been successfully added..'
            }
        case types.FETCH_CEFIT_TEST_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Test Mapping has been successfully tested..'
            }
        case types.FETCH_CEFIT_APPROVE_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Cefit has been successfully approved..'
            }
        case types.FETCH_CEFIT_DELETE_SUCCESS:
            return {
                ...state,
                success: true,
                deleteSuccess: true,
                message: 'Cefit has been successfully deleted..'
            }
        case types.RESET_STORE:
            return {
                ...state,
                success: false,
                deleteSuccess: false,
                message: '',
                error: null
            }
        case types.RESET_UPDATE_VALUE:
            return {
                ...state,
                cefitForEdit: undefined,
                updateExcel: undefined
            }
        case types.CEFIT_ERROR:
            return {
                ...state,
                error: action.payload.ErrorMessage || "Please Try again..."
            }
        default:
            return state;
    }
};