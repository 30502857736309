import * as types from '../actions/types';

const INITIAL_STATE = {
    data: [],
    item: {},
    countries: [],
    id: undefined,
    success: false,
    message: '',
    error: null
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.FETCH_ORGANISATION_SUCCESS:
            return {
                ...state,
                data: action.payload,
            }
        case types.ADD_ORGANISATION_SUCCESS: 
            return {
                ...state,
                success: true,
                message: 'Organisation has been successfully added..',
            }
        case types.UPDATE_ORGANISATION_SUCCESS: 
            return {
                ...state,
                success: true,
                message: 'Organisation has been successfully updated..',
            }
        case types.DELETE_ORGANIZATION_SUCCESS: 
            return {
                ...state,
                success: true,
                message: 'Organization has been successfully deleted..'
            }     
        case types.ORGANISATION_importHierarchyFile_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Organization Hierarchi imported successfully!'
            }       
        case types.ORGANISATION_Error: 
            return {
                ...state,
                error: 'Please try again...'
            }
        case types.RESET_STORE: 
            return {
                ...state,
                success: false,
                message: '',
                error: null
            }
        default:
            return state;
    }
};