import axios from "axios";
import { toAbsoluteUrl } from "../../_metronic/utils/utils";
const url = 'Modules/getAllModules';

export function getMenuItem () {//https://app-security-dev.azurewebsites.net/api/
    return axios.get(`${process.env.REACT_APP_SECURITY_API}${url}`).then(res => {
        const menuitem = res.data.result
        return menuitem;
    });
}

const menu = {
    header: {
        self: {
            skin: "light"
        },
        items: []
    },
    aside: {
        self: {},
        items: []
    }
};

export default menu;