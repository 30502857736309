import { takeEvery, call, fork, put } from 'redux-saga/effects';// takeLatest, take,
import * as types from '../actions/types';
import * as action from '../actions/permissionAction';
import { 
    getPermissionsList,
    savePermissions,
    addPermissions
} from '../../crud/permission.curd'
function* getPermissions(){
    try{
        const result = yield call(getPermissionsList);
        yield put({
            type: types.FETCH_PERMISSION_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getPermissionsRequest(){
    yield takeEvery(types.FETCH_PERMISSION, getPermissions);
}

function* savePermissionsData({payload}){
    try{
        const result = yield call(savePermissions, payload);
        yield put({
            type: types.SAVE_PERMISSION_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* savePermissionsRequest(){
    yield takeEvery(types.SAVE_PERMISSION, savePermissionsData);
}

function* addPermissionsData({payload}){
    try{
        const result = yield call(addPermissions, payload);
        yield put({
            type: types.ADD_PERMISSION_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* addPermissionsRequest(){
    yield takeEvery(types.ADD_PERMISSION, addPermissionsData);
}

const permissionSaga = [
    fork(getPermissionsRequest),
    fork(savePermissionsRequest),
    fork(addPermissionsRequest)
];

export default permissionSaga;