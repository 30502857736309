import React, { useState, useEffect } from 'react';
import {
    Paper,
    Button,
} from '@material-ui/core';
import { useDispatch , connect} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
  PortletHeaderTitle
} from "../../../../partials/content/Portlet";
import RoleInitializer from './RoleInitializer'
import SVG from "react-inlinesvg";
import RoleList from './Role-list/RoleList'
import * as actions from '../../../../store/actions/roleAction'

const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;


function RoleBase({ match , user}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [openAddRow, setOpenAddRow] = useState(false)
    const openAdd = () => {
        if(match.params.id){
            dispatch(actions.getRoleRequestByTenantId(match.params.id))
            return
        }
        dispatch(actions.addRoleCreateRequest())
    }

    const [userHasEditTenantPermission, setUserHasEditTenantPermission] = useState(false);
    useEffect(()=>{
        var hasPermission = !!user?.modules?.find(m=>m.moduleName==='Tenants' && m.permissions?.some(p=>p.permissionName === 'Create'||p.permissionName === 'Update'));
        setUserHasEditTenantPermission(hasPermission);
    },[]);
    const closeAdd = () => {
    }
    return(
        <RoleInitializer >
            <Paper className={classes.root}>
                <Portlet className={classes.portlet}>
                    <PortletHeader
                        title={
                            <PortletHeaderTitle>
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Layout/hourglass_full-24px.svg')} />
                                <span style={{color: '#000000', marginLeft: 10, fontWeight: 700}}>Role</span>
                            </PortletHeaderTitle>
                        }
                        toolbar={
                            <PortletHeaderToolbar>
                                {userHasEditTenantPermission && (<Button variant="contained" color="primary" className={classes.button} onClick={openAdd}>
                                + Add New Role
                                </Button>)}
                            </PortletHeaderToolbar>
                        }
                        headerClassName={classes.portletHead}
                    />
                </Portlet>
                <PortletBody 
                    style={{
                        position: 'relative', 
                        padding: '0px 10px 10px', 
                        maxHeight: 'calc( 100vh - 92px )', 
                        minHeight: 200, width: '100%'
                    }} 
                    data-ktmenu-vertical="1"
                >
                    <RoleList 
                        closeAdd={closeAdd} 
                        openAddRow={openAddRow}
                        match={match ? match : undefined}
                    />                  
                </PortletBody>
            </Paper>
        </RoleInitializer>
    )
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        width: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    }
})

const mapStateToProps = store => ({
    user: store.auth.user
  });
  
export default connect(mapStateToProps)(RoleBase);