import * as types from './types';

const getAllRoles = (data) => ({
    type: types.FETCH_ALL_Role,
    payload: data,
})

const fetchRoleSuccess = ({items}) => ({
    type: types.FETCH_Role_SUCCESS,
    payload: { 
        items
    }
})

const getAllTenantRoles = (data) => ({
    type: types.FETCH_ALL_Role_tenant,
    payload: data,
})

const fetchRoleTenantSuccess = ({items}) => ({
    type: types.FETCH_Role_Tenant_SUCCESS,
    payload: { 
        items
    }
})

const updateRoleRequest = (data) => ({
    type: types.UPDATE_Role_REQUEST,
    payload: data,
});

const updateRoleSuccess=({items})=>({
    type: types.UPDATE_Role_SUCCESS,
    payload: { 
        items
    }
})

const addRoleCreateRequest = () => ({
    type: types.ADD_Role_create_REQUEST
});

const addRoleRequest = (data) => ({
    type: types.ADD_Role_REQUEST,
    payload: data,
});

const addRoleSuccess=({additem})=>({
    type: types.ADD_Role_SUCCESS,
    payload: { 
        additem
    }

})

const deleteRoleRequest=(data)=>({
    type: types.DELETE_Role_Request,
    payload:data
    
})

const deleteRoleSuccess = ({ items }) => ({
    type: types.DELETE_Role_SUCCESS,
    payload: {
        items
    }
})

const getRoleRequestById = (data) => ({
    type: types.FETCH_Role_BY_ID_REQUEST,
    payload: data,
});

const getRoleRequestByIdSuccess = ({additem}) => ({
    type: types.FETCH_Role_BY_ID_SUCCESS,
    payload: { 
        additem
    }

})

const getRoleRequestByTenantId = (data) => ({
    type: types.FETCH_Role_BY_tenantID_REQUEST,
    payload: data,
});

const getRoleRequestByTenantIdSuccess = ({items}) => ({
    type: types.FETCH_Role_BY_tenantID_SUCCESS,
    payload: { 
        items
    }

});

const error = ({error}) => ({
    type: types.Role_Error,
    payload: error
});

const reset = () => ({
    type: types.RESET_STORE
});

export {
    getAllRoles,
    fetchRoleSuccess,
    updateRoleRequest,
    updateRoleSuccess,
    addRoleCreateRequest,
    addRoleRequest,
    addRoleSuccess,
    deleteRoleRequest,
    deleteRoleSuccess,
    getRoleRequestById,
    getRoleRequestByIdSuccess,
    getRoleRequestByTenantId,
    getRoleRequestByTenantIdSuccess,
    getAllTenantRoles,
    fetchRoleTenantSuccess,
    error,
    reset
}