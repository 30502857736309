import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Table, Button, Form } from "react-bootstrap";
import { Select, MenuItem } from '@material-ui/core'
import { useFieldArray } from "react-hook-form";

export function CustomTableItem({
    keys,
    tableName,
    onSelectChangeTableName,
    control, 
    register, 
    errors,
    reset,
    addNewRow,
    removeRow,
    setValue,
    getValues
}) {
    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: "test"
    });
    const {user} = useSelector((state)=>({
        user: state.auth.user
    }), shallowEqual);
    //const [userHasCreatePermission, setUserHasCreatePermission] = useState(false);
    const [userHasUpdatePermission, setUserHasUpdatePermission] = useState(false);
    // const [userHasDeletePermission, setUserHasDeletePermission] = useState(false);
    useEffect(()=>{
      if(user){
          console.log(user.vhUser);
          if(user.vhUser){
            //   setUserHasCreatePermission(true);  
              setUserHasUpdatePermission(true);  
            //   setUserHasDeletePermission(true);
          }else{
              var fileMappingModule = user.modules?.find(m=>m.moduleName==='FileMapping');
              if(fileMappingModule){
                  var hasCreatePermission = !!fileMappingModule.permissions.some(p=>p.permissionName === 'Create');
                  var hasUpdatePermission = !!fileMappingModule.permissions.some(p=>p.permissionName === 'Update');
                //   var hasDeletePermission = !!fileMappingModule.permissions.some(p=>p.permissionName === 'Delete');
                //   setUserHasCreatePermission(hasCreatePermission);  
                  setUserHasUpdatePermission(hasCreatePermission || hasUpdatePermission);  
                //   setUserHasDeletePermission(hasDeletePermission);  
                  console.log(hasCreatePermission,hasUpdatePermission);
              }else{            
                //   setUserHasCreatePermission(false);  
                  setUserHasUpdatePermission(false);  
                //   setUserHasDeletePermission(false);  
              }             
          }
      }
    },[user]);     
    function getColumn(item){
        const findColumn = tableName && tableName.find(column => column.name === item.excTabName)
        let columnOption = []
        findColumn && findColumn.columns.map((col, i) => {
            const createCol = [
                <option key={`Column-${i}`} value={col}>{col}</option>
            ]
            columnOption = columnOption.concat(createCol)
        })
        return columnOption
    }
    function prependCol(i){
        const data = getValues()
        addNewRow(data && data.test, i)
    }

    function removeRowItem(index){
        const data = getValues()
        removeRow(data && data.test, index)
    }
    return(
        <>
            {control && <>
                {fields.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <select disabled={!userHasUpdatePermission}
                                    name={`test[${index}].excName`}
                                    ref={register({ required: 'this field is required...' })}
                                    className={"form-control" + (errors && errors.test && errors.test[index] && errors.test[index].excName && errors.test[index].excName.message ? ' is-invalid' : ' is-valid')}
                                    defaultValue={item.excName}
                                >
                                    <option value="">Please Select Field Name...</option>
                                    {keys && keys.map((keyitem) => (
                                        <option key={`Field-${keyitem}-${index}`} value={keyitem}>
                                            {keyitem}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <select disabled={!userHasUpdatePermission}
                                    name={`test[${index}].excTabName`}
                                    ref={register({ required: 'this field is required...' })}
                                    className={"form-control" + (errors && errors.test && errors.test[index] && errors.test[index].excTabName && errors.test[index].excTabName.message ? ' is-invalid' : ' is-valid')}
                                    defaultValue={item.excTabName}
                                    onChange={(e) => onSelectChangeTableName(e, index)}
                                >
                                    <option value="">Please Select Table Name...</option>
                                    {tableName && tableName.map((tableitem, i) => (
                                        <option 
                                            key={`table${i}${index}`} 
                                            value={tableitem.name}
                                        >
                                            {tableitem.name}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <select disabled={!userHasUpdatePermission}
                                    id={`select-clumn-${index}`}
                                    name={`test[${index}].fieldName`}
                                    ref={register({ required: 'this field is required...' })}
                                    className={"form-control" + (errors && errors.test && errors.test[index] && errors.test[index].fieldName && errors.test[index].fieldName.message ? ' is-invalid' : ' is-valid')}
                                    defaultValue={item.fieldName}
                                    //onChange={(e) => selectRuleValue(e, index)}
                                >
                                    <option value="">Please Select Column Name...</option>
                                    {item.excTabName && tableName && tableName.length > 0 && getColumn(item)}
                                </select>
                            </td>
                            <td>
                                <Form.Control  disabled={!userHasUpdatePermission}
                                    name={`test[${index}].excRulesName`}
                                    ref={register()}
                                    type="text" 
                                    defaultValue={item.excRulesName} 
                                    placeholder="Rules" 
                                />
                            </td>
                            <td>{userHasUpdatePermission && 
                                (<><Button 
                                    variant="primary" 
                                    onClick={() => prependCol(index)}
                                >+</Button>
                                {' '}
                                <Button 
                                    disabled={fields.length == 1 ? true : false}
                                    variant="danger"
                                    onClick={() => removeRowItem(index)}
                                >-</Button></>)
                            }</td>
                        </tr>
                    );
                })}
            </>}
        </>
        
    )
}