import React, { useState } from 'react';//, Fragment
import {
    TableCell,
    TableRow,
    Checkbox
} from '@material-ui/core';
    // IconButton,
    // TextField,
import { makeStyles } from '@material-ui/core/styles';

export default function ConfigureWorkspacesItem(props) {
    const { items, index, data, col, setworkspaceConfiguration} = props
    const [item, setItem] = useState(props.item)
    React.useEffect(() => {
        setItem(props.item)
    }, [props.item])
    const [parentData, setParentData] = useState(data);
    const classes = useStyles();
    const [editGlobalConfig, setEditGlobalConfig] = useState({
        showlogo: "",
        allowAccount:""
    })
    const heandleChange = (e, type) => {
        setEditGlobalConfig({
            ...editGlobalConfig,
            [type]: e.target.value
        })
    }
    const updateSubmit = () => {
        data[index] = editGlobalConfig;
        props.onSave(editGlobalConfig);
        
    }
    function onChangeDynamicValue(e){
        const {name, checked} = e.target
        items[index][name] = checked === true ? 1 : 0;
        setItem({...item, [name]: checked === true ? 1 : 0})
        setworkspaceConfiguration(items)
    }
    return (
        <TableRow 
            className ='row-color' 
            key={"row-data-" +index}
        >
            <TableCell className = { classes.cell } >
                {item.workspaceName}
            </TableCell>
            <TableCell className = { classes.cell } >
                {item.reportName}
            </TableCell>
            <TableCell className = { classes.cell } >
                <Checkbox 
                     id={`standard-basic+${index}`}
                     color="primary"
                     checked={item.isSelected === 1 ? true : false}
                     name="isSelected"
                     onChange={(e) => onChangeDynamicValue(e)}
                />
            </TableCell>
        </TableRow>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
        padding: 0
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        '&:first-child': {
            borderRight: 'solid 1px #ddd',
        }
    },
    cellP0: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        padding: 0,
        borderRight: 'solid 1px #ddd',
    },
    cellDiv: {
        padding: 4,
        borderBottom: 'solid 1px #ddd',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        minHeight: 34,
        '&:last-child': {
            borderBottom: 'solid 1px transparent',
        }
    },
    cellDivcl: {
        padding: 4,
        borderBottom: 'solid 1px #ddd',
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 34,
        '&:last-child': {
            borderBottom: 'solid 1px transparent',
        }
    },
    cellBtn: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    }
}))