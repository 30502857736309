import * as types from '../actions/types';


const INITIAL_STATE={
    data: [],
    fileData: undefined,
    autoData: [],
    basedData: [],
    customer: undefined,
    stageData: undefined,
    id: undefined,
    success: ''
}

export default (state=INITIAL_STATE, action) => {
    switch(action.type)
    {
        /****SnapShot****/
        case types.FETCH_CONFIG_SUCCESS:
            const configData = JSON.parse(action.payload.items.configString);
            return {
                data: configData.configurations,
                id: action.payload.items.ID,
            }
        case types.ADD_CONFIG_SUCCESS:
            const configData2 = JSON.parse(action.payload.items.items);
            const configList1 = JSON.parse(configData2.configString);
            return {
                ...state,
                data: configList1.configurations,
                id: configData2.id,
                success: action.payload.items.success
            }
        case types.UPDATE_CONFIG_SUCCESS:
            const configData1 = JSON.parse(action.payload.items.items);
            const configList = JSON.parse(configData1.configString);
            return {
                ...state,
                data: configList.configurations,
                id: configData1.id,
                success: action.payload.items.success
            }
        case types.DELETE_CONFIG_SUCCESS:
            const configData6 = JSON.parse(action.payload.items.items);
            const configList7 = JSON.parse(configData6.configString);
            return {
                ...state,
                data: configList7.configurations,
                id: configData6.id,
                success: action.payload.items.success
            }    
        case types.CONFIG_ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        /****File Operations****/
        case types.FETCH_FILE_OPERATIONS_SUCCESS:
            const fileData = JSON.parse(action.payload.items.configString);
            return {
                fileData: fileData,
                id: action.payload.items.ID,
            }
        case types.CREATE_FILE_OPERATIONS_SUCCESS:
            const fileData2 = JSON.parse(action.payload.items.items);
            const fileData1 = JSON.parse(fileData2.configString);
            return {
                ...state,
                fileData: fileData1.fileData,
                id: fileData2.id,
                success: action.payload.items.success
            }
        case types.UPDATE_FILE_OPERATIONS_SUCCESS:
            const fileData4 = JSON.parse(action.payload.items.items);
            const fileData3 = JSON.parse(fileData4.configString);
            return {
                ...state,
                fileData: fileData3.fileData,
                id: fileData4.id,
                success: action.payload.items.success
            }
        case types.DELETE_FILE_OPERATIONS_SUCCESS:
            const fileData6 = JSON.parse(action.payload.items.items);
            const fileData5 = JSON.parse(fileData6.configString);
            return {
                ...state,
                fileData: fileData5.fileData,
                id: fileData6.id,
                success: action.payload.items.success
            }
        /**** Automated Cleansing ****/
        case types.FETCH_AUTO_CLEANSING_SUCCESS:
            const aData = JSON.parse(action.payload.items.configString);
            return {
                autoData: aData.configSet,
                id: action.payload.items.ID,
            }
        /**** Automated Cleansing ****/
        case types.FETCH_BASED_CLEANSING_SUCCESS:
            const basedData = JSON.parse(action.payload.items.configString);
            return {
                basedData: basedData.rulesSet,
                id: action.payload.items.ID,
            }
        /**** Automated Cleansing ****/
        case types.FETCH_CUSTOMERS_SUCCESS:
            const customerData = JSON.parse(action.payload.items.configString);
            return {
                customer: customerData,
                id: action.payload.items.ID,
            }
        case types.FETCH_STAGE_SUCCESS:
            const stageList =JSON.parse(action.payload.items.configString);
            return {
                stageData: stageList,
                id: action.payload.items.id
            }
        default:
        return state;
    }  
};