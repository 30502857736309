import axios from 'axios'
const getUrl = 'workspace/reports'
const updateUrl = 'report/updatereport'
const getByModuleIdUrl = 'report/getReportByModuleId'
const getByIdUrl = 'report/getReport'
const reloadFromPowerBiUrl = 'workspace/reloadReportsFromPowerBi'

export function getReport(params) {
    return axios.post(getUrl, params);
};

export function updateReport(params) {
    return axios.post(updateUrl, params);
}

export function getReportByModuleId(id) {
    return axios.get(`${getByModuleIdUrl}/${id}`);
}

export function getReportById(id) {
    return axios.get(`${getByIdUrl}/${id}`);
}

export function getReportFromPowerBi(params) {
    return axios.post(reloadFromPowerBiUrl, params);
};