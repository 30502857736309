import React, {useState, useEffect} from 'react'
import { connect} from 'react-redux'
import {
    TableRow,
    TableCell,
    IconButton
} from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import CreateIcon from '@material-ui/icons/Create'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/core/styles';

function CompanyPolicyItem({
    item, user
}){
    const history = useHistory()
    const classes = useStyles()
    const onOpenEditPage = (item) => {
        history.push({pathname: `${history.location.pathname}/${item.companyPolicyID}`, state: item})
    }
    const [userHasEditTenantPermission, setUserHasEditTenantPermission] = useState(false);
    useEffect(()=>{
        var hasPermission = !!user?.modules?.find(m=>m.moduleName==='Tenants' && m.permissions?.some(p=>p.permissionName === 'Create'||p.permissionName === 'Update'));
        setUserHasEditTenantPermission(hasPermission);
    },[]);
    return(
        <TableRow>
            <TableCell className={classes.cell}>{item.policyContent}</TableCell>
            <TableCell className={classes.cell}>{item.createdBy}</TableCell>
            <TableCell className={classes.cell}>{item.createdOn}</TableCell>
            <TableCell className={classes.cell}>{item.published ? 'true' : 'false'}</TableCell>
            <TableCell 
                className={classes.cell}
                align="right"
            >
                {userHasEditTenantPermission && (<IconButton
                    color="primary"
                    className={classes.button}
                    component="span"
                    onClick={() => onOpenEditPage(item)}
                >
                    <CreateIcon color="primary" />
                </IconButton>)}{userHasEditTenantPermission && (
                <IconButton
                    color="primary"
                    className={classes.button}
                    component="span"
                >
                    <FileCopyIcon color="primary" />
                </IconButton>)}
            </TableCell>
        </TableRow>
    )
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
        padding: 0
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    }
})
const mapStateToProps = store => ({
    user: store.auth.user
  });
  
export default connect(mapStateToProps)(CompanyPolicyItem);