import React, { Component } from 'react';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { CircularProgress } from '@material-ui/core';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { authProvider } from './authProvider';

 
class AzureLogin extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: {}
        }
    }

    // componentDidMount() {
    //     // setTimeout(()=> {
    //     //     this.setState( { user:  this.props.user })
    //     // }, 5000);
    // }

    logOut(logout){
        setTimeout(()=> {
            this.setState( { user:  this.props.user })
            !this.state.user && logout();
            !this.state.user && alert('Access Denied : Your credentials do not match our records. Please try again with valid Vegalytics credentials!');
            return !this.state.user ? <Redirect to="/logout" /> : '';
        }, 5000);
    }
    
    render(){
        const loginData = this.props.login;
        const { user } = this.state

        return(
            <AzureAD provider={authProvider} forceLogin={false}>
                {
                    ({login, logout, authenticationState, error, accountInfo}) => {
                        const isInProgress = authenticationState === AuthenticationState.InProgress;
                        const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
                        const isUnauthenticated = authenticationState === AuthenticationState.Unauthenticated;
                        //authenticationState && login(accountInfo);

                        if (isAuthenticated) {
                            loginData(accountInfo);
                            return(
                            <div className="d-flex justify-content-center" style={{width: '100%'}}>
                                {this.logOut(logout)}
                                <CircularProgress color="secondary" />
                            </div>)
                        } else if (isUnauthenticated || isInProgress) {
                            return (
                                <button 
                                    className="btn btn-primary btn-elevate kt-login__btn-primary" 
                                    style={{width: '100%'}} 
                                    onClick={login}
                                >Login with Azure</button>
                            );
                        }
                    }
                }
            </AzureAD>
        )
    }
}
const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default injectIntl(
    connect(
      mapStateToProps,
      auth.actions
    )(AzureLogin)
);