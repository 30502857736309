import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import { TextField } from "@material-ui/core";
import { resetPassword , CheckResetPasswordLinkExpired} from "../../crud/auth.crud";
import Login from "./Login";

const BasicSignUpSchema = Yup.object().shape({
  password: Yup.string()
    .required("This field is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/,
      "Must Contain 12 Characters, One Uppercase, One Lowercase and one special case Character"
    ),
  confirmPassword: Yup.string().when("password", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    )
  })
});
export default function ResetPassword() {
  let { code } = useParams();
  const [signupValue, setsignUpData] = useState({
    token: code,
    password: "",
    confirmPassword: ""
  })
  const params = {
    "token": code
  }

  const [userConfirmation, setuserConfirmation] = useState(400);

  const [isLinkValid, setIsLinkValid] = useState(null);

  useEffect(()=>{
    
    // set screen busy
    // check if signupLink is valid
    CheckResetPasswordLinkExpired({token: signupValue.token})
      .then(resp=>{
        setIsLinkValid(resp.status === 200);
      })
      .catch(error=>{
        setIsLinkValid(false);
      })
    // set screen shown
  },[])

  function onChangevalue(e) {
    const { name, value } = e.target
    setsignUpData({ ...signupValue, [name]: value });

  }


  const form = (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            <FormattedMessage id="RESET PASSWORD" />
          </h3>
        </div>

        {userConfirmation == 400 ? <Formik
          enableReinitialize
          initialValues={signupValue}
          validationSchema={BasicSignUpSchema}
          onSubmit={(values, { setSubmitting }) => {
            resetPassword(values)
              .then((res) => {
                setuserConfirmation(res.statusCode)
              })
              .catch(() => {
                setSubmitting(false);
                return (
                    <Login />
                )
              });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <div className="form-group mb-0">
                <TextField
                  type="password"
                  margin="normal"
                  label="Password"
                  className="kt-width-full"
                  name="password"
                  onBlur={handleBlur}
                  onChange={onChangevalue}
                  value={signupValue.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </div>

              <div className="form-group">
                <TextField
                  type="password"
                  margin="normal"
                  label="Confirm Password"
                  className="kt-width-full"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={onChangevalue}
                  value={signupValue.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                />
              </div>

              <div className="kt-login__actions">

                <button type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary btn-elevate kt-login__btn-primary"
                >
                  Reset
                </button>
              </div>
            </form>
          )}
        </Formik> : <Login />}
      </div>
    </div>
  );

if(isLinkValid)
  return form;
else if(isLinkValid == null){
  return (<div className="kt-login__body">
  <div className="kt-login__form">
    <div className="kt-login__title">
      <h3>Validating reset password link....</h3>
      </div></div></div>);
}else{
  return (<div className="kt-login__body">
  <div className="kt-login__form">
    <div className="kt-login__title">
      <h3>Your reset password link has expired!</h3>
      </div></div></div>);
}

}

