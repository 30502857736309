import React from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";

const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export default class MenuItemText extends React.Component {
  render() {
    const { item, parentItem } = this.props;
    const { user } = this.props
    return (
      <>
        {item.icon && <i className={`kt-menu__link-icon ${item.icon}`} />}
        {item.svg && <span className='kt-menu__link-svg'><SVG src={toAbsoluteUrl("/media/svg/icons/Layout/" + item.svg)} /></span>}
        {parentItem && parentItem.bullet === "dot" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
            <span />
          </i>
        )}

        {parentItem && parentItem.bullet === "line" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
            <span />
          </i>
        )}

        <span className="kt-menu__link-text" style={{ whiteSpace: "nowrap" }}>
          {!item.translate ? (
            item.displayModuleName
          ) : (
            <FormattedMessage id={item.translate} defaultMessage={item.displayModuleName} />
          )}
        </span>

        {item.badge && (
          <span className="kt-menu__link-badge">
            <span className={`kt-badge ${item.badge.type}`}>
              {item.badge.value}
            </span>
          </span>
        )}

        {user && !user.vhUser && item.displayModuleName !== 'Admin' && item.subModule.length > 0 && <i className="kt-menu__ver-arrow la la-angle-right" />}
        {user && user.vhUser && item.subModule.length > 0 && <i className="kt-menu__ver-arrow la la-angle-right" />}
      </>
    );
  }
}
