import { takeEvery, takeLatest, take, call, fork, put } from 'redux-saga/effects';
import * as types from '../actions/types';
import { } from "../../crud/config.crud";
import * as action from '../actions/configAction';
import {
    getReport,
    updateReport,
    getReportByModuleId,
    getReportById,
    getReportFromPowerBi
} from '../../crud/managereport.crud'

function* getReportItems({ payload }) {
    try {
        const result = yield call(getReport, payload);
        yield put({
            type: types.FETCH_MANAGE_REPORT_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    } catch (e) {
        yield put(action.configError({
            error: 'An error occurred when trying to get the report'
        }));
    }
}

function* getReportRequest() {
    yield takeLatest(types.FETCH_MANAGE_REPORT, getReportItems);
}

function* updateReportItem({ payload }) {
    try {
        const items = yield call(updateReport, payload.payload);
        yield put({
            type: types.UPDATE_REPORT_SUCCESS,
            payload: {
                items: items.data
            },
        });
        if (items.status == 200) {
            yield put({
                type: types.FETCH_MANAGE_REPORT,
                payload: payload.listParams
            });
        }
    } catch (e) {
        yield put(action.configError({
            error: 'An error occurred when trying to update the report item'
        }));
    }
}

function* updateReportRequest() {
    yield takeLatest(types.UPDATE_REPORT_REQUEST, updateReportItem);
}

function* getReportItemByModuleId({ payload }) {
    try {

        const result = yield call(getReportByModuleId, payload);
        yield put({
            type: types.FETCH_REPORT_BY_MODULEID_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    } catch (e) {
        yield put({
            type: types.FETCH_REPORT_BY_MODULEID_SUCCESS,
            payload: {
                items: null
            },
        });
    }
}

function* getReportByModuleIdRequest() {
    yield takeEvery(types.FETCH_REPORT_BY_MODULEID_REQUEST, getReportItemByModuleId);
}

function* getReportItemById({ payload }) {
    try {

        const result = yield call(getReportById, payload);
        yield put({
            type: types.FETCH_REPORT_BY_ID_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    } catch (e) {
        yield put({
            type: types.FETCH_REPORT_BY_ID_SUCCESS,
            payload: {
                items: null
            },
        });
    }
}

function* getReportByIdRequest() {
    yield takeEvery(types.FETCH_REPORT_BY_ID_REQUEST, getReportItemById);
}

function* getReportItemsFromPowerBi({ payload }) {
    try {
        const result = yield call(getReportFromPowerBi, payload);
        if (result.status == 200) {
            yield put({
                type: types.FETCH_MANAGE_REPORT,
                payload: payload
            });
        }
    } catch (e) {
        yield put(action.configError({
            error: 'An error occurred when trying to get the report'
        }));
    }
}

function* getReportFromPowerBiRequest() {
    yield takeEvery(types.FETCH_REPORT_FROM_POWERBI_REQUEST, getReportItemsFromPowerBi);
}

const manageReportSaga = [
    fork(getReportRequest),
    fork(updateReportRequest),
    fork(getReportByModuleIdRequest),
    fork(getReportByIdRequest),
    fork(getReportFromPowerBiRequest)
];

export default manageReportSaga;