import * as types from '../actions/types';

const INITIAL_STATE = {
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.FETCH_WORKSPACE_SUCCESS:
            return action.payload
        default:
            return state;
    }
};