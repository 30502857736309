import * as types from '../actions/types';


const INITIAL_STATE = {
    data: [],
    activeData: [],
    id: undefined,
    success: false,
    message: '',
    error: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_ALL_TENANTINDUSTRIES_SUCCESS:
            return {
                ...state,
                data: action.payload,
            }
        case types.GET_ACTIVE_TENANTINDUSTRIES_SUCCESS:
            return {
                ...state,
                activeData: action.payload,
            }
        case types.UPDATE_TENANTINDUSTRY_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Industry has been successfully updated..'
            }
        case types.ADD_TENANTINDUSTRY_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Industry has been successfully added..'
            }
        case types.DELETE_TENANTINDUSTRY_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Industry has been successfully deleted..'
            }
        case types.UPDATE_TENANTINDUSTRY_LEVELS_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Levels has been successfully updated..'
            }
        case types.ADD_TENANTINDUSTRY_LEVELS_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Levels has been successfully added..'
            }
        case types.DELETE_TENANTINDUSTRY_LEVELS_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Levels has been successfully deleted..'
            }
        case types.UPDATE_LEVELS_TYPE_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Type has been successfully updated..'
            }
        case types.ADD_LEVELS_TYPE_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Type has been successfully added..'
            }
        case types.DELETE_LEVELS_TYPE_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Type has been successfully deleted..'
            }
        case types.TENANTINDUSTRY_ERROR:
            return {
                ...state,
                error: action.payload.ErrorMessage || 'Please Try again...'
            }
        case types.RESET_STORE:
            return {
                ...state,
                success: false,
                message: '',
                error: null
            }
        default:
            return state;
    }
};