import axios from "axios";

export const getPowerBi = (details) => {
    return axios.get(`EmbedInfo?userName=${details.userName}&reportId=${details.reportID}`);
};

export const fetchPoewrBiByID = (id) => {
    return axios.get(`report/getReport/${id}`)
}

export const exportReport = (data)=>{
    return axios.post('report/exportReport', data, {responseType: 'blob'});
}