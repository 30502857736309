import React ,{ useState, useEffect} from 'react';
import Button from '@material-ui/core/Button'
import { Modal } from "react-bootstrap";
import ImportHirarchy from './ImportHirarchy'
import {  connect} from 'react-redux'

function ImportModel(props){
    const { id, organizationID,user } = props
    const [onshow, setOnShow] = useState(false)
    function hideShowModal(){
        setOnShow(false)
    }
    const [userHasEditTenantPermission, setUserHasEditTenantPermission] = useState(false);
    useEffect(()=>{
        var hasPermission = !!user?.modules?.find(m=>m.moduleName==='Tenants' && m.permissions?.some(p=>p.permissionName === 'Create'||p.permissionName === 'Update'));
        setUserHasEditTenantPermission(hasPermission);
    },[]);
    return(
        <>
            <div style={{marginBottom: 15, display: 'flex', justifyContent: 'flex-end'}}>
            {userHasEditTenantPermission && (<Button 
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setOnShow(!onshow)
                    }}
                >
                    <i className="fas fa-file-import mr-2"></i>
                    Import Hierarchy
                </Button>)}
            </div>
            <Modal
                style={{display:'flex !important',alignItems:'center',justifyContent:'center'}}
                show={onshow}
                onHide={() => hideShowModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Import Users</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <ImportHirarchy id={id} organizationID={organizationID} setOnShow={setOnShow} />
                </Modal.Body>
            </Modal>
        </>
    )
}
const mapStateToProps = store => ({
    user: store.auth.user
  });
  
export default connect(mapStateToProps)(ImportModel);