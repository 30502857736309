import { takeEvery, takeLatest, take, call, fork, put } from 'redux-saga/effects';
import * as types from '../actions/types';
import {
    getRunTimeAll, 
    getAuditTrailAll,
    getTableDataAll,
    updateTableData,
    getAuditTrailFilter,
    getRunTimeFilter
} from "../../crud/runtime.crud";
import * as action from '../actions/configAction';

/*** snapshot ***/
function* getPackageFilter(params){
    try{
        const result = yield call(getRunTimeFilter, {params});
        yield put({
            type: types.FETCH_RUNTIME_SUCCESS,
            payload: {
                items: result.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getPackageFilterRequest(){
    while(true){
        const {payload} = yield take(types.FETCH_RUNTIME_FILTER);
        yield call(getPackageFilter, payload);
    }
}

function* getRuntime(){
    try{
        const result = yield call(getRunTimeAll);
        yield put({
            type: types.FETCH_RUNTIME_SUCCESS,
            payload: {
                items: result.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getRuntimeRequest(){
    yield takeEvery(types.FETCH_RUNTIME, getRuntime);
}

function* getRuntimeFilter(params){
    try{
        const result = yield call(getAuditTrailFilter, {params});
        yield put({
            type: types.FETCH_AUDIT_FILTER_SUCCESS,
            payload: {
                items: result.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getRuntimeRequestFilter(){
    //yield takeEvery(types.FETCH_AUDIT_FILTER, getRuntimeFilter);
    while(true){
        const {payload} = yield take(types.FETCH_AUDIT_FILTER);
        yield call(getRuntimeFilter, payload);
    }
}

function* getAuditTrail(){
    try{
        const result = yield call(getAuditTrailAll);
        yield put({
            type: types.FETCH_AUDIT_SUCCESS,
            payload: {
                items: result.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getAuditTrailRequest(){
    yield takeEvery(types.FETCH_AUDIT, getAuditTrail);
}


function* getTableData(items){
    try{
        const result = yield call(getTableDataAll, items);
        yield put({
            type: types.FETCH_TABLEDATA_SUCCESS,
            payload: {
                items: result.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getTableDataRequest(){
    //yield takeEvery(types.FETCH_TABLEDATA, getTableData);
    while(true){
        const {payload} = yield take(types.FETCH_TABLEDATA);
        yield call(getTableData, payload.items);
    }
}

function* updateTableDataItem({payload}){
    try{
        
        const items = yield call(updateTableData, payload);
        yield put({
            type: types.UPDATE_TABLEDATA_SUCCESS,
            payload: {
                items
            },
        });
        yield call(getTableData, payload.type);
    } catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to update the File Operations'
        }));
        //yield call(getConfig);
    }
}

function* watchUpdateTableDataRequest(){
    yield takeLatest(types.UPDATE_TABLEDATA_REQUEST, updateTableDataItem);
}


const runtimeSaga = [
    fork(getRuntimeRequest),
    fork(getPackageFilterRequest),
    fork(getRuntimeRequestFilter),
    fork(getAuditTrailRequest),
    fork(getTableDataRequest),
    fork(watchUpdateTableDataRequest)
];

export default runtimeSaga;