import axios from 'axios'
const getUrl = 'tenantindustry'
const updateUrl = 'tenantindustry/updatetenantindustry'
const addUrl = 'tenantindustry/createtenantindustry'
const getLevelsUrl = 'tenantindustrylevels'
const updateLevelsUrl = 'tenantindustrylevels/updatetenantindustrylevel'
const addLevelsUrl = 'tenantindustrylevels/createtenantindustrylevel'
const getLevelsTypeUrl = 'levelsubtype'
const updateLevelsTypeUrl = 'levelsubtype/updatelevelsubtype'
const addLevelsTypeUrl = 'levelsubtype/createlevelsubtype'


export function getAllTenantIndustries() {
    // fetches all tenant industries including active and inactive
    return axios.get(`${getUrl}/getAll/false`);
}
export function getActiveTenantIndustries() {
    // fetches only active tenant industries
    return axios.get(`${getUrl}/getAll/true`);
}
export function addTenantIndustry(params) {
    return axios.post(addUrl, params);
}
export function updateTenantIndustry(params) {
    return axios.post(updateUrl, params);
}
export function deleteTenantIndustry(params) {
    return axios.delete(`${getUrl}/${params.tenantIndustryID}`);
}

export function fetchTenantIndustryLevelsById(id) {
    return axios.get(`${getLevelsUrl}/${id}`);
}



export function getTenantIndustryLevelsList() {
    return axios.get(getLevelsUrl);
}

export function updateTenantIndustryLevels(params) {
    return axios.post(updateLevelsUrl, params);
}

export function addTenantIndustryLevels(params) {
    return axios.post(addLevelsUrl, params);
}

export function deleteTenantIndustryLevels(params) {
    return axios.delete(`${getLevelsUrl}/${params.tenantIndustryLevelsID}`);
}


export function getLevelsTypeList(params) {
    return axios.get(`${getLevelsTypeUrl}/getsubtypebylevelid/${params.tenantIndustryLevelsID}`); 
}

export function updateLevelsType(params) {
    return axios.post(updateLevelsTypeUrl, params);
}

export function addLevelsType(params) {
    return axios.post(addLevelsTypeUrl, params);
}

export function deleteLevelsType(params) {
    return axios.delete(`${getLevelsTypeUrl}/${params.levelSubTypeID}`);
}