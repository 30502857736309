import { takeEvery,  call, fork, put } from 'redux-saga/effects';//takeLatest, take,
import * as types from '../actions/types';
import * as action from '../actions/configAction';
import { fetchFileOperationsById,fetchFileOperationsAdd } from '../../crud/fileOperations.crud';


function* getFileOperationById({ payload }){
    try{
        const result = yield call(fetchFileOperationsById, payload);
        yield put({
            type: types.FETCH_FILEOPERATION_SUCCESS,
            payload: result.data.result
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getFileOperationByIdRequest(){
    yield takeEvery(types.FETCH_FILEOPERATION, getFileOperationById);
}


function* addFileOperationsRow({ payload }){
    try{
        const result = yield call(fetchFileOperationsAdd, payload);
        yield put({
            type: types.FETCH_FILEOPERATION_SUCCESS,
            payload: result.data.result
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* addFileOperationsRowRequest(){
    yield takeEvery(types.ADD_FILEOPERATION, addFileOperationsRow);
}


const FileOperationsSaga = [
    fork(getFileOperationByIdRequest),
    fork(addFileOperationsRowRequest)
];

export default FileOperationsSaga;