import * as types from './types';

const getOrganisation = (id) => ({
    type: types.FETCH_ORGANISATION,
    payload: id
})

const getOrganisationSuccess = ({ items }) => ({
    type: types.FETCH_ORGANISATION_SUCCESS,
    payload: {
        items
    }
})

const fetchUpdateRow = (data) => ({
    type: types.UPDATE_ORGANISATION_REQUEST,
    payload: data
})

const fetchUpdateRowSuccess = ({items}) => ({
    type: types.UPDATE_ORGANISATION_SUCCESS,
    payload: {
        items
    }
})

const fetchAddRow = (data) => ({
    type: types.ADD_ORGANISATION_REQUEST,
    payload: data
})

const fetchAddRowSuccess = ({items}) => ({
    type: types.ADD_ORGANISATION_SUCCESS,
    payload: {
        items
    }
})

const importHierarchyFile = (data) => ({
    type: types.ORGANISATION_importHierarchyFile_REQUEST,
    payload: data
})

const fetchOrganisationRow = ({items}) => ({
    type: types.ORGANISATION_importHierarchyFile_SUCCESS,
    payload: {
        items
    }
});


const deleteOrganizationRequest = (data) => ({
    type: types.DELETE_ORGANIZATION_REQUEST,
    payload: data,
});

const deleteOrganizationSuccess = ({ items }) => ({
    type: types.DELETE_ORGANIZATION_SUCCESS,
    payload: {
        items
    }
});

const error = ({error}) => ({
    type: types.ORGANISATION_Error,
    payload: error
});

const reset = () => ({
    type: types.RESET_STORE
})

export {
    getOrganisation,
    getOrganisationSuccess,
    fetchUpdateRow,
    fetchUpdateRowSuccess,
    fetchAddRow,
    fetchAddRowSuccess,
    importHierarchyFile,
    fetchOrganisationRow,
    deleteOrganizationRequest,
    deleteOrganizationSuccess,
    error,
    reset
}