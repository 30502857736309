import * as types from '../actions/types';


const INITIAL_STATE={
    data: [],
    id: undefined,
    success: false,
    message: '',
    error: null
}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case types.FETCH_ConfigureWorkspaces_Success:
            return {
                ...state,
                data: action.payload
            } 
        case types.UPDATE_ConfigureWorkspaces_Success:
            return {
                ...state, 
                data: action.payload,
                success: true,
                message: 'Workspaces has been successfully updated..'
            }
        case types.ConfigureWorkspaces_Error: 
            return {
                ...state,
                error: 'Something is wrong...'
            }
        case types.RESET_STORE: 
            return {
                ...state,
                success: false,
                message: '',
                error: null
            }
        default:
        return state;
    }
}