import React, { useState,useEffect, Fragment, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import { DeleteConfirmation } from '../../../../crud/LayoutUtilsService'

import {
    Table,
    TableBody,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Done'
import {
    getComparator,    
    stableSort,
    EnhancedTableHead
} from '../../../../crud/SortGrid';
import { Form } from 'react-bootstrap'
import CompanyPolicyItem from './CompanyPolicyItem'

export default function CompanyPolicyList({ companyPolicy }){
    const classes = useStyles()
    const [dataSource, setDataSource] = useState(companyPolicy)
    useEffect(() => {
        setDataSource(companyPolicy)
    }, [companyPolicy])
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const handleRequestSort = (event, property) => {
        if(orderBy === ''){
            setOrder('desc');
            setOrderBy(property);
            setDataSource(stableSort(dataSource, getComparator('asc', property)))
        } else{
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
            setDataSource(stableSort(dataSource, getComparator(order, orderBy)))
        }
    };
    let allItemRows = [];
    dataSource.forEach((item, i) => {
        const perItemRows = <CompanyPolicyItem key={i} data={dataSource} item={item} index={i} />;
        allItemRows = allItemRows.concat(perItemRows);
    });
    return(
        <Table>
            <EnhancedTableHead
                classes={classes}
                order={order}
                headCells={headCells}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={dataSource ? dataSource.length : 0}
            /> 
            <TableBody>
                {allItemRows}
            </TableBody>
        </Table>
    )
}

const headCells = [
    { id: 'policyContent', numeric: false, disablePadding: false, align: 'left',  label: 'Policy Content' },
    { id: 'createdBy', numeric: false, disablePadding: false, align: 'left',  label: 'Created By' },
    { id: 'createdDate', numeric: false, disablePadding: false, align: 'left', label: 'Created Date' },
    { id: 'published', numeric: false, disablePadding: false, align: 'left', label: 'Published' },
    { id: 'actions', numeric: false, disablePadding: false, align: 'right', label: 'Actions' }
];

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        width: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    }
})