import React, { useState} from "react";//, useRef useMemo,
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import SVG from "react-inlinesvg";

import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar,
    PortletHeaderTitle
} from "../../../partials/content/Portlet";
import PerfectScrollbar from "react-perfect-scrollbar";
import { CefitsEditForm } from './CefitsEditForm'
import { CustomAddCol } from './custom-add-col/CustomAddCol'
import * as actions from '../../../store/actions/cefitAction'
import * as tenantAction from '../../../store/actions/tenantAction'
import { useEffect } from "react";
import XLSX from 'xlsx'
import { 
    setXlsxKey, 
    subject$, 
    tableName$, 
    setTableName, 
    columnName$, 
    setColumnName 
} from '../CefitUiHelpers'
import Toast from '../../../crud/Toast'
  
const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

const domReady = fn => {
    if (document.readyState === 'complete') {
      fn();
      return
    }
  
    document.addEventListener('readystatechange', () => {
      if (document.readyState !== 'complete') {
        return;
      }
  
      fn();
    });
};

export function CefitsEdit({
    history,
    match
}) {
    const loader = document.getElementById('splash-screen');
    const [level, setLevel] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [toastVisible, setToastVisible] = React.useState(false)
    let [roles, setRoles] = useState(undefined);
    const [updateData, setUpdateData] = useState(undefined)
    const [files, setFiles] = useState(undefined)
    const [key, setKey] = useState([])
    const [fileName, setFileName] = useState('Choose file...')
    const [cefitDate, setCefitDate] = useState([])
    const [cefitJson, setCefitJson] = useState([])
    const [accept, setAccept] = useState([''])
    const [cefitBase, setCefitBase] = useState(undefined)
    const [tableName, setTableNameData] = useState([])
    const [columnName, setColumnNameData] = useState([])
    const [objJsonB64, setObjJsonB64] = useState([])
    const [storeValue, setStoreValue] = useState(undefined)
    const [refreshMap, setRefreshMap] = useState(false)
    const [params, setParams] = useState({
        "sortField": "tenantName",
        "sortDirection": "asc",
        "pageSize": 1000,
        "pageNumber": 1,
        "searchText": ""
    })
    const [defaultValues, setDefaultValues] = useState({
        test: [
            { 
                excName: "",
                fieldName: "",
                excTabName: "",
                excRulesName: ""
            }
        ]
    });
    const [initCefit, setInitCefit] = useState({
        testMappingsArray: null,
        sqlTableNamesStr: "",
        excelFieldsNamesStr: "",
        excNames: null,
        excelDetails: [
            { 
                excName: "",
                fieldName: "",
                excTabName: "",
                excRulesName: ""
            }
        ],
        id: 0,
        rowsToSkip:0,
        configuration: "",
        healthSystem: "",
        employerName: "",
        fileDataType: "",
        fileFormat: "",
        stagingTableName: "",
        sheetName: {
            sheetNames: [],
            sheetNSel: ""
        },
        rowsToSkip: null,
        storedProcedureName: "",
        fileName: "",
        submittedOn: null,
        fileUrl: "",
        fileUrlJson: "",
        submittedBy: "",
        configurationStatus: "",
        user: "",
        file:'',
        deploymentStat: "Not Deployed",
        tenantID: undefined,
        tenantName: ""
    })
    subject$.subscribe(res => {
        setKey(res)
    })
    tableName$.subscribe(res => {
        setTableNameData(res)
    })
    columnName$.subscribe(res => {
        setColumnNameData(res)
    });

    const id = match.params.id;
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    const [ieBrowser] = React.useState(isIE ? true : false);
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const { 
        user,
        tableNameList, 
        updateExcel, 
        actionsLoading, 
        cefitForEdit, 
        tenantList, 
        columnNameList,
        updateResult,
        cefitSuccess,
        cefitMassage,
        error,
    } = useSelector(
        (state) => ({
            user: state.auth.user,
            actionsLoading: state.cefits.actionsLoading,
            cefitForEdit: state.cefits.cefitForEdit,
            tenantList: id ? state.tenant.item : state.tenant.data ? state.tenant.data.items : [],
            tableNameList: state.cefits.tableName,
            columnNameList: state.cefits.columnName,
            updateExcel: state.cefits.updateExcel,
            updateResult: state.cefits.updateResult,
            cefitSuccess: state.cefits.success,
            cefitMassage: state.cefits.message,
            error: state.cefits.error
        }),
        shallowEqual
    );
    useEffect(()=>{
        dispatch(actions.resetUpdateValue());
    },[]);
    React.useEffect(() => {
        if(cefitSuccess){
            setLevel('success');
            setMessage(cefitMassage);
            setToastVisible(cefitSuccess);
            loader.classList.add('hidden');
            setTimeout(() => {
            dispatch(actions.reset());
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 5000)
        }else { 
            setLevel('')
            setMessage('')
            setToastVisible(false)
        }
    }, [cefitSuccess, cefitMassage])
    React.useEffect(() => {
        if(error){
            setLevel('danger')
            setMessage(error)
            setToastVisible(true)
            loader.classList.add('hidden');
            setTimeout(() => {
            dispatch(actions.reset());
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 5000)
        }
    }, [error])
    
    useEffect(() => {
        if(match.params.id){
            loader.classList.remove('hidden')
            dispatch(actions.fetchCefitById(match.params.id))
        } else {
            dispatch(tenantAction.getAllTenant(params))
        }
    }, [dispatch]);
    
    useEffect(() => {
        setCefitDate(cefitForEdit)
        setFileName(cefitForEdit ? cefitForEdit.fileName : 'Choose file...')
        const fieldName = cefitForEdit ?  JSON.parse(cefitForEdit.excelFieldsNamesStr)  : key
        const table = cefitForEdit ? JSON.parse(cefitForEdit.sqlTableNamesStr) : []
        setKey(fieldName)
        if(id && cefitForEdit && cefitForEdit.tenantID === 0) {
            setTimeout(() => {
                alert('TenantId not found...')
                dispatch(actions.resetUpdateValue())
                backToCefitsList()
            }, 700)
        }
        if(cefitForEdit?.tenantID){
            loader.classList.add('hidden');
            dispatch(tenantAction.getTenantById(cefitForEdit.tenantID));
            setDefaultValues({...defaultValues, ['test']: cefitForEdit.excelDetails})
            setUpdateData(cefitForEdit)
            setStoreValue(cefitForEdit)
            if(cefitForEdit.fileFormat == 'CSV'){
                setAccept('.csv')
            } else {
                setAccept('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel')
            }
            setTimeout(() => {
                if(cefitForEdit.fileDataType){
                    dispatch(actions.fetchCefitTableName(cefitForEdit.fileDataType));
                } 
            }, 300)
        }
        setRefreshMap(false)
    }, [cefitForEdit]);
    useEffect(() => {
        if(updateResult && updateResult.id){
            history.push(`/cefit/list/${updateResult.id}/edit`); 
            setTimeout(() => {   
                dispatch(actions.fetchCefitById(updateResult.id)) 
                loader.classList.remove('hidden')
                setRefreshMap(true)
            }, 700) 
        }
    }, [updateResult])
    useEffect(() => {
        if(user){
            Object.keys(user.roles).forEach((i) => {
                if(user.roles[i].roleName === "Tool Admin"){
                    setRoles(user.roles[i])
                    return;
                } else if(user.roles[i].roleName === "Tool User"){
                    setRoles(user.roles[i])
                    return
                }
            })
        }
    }, [user]);
    useEffect(() => {
        const currentData = id ? cefitForEdit || initCefit : initCefit;
        currentData['sheetName'].sheetNames = updateExcel && updateExcel.sheets || [];
        currentData['rowsToSkip']= updateExcel && updateExcel.rowsToSkip || 0;
        currentData['fileName']= updateExcel && updateExcel.fileName || '';
        currentData['fileDataType']= updateExcel && updateExcel.fileDataType || '';
        currentData['tenantID']= updateExcel && updateExcel.tenantID || undefined;
        setXlsxKey(updateExcel ? updateExcel.columns : []);

        //console.log("Update Excel object Updated!", updateExcel, id, cefitForEdit);

        if(updateExcel){
            if(!id) {
                setInitCefit(currentData);
                loader.classList.add('hidden');
                
            } else {
                setUpdateData(currentData);
                loader.classList.add('hidden');                
            }
            var mappings = updateExcel.columns.map(c=>({ 
                excName: c,
                fieldName: "",
                excTabName: "",
                excRulesName: ""
            }));
            setDefaultValues(prev=>({...prev, ['test']: mappings}));
        }
        else{
            setDefaultValues(prev=>({...prev, ['test']: []}));
        }
    }, [updateExcel]);


    useEffect(() => {
        setTableName(tableNameList)
        setTableNameData(tableNameList)
    }, [tableNameList])

    useEffect(() => {
        setColumnName(columnNameList)
    }, [columnNameList])
   
    const saveCefit = (values) => {
        const paramsUpload = prepareUpload(values);
        setStoreValue(values);
        if (!match.params.id) {
            setInitCefit(values);
        }
        dispatch(actions.fetchUpdateExcel(paramsUpload));
        loader.classList.remove('hidden');
    };


    const prepareUpload = (values) => {
        const paramsUpload = {};
        paramsUpload['JsonFile'] = files;
        paramsUpload['FileDataType'] = values.fileDataType;
        paramsUpload['rowsToSkip'] = values.rowsToSkip;
        paramsUpload['tenantid'] = values.tenantID;
        paramsUpload['fileName'] = values.fileName;
        return paramsUpload;
    }
    
    const backToCefitsList = () => {
        history.push(`/cefit`);
    };

    const handleFileInput = (e, setValues) => {
        const file = e.target.files[0];
        setFiles(file)
        // console.log(file)
        loader.classList.remove('hidden') 
        setFileName(file ? file.name : 'Choose file...')
        if(id){
            setValues(prevValues => ({
                ...prevValues,
                ['fileName']: file && file.name,
            }))
        } else {
            setValues(prevValues => ({
                ...prevValues,
                ['fileName']: file && file.name,
            }))
        }
        loader.classList.add('hidden') 
    };
    function XlsxToJson(selectedFile){
        if(selectedFile) {
            // console.log("hi");
            var fileReader = new FileReader();
            fileReader.onload = function(event) {
                var data = event.target.result;
                var workbook = XLSX.read(data, {
                    type: "binary"
                });
                workbook.SheetNames.forEach(sheet => {
                    let rowObject = XLSX.utils.sheet_to_row_object_array(
                        workbook.Sheets[sheet]
                    );
                    // console.log('rowObject', rowObject)
                    let objJson = Buffer.from(rowObject).toString("base64");
                    setCefitJson(rowObject);
                    setObjJsonB64(objJson);
                    if(rowObject.length > 0) {
                        const keys = Object.keys(rowObject[0])
                        setXlsxKey(keys)
                        loader.classList.add('hidden') 
                    }
                });
            };
            fileReader.readAsBinaryString(selectedFile);
        }
    }
    function selectDataType(e){
        setUpdateData(null)
        setFileName('Choose file...')
        setFiles(undefined)
        if(e.target.value){
            dispatch(actions.fetchCefitTableName(e.target.value));
        }
    }

    const selectTableName = (e, index) => {
        const select = document.getElementById(`select-clumn-${index}`)
        removeOptions(select)
        let findColumn = tableName && tableName.find(item => item.name == e.target.value)
        let data = findColumn && findColumn.columns
        if(data){
            for (var i = 0; i <= data.length-1; i++){
                var opt = document.createElement('option');
                opt.value = data[i];
                opt.innerHTML = data[i];
                select.appendChild(opt);
            }
        }
    }
    function removeOptions(selectElement) {
        var i, L = selectElement.options.length - 1;
        for(i = L; i >= 0; i--) {
            if(i != 0){
                selectElement.remove(i);
            }
        }
     }
    const setAcceptType = (type) => {
        if(type == 'CSV'){
            setUpdateData(null)
            setFileName('Choose CSV file...')
            setFiles(undefined)
            setAccept('.csv')  
        } else if(type == 'Excel'){
            setUpdateData(null)
            setFileName('Choose Excel file...')
            setFiles(undefined)
            setAccept('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel')
        }
        const sheetName = {
            sheetNames: [],
            sheetNSel: ''
        }
        setInitCefit({
            ...initCefit,
            ['sheetName']: sheetName
        })
    }

    function onSubmiHendle(value){
        let saveParams = prepearSubmit(value);
        // console.log('saveParams', saveParams)
        dispatch(actions.fetchUpdate(saveParams))
        loader.classList.remove('hidden')
    }

    function onTestMapping(){
        dispatch(actions.fetchTestMapping(id))
        loader.classList.remove('hidden')
    }

    function onCefitApprove(){
        dispatch(actions.fetchCefitApprove(id))
        loader.classList.remove('hidden')
    }

    const prepearSubmit = (mappingValue) => {
        let values = storeValue;
        if(!values){
            alert('Something is wrong with your submission!');
            return;
        }
        if(values.fileFormat != 'CSV' && values.sheetName && !values.sheetName.sheetNSel){
            alert('please select sheet name...')
            return 
        } else if(values.rowsToSkip < 0){
            alert('please select Rows To Skip...')
            return 
        }
        console.log(values);
        let saveParams = {};
        saveParams["testMappingsArray"]= values.testMappingsArray 
        saveParams["sqlTableNamesStr"]= values.sqlTableNamesStr
        saveParams["excelFieldsNamesStr"]= JSON.stringify(values.excelFieldsNamesStr)
        saveParams["excNames"]= values.excNames
        saveParams["excelDetails"]= mappingValue
        saveParams["id"]= values.id
        saveParams["tenantID"]= values.tenantID
        saveParams["tenantName"]= values.tenantName || tenantList[0]?.tenantName || '-'
        saveParams["fileDataType"]= values.fileDataType
        saveParams["fileFormat"]= values.fileFormat
        saveParams["stagingTableName"]= values.stagingTableName
        saveParams["sheetName"]= values.sheetName
        saveParams["rowsToSkip"]= values.rowsToSkip
        saveParams["storedProcedureName"]= values.storedProcedureName
        saveParams["fileName"]= values.fileName
        saveParams["submittedOn"]= id ? new Date(values.submittedOn) : null
        saveParams["sourceBlobFileUrl"]= values.sourceBlobFileUrl
        saveParams["columnMappingJson"]= values.columnMappingJson
        saveParams["submittedBy"]= user.email
        saveParams["configurationStatus"]= values.configurationStatus
        saveParams["user"]= id ? values.user : null
        saveParams["deploymentStatus"]= values.deploymentStatus
        return saveParams;
    }
    function onRefreshMaping(){
        const values = id ? updateData : initCefit
        const params = prepareRefresh(values)
        dispatch(actions.fetchRefreshCifit(params));
    }
    const prepareRefresh = (values) => {
        const params = {
            "filedataType": values.fileDataType,
            "fileName": values.fileName.sheetNSel,
            "sheetName": values.sheetName,
            "tenantID": values.tenantID
        }
        return params;
    }
    function onChangedSheetName(e){
        // console.log(e)
        if(!id) {
            const sheetName = {
                sheetNames: initCefit.sheetName.sheetNames,
                sheetNSel: e
            }
            setInitCefit({
                ...initCefit,
                ['fileFormat']: accept != '.csv' ? 'Excel' : 'CSV' ,
                ['sheetName']: sheetName
            })
            setStoreValue({
                ...initCefit,
                ['fileFormat']: accept != '.csv' ? 'Excel' : 'CSV' ,
                ['sheetName']: sheetName
            })
        } else {
            const sheetName = {
                sheetNames: updateData.sheetName.sheetNames,
                sheetNSel: e
            }
            setUpdateData({
                ...updateData, 
                ['fileFormat']: accept != '.csv' ? 'Excel' : 'CSV' ,
                ['sheetName']: sheetName
            })
            setStoreValue({
                ...updateData,
                ['fileFormat']: accept != '.csv' ? 'Excel' : 'CSV' ,
                ['sheetName']: sheetName
            })
        }
    }
    function addNewRow(value, i){
        //console.log("addNewRow", value, i);
        setRefreshMap(true)
        setTimeout(() => {
            const rows = value ? value : []
            const newRow = { 
                excName: "",
                fieldName: "",
                excTabName: "",
                excRulesName: ""
            };
            rows.splice(i+1, 0, newRow);
            setDefaultValues({...defaultValues, ['test']: rows})
            setRefreshMap(false)
        }, 100)
    }
    function removeRow(value, i) {
        setRefreshMap(true)
        setTimeout(() => {
            var array = [...value];
            if (i !== -1) {
              array.splice(i, 1);
              setDefaultValues({...defaultValues, ['test']: array})
            }
            setRefreshMap(false)
        }, 100)
    }
    function onCancleButton(){
        history.push('./cefit/list')
    }
    function onChangedRowsToSkip(value){
        if(storeValue){
            setStoreValue({...storeValue, ['rowsToSkip']: value})
        }
    }
    return (
        <Portlet className={classes.portlet}>
            <PortletHeader 
                title={
                    <PortletHeaderTitle>
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Layout/hourglass_full-24px.svg')} />
                        <span style={{color: '#000000', marginLeft: 10, fontWeight: 700}}>{id ? 'Edit' : 'Add'} Configurations</span>
                    </PortletHeaderTitle>
                }
                toolbar={
                    <PortletHeaderToolbar>
                        {/* <Button variant="contained" color="primary" className={classes.button} onClick={saveCefitClick}>
                            Save
                        </Button> */}
                    </PortletHeaderToolbar>
                }
                headerClassName={classes.portletHead}
            />
            <PortletBody style={{position: 'relative', padding: '20px 10px 10px', maxHeight: 'calc( 100vh - 92px )', minHeight: 200, width: '100%'}}>           
                <PerfectScrollbar 
                    options={{ 
                        wheelSpeed: 2, 
                        wheelPropagation: false 
                    }}
                    style={{padding: 0, maxHeight: ieBrowser && 'calc( 100vh - 180px )'}}
                >   
                    <div style={{ padding:15 }}>
                        <CefitsEditForm 
                            cefit={id ? updateData || initCefit : initCefit}
                            saveCefit={saveCefit}
                            handleFileInput={handleFileInput}
                            tenantList={tenantList || []}
                            selectDataType={selectDataType}
                            setAcceptType={setAcceptType}
                            onChangedSheetName={onChangedSheetName}
                            onChangedRowsToSkip={onChangedRowsToSkip}
                            fileName={fileName}
                            accept={accept}
                        />
                        {/* {console.log("CefitsEdit is passing defaultValues ", defaultValues)} */}
                        {!refreshMap && id ? updateData && updateData.fileName && <CustomAddCol 
                            keys={key || []} 
                            tableName={tableName || []} 
                            columnName={columnName || []}
                            selectTableName={selectTableName}
                            onSubmiHendle={onSubmiHendle}
                            defaultValues={defaultValues}
                            onTestMapping={onTestMapping}
                            onCefitApprove={onCefitApprove}
                            onRefreshMaping={onRefreshMaping}
                            addNewRow={addNewRow}
                            removeRow={removeRow}
                            onCancleButton={onCancleButton}
                            roles={roles}
                            match={match}
                        /> 
                        :
                        !refreshMap && updateExcel && 
                        fileName !== 'Choose file...' && 
                        key.length > 0 && tableName && <CustomAddCol 
                            keys={key || []} 
                            tableName={tableName || []} 
                            columnName={columnName || []}
                            selectTableName={selectTableName}
                            onSubmiHendle={onSubmiHendle}
                            defaultValues={defaultValues}
                            onTestMapping={onTestMapping}
                            onCefitApprove={onCefitApprove}
                            onRefreshMaping={onRefreshMaping}
                            addNewRow={addNewRow}
                            removeRow={removeRow}
                            onCancleButton={onCancleButton}
                            roles={roles}
                            match={match}
                        />
                        }
                     </div>
                </PerfectScrollbar>
                <Toast
                    level={level}
                    message={message}
                    visible={toastVisible}
                />
            </PortletBody>
        </Portlet>
    );
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
        padding: '6px 16px'
    },
    textField:{

    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        width: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    }
})
