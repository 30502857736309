import { takeEvery, takeLatest, take, call, fork, put } from 'redux-saga/effects';
import * as types from '../actions/types';
import {
    getConfigAll, 
    createConfig, 
    deleteConfig, 
    updateConfig, 
    getAllFileOperations, 
    getAllAutoCleansing,
    gatAllBasedCleansing,
    gatAllCustomers,
    gatAllStage
} from "../../crud/config.crud";
import * as action from '../actions/configAction';

/*** snapshot ***/
function* getConfig(){
    try{
        const result = yield call(getConfigAll);
        yield put({
            type: types.FETCH_CONFIG_SUCCESS,
            payload: {
                items: result.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getConfigRequest(){
    yield takeEvery(types.FETCH_CONFIG, getConfig);
}

function* createConfigItem({payload}){
    try{
        const items = yield call(createConfig, payload);

        yield put({
            type: types.ADD_CONFIG_SUCCESS,
            payload: {
                items: items.data
            },
        });

    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to create the snapshop'
        }));
    }
}

function* watchCreateCinfigRequest(){
    yield takeLatest(types.CREATE_CONFIG_REQUEST, createConfigItem);
}

function* updateConfigItem({payload}){
    try{
        
        const items = yield call(updateConfig, payload);
        yield put({
            type: types.UPDATE_CONFIG_SUCCESS,
            payload: {
                items: items.data
            },
        });
    } catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to update the snapshop'
        }));
        //yield call(getConfig);
    }
}

function* watchUpdateCinfigRequest(){
    yield takeLatest(types.UPDATE_CONFIG_REQUEST, updateConfigItem);
}

function* deleteConfigItem(userId){
    try{
        const items =  yield call(deleteConfig, userId);

        yield put({
            type: types.DELETE_CONFIG_SUCCESS,
            payload: {
                items: items.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to delete the snapshop'
        }));
	}
}

function* watchDeleteConfigRequest(){
    while(true){
        const {payload} = yield take(types.DELETE_CONFIG_REQUEST);
        yield call(deleteConfigItem, payload.userId);
    }
}

/*** File Operations ***/

function* getFileOperations(){
    try{
        const result = yield call(getAllFileOperations);
        yield put({
            type: types.FETCH_FILE_OPERATIONS_SUCCESS,
            payload: {
                items: result.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getFileOperationsRequest(){
    yield takeEvery(types.FETCH_FILE_OPERATIONS, getFileOperations);
}


function* createFileOperationsItem({payload}){
    try{
        const items = yield call(createConfig, payload);

        yield put({
            type: types.CREATE_FILE_OPERATIONS_SUCCESS,
            payload: {
                items: items.data
            },
        });

    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to create the File Operations'
        }));
    }
}

function* watchCreateFileOperationsRequest(){
    yield takeLatest(types.CREATE_FILE_OPERATIONS_REQUEST, createFileOperationsItem);
}


function* updateFileOperationsItem({payload}){
    try{
        
        const items = yield call(updateConfig, payload);
        yield put({
            type: types.UPDATE_FILE_OPERATIONS_SUCCESS,
            payload: {
                items: items.data
            },
        });
        yield call(getFileOperations);
    } catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to update the File Operations'
        }));
        //yield call(getConfig);
    }
}

function* watchUpdateFileOperationsRequest(){
    yield takeLatest(types.UPDATE_FILE_OPERATIONS_REQUEST, updateFileOperationsItem);
}


function* deleteFileOperationsItem(userId){
    try{
        const items =  yield call(deleteConfig, userId);

        yield put({
            type: types.DELETE_FILE_OPERATIONS_SUCCESS,
            payload: {
                items: items.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to delete the File Operations'
        }));
	}
}

function* watchDeleteFileOperationsRequest(){
    while(true){
        const {payload} = yield take(types.DELETE_FILE_OPERATIONS_REQUEST);
        yield call(deleteFileOperationsItem, payload.userId);
    }
}

/**** Automated Cleansing ****/

function* getAutoCleansing(){
    try{
        const result = yield call(getAllAutoCleansing);
        yield put({
            type: types.FETCH_AUTO_CLEANSING_SUCCESS,
            payload: {
                items: result.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getAutoCleansingRequest(){
    yield takeEvery(types.FETCH_AUTO_CLEANSING, getAutoCleansing);
}

/**** Rule Based Cleansing ****/

function* getBaseCleansing(){
    try{
        const result = yield call(gatAllBasedCleansing);
        yield put({
            type: types.FETCH_BASED_CLEANSING_SUCCESS,
            payload: {
                items: result.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getBasedCleansingRequest(){
    yield takeEvery(types.FETCH_BASED_CLEANSING, getBaseCleansing);
}

/**** Customers ****/

function* getCustomers(){
    try{
        const result = yield call(gatAllCustomers);
        yield put({
            type: types.FETCH_CUSTOMERS_SUCCESS,
            payload: {
                items: result.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getCustomersRequest(){
    yield takeEvery(types.FETCH_CUSTOMERS, getCustomers);
}

/*** Staging ***/

function* getStage(){
    try{
        const result = yield call(gatAllStage);
        yield put({
            type: types.FETCH_STAGE_SUCCESS,
            payload: {
                items: result.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getStageRequest(){
    yield takeEvery(types.FETCH_STAGE, getStage);
}


const configSaga = [
    fork(getConfigRequest),
    fork(watchCreateCinfigRequest),
    fork(watchDeleteConfigRequest),
    fork(watchUpdateCinfigRequest),
    fork(getFileOperationsRequest),
    fork(watchCreateFileOperationsRequest),
    fork(watchUpdateFileOperationsRequest),
    fork(watchDeleteFileOperationsRequest),
    fork(getAutoCleansingRequest),
    fork(getBasedCleansingRequest),
    fork(getCustomersRequest),
    fork(getStageRequest)
];

export default configSaga;