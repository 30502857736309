import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import * as auth from "../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from '../../pages/auth/authProvider';
import { LayoutSplashScreen } from "../../../_metronic";

 
class AzureLogout extends Component {
    componentDidMount() {
    }
    onLogOut(logout){
        const { hasAuthToken } = this.props;
        this.props.logout();
        logout();
        return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
    }

    render(){
        return(
            <AzureAD provider={authProvider} forceLogin={false}>
                {
                    ({login, logout, authenticationState, error, accountInfo}) => {
                        const isInProgress = authenticationState === AuthenticationState.InProgress;
                        const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
                        const isUnauthenticated = authenticationState === AuthenticationState.Unauthenticated;

                        if (isAuthenticated) {
                            return (
                                <React.Fragment>
                                    <Link
                                        //to="/logout"
                                        onClick={this.onLogOut.bind(this, logout)}
                                        className="btn btn-label-brand btn-sm btn-bold"
                                    >
                                        Sign Out
                                    </Link>
                                </React.Fragment>
                            );
                        } else if (isUnauthenticated || isInProgress) {
                            return (
                                <React.Fragment>
                                    <Link
                                        to="/logout"
                                        className="btn btn-label-brand btn-sm btn-bold"
                                    >
                                        Sign Out
                                    </Link>
                                </React.Fragment>
                            );
                        }
                    }
                }
            </AzureAD>
        )
    }
}

export default connect(
    ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
    auth.actions
)(AzureLogout);