import React from 'react';//, { useEffect, useState } 
import { ThemeProvider,  createMuiTheme } from '@material-ui/core/styles';//withStyles,
import RoleBase from './RoleBase'
// import RoleList from './Role-list/RoleList';
import TenantEditHead from '../../../tenants/TenantEditHead';

export default function Role(props) {
    const { match, tenant } = props;
    return (
        <ThemeProvider theme={theme}>
            {tenant && <TenantEditHead tenant={tenant} />}
            <div style={{display: 'flex', justifyContent: 'space-beetwin'}}>
                <RoleBase match={match ? match : undefined}/>
            </div>
        </ThemeProvider>
    )
}

const theme = createMuiTheme({
    overrides: {
        MuiExpansionPanelSummary: {
            content: {
                padding: 0,
                margin: '0px !important'
            }
        },
        MuiButtonBase: {
            root: {
                minHeight: '25px !important',
                lineHeight: '1.2 !important'
            },
            expandIcon: {
                padding: 5
            },
        },
        MuiIconButton: {
            root: {
                padding: 0
            },
            colorPrimary: {
                padding: 0
            }
        },
        MuiButton: {
            root: {
                borderRadius: 0
            },
            text: {
                color: '#333333',
            },
            containedPrimary: {
                backgroundColor: '#0078d4',
                '&:hover': {
                    backgroundColor: '#0078d4',
                }
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: '0px !important',
                backgroundColor: 'rgba(0, 0, 0, 0.0) !important'
            }
        },
        MuiTableCell: {
            root: {
                padding: 5,
                fontSize: '1rem'
            },
            head: {
                lineHeight: '2.5rem'
            },
            sizeSmall: {
                padding: 5,
            },
            stickyHeader: {
                backgroundColor: '#ffffff'
            }
        },
        MuiTableHead: {
            root: {
                borderTop: 'solid 1px #ddd'
            }
        },
        MuiFormControl: {
            root: {
                width: 'auto'
            }
        },
        MuiFormControlLabel: {
            root: {
                marginBottom: 0,
                marginLeft: 0,
                marginRight: 8
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiListItem: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14
            }
        },
        MuiInputLabel: {
            formControl: {
                transform: 'translate(0, 7px) scale(1)'
            },
            shrink: {
                transform: 'translate(0, 31px) scale(0.75)'
            }
        },
        MuiInput: {
            formControl: {
                marginTop: '0 !important'
            }
        },
        PrivateSwitchBase: {
            root: {
                padding: 0
            }
        }
    },
});

// const useStyles = theme => ({
//     progressBar: {
//         top: 31,
//         right: 0,
//         bottom: 0,
//         left: 0,
//         position: 'absolute',
//         background: 'rgba(0, 0, 0, 0.1)',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         zIndex: 99999
//     }
// });
