import axios from 'axios'
const getUrl = 'roles/getAll'
// const updateUrl = 'roles/updaterole'
// const addUrl = 'roles/createrole'
const url = 'roles'
// const getUrlID='roles/getRolesPermissions'


export function getRolesAllList(params) {
    return axios.post(getUrl, params);
}
export function getRolesAllTenantList(data) {
    return axios.post(`${getUrl}/tenant/${data.id}`, data.params);
}
export function updateRole(params) {
    return axios.post(`${url}/save`, params);
}
export function addRole(params) {
    return axios.post(`${url}/save`, params);
}
export function getCreateRole() {
    return axios.get(`${url}/create`);
}
export function deleteRole(params) {
    return axios.delete(`${url}/${params.roleID}`,params);
}
export function getRoleItem(roleID) {
    return axios.get(`${url}/${roleID}`);
}
export function getTenantRoleCreate(id) {
    return axios.get(`${url}/create/tenant/${id}`);
}