import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import IconCancel from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

// const MessageType = {
//     Success: 'success', 
//     Warning: 'warning', 
//     Error: 'error', 
//     Info: 'info'
// }

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const MySnackbarContentWrapper = (props) => {
    const classes = useStyles1();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];
  
    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={() => onClose(false)}>
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const useStyles2 = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

const ShowActionNotification = ({ _message, _type, _duration, _showCloseButton, _showUndoButton, _undoButtonDuration, _verticalPosition, _open, onClose }) => {
    const _data = { 
        message: _message, 
        showCloseButton: _showCloseButton, 
        showUndoButton: _showUndoButton, 
        undoButtonDuration: _undoButtonDuration, 
        verticalPosition: _verticalPosition, 
        type: _type, 
        action: 'Undo', 
        open: _open 
    };
    const classes = useStyles2();
    const [open] = React.useState(_open);

    // const handleClose = (data) => {
    //     setOpen(false)
    // }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: _data.verticalPosition,
                horizontal: 'center',
            }}
            open={open}
            autoHideDuration={6000}
            //onClose={onClose}
        >
            <MySnackbarContentWrapper
                variant={_data.type}
                className={classes.margin}
                message={_data.message}
                onClose={onClose}
            />
        </Snackbar>
    );
}

const DeleteConfirmation = ({_openDialog, handleDelete, index}) => {
    const [showDialog] = React.useState(_openDialog);

    // const handleCloseClick = () => {
    //     setShowDialog(false);
    // };

    return (
        <Dialog fullWidth open={showDialog} aria-label="Are you sure?">
            <DialogTitle>Are you sure you want to delete this entity?</DialogTitle>
                <DialogContent>
                {/* <div>
                    //Your actions will be logged.
                </div> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDelete(index, 'delete')} >
                        delete
                    </Button>
                    <Button label="ra.action.cancel" onClick={() => handleDelete(index, 'close')}>
                        <IconCancel />
                    </Button>
                </DialogActions>
        </Dialog>
    )
}

DeleteConfirmation.propTypes = {
    handleDelete: PropTypes.func.isRequired,
};

export {
    ShowActionNotification,
    DeleteConfirmation
}