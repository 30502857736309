import { takeEvery, takeLatest, take, call, fork, put } from 'redux-saga/effects';
import * as types from '../actions/types';
import * as action from '../actions/roleAction';

import { 
    getRolesAllList,
    updateRole,
    addRole,
    deleteRole,
    getRoleItem,
    getCreateRole,
    getTenantRoleCreate,
    getRolesAllTenantList
} from '../../crud/role.crud';

function* getRole({payload}) {
    try {
        const res = yield call(getRolesAllList, payload);
        yield put({
            type: types.FETCH_Role_SUCCESS,
            payload: {
                items: res.data.result.items
            },
        });
    } catch (e) {
        yield put(action.error({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getRoleRequest() {
    yield takeEvery(types.FETCH_ALL_Role, getRole);
}


function* getRoleTenant({payload}) {
    try {
        const res = yield call(getRolesAllTenantList, payload);
        yield put({
            type: types.FETCH_Role_SUCCESS,
            payload: {
                items: res.data.result.items
            },
        });
    } catch (e) {
        yield put(action.error({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getRoleTenantRequest() {
    yield takeEvery(types.FETCH_ALL_Role_tenant, getRoleTenant);
}

function* getRoleByIDReq({payload}) {
    try {
        const res = yield call(getRoleItem ,payload);
        yield put({
            type: types.FETCH_Role_BY_ID_SUCCESS,
            payload: res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: 'An error occurred when trying to get the role'
        }));
    }
}

function* getRoleByIdRequest() {
    yield takeEvery(types.FETCH_Role_BY_ID_REQUEST, getRoleByIDReq);
}

function* updateRoleItem({ payload }) {
    try {
        const items = yield call(updateRole, payload);
        yield put({
            type: types.UPDATE_Role_SUCCESS,
            payload: {
                items: items.data
            },
        });
        yield call(getRoleRequest);
    } catch (e) {
        yield put(action.error({
            error: 'An error occurred when trying to update the Role'
        }));
    }
}

function* watchUpdateRoleRequest() {
    yield takeLatest(types.UPDATE_Role_REQUEST, updateRoleItem);
}

function* addRoleItem({ payload }) {
    try {

        const items = yield call(addRole, payload);
        yield put({
            type: types.ADD_Role_SUCCESS,
            payload: {
                items: items.data
            },
        });
        yield call(getRoleRequest);
    } catch (e) {
        yield put(action.error({
            error: 'An error occurred when trying to add the Role'
        }));
    }
}

function* watchAddRoleRequest() {
    yield takeLatest(types.ADD_Role_REQUEST, addRoleItem);
}

function* addRoleCreateItem() {
    try {

        const res = yield call(getCreateRole);
        yield put({
            type: types.ADD_Role_create_SUCCESS,
            payload: res.data.result,
        });
        yield call(getRoleRequest);
    } catch (e) {
        yield put(action.error({
            error: 'An error occurred when trying to add the Role'
        }));
    }
}

function* watchAddRoleCreateRequest() {
    yield takeLatest(types.ADD_Role_create_REQUEST, addRoleCreateItem);
}

function* deleteRoleItem({ payload }) {
    try {

        const items = yield call(deleteRole, payload);
        yield put({
            type: types.DELETE_Role_SUCCESS,
            payload: {
                items: items.data
            },
        });
       // yield call(getRoleRequest);
    } catch (e) {
        yield put(action.error({
            error: 'An error occurred when trying to delete the Role'
        }));
    }
}

function* watchDeleteRoleRequest() {
    yield takeLatest(types.DELETE_Role_Request, deleteRoleItem);
}

function* addRoleCreateTenantItem({ payload }) {
    try {

        const res = yield call(getTenantRoleCreate, payload);
        yield put({
            type: types.FETCH_Role_BY_tenantID_SUCCESS,
            payload: res.data.result,
        });
        yield call(getRoleRequest);
    } catch (e) {
        yield put(action.error({
            error: 'An error occurred when trying to add the Role'
        }));
    }
}

function* watchAddRoleCreateTenantRequest() {
    yield takeLatest(types.FETCH_Role_BY_tenantID_REQUEST, addRoleCreateTenantItem);
}


const RoleSaga = [
    fork(getRoleRequest),
    fork(watchUpdateRoleRequest),
    fork(watchAddRoleRequest),
    fork(watchDeleteRoleRequest),
    fork(getRoleByIdRequest),
    fork(watchAddRoleCreateRequest),
    fork(watchAddRoleCreateTenantRequest),
    fork(getRoleTenantRequest)
];

export default RoleSaga;
