import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider, withStyles, createMuiTheme } from '@material-ui/core/styles'
import { fetchRunTime, fetchRunTimeFilter } from '../../../../store/actions/runtimeAction'
import PackageList from './PackageList'

class Package extends Component {
    constructor(props){
        super(props);
        this.props.fetchRunTime();
        this.state = {
            packageList: [],
            pageLoader: true
        }
        this.heandleFilter = this.heandleFilter.bind(this);
    }

    componentDidMount(){
        const { packageList } = this.state
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.package){
            if(nextProps.package !== this.state.packageList){
                if(nextProps.package){
                    nextProps.package.map(res => {
                        res['open'] = true;
                        res['rowref'] = React.createRef()
                    })
                }
                this.setState({
                    packageList: nextProps.package,
                    pageLoader: false 
                });
            }
        }
    }

    heandleFilter(params, load){
        params ? this.props.fetchRunTimeFilter(params) : this.props.fetchRunTime();
        this.setState({
            pageLoader: load
        })
    }

    render(){
        const { classes } = this.props;
        const { packageList, pageLoader } = this.state
        return(
            <ThemeProvider theme={theme}>
                <PackageList data={packageList} onFilter={this.heandleFilter} pageLoader/>
                {pageLoader &&
                    <div className={classes.progressBar}>
                        <CircularProgress />
                    </div>
                }
            </ThemeProvider>
        )
    }
}

const theme = createMuiTheme({
    overrides: {
        MuiExpansionPanelSummary: {
            content: {
                padding: 0,
                margin: '0px !important'
            }
        },
        MuiButtonBase: {
            root: {
                minHeight: '25px !important',
                lineHeight: '1.2 !important'
            },
            expandIcon: {
                padding: 5
            },
        },
        MuiButton: {
            text: {
                color: '#333333',
            },
            containedPrimary: {
                backgroundColor: '#0078d4',
                '&:hover': {
                    backgroundColor: '#0078d4',
                }
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: '0px !important',
                backgroundColor: 'rgba(0, 0, 0, 0.0) !important'
            }
        },
        MuiTableCell: {
            root: {
                padding: 5,
                fontSize: '1rem'
            },
            head: {
                lineHeight: '2.5rem'
            },
            sizeSmall: {
                padding: 5,
            },
            stickyHeader: {
                backgroundColor: '#ffffff'
            }
        },
        MuiFormControl: {
            root: {
                width: 'auto'
            }
        },
        MuiFormControlLabel: {
            root: {
                marginBottom: 0,
                marginLeft: 0
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiListItem: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14
            }
        },
        MuiInputLabel: {
            formControl: {
                transform: 'translate(0, 7px) scale(1)'
            },
            shrink: {
                transform: 'translate(0, 31px) scale(0.75)'
            }
        },
        MuiInput: {
            formControl: {
                marginTop: '0 !important'
            }
        }
    },
});


const useStyles = theme => ({
    progressBar: {
        top: 31,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
        background: 'rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 99999
    }
});

const mapStateToProps = state => ({
    package: state.runtime.package
})

export default connect(mapStateToProps, {
    fetchRunTime,
    fetchRunTimeFilter
})(withStyles(useStyles)(Package));