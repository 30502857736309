import * as types from './types';


const fetchRunTime = () => ({
    type: types.FETCH_RUNTIME,
})

const fetchRunTimeSuccess = ({items}) => ({
    type: types.FETCH_RUNTIME_SUCCESS,
    payload: { 
        items
    }
})

const fetchRunTimeFilter = (data) => ({
    type: types.FETCH_RUNTIME_FILTER,
    payload: data
})

const fetchRunTimeFilterSuccess = ({items}) => ({
    type: types.FETCH_RUNTIME_FILTER_SUCCESS,
    payload: { 
        items
    }
})

const fetchAuditTrail = () => ({
    type: types.FETCH_AUDIT,
})

const fetchAuditTrailFilter = (data) => ({
    type: types.FETCH_AUDIT_FILTER,
    payload: data
})

const fetchAuditTrailFilterSuccess = ({items}) => ({
    type: types.FETCH_AUDIT_FILTER_SUCCESS,
    payload: { 
        items
    }
})

const fetchAuditTrailSuccess = ({items}) => ({
    type: types.FETCH_AUDIT_SUCCESS,
    payload: { 
        items
    }
})

const fetchTableData = (items) => ({
    type: types.FETCH_TABLEDATA,
    payload: { 
        items
    }
})

const fetchTableDataSuccess = ({items}) => ({
    type: types.FETCH_TABLEDATA_SUCCESS,
    payload: { 
        items
    }
})

const updateTableDataRequest = (data, type) => ({
    type: types.UPDATE_TABLEDATA_REQUEST,
    payload: {
        data,
        type
    },
});

const updateTableDataSuccess = ({ items }) => ({
    type: types.UPDATE_TABLEDATA_SUCCESS,
    payload: {
        items
    }
})

export {
    fetchRunTime,
    fetchRunTimeSuccess,
    fetchAuditTrail,
    fetchAuditTrailFilter,
    fetchAuditTrailFilterSuccess, 
    fetchAuditTrailSuccess,
    fetchTableData,
    fetchTableDataSuccess,
    updateTableDataRequest,
    updateTableDataSuccess,
    fetchRunTimeFilter,
    fetchRunTimeFilterSuccess
}