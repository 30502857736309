import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { Button } from '@material-ui/core'
 
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const createEditorState = (text) => {
    const contentState = stateFromHTML(text);
    return EditorState.createWithContent(contentState);
};

class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: createEditorState(props.item.policyContent || ''),
        };
    }

    onEditorStateChange = (editorState) => {
        this.setState({ editorState });
        this.props.resetTest(convertToRaw(editorState.getCurrentContent()))
    };

    render() {
        const { editorState } = this.state;
        return (
            <>
                <div>
                    <Editor
                        editorState={editorState}
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorStateChange}
                    />
                </div>
            </>
        );
    }
}

export default TextEditor;