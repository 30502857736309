import { takeEvery, takeLatest, take, call, fork, put } from 'redux-saga/effects';
import * as types from '../actions/types';
import * as action from '../actions/tenantAction';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { 
    getTenantAllList, 
    getTenantById, 
    getCountry, 
    updateInformation, 
    createInformation, 
    getState, 
    deleteInformation,
    runE2E,
    runAddNewUsersToReports 
} from '../../crud/tenant.crud';

function* getTenant({ payload }) {
    try {
        const result = yield call(getTenantAllList, payload);
        yield put({
            type: types.FETCH_TENANT_SUCCESS,
            payload: result.data.result
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getTenantRequest() {
    yield takeEvery(types.FETCH_TENANT, getTenant);
}

function* getTenantByTenantId({ payload }) {
    try {
       
        const result = yield call(getTenantById, payload);
        yield put({
            type: types.FETCH_TENANT_BY_ID_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getTenantByIdRequest() {
    yield takeEvery(types.FETCH_TENANT_BY_ID, getTenantByTenantId);
}



function* getCountries() {
    try {
       
        const result = yield call(getCountry);
        yield put({
            type: types.FATCH_COUNTRY_SUCCESS,
            payload: result.data.result
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getCountriesRequest() {
    yield takeEvery(types.FATCH_COUNTRY, getCountries);
}

function* getStates({ payload }) {
    try {
       
        const result = yield call(getState, payload);
        yield put({
            type: types.FATCH_STATE_SUCCESS,
            payload: result.data.result
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getStateRequest() {
    yield takeEvery(types.FATCH_STATE, getStates);
}

function* updateTenantItem({payload}){
    try{
        
        const items = yield call(updateInformation, payload);
        yield put({
            type: types.UPDATE_TENANT_SUCCESS,
            payload: items.data.result,
        });
        // yield call(getTenantByIdRequest);
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* watchUpdateTenantRequest(){
    yield takeLatest(types.UPDATE_TENANT_REQUEST, updateTenantItem);
}

function* addTenantItem({payload}){
    try{
        const items = yield call(createInformation, payload.data);
        const loader = document.getElementById('splash-screen');
        items.data.result && payload.history.push(`/tenant/${items.data.result[0].tenantID}/edit`)
        loader.classList.add('hidden')
        yield put({
            type: types.FETCH_TENANT_BY_ID_SUCCESS,
            payload: {
                items: items.data.result
            },
        });
        //yield call(getTenantById, items.data.result.tenantID);
    } catch (e) {
        const loader = document.getElementById('splash-screen');
        console.log("Exception while add tenant", e);
        yield put(action.error({
            error: e.response.data
        }));
        loader.classList.add('hidden')
    }
}

function* watchAddTenantRequest(){
    yield takeLatest(types.ADD_TENANT_REQUEST, addTenantItem);
}

function* deleteTenantItem({payload}){
    try{
        const items = yield call(deleteInformation, payload);
        yield put({
            type: types.DELETE_TENANT_SUCCESS,
            payload: items.data.result,
        });
        //yield call(getTenantRequest);
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* watchDeleteTenantRequest(){
    yield takeLatest(types.DELETE_TENANT_REQUEST, deleteTenantItem);
}
function* callRunE2E({payload}){
    try{
        console.log(payload);
        const response = yield call(runE2E, payload);
        const loader = document.getElementById('splash-screen');
        loader.classList.add('hidden');
        console.log(response);
        yield put({
            type: types.RUN_E2E_SUCCESS,
            payload: {
                E2ERan: response.data
            },
        });
    } catch (e) {
        const loader = document.getElementById('splash-screen');
        console.log("Exception while runE2E", e);
        yield put(action.error({
            error: e.response.data
        }));
        loader.classList.add('hidden')
    }
}
function* watchRunE2ERequest(){
    yield takeLatest(types.RUN_E2E_REQUEST, callRunE2E);
}
function* callRunAddNewUsersToReports({payload}){
    try{
        console.log(payload);
        const response = yield call(runAddNewUsersToReports, payload);
        const loader = document.getElementById('splash-screen');
        loader.classList.add('hidden');
        console.log(response);
        yield put({
            type: types.RUN_ADD_NEW_USERS_TO_REPORTS_SUCCESS,
            payload: {
                E2ERan: response.data
            },
        });
    } catch (e) {
        const loader = document.getElementById('splash-screen');
        console.log("Exception while runAddNewUsersToReports", e);
        yield put(action.error({
            error: e.response.data
        }));
        loader.classList.add('hidden')
    }
}
function* watchRunAddNewUsersToReportsRequest(){
    yield takeLatest(types.RUN_ADD_NEW_USERS_TO_REPORTS_REQUEST, callRunAddNewUsersToReports);
}
const tenantSaga = [
    fork(getTenantRequest),
    fork(getTenantByIdRequest),
    fork(getCountriesRequest),
    fork(getStateRequest),
    fork(watchUpdateTenantRequest),
    fork(watchAddTenantRequest),
    fork(watchDeleteTenantRequest),
    fork(watchRunE2ERequest),
    fork(watchRunAddNewUsersToReportsRequest)
];

export default tenantSaga;