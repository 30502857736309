import React, { useState, useEffect, useRef, Fragment } from 'react'
import { useDispatch, connect } from 'react-redux'
import {
    TableCell,
    TableRow,
    IconButton,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Done'
import RemoveIcon from '@material-ui/icons/Remove';
import ClearIcon from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button';
import * as actions from '../../../../store/actions/organisationAction'
import { DeleteConfirmation } from '../../../../crud/LayoutUtilsService'
import ChildOrganisationList from './ChildOrganisationList'

function OrganisationItem(props) {
    const { updatedRow, setUpdatedRow, expandedRows, item, getExpandedRows, user } = props
    const classes = useStyles();
    const dispatch = useDispatch();
    const loader = document.getElementById('splash-screen');
    const childRef = useRef();
    // const [item, setItem] = useState({});
    // React.useEffect(() => {
    //     setItem(props.item);
    // }, [props.item])
    const [userHasEditTenantPermission, setUserHasEditTenantPermission] = useState(false);
    useEffect(()=>{
        var hasPermission = !!user?.modules?.find(m=>m.moduleName==='Tenants' && m.permissions?.some(p=>p.permissionName === 'Create'||p.permissionName === 'Update'));
        // console.log(hasPermission)
        setUserHasEditTenantPermission(hasPermission);
    },[]);
    const [index, setIndex] = useState(props.index);
    const [data, getData] = useState(props.data);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [panelIndex, setPanelIndex] = useState(undefined);
    const [panelItem, setPanelItem] = useState({});
    const [isModalActive, setIsModalActive] = useState(false)
    const [editTenantIndutry, setEditTenantIndutry] = useState({
        name: "",
        organizationID: undefined,
        levelID: null
    })
    const [deleteItem, setDelete] = useState(false);
    const handleRowClick = () => {
        setPanelIndex(index);
        setPanelItem(item);
        const currentExpandedRows = [];
        const isRowCurrentlyExpanded = currentExpandedRows.includes(index);

        const newExpandedRows = isRowCurrentlyExpanded ?
            currentExpandedRows.filter((id) => id !== index) :
            currentExpandedRows.concat(index);
        getExpandedRows(newExpandedRows);
        setIsModalActive(true);
    }
    const handleRowClose = () => {
        setPanelIndex(index);
        setPanelItem(item);
        const currentExpandedRows = expandedRows;
        const isRowCurrentlyExpanded = currentExpandedRows.includes(index);

        const newExpandedRows = isRowCurrentlyExpanded ?
            currentExpandedRows.filter((id) => id !== index) :
            currentExpandedRows.concat(index);
        getExpandedRows(newExpandedRows);
        setIsModalActive(true);
    }
    const heandleUpdate = () => {
        const currentExpandedRows = [];
        const isRowCurrentlyExpanded = currentExpandedRows.includes(index);
        const newExpandedRows = isRowCurrentlyExpanded ?
            currentExpandedRows.filter((id) => id !== index) :
            currentExpandedRows.concat(index);
        setUpdatedRow(newExpandedRows);
        setEditTenantIndutry(item);
    }
    
    const heandleClose = () => {
        const currentExpandedRows = updatedRow;
        const isRowCurrentlyExpanded = currentExpandedRows.includes(index);
        const newExpandedRows = isRowCurrentlyExpanded ?
            currentExpandedRows.filter((id) => id !== index) :
            currentExpandedRows.concat(index);
        setUpdatedRow(newExpandedRows);
        setEditTenantIndutry(item);
    }
    const deleteConfirm = () => {
        setDelete(true)
    }
    const deleteClose = (rowIndex, action) => {
        if (action === 'delete') {
            const loader = document.getElementById('splash-screen');
            loader.classList.remove('hidden');
            props.onDelete(item);
            setTimeout(() => {
                loader.classList.add('hidden')
                setDelete(false);
            }, 2000);
            return;
        } else if (action === 'close') {
            setTimeout(()=>{
                setDelete(false);
            },1);
        }
    }

    const submit = () => {
        dispatch(actions.fetchUpdateRow(editTenantIndutry))
        loader.classList.remove('hidden')
        setTimeout(function(){ 
            heandleUpdate();
            props.tenant && dispatch(actions.getOrganisation(props.tenant.tenantID))
            return;
        }, 2000);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditTenantIndutry({ ...editTenantIndutry, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(editTenantIndutry));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};
    
        if (!values.name) {
          errors.levelID = "Cannot be blank";
        } 
    
        if (!values.levelID) {
          errors.levelID = "Cannot be blank";
        } 
    
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
          submit();
        }
    }, [formErrors]);
    return ( 
        <Fragment>
            <TableRow className = { updatedRow.includes(index) ? 'row-color editRow' : 'row-color' } key = { "row-data-" + index } >
                <TableCell className = { classes.cellBtn } >
                    {props.tenant.tenantLevel == 3 ? 
                    props.tenant.maxLevel !== item.currentLevel : 
                    props.tenant.maxLevel !== item.currentLevel - 1 && 
                    <>
                    {!expandedRows.includes(index) && <IconButton color = "primary"
                        component = "span"
                        className = {classes.button}
                        onClick = {handleRowClick}
                        disabled = {updatedRow.includes(index)} 
                    >
                        <AddIcon color = "primary" />
                    </IconButton>} 
                    {expandedRows.includes(index) && <IconButton color = "primary"
                        component = "span"
                        className = {classes.button}
                        onClick = {handleRowClose}
                        disabled = {updatedRow.includes(index)} 
                    >
                        <RemoveIcon color = "primary" />
                    </IconButton>}
                    </>} 
                </TableCell>
                <TableCell className={classes.cellBtn} > 
                    {updatedRow.includes(index) ? 
                        <TextField
                            id = "standard-basic3"
                            label = "Edit Name"
                            error={formErrors.levelID ? true : false}
                            name='name'
                            value = {editTenantIndutry.name}
                            onChange = {
                                (e) => handleChange(e) 
                            }
                        />  : item.name
                    } 
                </TableCell>
                <TableCell 
                    className={classes.cellBtn}
                > 
                    {updatedRow.includes(index) ? 
                        <TextField
                            id = "standard-basic3"
                            label = "Edit Name"
                            name="levelID"
                            error={formErrors.levelID ? true : false}
                            value = {editTenantIndutry.levelID}
                            onChange = {
                                (e) => handleChange(e) 
                            }
                        />  : item.levelID
                    } 
                </TableCell>
                
                <TableCell 
                    className = { classes.cell }
                    align = "right"
                    style = {{ paddingRight: 0 }} 
                > 
                {!updatedRow.includes(index) && userHasEditTenantPermission && 
                        <Fragment>
                            <IconButton
                                color = "primary"
                                className = { classes.button }
                                component = "span"
                                onClick = {heandleUpdate} 
                            >
                                <CreateIcon color = "primary" />
                            </IconButton> 
                            { item.childOrganization && item.childOrganization.length === 0 &&
                            <IconButton
                                color = "default"
                                className = { classes.button }
                                component = "span"
                                onClick = {deleteConfirm} 
                            >
                                <DeleteIcon color = "error" />
                            </IconButton> }
                            {deleteItem &&
                                <DeleteConfirmation
                                    _openDialog = { deleteItem }
                                    index = { index }
                                    handleDelete = {deleteClose}
                                />
                            } 
                        </Fragment>
                    } 
                    {updatedRow.includes(index) && 
                        <Fragment>
                            <IconButton
                                color = "primary"
                                className = { classes.button }
                                component = "span"
                                size = 'small'
                                onClick = {handleSubmit} 
                            >
                                <DoneIcon color = "primary" />
                            </IconButton> 
                            <IconButton
                                color = "default"
                                className = { classes.button }
                                component = "span"
                                size = 'small'
                                onClick = {heandleClose} 
                            >
                                <ClearIcon color = "error" />
                            </IconButton> 
                        </Fragment>
                    } 
                </TableCell>			
            </TableRow> 
            {expandedRows.includes(index) &&
                <tr key = { "row-expanded-" + index } className="MuiTableRow-root" >
                    <td className = "MuiTableCell-root MuiTableCell-body TableDetailCell-active-225" style = {{ padding: 10 }} colSpan = "6" >
                        <div style={{display: 'flex', justifyContent: 'space-between',alignItems: 'center', padding:'0px 0px', marginBottom: 10}}>
                            <div className={classes.title}>{props.tenant.tenantLevels[props.tenant.tenantLevel == 2 || props.tenant.tenantLevel == 3 ? item.currentLevel + 1 : item.currentLevel]}</div>
                            {userHasEditTenantPermission && (<div>
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        childRef.current.getParentAdd(item.organizationID)
                                    }}
                                >
                                    {`+ Add New ${props.tenant.tenantLevels[props.tenant.tenantLevel == 2 || props.tenant.tenantLevel == 3 ? item.currentLevel + 1 : item.currentLevel]}`}
                                </Button>
                            </div>)}
                        </div>
                        <ChildOrganisationList 
                            dataSource={item.childOrganization ? item.childOrganization : []} 
                            organizationID={item.organizationID}  
                            tenant={props.tenant}
                            ref={childRef}
                            idColumnName = {props.tenant.tenantLevels[item.currentLevel]}
                        />
                    </td> 
                </tr>
            }

        </Fragment>
    )
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
        padding: 0
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    title: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 500,
        lineHeight: '2.5rem'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    }
})


const mapStateToProps = store => ({
    user: store.auth.user
  });
  
export default connect(mapStateToProps)(OrganisationItem);