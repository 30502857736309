import axios from "axios";
const url = 'CompanyPolicy';

export const getCompanyPolicyList = () => {
    return axios.post(`${url}/getAll`);
}

export const getCompanyPolicyTenantList = (id) => {
    return axios.post(`${url}/getAll/tenant/${id}`);
}

export const getCompanyPolicyListByTenantId = (id) => {
    return axios.get(`${url}/GetLatestPolicy/${id}`);
}

export const companyPolicyUpdate = (params) => {
    return axios.post(url, params);
}

export const companyPolicyCreate = () => {
    return axios.get(`CompanyPolicy/create`);
}

export const companyPolicyAdd = (id) => {
    return axios.get(`${url}/create/tenant/${id}`);
}

export const companyPolicyDelete = (id) => {
    return axios.delete(`${url}/${id}`);
}

export const companyPolicyPublish = (id) => {
    return axios.put(`CompanyPolicy/publishPolicy/${id}`);
}

export function companyPolicyAccept(params){
    return axios.post(`CompanyPolicy/accept`, params)
}

export function companyPolicyLatest(){
    return axios.get(`CompanyPolicy/GetLatestPolicy`)
}

export function companyPolicyLatestTenant(id){
    return axios.get(`CompanyPolicy/GetLatestPolicy/${id}`)
}