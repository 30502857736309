import * as types from '../actions/types';


const INITIAL_STATE={
    data:undefined,
    id: undefined,
    success: false,
    message: '',
    error: null
}

export default (state=INITIAL_STATE, action) => {
    switch(action.type)
    {
        case types.FETCH_TenantConfig_SUCCESS:
            return {
                ...state,
                data: action.payload,
            }
        case types.UPDATE_TenantConfig_success: 
            return {
                ...state,
                success: true,
                message: 'Configuration has been successfully updated..'

            }
        case types.TenantConfig_Error: 
            return {
                ...state,
                error: action.payload.ErrorMessage ? action.payload.ErrorMessage : 'Please Try again...'
            }
        case types.RESET_STORE: 
            return {
                ...state,
                success: false,
                message: '',
                error: null
            }
        default:
        return state;
    }  
};