import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { connect } from "react-redux";

class MenuSubmenu extends React.Component {
    render() {
        const { 
            item, 
            currentUrl, 
            layoutConfig 
        } = this.props;
        return (
            <ul className="kt-menu__subnav">
                {item.subModule.map((child, index) => (
                    <React.Fragment key={index}>
                        {child.section && (
                            <MenuSection
                                item={child}
                                parentItem={item}
                                currentUrl={currentUrl}
                            />
                        )}

                        {child.separator && (
                            <MenuItemSeparator
                                item={child}
                                parentItem={item}
                                currentUrl={currentUrl}
                            />
                        )}
                        {child.moduleName && (this.props.user.vhUser || this.props.user.modules.find(m=>m.moduleName.trim() === child.moduleName.trim())) && (
                            <MenuItem
                                item={child}
                                parentItem={item}
                                currentUrl={currentUrl}
                                layoutConfig={layoutConfig}
                            />
                        )}
                    </React.Fragment>
                ))}
            </ul>
        );
    }
}

const mapStateToProps = store => ({
    user: store.auth.user
  });
  
export default connect(mapStateToProps)(MenuSubmenu);
