import React, { Component, Fragment } from 'react';
import clsx from "clsx";
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
// import { 
//     getComparator,
//     stableSort,
//     EnhancedTableHead 
// } from '../../../../crud/SortGrid';
import Tooltip from '@material-ui/core/Tooltip';

class AuditTrailItems extends Component {
    constructor(props){
        super(props);
        this.state = {
            auditTrailList: this.props.auditTrailList
        }
    }

    componentDidMount() {
        this.bindToolTip()
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.auditTrailList){
            if(nextProps.auditTrailList !== this.state.auditTrailList){
                this.setState({
                    auditTrailList: nextProps.auditTrailList,
                });
                this.bindToolTip()
            }
        }
    }

    bindToolTip(){
        const { auditTrailList } = this.state;
        const auditTrailRow = auditTrailList.slice();
        const newRows = auditTrailRow.map(val => {
            if (val.rowref.current.scrollWidth > val.rowref.current.clientWidth) {
              val.open = false;
            }
            return val;
        });
        this.setState({ auditTrailRow: newRows });

    }

    findDate(item){
        const findDate = `${new Date(item).getFullYear()}/${new Date(item).getMonth() + 1}/${new Date(item).getDate()} ${new Date(item).getHours()}:${new Date(item).getMinutes()}:${new Date(item).getSeconds()}:${new Date(item).getMilliseconds()}`;
        return findDate;
    }

    renderItem(item, i){
        const { classes } = this.props;
        const itemReturn = [
            <TableRow className='row-color' key={"row-data-" + i}>
                <TableCell className={clsx('row-overflow', classes.cell)}>
                    {this.findDate(item.dataTime)}
                </TableCell>
                <Tooltip title={item.fileName} disableHoverListener={item.open}>
                    <TableCell
                        ref={item.rowref}
                        className={classes.cell}
                    >
                        {item.fileName}
                    </TableCell>
                </Tooltip>
                <Tooltip title={item.packageId} disableHoverListener={item.open}>
                    <TableCell
                        ref={item.rowref}
                        className={classes.cell}
                    >
                        {item.packageId}
                    </TableCell>
                </Tooltip>
                <Tooltip title={item.snapShotName} disableHoverListener={item.open}>
                    <TableCell
                        ref={item.rowref}
                        className={classes.cell}
                    >
                        {item.snapShotName}
                    </TableCell>
                </Tooltip>
                <Tooltip title={item.processStep} disableHoverListener={item.open}>
                    <TableCell
                        ref={item.rowref}
                        className={classes.cell}
                    >
                        {item.processStep}
                    </TableCell>
                </Tooltip>
                <Tooltip title={item.additionalInfo} disableHoverListener={item.open}>
                    <TableCell
                        ref={item.rowref}
                        className={classes.cell}
                    >
                        {item.additionalInfo}
                    </TableCell>
                </Tooltip>
                <TableCell align="right" className={clsx('row-overflow', classes.cell)}>
                    {item.userId}
                </TableCell>
            </TableRow>
        ]
        return itemReturn;
    }

    render(){
        const { page, rowsPerPage } = this.props
        const { auditTrailList, order, orderBy } = this.state;
        let allItemRows = [];
        auditTrailList.length > 0 && auditTrailList.forEach((item, i) => {
            const perItemRows = this.renderItem(item, i);
            allItemRows = allItemRows.concat(perItemRows);
        });
        return(
            <Fragment>
                {allItemRows}
            </Fragment>
        )
    }
}

export default AuditTrailItems;