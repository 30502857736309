import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {runE2ERequest} from "../../store/actions/tenantAction";

export default function TenantEditHead(props){
    const { tenant } = props
    const classes = useStyles()
    const { user, E2ERan } = useSelector((state)=>({
        user: state.auth.user,
        E2ERan: state.tenant.E2ERan
    }), shallowEqual);
    const dispatch = useDispatch();
    const [userHasSuperAdminRole, setUserHasSuperAdminRole] = useState(false);

    useEffect(() => {
        if(user){
            if(user.vhUser){
                // setUserHasSuperAdminRole(true);
            }else{
                var superAdminRole = user.roles?.find(r=> r.systemDefined === true && r.isDeleted === false && r.roleName === 'Super Admin');
                if(superAdminRole){
                    setUserHasSuperAdminRole(true);
                }
                else{
                    setUserHasSuperAdminRole(false);
                }                
            }
        }else{            
            setUserHasSuperAdminRole(false);
        }
    },[user]);
    const runE2E=()=>{
        dispatch(runE2ERequest({tenantGUID: tenant.tenantGUID}));
    }
    return(
        <>
            {tenant && <div className={classes.containerTop}>
                <div className="row">
                    <div className="col-sm-6">
                        <div className={classes.list}>
                            <div style={{paddingRight: 10, minWidth: 165}}>
                                <h6 style={{margin: 0}}>Subscription Type:</h6>
                            </div>
                            <div>
                                <p style={{margin: 0}}>{tenant.subscriptionType}</p>
                            </div>
                        </div>
                        <div className={classes.list}>
                            <div style={{paddingRight: 10, minWidth: 165}}>
                                <h6 style={{margin: 0}}>Domain:</h6>
                            </div>
                            <div>
                                <p style={{margin: 0}}>{tenant.domain}</p>
                            </div>
                        </div>
                        <div className={classes.list}>
                            <div style={{paddingRight: 10, minWidth: 165}}>
                                <h6 style={{margin: 0}}>Tenant ID:</h6>
                            </div>
                            <div>
                                <p style={{margin: 0}}>{tenant.tenantGUID}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={classes.list}>
                            <div style={{paddingRight: 10, minWidth: 165}}>
                                <h6 style={{margin: 0}}>Tenant Industry Type:</h6>
                            </div>
                            <div>
                                <p style={{margin: 0}}>{tenant.tenantIndustryType}</p>
                            </div>
                        </div>
                        <div className={classes.list}>
                            <div style={{paddingRight: 10, minWidth: 165}}>
                                <h6 style={{margin: 0}}>Tenant Type:</h6>
                            </div>
                            <div>
                                <p style={{margin: 0}}>{tenant.tenantType}</p>
                            </div>
                        </div>
                        <div className={classes.list}>
                            <div style={{paddingRight: 10, minWidth: 165}}>
                                <h6 style={{margin: 0}}>Sub Type:</h6>
                            </div>
                            <div>
                                <p style={{margin: 0}}>{tenant.subType}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {userHasSuperAdminRole && 
                <div className="row">
                    <div className="col-sm-6">
                        <div className={classes.list}>
                            <div style={{paddingRight: 10, minWidth: 165}}>
                                {/* <h6 style={{margin: 0}}>e2e Aggregator:</h6> */}
                            </div>
                            <div>
                                <p style={{margin: 0}}>
                                    <Button variant="contained"
                                            color="primary"
                                            onClick={() => runE2E()}>Trigger End 2 End</Button>
                                           
                                {E2ERan && <i className="fas fa-check" style={{margin: '5px', fontSize: '20px', color: '#0078d4'}}></i>} 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>}
        </>
    )
}

const useStyles = makeStyles({
    container: {
        width: '100%',
        padding: 10,
    },
    paper: {
        width: '100%',
        padding: 10
    },
    table: {
        minWidth: '100%',
        //borderLeft: 'solid 2px #0078d4'
    },
    title: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 500,
        lineHeight: '2.5rem'
    },
    containerTop: {
        padding: 10,
        marginBottom: 10,
        borderTop: 'solid 1px #dddddd',
        borderBottom: 'solid 1px #dddddd'
    },
    list: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '8px 0px'
    },
    commonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    basicLable: {
        minWidth: 165,
    },
    profileContainer: {
        minWidth: 240,
    },
    profileImage: {
        width: 120,
        height: 120
    },
    uploadContainer: {
        width: 'calc(100% - 405px)',
    },
    inputContainer: {
        width: 'calc(100% - 165px)'
    }
});