import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from "react-router-dom";
import GlobalDashboard from './globalDashboard';
import Eligibility from './eligibility';
import ChronicConditions from './chronicConditions';
import JBSQuarterlyReportComponent from './jbs-quarterly-reports';
import JBSUtilizationIndicatorsComponent from './jbs-utilization-indicators'
import JBSClaimsComponent from './jbs-claims'
import JBSClaimsLogComponent from './jbs-claims-lag'
import POCUtilizationComponent from './poc-utilization'
import POCDemographicsComponent from './poc-demographics'
import ReportsPages from './ReportsPages'

class Reports extends Component{ 
    constructor(props){
        super(props)
        const modules = props.modules.find(item => item.moduleName === 'Reports')
        this.state = {
            routers: modules ? modules.subModule : []
        }
    }

    componentWillReceiveProps(nextProps){
        const modules = nextProps && nextProps.modules ? nextProps.modules.find(item => item.moduleName === 'Reports') : []
        // console.log('nextProps', modules)
        this.setState({
            routers: modules.subModule.length > 0 ? modules.subModule : []
        })
    }
    
    render(){
        const { routers } = this.state
        return(
            <Switch>
                {
                    routers && routers.map(
                        (item, key) => 
                        <Route 
                            key={key}
                            exact
                            path={item.pageUrl} 
                            render={ props => <ReportsPages {...props} data={item} /> }
                        />
                    )
                }
            </Switch> 
        )
    }
}

const matStateToProps = (state) => ({
    modules: state.modules.data
})

export default connect(matStateToProps)(Reports);