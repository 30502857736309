import * as types from './types';


const fetchTenantConfigAllList = () => ({
    type: types.FETCH_ALL_TenantConfig
})

const fetchTenantConfigSuccess = ({items}) => ({
    type: types.FETCH_TenantConfig_SUCCESS,
    payload: { 
        items
    }
})

const updateTenantConfigAllList = (data) => ({
    type: types.UPDATE_TenantConfig,
    payload: data
})

const updateTenantConfigSuccess = ({items}) => ({
    type: types.UPDATE_TenantConfig_success,
    payload: { 
        items
    }
})

const error = ({error}) => ({
    type: types.TenantConfig_Error,
    payload: error
});

const reset = () => ({
    type: types.RESET_STORE
})


export {
    fetchTenantConfigAllList,
    fetchTenantConfigSuccess,
    updateTenantConfigAllList,
    updateTenantConfigSuccess,
    error,
    reset
}