import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/tenantAction";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TablePagination
} from '@material-ui/core';
import { EnhancedTableHead } from '../../crud/SortGrid';
import TenantItem from './tenant-item/TenantItem';
import Toast from '../../crud/Toast';

export default function Tenants(props) {
    const dispatch = useDispatch();
    const { currentState, tenentSuccess, tenantMassage, error } = useSelector(
        (state) => ({ 
            currentState: state.tenant.data,
            tenentSuccess: state.tenant.success,
            tenantMassage: state.tenant.message,
            error: state.tenant.error
        }),
        shallowEqual
    );
    const [params, setParams] = useState({
        "sortField": "tenantName",
        "sortDirection": "asc",
        "pageSize": 10,
        "pageNumber": 1,
        "searchText": ""
    }); 
    
    const loader = document.getElementById('splash-screen');
    useEffect(() => {        
        loader.classList.remove('hidden')
        dispatch(actions.getAllTenant(params));
        setTimeout(() => {
            loader.classList.add('hidden') 
        }, 2000)
    }, [dispatch, params]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);  

    const handleChangePage = (event, value) => {
        setAllItemRows([])
        const pageNumber = value + 1
        setPage(value)
        setParams({...params, pageNumber})
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setAllItemRows([]);
        const pageSize= event.target.value;
        setParams({...params, pageSize});
    };
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('tenantName');
    useEffect(()=>{
        if(orderBy.length  > 0){
            setParams(prev=>({...prev, sortField: orderBy,sortDirection: order}));
         //   console.log("handleRequestSort", order, orderBy);
        }        
    },[order, orderBy]);
    const handleRequestSort = (event, property) => {
        if (orderBy === '') {
            setOrder('desc');
            setOrderBy(property);
        } else {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
    };
    let [allItemRows, setAllItemRows] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [count, setCount] = useState(0);
    const classes = useStyles();
    const [level, setLevel] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [toastVisible, setToastVisible] = React.useState(false)
    const [items, setItems] = useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = pageNumber => { setCurrentPage(pageNumber) }


    useEffect(() => {
        if(tenentSuccess){
            setLevel('success')
            setMessage(tenantMassage)
            setToastVisible(tenentSuccess)
            loader.classList.add('hidden');
            //dispatch(actions.getAllTenant(params));
            setTimeout(() => {
                dispatch(actions.getAllTenant(params))
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 1)
        }else { 
            setLevel('')
            setMessage('')
            setToastVisible(false)
        }
    }, [tenentSuccess, tenantMassage, dispatch]);
    useEffect(() => {
        if(error){
            setLevel('danger')
            setMessage(error)
            setToastVisible(true)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.reset())
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 3000)
        }
    }, [error]);
    useEffect(() => {
        setItems(currentState && currentState.items ? currentState.items : []);
    }, [currentState])
    useEffect(()=>{
        setParams(prev=>({...prev, searchText: props.searchText}));
    }, [props.searchText]);



    const onDelete = (item) => {
        if(item.tenantID){
            dispatch(actions.deleteTenantRequest(item.tenantID));
            loader.classList.remove('hidden');
            setTimeout(()=>dispatch(actions.getAllTenant(params)),3000);
        }
    }
    
    const pageNumbers = [];
    if(currentState && currentState.totalItems){
        for (let i = 1; i <= Math.ceil(currentState.totalItems / params.pageSize); i++) {
            pageNumbers.push(i);
        }
    }
    useEffect(() => {
        setCount(Math.ceil(currentState && currentState.totalItems / params.pageSize));
    }, [pageNumbers])
    const emptyRows = currentState && rowsPerPage - Math.min(rowsPerPage, currentState.length - page * rowsPerPage);
    currentState && currentState.items.forEach((item, i) => {
        const perItemRows = <TenantItem key={i} item = {item} index = {i} onDelete={onDelete}/>;
        allItemRows = allItemRows.concat(perItemRows);
    });

    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    const [ieBrowser, setIeBrowser] = useState(isIE ? true : false);
    return ( <>
        {/* <PerfectScrollbar options={{ wheelSpeed: 2, wheelPropagation: false }}
                            style={{padding: 0, maxHeight: ieBrowser && 'calc( 100vh - 180px )'}}> */}
            <Table className={classes.table} >
                <EnhancedTableHead classes = {classes}
                    order={order}
                    headCells={headCells}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={items ? items.length : 0}
                />
                <TableBody >
                    {allItemRows}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 31 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        {/* </PerfectScrollbar> */}
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={currentState ? currentState.totalItems : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Toast
                level={level}
                message={message}
                visible={toastVisible}
            />        
    </>)
}

const headCells = [
    { id: 'tenantID', numeric: false, disablePadding: false, align: 'left', label: 'ID' },
    { id: 'tenantName', numeric: false, disablePadding: false, align: 'left', label: 'Tenant Name ' },
    { id: 'subscriptionName', numeric: false, disablePadding: false, align: 'left', label: 'Subscription Type' },
    { id: 'domain', numeric: false, disablePadding: false, align: 'left', label: 'Domain' },
    { id: 'actions', numeric: false, disablePadding: false, align: 'right', label: 'Actions' },
];

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        width: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    }
})
