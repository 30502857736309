import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import configSaga from './sagas/config';
import globalConfigSaga from './sagas/globalConfig';
import runtimeSaga from './sagas/runtime';
import reportsSaga from './sagas/reports'
import sunbscritionsSaga from './sagas/subscriptions'
import tenantSaga from './sagas/tenant'
import tenantIdustrySaga from './sagas/tenantIndustry'
import organisationSaga from './sagas/organisation'
import tenantconfigurationSaga from './sagas/tenantconfiguration'
import roles from './sagas/roles'
import tenantWorkspacerSaga from './sagas/workspace'
import permissionSaga from './sagas/permission';
import powerBICapacityManagementSaga from './sagas/powerBICapacityManagement'
import companyPolicySaga from './sagas/companyPolicy'
import * as auth from "./ducks/auth.duck";
import fileOperationsSaga from "./sagas/fileOperations";
import ConfigureWorkspacesSaga from './sagas/configureWorkspaces'
import RoleSaga from "./sagas/roles";
import manageUserSaga from './sagas/manageUser'
import manageReportSaga from './sagas/managereport';
import modulesSaga from './sagas/modules'
import cefitSaga from './sagas/cefit';


//import * as config from "./ducks/configurations.duck"
import ConfigReducer from "./reducers/configReducer";
import GlobalConfigReducer from './reducers/globalConfigReducer';
import RuntimeReducer from "./reducers/runtimeReducer";
import ReportReducer from './reducers/reportsReducer';
import { metronic } from "../../_metronic";
import  authReducer from './reduxStore';
import SubscriptionReducer from './reducers/subscriptionsReducer';
import TenantReducer from './reducers/tenantReducer'
import tenantIndustryReducer from './reducers/tenantIndustryReducer'
import tenantIndustryLevels from './reducers/tenantIndustryLevelsReducer'
import levelsTypeReducer from './reducers/levelsTypeReducer'
import organisationReducer from './reducers/organisationReducer'
import tenantconfigurationReducer from './reducers/tenantconfigurationReducer'
import rolereducer from './reducers/roleReducer'
import workspaceReducer from './reducers/workspaceReducer'
import permissionRaducer from './reducers/permissionRaducer'
import permissionAddRaducer from './reducers/permissionRaducer'
import companyPolicyReducer from './reducers/companyPolicyReducer'
import fileOperationsReducer from './reducers/fileOperationReducer'
import configureWorkspacesRaducer from './reducers/configureWorkspacesRaducer'
import manageUserReducer from './reducers/manageUserReducer'
import manageReportReducer from './reducers/manageReportReducer';
import modulesReducer from './reducers/modulesReducer'
import cefitReducer from "./reducers/cefitReducer";
import powerBICapacityManagementReducer from "./reducers/powerBICapacityManagementReducer";

export const rootReducer = combineReducers(Object.assign({
  config: ConfigReducer,
  globalConfig: GlobalConfigReducer,
  runtime: RuntimeReducer,
  auth: auth.reducer,
  authReducer: authReducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  reports: ReportReducer,
  subscriptions: SubscriptionReducer,
  tenant: TenantReducer,
  tenantIndustry: tenantIndustryReducer,
  industryLevels: tenantIndustryLevels,
  levelsType: levelsTypeReducer,
  organisation: organisationReducer,  
  tenantConfig:tenantconfigurationReducer,
  roles:rolereducer,
  workspace: workspaceReducer,
  permission: permissionRaducer,
  permissionAdd: permissionAddRaducer,
  powerBICapacityManagement: powerBICapacityManagementReducer,
  companyPolicy: companyPolicyReducer,
  fileOperation:fileOperationsReducer,
  configureWorkspaces: configureWorkspacesRaducer,
  manageUser: manageUserReducer,
  manageReport: manageReportReducer,
  modules: modulesReducer,
  cefits: cefitReducer
}));

export function* rootSaga() {
  yield all([
    ...configSaga,
    ...globalConfigSaga,
    ...runtimeSaga,
    ...reportsSaga,
    ...sunbscritionsSaga,
    ...tenantSaga,
    ...tenantIdustrySaga,
    ...organisationSaga,
    ...tenantconfigurationSaga,
    ...RoleSaga,
    ...tenantWorkspacerSaga,
    ...permissionSaga,
    ...powerBICapacityManagementSaga,
    ...companyPolicySaga,
    ...fileOperationsSaga,
    ...ConfigureWorkspacesSaga,
    ...manageUserSaga,
    ...manageReportSaga,
    ...modulesSaga,
    ...cefitSaga,
    auth.saga(),
  ]);
}
