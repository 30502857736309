/****SnapShot Types****/
export const FETCH_CONFIG = "FETCH_CONFIG";
export const CREATE_CONFIG_REQUEST = "CREATE_CONFIG_REQUEST";
export const FETCH_CONFIG_SUCCESS = "FETCH_CONFIG_SUCCESS";
export const FETCH_CONFIG_FAILURE = "FETCH_CONFIG_FAILURE";
export const DELETE_CONFIG_REQUEST = "DELETE_CONFIG_REQUEST";
export const UPDATE_CONFIG_REQUEST = "UPDATE_CONFIG_REQUEST";
export const CONFIG_ERROR = "CONFIG_ERROR";
export const UPDATE_CONFIG_SUCCESS = "UPDATE_CONFIG_SUCCESS";
export const ADD_CONFIG_SUCCESS = "ADD_CONFIG_SUCCESS";
export const DELETE_CONFIG_SUCCESS = "DELETE_CONFIG_SUCCESS";
/****File Operations****/
export const FETCH_FILE_OPERATIONS = "FETCH_FILE_OPERATIONS";
export const FETCH_FILE_OPERATIONS_SUCCESS = "FETCH_FILE_OPERATIONS_SUCCESS";
export const CREATE_FILE_OPERATIONS_REQUEST = "CREATE_FILE_OPERATIONS_REQUEST";
export const CREATE_FILE_OPERATIONS_SUCCESS = "CREATE_FILE_OPERATIONS_SUCCESS";
export const UPDATE_FILE_OPERATIONS_REQUEST = "UPDATE_FILE_OPERATIONS_REQUEST";
export const UPDATE_FILE_OPERATIONS_SUCCESS = "UPDATE_FILE_OPERATIONS_SUCCESS";
export const DELETE_FILE_OPERATIONS_REQUEST = "DELETE_FILE_OPERATIONS_REQUEST";
export const DELETE_FILE_OPERATIONS_SUCCESS = "DELETE_FILE_OPERATIONS_SUCCESS";
/**** Automated Cleansing ****/
export const FETCH_AUTO_CLEANSING = 'FETCH_AUTO_CLEANSING';
export const FETCH_AUTO_CLEANSING_SUCCESS = "FETCH_AUTO_CLEANSING_SUCCESS";
/**** Based Cleansing */
export const FETCH_BASED_CLEANSING_SUCCESS = "FETCH_BASED_CLEANSING_SUCCESS";
export const FETCH_BASED_CLEANSING = "FETCH_BASED_CLEANSING";
/*** Customer ***/
export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
/*** Staging ***/
export const FETCH_STAGE = "FETCH_STAGE";
export const FETCH_STAGE_SUCCESS = "FETCH_STAGE_SUCCESS";
/***********************RunTime************************/
export const FETCH_RUNTIME = 'FETCH_RUNTIME';
export const FETCH_RUNTIME_SUCCESS = 'FETCH_RUNTIME_SUCCESS';
export const FETCH_RUNTIME_FILTER = 'FETCH_RUNTIME_FILTER';
export const FETCH_RUNTIME_FILTER_SUCCESS = 'FETCH_RUNTIME_FILTER_SUCCESS';
export const FETCH_AUDIT = 'FETCH_AUDIT';
export const FETCH_AUDIT_FILTER = 'FETCH_AUDIT_FILTER';
export const FETCH_AUDIT_FILTER_SUCCESS = 'FETCH_AUDIT_FILTER_SUCCESS'
export const FETCH_AUDIT_SUCCESS = 'FETCH_AUDIT_SUCCESS';
export const FETCH_TABLEDATA = 'FETCH_TABLEDATA';
export const FETCH_TABLEDATA_SUCCESS = 'FETCH_TABLEDATA_SUCCESS';
export const UPDATE_TABLEDATA_REQUEST = 'UPDATE_TABLEDATA_REQUEST';
export const UPDATE_TABLEDATA_SUCCESS = 'UPDATE_TABLEDATA_SUCCESS';

/****************************Reports******************************/

export const FETCH_REPORTS = 'FETCH_REPORTS';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const EXPORT_REPORT = 'EXPORT_REPORT';
export const EXPORT_REPORT_SUCCESS = 'EXPORT_REPORT_SUCCESS';
export const RESET_REPORT_FILE = 'RESET_REPORT_FILE';

/****************************Global Config******************************/

export const FETCH_GLOBAL_CONFIG = "FETCH_GLOBAL_CONFIG";
export const FETCH_GLOBAL_CONFIG_SUCCESS = "FETCH_GLOBAL_CONFIG_SUCCESS";
export const FETCH_ALL_GLOBAL_CONFIG = "FETCH_ALL_GLOBAL_CONFIG";
export const FETCH_ALL_GLOBAL_CONFIG_SUCCESS = "FETCH_ALL_GLOBAL_CONFIG_SUCCESS";
export const UPDATE_GLOBAL_CONFIG_REQUEST = 'UPDATE_GLOBAL_CONFIG_REQUEST';
export const UPDATE_GLOBAL_CONFIG_SUCCESS = 'UPDATE_GLOBAL_CONFIG_SUCCESS';
export const ADD_GLOBAL_CONFIG_REQUEST = 'ADD_GLOBAL_CONFIG_REQUEST';
export const ADD_GLOBAL_CONFIG_SUCCESS = 'ADD_GLOBAL_CONFIG_SUCCESS';
export const DELETE_GLOBAL_CONFIG_REQUEST = 'DELETE_GLOBAL_CONFIG_REQUEST';
export const DELETE_GLOBAL_CONFIG_SUCCESS = 'DELETE_GLOBAL_CONFIG_SUCCESS';
export const GLOBAL_CONFIG_ERROR = 'GLOBAL_CONFIG_ERROR';

/****************************Global Config******************************/

export const GET_ALL_POWERBI_CAPACITY_MANAGEMENT = "GET_ALL_POWERBI_CAPACITY_MANAGEMENT";
export const GET_ALL_POWERBI_CAPACITY_MANAGEMENT_SUCCESS = "GET_ALL_POWERBI_CAPACITY_MANAGEMENT_SUCCESS";
export const GET_ALL_CAPACITIES = "GET_ALL_CAPACITIES";
export const GET_ALL_CAPACITIES_SUCCESS = "GET_ALL_CAPACITIES_SUCCESS";
export const UPDATE_POWERBI_CAPACITY_MANAGEMENT = "UPDATE_POWERBI_CAPACITY_MANAGEMENT";
export const UPDATE_POWERBI_CAPACITY_MANAGEMENT_SUCCESS = "UPDATE_POWERBI_CAPACITY_MANAGEMENT_SUCCESS";
export const POWERBI_CAPACITY_MANAGEMENT_ERROR="POWERBI_CAPACITY_MANAGEMENT_ERROR";
export const POWERBI_CAPACITY_MANAGEMENT_RESET="POWERBI_CAPACITY_MANAGEMENT_RESET";
/****************************Subscriptions******************************/
export const FETCH_ACTIVE_SUBSCRIPTIONS = "FETCH_ACTIVE_SUBSCRIPTIONS";
export const FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS = "FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS";
export const FETCH_ALL_SUBSCRIPTIONS = "FETCH_ALL_SUBSCRIPTIONS";
export const FETCH_ALL_SUBSCRIPTIONS_SUCCESS = "FETCH_ALL_SUBSCRIPTIONS_SUCCESS";

export const UPDATE_SUBSCRIPTIONS_REQUEST = 'UPDATE_SUBSCRIPTIONS_REQUEST';
export const UPDATE_SUBSCRIPTIONS_SUCCESS = 'UPDATE_SUBSCRIPTIONS_SUCCESS';
export const ADD_SUBSCRIPTIONS_REQUEST = 'ADD_SUBSCRIPTIONS_REQUEST';
export const ADD_SUBSCRIPTIONS_SUCCESS = 'ADD_SUBSCRIPTIONS_SUCCESS';
export const DELETE_SUBSCRIPTIONS_REQUEST = 'DELETE_SUBSCRIPTIONS_REQUEST';
export const DELETE_SUBSCRIPTIONS_SUCCESS = 'DELETE_SUBSCRIPTIONS_SUCCESS';
export const SUBSCRIPTIONS_ERROR = 'SUBSCRIPTIONS_ERROR'

/****************************Tenant******************************/

export const FETCH_TENANT = "FETCH_TENANT";
export const FETCH_TENANT_SUCCESS = "FETCH_TENANT_SUCCESS";
export const FETCH_TENANT_BY_ID = "FETCH_TENANT_BY_ID";
export const FETCH_TENANT_BY_ID_SUCCESS = "FETCH_TENANT_BY_ID_SUCCESS";
export const UPDATE_TENANT_REQUEST = 'UPDATE_TENANT_REQUEST';
export const UPDATE_TENANT_SUCCESS = 'UPDATE_TENANT_SUCCESS';
export const ADD_TENANT_REQUEST = 'ADD_TENANT_REQUEST';
export const ADD_TENANT_SUCCESS = 'ADD_TENANT_SUCCESS';
export const DELETE_TENANT_REQUEST = 'DELETE_TENANT_REQUEST';
export const DELETE_TENANT_SUCCESS = 'DELETE_TENANT_SUCCESS';
export const FATCH_COUNTRY = 'FATCH_COUNTRY';
export const FATCH_COUNTRY_SUCCESS = 'FATCH_COUNTRY_SUCCESS';
export const FATCH_STATE = 'FATCH_STATE';
export const FATCH_STATE_SUCCESS = 'FATCH_STATE_SUCCESS';
export const TENANT_ACTIVE_STEP_SET = 'TENANT_ACTIVE_STEP_SET';
export const RUN_E2E_REQUEST = 'RUN_E2E_REQUEST';
export const RUN_E2E_SUCCESS = 'RUN_E2E_SUCCESS';
export const RUN_ADD_NEW_USERS_TO_REPORTS_REQUEST = 'RUN_ADD_NEW_USERS_TO_REPORTS_REQUEST';
export const RUN_ADD_NEW_USERS_TO_REPORTS_SUCCESS = 'RUN_ADD_NEW_USERS_TO_REPORTS_SUCCESS';
/****************************TenantIndustry******************************/

export const GET_ALL_TENANTINDUSTRIES = "GET_ALL_TENANTINDUSTRIES";
export const GET_ALL_TENANTINDUSTRIES_SUCCESS = "GET_ALL_TENANTINDUSTRIES_SUCCESS";

export const GET_ACTIVE_TENANTINDUSTRIES = "GET_ACTIVE_TENANTINDUSTRIES";
export const GET_ACTIVE_TENANTINDUSTRIES_SUCCESS = "GET_ACTIVE_TENANTINDUSTRIES_SUCCESS";

export const UPDATE_TENANTINDUSTRY_REQUEST = 'UPDATE_TENANTINDUSTRY_REQUEST';
export const UPDATE_TENANTINDUSTRY_SUCCESS = 'UPDATE_TENANTINDUSTRY_SUCCESS';
export const ADD_TENANTINDUSTRY_REQUEST = 'ADD_TENANTINDUSTRY_REQUEST';
export const ADD_TENANTINDUSTRY_SUCCESS = 'ADD_TENANTINDUSTRY_SUCCESS';
export const DELETE_TENANTINDUSTRY_REQUEST = 'DELETE_TENANTINDUSTRY_REQUEST';
export const DELETE_TENANTINDUSTRY_SUCCESS = 'DELETE_TENANTINDUSTRY_SUCCESS';
export const TENANTINDUSTRY_ERROR = 'TENANTINDUSTRY_ERROR'

/****************************TenantIndustryLevels******************************/

export const FETCH_TENANTINDUSTRY_LEVELS = "FETCH_TENANTINDUSTRY_LEVELS";
export const FETCH_TENANTINDUSTRY_LEVELS_SUCCESS = "FETCH_TENANTINDUSTRY_LEVELS_SUCCESS";
export const FETCH_TENANTINDUSTRY_BYID_LEVELS = "FETCH_TENANTINDUSTRY_BYID_LEVELS";
export const FETCH_TENANTINDUSTRY_BYID_LEVELS_SUCCESS = "FETCH_TENANTINDUSTRY_BYID_LEVELS_SUCCESS";
export const UPDATE_TENANTINDUSTRY_LEVELS_REQUEST = 'UPDATE_TENANTINDUSTRY_LEVELS_REQUEST';
export const UPDATE_TENANTINDUSTRY_LEVELS_SUCCESS = 'UPDATE_TENANTINDUSTRY_LEVELS_SUCCESS';
export const ADD_TENANTINDUSTRY_LEVELS_REQUEST = 'ADD_TENANTINDUSTRY_LEVELS_REQUEST';
export const ADD_TENANTINDUSTRY_LEVELS_SUCCESS = 'ADD_TENANTINDUSTRY_LEVELS_SUCCESS';
export const DELETE_TENANTINDUSTRY_LEVELS_REQUEST = 'DELETE_TENANTINDUSTRY_LEVELS_REQUEST';
export const DELETE_TENANTINDUSTRY_LEVELS_SUCCESS = 'DELETE_TENANTINDUSTRY_LEVELS_SUCCESS';

/****************************LevelsType******************************/

export const FETCH_LEVELS_TYPE = "FETCH_LEVELS_TYPE";
export const FETCH_LEVELS_TYPE_SUCCESS = "FETCH_LEVELS_TYPE_SUCCESS";
export const UPDATE_LEVELS_TYPE_REQUEST = 'UPDATE_LEVELS_TYPE_REQUEST';
export const UPDATE_LEVELS_TYPE_SUCCESS = 'UPDATE_LEVELS_TYPE_SUCCESS';
export const ADD_LEVELS_TYPE_REQUEST = 'ADD_LEVELS_TYPE_REQUEST';
export const ADD_LEVELS_TYPE_SUCCESS = 'ADD_LEVELS_TYPE_SUCCESS';
export const DELETE_LEVELS_TYPE_REQUEST = 'DELETE_LEVELS_TYPE_REQUEST';
export const DELETE_LEVELS_TYPE_SUCCESS = 'DELETE_LEVELS_TYPE_SUCCESS';

/****************************LevelsType******************************/

export const FETCH_ALL_TenantConfig="FETCH_ALL_TenantConfig";
export const FETCH_TenantConfig_SUCCESS="FETCH_TenantConfig_SUCCESS";
export const UPDATE_TenantConfig="UPDATE_TenantConfig";
export const UPDATE_TenantConfig_success="UPDATE_TenantConfig_success";
export const TenantConfig_Error = 'TenantConfig_Error'

/************************organisation*******************************/

export const FETCH_ORGANISATION = "FETCH_ORGANISATION";
export const FETCH_ORGANISATION_SUCCESS = "FETCH_ORGANISATION_SUCCESS";
export const UPDATE_ORGANISATION_REQUEST = 'UPDATE_ORGANISATION_REQUEST';
export const UPDATE_ORGANISATION_SUCCESS = 'UPDATE_ORGANISATION_SUCCESS';
export const ADD_ORGANISATION_REQUEST = 'ADD_ORGANISATION_REQUEST';
export const ADD_ORGANISATION_SUCCESS = 'ADD_ORGANISATION_SUCCESS';
export const DELETE_ORGANIZATION_REQUEST = 'DELETE_ORGANIZATION_REQUEST';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const ORGANISATION_importHierarchyFile_REQUEST = 'ORGANISATION_importHierarchyFile_REQUEST';
export const ORGANISATION_importHierarchyFile_SUCCESS = 'ORGANISATION_importHierarchyFile_SUCCESS';
export const ORGANISATION_Error = 'ORGANISATION_Error'

/****************************Role******************************/

export const FETCH_ALL_Role="FETCH_ALL_Role";
export const FETCH_Role_SUCCESS="FETCH_Role_SUCCESS";
export const FETCH_ALL_Role_tenant="FETCH_ALL_Role_tenant";
export const FETCH_Role_Tenant_SUCCESS="FETCH_Role_Tenant_SUCCESS";
export const ADD_Role_REQUEST="ADD_Role_REQUEST";
export const ADD_Role_SUCCESS="ADD_Role_SUCCESS";
export const ADD_Role_create_REQUEST="ADD_Role_create_REQUEST";
export const ADD_Role_create_SUCCESS="ADD_Role_create_SUCCESS"
export const UPDATE_Role_REQUEST="UPDATE_Role_REQUEST";
export const UPDATE_Role_SUCCESS="UPDATE_Role_SUCCESS";
export const DELETE_Role_Request="DELETE_Role_Request";
export const DELETE_Role_SUCCESS="DELETE_Role_SUCCESS";
export const FETCH_Role_BY_ID_REQUEST="FETCH_Role_BY_ID_REQUEST";
export const FETCH_Role_BY_ID_SUCCESS="FETCH_Role_BY_ID_SUCCESS"
export const FETCH_Role_BY_tenantID_REQUEST="FETCH_Role_BY_tenantID_REQUEST"
export const FETCH_Role_BY_tenantID_SUCCESS="FETCH_Role_BY_tenantID_SUCCESS"
export const Role_Error = 'Role_Error'

/**************************Workspace***********************/

export const FETCH_WORKSPACE = "FETCH_WORKSPACE";
export const FETCH_WORKSPACE_SUCCESS = "FETCH_WORKSPACE_SUCCESS";

/**************************Permission***********************/

export const FETCH_PERMISSION = "FETCH_PERMISSION";
export const FETCH_PERMISSION_SUCCESS = "FETCH_PERMISSION_SUCCESS";
export const SAVE_PERMISSION = "SAVE_PERMISSION";
export const SAVE_PERMISSION_SUCCESS = "SAVE_PERMISSION_SUCCESS";
export const ADD_PERMISSION = "ADD_PERMISSION";
export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS";
export const PERMISSION_ERROR = 'PERMISSION_ERROR'

/**************************CompanyPolicy***********************/

export const FETCH_COMPANYPOLICY = "FETCH_COMPANYPOLICY";
export const FETCH_COMPANYPOLICY_SUCCESS = "FETCH_COMPANYPOLICY_SUCCESS";
export const FETCH_COMPANYPOLICY_TENANT = "FETCH_COMPANYPOLICY_TENANT";
export const FETCH_COMPANYPOLICY_TENANT_SUCCESS = "FETCH_COMPANYPOLICY_TENANT_SUCCESS";
export const SAVE_COMPANYPOLICY = "SAVE_COMPANYPOLICY";
export const SAVE_COMPANYPOLICY_SUCCESS = "SAVE_COMPANYPOLICY_SUCCESS";
export const ADD_COMPANYPOLICY = "ADD_COMPANYPOLICY";
export const ADD_COMPANYPOLICY_SUCCESS = "ADD_COMPANYPOLICY_SUCCESS";
export const CREATE_COMPANYPOLICY = "CREATE_COMPANYPOLICY";
export const CREATE_COMPANYPOLICY_SUCCESS = "CREATE_COMPANYPOLICY_SUCCESS";
export const COMPANYPOLICY_PUBLISH = "COMPANYPOLICY_PUBLISH";
export const COMPANYPOLICY_PUBLISH_SUCCESS = "COMPANYPOLICY_PUBLISH_SUCCESS";
export const COMPANYPOLICY_ACCEPT = "COMPANYPOLICY_ACCEPT";
export const COMPANYPOLICY_ACCEPT_SUCCESS = "COMPANYPOLICY_ACCEPT_SUCCESS";
export const COMPANYPOLICY_LATEST = "COMPANYPOLICY_LATEST";
export const COMPANYPOLICY_LATEST_SUCCESS = "COMPANYPOLICY_LATEST_SUCCESS";
export const COMPANYPOLICY_LATEST_TENANT = "COMPANYPOLICY_LATEST_TENANT";
export const COMPANYPOLICY_LATEST_TENANT_SUCCESS = "COMPANYPOLICY_LATEST_TENANT_SUCCESS";
export const COMPANYPOLICY_ERROR = 'COMPANYPOLICY_ERROR'

/**************************File Operations***********************/

export const FETCH_FILEOPERATION = "FETCH_FILEOPERATION";
export const FETCH_FILEOPERATION_SUCCESS = "FETCH_FILEOPERATION_SUCCESS";
export const SAVE_FILEOPERATION = "SAVE_FILEOPERATION";
export const SAVE_FILEOPERATION_SUCCESS = "SAVE_FILEOPERATION_SUCCESS";
export const ADD_FILEOPERATION = "ADD_FILEOPERATION";
export const ADD_FILEOPERATION_SUCCESS = "ADD_FILEOPERATION_SUCCESS";

/************************ConfigureWorkspaces************************/

export const FETCH_ConfigureWorkspaces = 'FETCH_ConfigureWorkspaces';
export const FETCH_ConfigureWorkspaces_Success = 'FETCH_ConfigureWorkspaces_Success';
export const UPDATE_ConfigureWorkspaces = 'UPDATE_ConfigureWorkspaces'
export const UPDATE_ConfigureWorkspaces_Success = 'UPDATE_ConfigureWorkspaces_Success'
export const ConfigureWorkspaces_Error = 'ConfigureWorkspaces_Error'

/***************************ManageUser****************************/

export const FETCH_MANAGEUSER_REQUEST = "FETCH_MANAGEUSER_REQUEST";
export const FETCH_MANAGEUSER_SUCCESS = "FETCH_MANAGEUSER_SUCCESS";
export const FETCH_MANAGEUSER_TENANTID_REQUEST = "FETCH_MANAGEUSER_TENANTID_REQUEST";
export const FETCH_MANAGEUSER_TENANTID_SUCCESS = "FETCH_MANAGEUSER_TENANTID_SUCCESS";
export const FETCH_MANAGEUSER_BYTENANTID_REQUEST = "FETCH_MANAGEUSER_BYTENANTID_REQUEST";
export const FETCH_MANAGEUSER_BYTENANTID_SUCCESS = "FETCH_MANAGEUSER_BYTENANTID_SUCCESS";
export const FETCH_MANAGEUSER_BYUSERID_REQUEST = "FETCH_MANAGEUSER_BYUSERID_REQUEST";
export const FETCH_MANAGEUSER_BYUSERID_SUCCESS = "FETCH_MANAGEUSER_BYUSERID_SUCCESS";
export const ADD_MANAGEUSER_REQUEST = "ADD_MANAGEUSER_REQUEST";
export const ADD_MANAGEUSER_SUCCESS = "ADD_MANAGEUSER_SUCCESS";
export const UPDATE_MANAGEUSER_REQUEST = "UPDATE_MANAGEUSER_REQUEST";
export const UPDATE_MANAGEUSER_SUCCESS = "UPDATE_MANAGEUSER_SUCCESS";
export const DELETE_MANAGEUSER_REQUEST = "DELETE_MANAGEUSER_REQUEST";
export const DELETE_MANAGEUSER_SUCCESS = "DELETE_MANAGEUSER_SUCCESS";
export const CREATE_MANAGEUSER_REQUEST = "CREATE_MANAGEUSER_REQUEST";
export const CREATE_MANAGEUSER_SUCCESS = "CREATE_MANAGEUSER_SUCCESS";
export const CREATE_MANAGEUSER_BYTENANTID_REQUEST = "CREATE_MANAGEUSER_BYTENANTID_REQUEST";
export const CREATE_MANAGEUSER_BYTENANTID_SUCCESS = "CREATE_MANAGEUSER_BYTENANTID_SUCCESS";
export const MANAGEUSER_ERROR = 'MANAGEUSER_ERROR';
export const RESET_MANAGEUSER_STORE_DATA = "RESET_MANAGEUSER_STORE_DATA";

/****************************Manage Report******************************/

export const FETCH_MANAGE_REPORT = "FETCH_MANAGE_REPORT";
export const FETCH_MANAGE_REPORT_SUCCESS = "FETCH_MANAGE_REPORT_SUCCESS";
export const UPDATE_REPORT_REQUEST = "UPDATE_REPORT_REQUEST";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
export const FETCH_REPORT_BY_MODULEID_REQUEST = 'FETCH_REPORT_BY_MODULEID_REQUEST';
export const FETCH_REPORT_BY_MODULEID_SUCCESS = 'FETCH_REPORT_BY_MODULEID_SUCCESS';
export const FETCH_REPORT_BY_ID_REQUEST = 'FETCH_REPORT_BY_ID_REQUEST';
export const FETCH_REPORT_BY_ID_SUCCESS = 'FETCH_REPORT_BY_ID_SUCCESS';
export const FETCH_REPORT_FROM_POWERBI_REQUEST = 'FETCH_REPORT_FROM_POWERBI_REQUEST';
export const FETCH_REPORT_FROM_POWERBI_SUCCESS = 'FETCH_REPORT_FROM_POWERBI_SUCCESS';

/*******************************Modules************************************/

export const FETCH_MODULES = 'FETCH_MODULES'
export const FETCH_MODULES_SUCCESS = 'FETCH_MODULES_SUCCESS'

/*******************************REPORT*************************************/

export const FETCH_REPORT_BY_REPORTID = 'FETCH_REPORT_BY_REPORTID';
export const FETCH_REPORT_BY_REPORTID_SUCCESS = 'FETCH_REPORT_BY_REPORTID_SUCCESS';
export const TENANT_ERROR = 'TENANT_ERROR'
export const RESET_STORE = 'RESET_STORE';

/*******************************Cefit********************************/

export const FETCH_CEFIT_LIST = 'FETCH_CEFIT_LIST';
export const FETCH_CEFIT_LIST_SUCCESS = 'FETCH_CEFIT_LIST_SUCCESS';
export const FETCH_CEFIT_BY_ID = 'FETCH_CEFIT_BY_ID';
export const FETCH_CEFIT_BY_ID_SUCCESS = 'FETCH_CEFIT_BY_ID_SUCCESS'
export const FETCH_CEFIT_TABLE_NAME = 'FETCH_CEFIT_TABLE_NAME';
export const FETCH_CEFIT_TABLE_NAME_SUCCESS = 'FETCH_CEFIT_TABLE_NAME_SUCCESS';
export const FETCH_CEFIT_COLUMN_NAME = 'FETCH_CEFIT_COLUMN_NAME';
export const FETCH_CEFIT_COLUMN_NAME_SUCCESS = 'FETCH_CEFIT_COLUMN_NAME_SUCCESS';
export const FETCH_CEFIT_UPDATE_EXCEL = 'FETCH_CEFIT_UPDATE_EXCEL';
export const FETCH_CEFIT_UPDATE_EXCEL_SUCCESS = 'FETCH_CEFIT_UPDATE_EXCEL_SUCCESS';
export const FETCH_CEFIT_UPDATE = 'FETCH_CEFIT_UPDATE';
export const FETCH_CEFIT_UPDATE_SUCCESS = 'FETCH_CEFIT_UPDATE_SUCCESS';
export const FETCH_CEFIT_CREATE = 'FETCH_CEFIT_CREATE';
export const FETCH_CEFIT_CREATE_SUCCESS = 'FETCH_CEFIT_CREATE_SUCCESS';
export const FETCH_CEFIT_DELETE = 'FETCH_CEFIT_DELETE';
export const FETCH_CEFIT_DELETE_SUCCESS = 'FETCH_CEFIT_DELETE_SUCCESS';
export const FETCH_CEFIT_TEST = 'FETCH_CEFIT_TEST';
export const FETCH_CEFIT_TEST_SUCCESS = 'FETCH_CEFIT_TEST_SUCCESS';
export const FETCH_CEFIT_APPROVE = 'FETCH_CEFIT_APPROVE';
export const FETCH_CEFIT_APPROVE_SUCCESS = 'FETCH_CEFIT_APPROVE_SUCCESS';
export const FETCH_CEFIT_REFRESH = 'FETCH_CEFIT_REFRESH';
export const FETCH_CEFIT_REFRESH_SUCCESS = 'FETCH_CEFIT_REFRESH_SUCCESS';
export const RESET_UPDATE_VALUE = 'RESET_UPDATE_VALUE'
export const CEFIT_ERROR = 'CEFIT_ERROR'