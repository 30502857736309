import React, { useState, Fragment } from 'react'
import {
    TableCell,
    TableRow,
    IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import {  useHistory } from 'react-router-dom';//Link,
import { DeleteConfirmation } from '../../../crud/LayoutUtilsService'

export default function TenantItem(props) {
    const history = useHistory()
    const classes = useStyles();
    const [item, setItem] = useState(props.item);
    React.useEffect(() => {
        setItem(props.item)
    }, [props.item])
    const [index, setIndex] = useState(props.index)
    const [deleteItem, setDelete] = useState(false);
    const onOpenEditPage = (tenant) => {
        history.push(`/tenant/${tenant.tenantID}/edit`)
    }
    const deleteConfirm = () => {
        setDelete(true)
    }
    
    const deteteClose = (rowIndex, action) => {
        if (action === 'delete') {
            const loader = document.getElementById('splash-screen');
            loader.classList.remove('hidden')
            props.onDelete(item);
            setTimeout(() => {
                loader.classList.add('hidden')
                setDelete(false);
            }, 2000);
        } else if (action === 'close') {
            setTimeout(() => {
                setDelete(false);
            }, 500)
        }
    }
    return ( 
        <TableRow className = 'row-color' key = { "row-data-" + index } >
            <TableCell className = { classes.cellBtn } > { item.tenantID } </TableCell>
            <TableCell>
                <a type="button" onClick={() => onOpenEditPage(item)} style={{color: '#0095ff'}}>
                    {item.tenantName}
                </a>
            </TableCell>
            <TableCell className = { classes.cell } > { item.subscriptionName } </TableCell>
            <TableCell className = { classes.cell } > { item.domain } </TableCell>
            <TableCell className = { classes.cell } align = "right" style = {{ paddingRight: 0 } } >
                <Fragment >
                    <IconButton 
                        color="default" 
                        className={classes.button} 
                        component ="span" 
                        onClick={deleteConfirm} 
                    >
                        <DeleteIcon color = "error"/>
                        {deleteItem &&
                            <DeleteConfirmation
                                _openDialog={deleteItem}
                                index={index}
                                handleDelete={deteteClose}
                            />
                        } 
                    </IconButton> 
                </Fragment>
            </TableCell>
        </TableRow>
    )
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
        padding: 0
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        width: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    }
});
