import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, call } from "redux-saga/effects";
import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';
import { getUserByToken, getUserByTokenOne } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";

export const actionTypes = {
  Login: "[Login] Action",
  OneLogin: "[OneLogin] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserRequestedOne: "[User Requested One] Action",
  UserLoaded: "[Load User] Auth API"
};

const initialAuthState = {
  user: undefined,
  oneUser: undefined,
  azureUser: undefined,
  accessToken: undefined
};

export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "accessToken"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
          case actionTypes.Login: {
            
              // console.log("Login", state, action.payload );
              const { accessToken } = action.payload;
              return { accessToken: action.payload.accessToken.jwtIdToken, azureUser: action.payload.accessToken.account };
          }

          case actionTypes.OneLogin: {
            // console.log("OneLogin", state, action.payload );
            return {accessToken: action.payload.accessToken, oneUser: action.payload.oneUser};
          }

          case actionTypes.Register: {
              const { accessToken } = action.payload;
              return { accessToken, user: action.payload.oneUser };
          }

          case actionTypes.UserRequestedOne: {
            
              // console.log("UserRequestedOne", state, action.payload );
              return { 
                  ...state,
                  accessToken: action.payload.accessToken, 
                  user: undefined//action.payload.oneUser 
              };
          }

          case actionTypes.Logout: {
              routerHelpers.forgotLastLocation();
              sessionStorage.removeItem('accessToken');
              return initialAuthState;
          }

          case actionTypes.UserLoaded: {
            // console.log("UserLoaded", state, action.payload );

            const { user } = action.payload;  
            return { ...state, user };
          }

        default:
            return state;
        }
    }
);

export const actions = {
  login: (accessToken, azureUser) => ({ 
    type: actionTypes.Login, 
    payload: { accessToken, azureUser } 
  }),

  oneLogin: (accessToken, oneUser) => ({ 
    type: actionTypes.OneLogin, 
    payload: {accessToken, oneUser}
  }),

  register: accessToken => ({
    type: actionTypes.Register,
    payload: { accessToken }
  }),
  logout: () => ({ 
    type: actionTypes.Logout 
  }),
  requestUser: (user, azureUser) => ({
    type: actionTypes.UserRequested,
    payload: { user, azureUser },
  }),
  requestUserOne: (accessToken, oneUser) => ({
    type: actionTypes.UserRequestedOne,
    payload: {accessToken, oneUser},
  }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga({payload}) {
    yield put(actions.requestUser(undefined, payload.accessToken.account));
  });

  yield takeLatest(actionTypes.OneLogin, function* loginSagaOne({payload}) {
    yield put(actions.requestUserOne(payload.accessToken, payload.oneUser));
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested({payload}) {
    const { data: user } = yield call(getUserByToken, payload.azureUser);
    if(user.statusCode === 200){
      yield put(actions.fulfillUser(user['result']));
    } else {
      yield put(actions.logout());
    }
    
  });

  yield takeLatest(actionTypes.UserRequestedOne, function* ({payload}) {
    const { data: user } = yield call(getUserByTokenOne, payload);
    if(user.statusCode === 200){
      yield put(actions.fulfillUser(user['result']));
    } else {
      yield put(actions.logout());
    }
    
  });
}
