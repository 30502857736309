/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import KTToggle from "../../_assets/js/toggle";
import { OutlinedInput, InputAdornment, IconButton, Visibility, createMuiTheme, ThemeProvider } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import * as builder from "../../ducks/builder";
import { ReactComponent as AngleDoubleLeftIcon } from "../assets/layout-svg-icons/Angle-double-left.svg";
import { ReactComponent as AngleDoubleRightIcon } from "../assets/layout-svg-icons/Angle-double-right.svg";


class HeaderSearch extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            values: ''
        }
    }
  ktToggleRef = React.createRef();

  componentDidMount() {
    // eslint-disable-next-line no-undef
    new KTToggle(this.ktToggleRef.current, this.props.toggleOptions);
  }

  heandleChange(event){
      const { filterMenu } = this.props;
    this.setState({
        values: event.target.value
    }) 
    filterMenu(event.target.value)
  }

  render() {
      const { values } = this.state;
    return (
        <ThemeProvider theme={theme}>
            <div
                className={`kt-aside__search kt-grid__item ${this.props.searchClasses}`}
                id="kt_aside_search"
            >
                <div className="kt-aside__search-logo">
                    {/* <OutlinedInput
                        id="outlined-adornment-password"
                        type='text'
                        value={values}
                        style={{padding: '5px !important'}}
                        onChange={(e) => this.heandleChange(e)}
                        className='qqqq'
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            >
                            {<SearchIcon />}
                            </IconButton>
                        </InputAdornment>
                        }
                        //labelWidth='100%'
                    /> */}
                </div>

                {this.props.asideSelfMinimizeToggle && (
                    <div className="kt-aside__search-tools">
                        <button
                            ref={this.ktToggleRef}
                            className="kt-aside__search-aside-toggler"
                            id="kt_aside_toggler"
                        >
                            <span>
                                <AngleDoubleLeftIcon />
                            </span>
                            <span>
                                <AngleDoubleRightIcon />
                            </span>
                        </button>
                    </div>
                )}
            </div>
        </ThemeProvider>
    );
  }
}

const mapStateToProps = store => {
  return {
    searchClasses: builder.selectors.getClasses(store, {
      path: "search",
      toString: true
    }),
    asideSelfMinimizeToggle: objectPath.get(
      store.builder.layoutConfig,
      "aside.self.minimize.toggle"
    ),
    headerLogo: builder.selectors.getLogo(store),
    headerStickyLogo: builder.selectors.getStickyLogo(store),
    toggleOptions: {
      target: "body",
      targetState: "kt-aside--minimize",
      togglerState: "kt-aside__search-aside-toggler--active"
    }
  };
};

const theme = createMuiTheme({
    MuiOutlinedInput: {
        input: {
            padding: 5
        }
    }
})

export default connect(mapStateToProps)(HeaderSearch);