import React, { useState,useEffect, Fragment, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import * as actions from "../../../../store/actions/manageUserAction";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as AngleDoubleLeftIcon } from "../../../../../_metronic/layout/assets/layout-svg-icons/Angle-double-left.svg"
import { ReactComponent as AngleDoubleRightIcon } from "../../../../../_metronic/layout/assets/layout-svg-icons/Angle-double-right.svg";
import { DeleteConfirmation } from '../../../../crud/LayoutUtilsService'

import Toast from '../../../../crud/Toast';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Checkbox
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Done'
import {
    getComparator,    
    stableSort,
    EnhancedTableHead
} from '../../../../crud/SortGrid';
import ManageUserAdd from './ManageUserAdd';
import { Form } from 'react-bootstrap'

function  ManangeUserList(props){
    const { match, user } = props
    const loader = document.getElementById('splash-screen');
    const [isSending, setIsSending] = useState(true)
    const[updateItem, setUpdateItem] = useState(null)
    const[deleteItem, setDelete] = useState(null)
    const [userToDelete, setUserToDelete] = useState(null)
    const classes = useStyles();
    const [addItem, setAddItem] = useState(null);
    const [minMenu, setMinMenu] = useState(false)
    const history = useHistory()
    const params = {
        "sortField":"",
        "sortDirection":"",
        "pageSize":60,
        "pageNumber":1,
        "searchText":""
    }
    const initialRole = {
        userID: undefined,
        roleName: "",
        description: "",
        systemDefined: false,
        active: false,
        user: null,
        rolePermissions: null
    }
    const [userHasEditTenantPermission, setUserHasEditTenantPermission] = useState(false);
    useEffect(()=>{
        var hasPermission = !!user?.modules?.find(m=>m.moduleName==='Tenants' && m.permissions?.some(p=>p.permissionName === 'Create'||p.permissionName === 'Update'));
        setUserHasEditTenantPermission(hasPermission);
    },[]);
    const dispatch = useDispatch();
    const[dataSource, setDataSource] = useState([])
    useEffect(() => {
        loader.classList.remove('hidden')
        if(match.params.id){
            const data = {
                params: params,
                id: match.params.id
            }
            dispatch(actions.getManageUserTenantId(data));
        } else {
            dispatch(actions.getManageUser(params))
        }
        setTimeout(() => {
            loader.classList.add('hidden')
        }, 1000)
    }, [match.params]);
    const { currentState, row, manageUserSuccess, manageUserMassage, error } = useSelector(
        (state) => ({ 
            currentState: state.manageUser.users, 
            row: state.manageUser.selectRow,
            manageUserSuccess: state.manageUser.success,
            manageUserMassage: state.manageUser.message,
            error: state.manageUser.error
        }),
        shallowEqual
    );
    const [level, setLevel] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [toastVisible, setToastVisible] = React.useState(false)  
    useEffect(() => {
        if(manageUserSuccess){
            setLevel('success')
            setMessage(manageUserMassage)
            setToastVisible(manageUserSuccess)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.getManageUserTenantId({params: params, id: match.params.id}));
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 100);
        }else { 
            setLevel('')
            setMessage('')
            setToastVisible(false)
        }
    }, [manageUserSuccess, manageUserMassage]);
    useEffect(() => {
        if(error){
            setLevel('danger')
            setMessage(error)
            setToastVisible(true)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.reset())
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 100)
        }
    }, [error]);
    let items = currentState && currentState.items;
    useEffect(() => {
        const currentExpandedRows = [];
        const isRowExpanded = currentExpandedRows.includes(row);
        let obj = {};
        isRowExpanded ? (obj[row] = false) :  (obj[row] = true);
        setExpandState(obj);
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== row) :
            currentExpandedRows.concat(row);
        setExpandedRows(newExpandedRows);
        setAddItem(row == 0 ? currentState && currentState.items && currentState.items[0] : null);
    }, [row])
    useEffect(() => {
        setDataSource(currentState && currentState.items);
        setAddItem(row == 0 ? currentState && currentState.items && currentState.items.length > 0 ? currentState.items[0] : null : null)
        if(currentState && currentState.items && currentState.items.length > 0){
            const currentExpandedRows = [];
            const isRowExpanded = currentExpandedRows.includes(currentState.items[0].userID);
            let obj = {};
            isRowExpanded ? (obj[currentState.items[0].userID] = false) :  (obj[currentState.items[0].userID] = true);
            setExpandState(obj);
            const newExpandedRows = isRowExpanded ?
                currentExpandedRows.filter(id => id !== currentState.items[0].userID) :
                currentExpandedRows.concat(currentState.items[0].userID);
            setExpandedRows(newExpandedRows);
            dispatch(actions.getManageUserByUserId(currentState.items[0].userID))
        }
    },[items])
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const handleRequestSort = (event, property) => {
        if(orderBy === ''){
            setOrder('desc');
            setOrderBy(property);
            setDataSource(stableSort(dataSource, getComparator('asc', property)))
        } else{
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
            setDataSource(stableSort(dataSource, getComparator(order, orderBy)))
        }
    };
    useEffect(() => {
        if (updateItem){
            setIsSending(false)
            setDataSource([]);
            //dispatch(actions.updateRoleRequest(updateItem));
            setTimeout(() => {
                setUpdateItem(null);
                setIsSending(true)
            }, 500)
        }
    }, [dispatch, updateItem]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandState, setExpandState] = useState({});

    const handleEpandRow = (event, item) => {
        setAddItem(null);
        const currentExpandedRows = [];
        const isRowExpanded = currentExpandedRows.includes(item.userID);
        let obj = {};
        isRowExpanded ? (obj[item.userID] = false) :  (obj[item.userID] = true);
        setExpandState(obj);
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== item.userID) :
            currentExpandedRows.concat(item.userID);
        setExpandedRows(newExpandedRows);
        setAddItem(item);
        dispatch(actions.getManageUserByUserId(item.userID))
    }

    const hendleMinMenu = () => {
        setMinMenu(!minMenu);
    }
    const hendleChange = (e) => {
        params.searchText = e.target.value;
        //dispatch(actions.getAllRoles());
    }
    const deleteConfirm = (item) => {
        setUserToDelete(item);
        setDelete(true)
    }
    const onDelete = (item)=>{
        if(item.userID){
            dispatch(actions.deleteManageUserRequest({tenantID: item.tenantID, id: item.userID}));
            loader.classList.remove('hidden');
        }        
    }
    const deleteClose = (rowIndex, action) => {
        if (action === 'delete') {
            const loader = document.getElementById('splash-screen');
            loader.classList.remove('hidden');
            onDelete(userToDelete);
            setTimeout(() => {
                setDelete(false);
                loader.classList.add('hidden');
            }, 2000);
            return;
        } else if (action === 'close') {
            setDelete(false);
        }
    }
    let allItemRows = [];
    dataSource && dataSource.forEach((item, i) => {
        const perItemRows = <TableRow 
            onClick={event => handleEpandRow(event, item)}
            className={expandedRows.includes(item.userID) ? clsx('row-color role-row ', classes.selected) : 'row-color role-row'}
            key={"row-data-" + i}
        >
            <TableCell className={classes.cellBtn}>
                {item.firstName}
            </TableCell>
            <TableCell className={classes.cellBtn}>
                {item.lastName}
            </TableCell>
            <TableCell className={classes.cell}>
                {item.email}
            </TableCell>  
            {/* <TableCell className={classes.cell}>
                {item.organizationName}
            </TableCell>   */}
            <TableCell className={classes.cell}
                align="right"
                style={{paddingRight: 0}} 
            > 
                {userHasEditTenantPermission && !item.systemDefined  && (<Fragment>
                    <IconButton  
                        color="default"
                        className={classes.button}
                        aria-label="Delete"
                        component="span"
                        onClick={()=>deleteConfirm(item)}>
                        <DeleteIcon color="error" />
                    </IconButton>
                    {deleteItem && <DeleteConfirmation
                        _openDialog={deleteItem}
                        index={i}
                        handleDelete={deleteClose}
                    />}
                </Fragment>)}
            </TableCell>
        </TableRow>;
        //<RoleItem key={i} data={dataSource} item={item} index={i} onSave={onUpdateRow} onDelete={onDeleteRow} />;
        allItemRows = allItemRows.concat(perItemRows);
    });
    return (
        <div className={classes.container}>
        
            <div>
                <div className={clsx("panel panel-default ", minMenu ? classes.tenantContentSidebar : classes.pannelList)}>
                    <div className={clsx('tenant-menu', classes.tenantMenu, minMenu ? `${classes.minMenu} minMenu` : '')}>
                        <div className="menu__search">
                            <div className="search-tools">
                                <button
                                    className="search-toggler"
                                    id="search_toggler"
                                    onClick={() => hendleMinMenu()}
                                >
                                    <span>
                                        <AngleDoubleLeftIcon />
                                    </span>
                                    <span>
                                        <AngleDoubleRightIcon />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="menu-container">
                            <Table className={classes.table}>
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    headCells={headCells}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={dataSource ? dataSource.length : 0}
                                />
                                <TableBody>
                                    {props.openAddRow && <TableRow>
                                        <TableCell className={classes.cellBtn}>
                                            <Form.Control 
                                                placeholder="First Name"
                                                value={initialRole.firstName}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.cellBtn}>
                                            <Form.Control 
                                                placeholder="Last Name"
                                                value={initialRole.lastName}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.cellBtn}>
                                            <Form.Control 
                                                placeholder="User Name"
                                                value={initialRole.email}
                                            />
                                        </TableCell>
                                        {/* <TableCell className={classes.cell}>
                                            <Checkbox 
                                                value={initialRole.systemDefined}
                                                //onChange={(e) => handleChange(e, index, i)} 
                                                name="systemDefined" 
                                                color="primary"
                                            />
                                        </TableCell>   */}
                                        <TableCell className={classes.cell}
                                            align="right"
                                            style={{paddingRight: 0}} 
                                        > 
                                            <Fragment>
                                                <IconButton
                                                    color="primary"
                                                    className={classes.button}
                                                    component="span"
                                                    size='small'
                                                >
                                                    <DoneIcon color = "primary" />
                                                </IconButton>
                                            </Fragment>
                                        </TableCell>
                                    </TableRow>}
                                    {allItemRows}
                                </TableBody>
                            </Table>
                            <Toast
                                level={level}
                                message={message}
                                visible={toastVisible}
                            />
                        </div>
                    </div>
                </div>
                <div className={clsx(minMenu ? classes.tenantMinContent : classes.tenantdetailrightcontent)}>
                    <>
                        <ManageUserAdd 
                            close={props.closeAdd} 
                            itemData={row == 0 ? addItem : null} 
                            match={match ? match : null}                            
                            emailDomain={props.emailDomain}
                        />
                    </>
                </div>
            </div>
        </div>     
    )
}

const headCells = [
    { id: 'firstName', numeric: false, disablePadding: false, align: 'left', label: 'First Name' },
    { id: 'lastName', numeric: false, disablePadding: false, align: 'left', label: 'Last Name' },
    { id: 'userName', numeric: false, disablePadding: false, align: 'left', label: 'UserName' },
    // { id: 'systemDefined', numeric: false, disablePadding: false, align: 'left', label: 'System Defined' },
    //{ id: 'parentOrganisation', numeric: false, disablePadding: false, align: 'left', label: 'Parent Organisation' },
    { id: 'actions', numeric: false, disablePadding: false, align: 'right', label: 'Actions' },

];

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    pannelList: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: 608,
        background: '#fff',
        transition: 'all .1s ease-out',
        paddingRight: 10
    },
    tenantContentSidebar : {
        position: 'absolute',
        top: '0',
        left: '0',
        width: 49,
        background: '#fff',
        transition: 'all .1s ease-out',
        paddingRight: 10
    },
    tenantdetailrightcontent: {
        marginLeft: 608,
        transition: 'all .1s ease-out',
        paddingLeft: 0
    },
    tenantMinContent: {
        marginLeft: 49,
        transition: 'all .1s ease-out',
        paddingLeft: 0
    },
    button: {
        margin: '0 8px 0 0',
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        //maxWidth: 125,
        //minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        //maxWidth: 125,
        //minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        width: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '30%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    },
    tenantMenu: {
        width: '100%',
        // height: '100vh',
        background:'#ffffff',
        zIndex: '1',
        borderRight: 'solid 1px #ddd'
    },
    minMenu: {
        width:50,
        overflow: 'hidden',
    },
    addbtn: {
        padding: '4px 10px'
    },
    searchControl:{
        width:30
    },
    rolesearchcontainer:{
        padding:10,
        width:35
    },
    hidden: {
        display:'none' 
    },
    selected: {
        '& > td': {
            borderTop: 'solid 1px #004d88 !important',
            borderBottom: 'solid 1px #004d88 !important',
            background: '#0078d4',
            color: '#ffffff'
        }
    }
})


const mapStateToProps = store => ({
    user: store.auth.user
  });
  
export default connect(mapStateToProps)(ManangeUserList);