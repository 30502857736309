import { takeEvery, takeLatest, take, call, fork, put } from 'redux-saga/effects';
import * as types from '../actions/types';
import * as action from '../actions/organisationAction';
import { 
    fetchOrganisationById, 
    fetchOrganisationAdd, 
    fetchOrganisationUpdate, 
    importHierarchyFile,
    deleteOrganization
 } from '../../crud/organisation.crud';


function* getOrganisationById({ payload }){
    try{
        const result = yield call(fetchOrganisationById, payload);
        yield put({
            type: types.FETCH_ORGANISATION_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getOrganisationByIdRequest(){
    yield takeEvery(types.FETCH_ORGANISATION, getOrganisationById);
}


function* addOrganisationRow({ payload }){
    try{
        const result = yield call(fetchOrganisationAdd, payload);
        yield put({
            type: types.ADD_ORGANISATION_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* addOrganisationRowRequest(){
    yield takeEvery(types.ADD_ORGANISATION_REQUEST, addOrganisationRow);
}

function* updateOrganisationRow({ payload }){
    try{
        const result = yield call(fetchOrganisationUpdate, payload);
        yield put({
            type: types.UPDATE_ORGANISATION_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* updateOrganisationRowRequest(){
    yield takeEvery(types.UPDATE_ORGANISATION_REQUEST, updateOrganisationRow);
}

function* importHierarchyFileHandler({ payload }){
    try{
        const result = yield call(importHierarchyFile, payload);
        yield put({
            type: types.ORGANISATION_importHierarchyFile_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    }catch (e) {
        console.log("Organization Saga", e);
        yield put(action.error({
            error: e
        }));
    }
}

function* importOrganisationsRequest(){
    yield takeEvery(types.ORGANISATION_importHierarchyFile_REQUEST, importHierarchyFileHandler);
}

function* deleteOrganizationItem({payload}){
    try{
        const items = yield call(deleteOrganization, payload);
        yield put({
            type: types.DELETE_ORGANIZATION_SUCCESS,
            payload: items.data.result
        });
        yield call(getOrganisationByIdRequest);
    }catch(e){
        yield put(action.error({
            error: e
        }));
    }
}

function* watchDeleteOrganizationRequest(){
    yield takeLatest(types.DELETE_ORGANIZATION_REQUEST, deleteOrganizationItem);
}

const OrganisationSaga = [
    fork(getOrganisationByIdRequest),
    fork(addOrganisationRowRequest),
    fork(updateOrganisationRowRequest),
    fork(importOrganisationsRequest),
    fork(watchDeleteOrganizationRequest)
];

export default OrganisationSaga;