import React, { Component } from 'react';
import { connect } from "react-redux";
import PowerbiEmbedded from "react-powerbi";
import {
    fetchReports
} from '../../../../store/actions/reportsAction'

class JBSClaimsComponent extends Component{
    constructor(props){
        super(props);
        this.props.fetchReports('b44ee1eb-e7bb-417e-99a1-ac5af6c44b0f');
        this.state = {
            reportID: 'b44ee1eb-e7bb-417e-99a1-ac5af6c44b0f',
            user: this.props.user,
            token: {}
        }
        const {user, reportID} = this.state;
        user && this.props.fetchReports({reportID, user});
    }

    componentWillReceiveProps(nextProps, prevProps){
        if(nextProps.token){
            if(nextProps.token !== this.state.token){
                this.setState({
                    token: nextProps.token,
                    user: nextProps.user
                });
                
            }
        }
    }

    render(){
        const { token, user } = this.state;
        const { reportID } = this.state;
        return(
            <div style={{width: '100%', padding: 0, height: 'calc( 100vh - 60px )'}}>
                {token && <PowerbiEmbedded
                    id={`${reportID}`}
                    userId={ user.userId }
                    embedUrl={token.embedUrl}
                    accessToken={token.accessToken}
                    filterPaneEnabled={true}
                    navContentPaneEnabled={true}
                    tokenType='Embed'
                    width="100%"
                    height="100%"
                    onEmbedded={this.onEmbedded}
                />}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    token: state.token.token,
    user: state.auth.user
})

export default connect(mapStateToProps, {
    fetchReports
})(JBSClaimsComponent);