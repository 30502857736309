import React, { Component, Fragment } from 'react';
import clsx from "clsx";
import { withStyles } from '@material-ui/core/styles'
import { 
    TableFooter, 
    TablePagination,
    Button,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Paper,
    TextField,
    Tooltip
} from '@material-ui/core'
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../partials/content/Portlet";
import { TablePaginationActions } from '../../../../partials/layout/Pagination';
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { 
    getComparator,
    stableSort,
    EnhancedTableHead 
} from '../../../../crud/SortGrid';
import { Modal } from "react-bootstrap";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import AuditTrailItems from './AuditTrailItems';

class AuditTrailList extends Component {
    constructor(props){
        super(props);
        const endDateFind = new Date();
        endDateFind.setHours(24,59,59,999);
        const setTime = endDateFind.toUTCString();//new Date(endDateFind).getFullYear() + "/" + new Date(endDateFind).getMonth() + 1 + "/" + new Date(endDateFind).getDate();
        this.state = {
            auditTrailList: this.props.data,
            updatedRow: [],
            page: 0,
            rowsPerPage: 25,
            order: 'asc',
            orderBy: 'fileName',
            show: false,
            modelText: '',
            limit: '23',
            selectedStartDate: new Date().toUTCString(),
            selectedEndDate: setTime,
            ieBrowser: false,
            addAuditTrail: {
                ID: undefined,
                dataTime: "",
                fileName: "",
                packageId: undefined,
                snapShotName: "",
                processStep: "",
                additionalInfo: "",
                userId: ""
            }
        }
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onFilderByDate = this.onFilderByDate.bind(this);
    }

    

    componentDidMount(){
        // const {selectedEndDate} = this.state;
        // selectedEndDate.setHours(24,59,59,999);
        // const setTime = new Date().getFullYear() + "/" + new Date().getMonth() + 1 + "/" + new Date().getDate();
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        this.setState({
            auditTrailList: this.props.data,
            ieBrowser: isIE ? true : false
        }) 
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.data){
            if(nextProps.data !== this.state.auditTrailList){
                this.setState({
                    auditTrailList: nextProps.data,
                });
            }
        }
    }

    handleChangePage(event, newPage) {
        this.setPage(newPage)
        return this.state.page;
    }

    setPage(newPage){
      this.setState({
          page: newPage
      })
    }
    
    handleChangeRowsPerPage(event) {
        const data = parseInt(event.target.value, 10);
        this.setRowsPerPage(data);
        return this.state.rowsPerPage;
    }

    setRowsPerPage(data){
      this.setState({rowsPerPage: data})
    }

    heandleChange(e, type){
        const { addAuditTrail } = this.state;
        this.setState({
            addAuditTrail: {
                ...addAuditTrail,
                [type]: e.target.value
            }
        })
    }

    heandleUpdate(item, rowId){
        const { updatedRow, addAuditTrail } = this.state
        setTimeout(() => {
            updatedRow.length > 0 && updatedRow.splice(0 , 1);
            const currentExpandedRows = updatedRow;
            const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
            const newExpandedRows = isRowCurrentlyExpanded ? 
                currentExpandedRows.filter((id) => id !== rowId) : 
                (
                    currentExpandedRows.concat(rowId)
                );
                rowId === 0 || rowId > 0 ?
            this.setState({updatedRow : newExpandedRows, addAuditTrail : item}) :
            this.setState({updatedRow : newExpandedRows, addAuditTrail: addAuditTrail})

        }, 500)
    }

    closeRow(){
        const { addAuditTrail } = this.state
        this.setState({updatedRow : [], addAuditTrail : addAuditTrail})
    }

    heandlePopup(event, item){
        this.setState({
            show: true,
            modelText: item
        })
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleDateChange(item) {
        const { selectedEndDate, selectedStartDate } = this.state;
        const setDate = new Date(item)
        const date = new Date(item).getDate()
        const year = new Date(item).getFullYear()
        const month = new Date(item).getMonth() + 1
        const full =  year + "/" + month + "/" + date;
        const serHours = setDate.setHours(24,59,59,999);
        const findDate = `${new Date(serHours).getFullYear()}/${new Date(serHours).getMonth() + 1}/${new Date(serHours).getDate()}`;
        const endDate = `${new Date(selectedEndDate).getFullYear()}/${new Date(selectedEndDate).getMonth() + 1}/${new Date(selectedEndDate).getDate()}`;
        this.setState({
            selectedStartDate: full,
        })
        
        if(Date.parse(endDate) < Date.parse(full)) {
            this.setState({
                selectedEndDate: findDate
            })
        }
    }


    handleEndDateChange(item) {
        const date = new Date(item).getDate()
        const year = new Date(item).getFullYear()
        const month = new Date(item).getMonth() + 1
        const full =  year + "/" + month + "/" + date;
        this.setState({
            selectedEndDate: full
        })
    }

    renderItem(item, i){
        const { classes } = this.props;
        const { updatedRow, addAuditTrail, limit } = this.state;
        const itemReturn = [
            <TableRow className='row-color' key={"row-data-" + i}>
                <TableCell className={clsx('row-overflow', classes.cell)}>
                    {updatedRow.includes(i) ? <TextField 
                        id="standard-basic3" 
                        label="Edit dataTime" 
                        value={addAuditTrail.dataTime || ''} 
                        onChange={(e) => this.heandleChange(e, 'dataTime')}
                    /> 
                    :
                    new Date(item.dataTime).getTime()}
                </TableCell>
                <TableCell className={clsx('row-overflow', classes.cell)}>
                    {updatedRow.includes(i) ? <TextField 
                        id="standard-basic4" 
                        label="Edit CreatedOn" 
                        value={addAuditTrail.fileName || ''} 
                        onChange={(e) => this.heandleChange(e, 'CreatedOn')}
                    /> 
                    :
                    <Fragment> 
                        <div className={clsx('row-overflow')}>
                            {item.fileName.length > 20 ? <Tooltip title={item.fileName}><p>{item.fileName.substring(0,limit)+"..."}</p></Tooltip> : item.fileName}
                            {/* {item.fileName.length > 20 && <a href onClick={(e) => this.heandlePopup(e, item.fileName)}>read more</a>} */}
                        </div>
                    </Fragment>}
                </TableCell>
                <TableCell className={clsx('row-overflow', classes.cell)}>
                    {updatedRow.includes(i) ? <TextField 
                        id="standard-basic5" 
                        label="Edit ItemCount" 
                        value={addAuditTrail.packageId || ''} 
                        onChange={(e) => this.heandleChange(e, 'ItemCount')}
                    /> 
                    :
                    <Fragment> 
                        <div className={clsx('row-overflow')}>
                            {item.packageId.length > 20 ? <Tooltip title={item.packageId}><p>{item.packageId.substring(0,limit)+"..."}</p></Tooltip> : item.packageId}
                            {/* {item.packageId.length > 20 && <a href onClick={(e) => this.heandlePopup(e, item.packageId)}>read more</a>} */}
                        </div>
                    </Fragment>}
                </TableCell>
                <TableCell className={clsx('row-overflow', classes.cell)}>
                    {updatedRow.includes(i) ? <TextField 
                        id="standard-basic6" 
                        label="Edit SnapShotTableName" 
                        value={addAuditTrail.snapShotName || ''} 
                        onChange={(e) => this.heandleChange(e, 'SnapShotTableName')}
                    /> 
                    :
                    <Fragment> 
                        {item.snapShotName ?
                        <div className={clsx('row-overflow')}>
                            {item.snapShotName.length > 20 ? <Tooltip title={item.snapShotName}><p>{item.snapShotName.substring(0,limit)+"..."}</p></Tooltip> : item.snapShotName}
                            {/* {item.snapShotName.length > 20 && <a 
                                href
                                onClick={(e) => this.heandlePopup(e, item.snapShotName)}
                            >
                                read more
                            </a>} */}
                        </div>
                        : item.snapShotName}
                    </Fragment>}
                </TableCell>
                <TableCell className={clsx('row-overflow', classes.cell)}>
                    {updatedRow.includes(i) ? <TextField 
                        id="standard-basic7" 
                        label="Edit TagTableName" 
                        value={addAuditTrail.processStep || ''} 
                        onChange={(e) => this.heandleChange(e, 'TagTableName')}
                    /> 
                    :
                    <Fragment> 
                        <div className={clsx('row-overflow')}>
                            {item.processStep.length > 20 ? <Tooltip title={item.processStep}><p>{item.processStep.substring(0,limit)+"..."}</p></Tooltip> : item.processStep}
                            {/* {item.processStep.length > 20 && <a href onClick={(e) => this.heandlePopup(e, item.processStep)}>read more</a>} */}
                        </div>
                    </Fragment>}
                </TableCell>
                <TableCell className={classes.cell}>
                    {updatedRow.includes(i) ? <TextField 
                        id="standard-basic7" 
                        label="Edit Status" 
                        value={addAuditTrail.additionalInfo || ''} 
                        onChange={(e) => this.heandleChange(e, 'Status')}
                    /> 
                    :
                    <Fragment> 
                        <div className={clsx('row-overflow')}>
                            {item.additionalInfo.length > 20 ? <Tooltip title={item.additionalInfo}><p>{item.additionalInfo.substring(0,limit)+"..."}</p></Tooltip> : item.additionalInfo}
                            {/* {item.additionalInfo.length > 20 && <a href onClick={(e) => this.heandlePopup(e, item.additionalInfo)}>read more</a>} */}
                        </div>
                    </Fragment>
                    }
                </TableCell>
                <TableCell align="right" className={clsx('row-overflow', classes.cell)}>
                    {updatedRow.includes(i) ? <TextField 
                        id="standard-basic7" 
                        label="Edit Status" 
                        value={addAuditTrail.userId || ''} 
                        onChange={(e) => this.heandleChange(e, 'userId')}
                    /> 
                    :
                    item.userId}
                </TableCell>
            </TableRow>
        ]
        return itemReturn;
    }

    handleRequestSort(event, property) {
        const { orderBy, order } = this.state;
        // const isAsc = orderBy === property && order === 'asc';
        // this.setState({
        //     order: isAsc ? 'desc' : 'asc',
        //     orderBy: property
        // })
        if(orderBy === ''){
            const isAsc = true;
            this.setState({
                order: 'desc',
                orderBy: property,
            })
        } else{
            const isAsc = orderBy === property && order === 'asc';
            this.setState({
                order: isAsc ? 'desc' : 'asc',
                orderBy: property,
            })
        }
    };

    onFilderByDate(){
        const { onFilter } = this.props;
        const { selectedStartDate, selectedEndDate } = this.state;
        const params = {
            'startdate': selectedStartDate,
            'enddate': selectedEndDate
        }
        onFilter(params);
    }



    render(){
        const { classes } = this.props;
        const { auditTrailList, ieBrowser, order, orderBy, selectedStartDate, rowsPerPage, page, selectedEndDate } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, auditTrailList.length - page * rowsPerPage);
        let allItemRows = [];
        stableSort(auditTrailList, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).forEach((item, i) => {
            const perItemRows = this.renderItem(item, i);
            allItemRows = allItemRows.concat(perItemRows);
        });
        let dataslice = auditTrailList.length> 0 ? stableSort(auditTrailList, getComparator(order, orderBy)) : auditTrailList;
        let items = dataslice.length> 0 ? dataslice.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)  : dataslice;
        // let dataslice = auditTrailList.length> 0 ? auditTrailList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)  : auditTrailList;
        // let items = stableSort(dataslice, getComparator(order, orderBy));
        return(
            <Paper className={classes.root}>
                <Portlet className={classes.portlet}>
                    <PortletHeader 
                        title='Audit Trail'
                        headerClassName={classes.portletHead}
                    />
                        <PortletBody style={{position: 'relative', padding: 10, maxHeight: 'calc( 100vh - 92px )', width: '100%'}} data-ktmenu-vertical="1">
                            <div style={{padding: '0 0 15px 0', display: 'flex', alignItems: 'center', zIndex: 9999}}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        label="Start Date"
                                        id="mui-pickers-date"
                                        placeholder="Select data for filter"
                                        value={selectedStartDate}
                                        onChange={(e) => this.handleDateChange(e)}
                                        format="yyyy/MM/dd"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        className={classes.formMargin}
                                    />
                                    
                                    <KeyboardDatePicker
                                        margin="normal"
                                        label="End Date"
                                        id="mui-pickers-date"
                                        placeholder="Select data for filter"
                                        value={selectedEndDate}
                                        minDate={selectedStartDate}
                                        onChange={(e) => this.handleEndDateChange(e)}
                                        format="yyyy/MM/dd"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        className={classes.formMargin}
                                    />
                                </MuiPickersUtilsProvider>
                                <Button variant="contained" color="primary" onClick={this.onFilderByDate} className={classes.button}>
                                    Filter
                                </Button>
                            </div>
                            
                    <PerfectScrollbar
                        options={{ 
                            wheelSpeed: 2, 
                            wheelPropagation: false 
                        }}
                        style={{padding: '0px 0 0', maxHeight: ieBrowser && 'calc( 100vh - 180px )',}}
                    >
                            <Table stickyHeader aria-label="sticky table" style={{height: '100%'}}>
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    headCells={headCells}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={auditTrailList.length}
                                />
                                <TableBody>
                                    {auditTrailList.length > 0 && <AuditTrailItems 
                                        auditTrailList={items}
                                        classes={classes}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                    />}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 31 * emptyRows }}>
                                        <TableCell colSpan={7} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
                                            colSpan={7}
                                            count={auditTrailList.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                            </PerfectScrollbar>
                        </PortletBody>
                </Portlet>
                <Modal size="lg" show={this.state.show} onHide={this.handleClose} centered>
                    <Modal.Header closeButton style={{alignItems: 'center'}}>
                        <Modal.Title className={classes.modalTitle} style={{width: '100%'}}><p style={{whiteSpace: 'pre', width: '100%', marginBottom: 0, overflowX: 'auto'}}>{this.state.modelText}</p></Modal.Title>
                    </Modal.Header>
                </Modal>
            </Paper>
        )
    }
}

const headCells = [
    { id: 'dataTime', numeric: false, disablePadding: true, align: 'left', label: 'Data Time' },
    { id: 'fileName', numeric: false, disablePadding: false, align: 'left', label: 'File Name' },
    { id: 'packageId', numeric: false, disablePadding: false, align: 'left', label: 'Package Id' },
    { id: 'snapShotName', numeric: false, disablePadding: false, align: 'left', label: 'SnapShot Name' },
    { id: 'processStep', numeric: false, disablePadding: false, align: 'left', label: 'Process Step' },
    { id: 'additionalInfo', numeric: false, disablePadding: false, align: 'left', label: 'Additional Info' },
    { id: 'userId', numeric: false, disablePadding: false, align: 'right', label: 'User Id' },
];

const useStyles = theme => ({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 300,
        width: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        maxWidth: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    modalTitle: {
        fontSize: '1rem !important'
    },
    formMargin: {
        marginRight: '15px !important'
    }
})

export default withStyles(useStyles)(AuditTrailList);