
import React, { useState } from "react";
import Link from '@material-ui/core/Link';
import Login from './Login'
import { useHistory } from 'react-router-dom'



export default function UserConfirmation() {
    const history = useHistory()
    function handleClick(event) {
        event.preventDefault();
        history.push('/auth/login')
    }
 
    return (
        <>
            <div className="kt-login__body">
                <div className="kt-login__form">
                    <div className="kt-login__title">
                        <h5>
                            Your account has been activated. Click here to  <strong>
                                <Link
                                    color="primary"
                                    onClick={handleClick}>
                                    login
    </Link>
                            </strong>
                        </h5>
                    </div>
                </div>
            </div>

        </>

    );

}
