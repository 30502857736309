import * as Yup from "yup";
// import {DoesDomainExists} from '../../../../crud/tenant.crud';
const FormValidationsAdd = Yup.object().shape({
    tenantName: Yup.string()
        .required("Tenant Name is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    emailDomain: Yup.string()
        .required("Email Domain Name is required")
        .matches(/^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/, "Valid domain name allowed"),
    subscriptionID: Yup.string()
        .required("subscriptionID is required"),
    // domain: Yup.string()
    // .test(
    //     'does-domain-exists',
    //     'domain already exists',
    //     async (value, testContext) => {
    //         var response = await fetch('http://localhost:5001/api/tenant/DoesDomainExists/' + value + '/0').result;
    //         console.log(response);
    //         return response;
    //     }
    //     // {
    //     //     // var response = (await DoesDomainExists(value,0));
    //     //     // console.log(response.data.result);
    //     //     return true;
    //     // }
    //   ),
    allowAccount: Yup.number()
        .required("allowAccount is required"),
    superFirstName: Yup.string()
        .required("First Name is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    superLastName: Yup.string()
        .required("Last Name is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    superPhoneNo: Yup.string().length(10,"Should have exactly 10 digits")
        .matches(/(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/, {
            message: "Please provide a valid Phone Number.",
            excludeEmptyString: false,
        })
        .required("Phone No. is required"),
    superEmail: Yup.string()
        .email('Invalid email')
        .required("Email is required")
        .test('ends-email-domain','email must ends with email-doamin', function (value) {
            console.log(value);
            console.log(this.parent.emailDomain);
            if(value && this.parent.emailDomain){
                return value.endsWith(this.parent.emailDomain);     
            }  
            return true;    
        }),
    sponsarFirstName: Yup.string()
        .required("First Name is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    sponsarLastName: Yup.string()
        .required("Last Name is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    sponsarPhoneNo: Yup.string().length(10,"Should have exactly 10 digits")
        .matches(/(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/, {
            message: "Please provide a valid Phone Number.",
            excludeEmptyString: false,
        })
        .required("Phone No. is required"),
    sponsarEmail: Yup.string()
        .email('Invalid email')
        .required("Email is required")
        .test('ends-email-domain','email must ends with email-doamin', function (value) {
            console.log(value);
            console.log(this.parent.emailDomain);
            if(value && this.parent.emailDomain){
                return value.endsWith(this.parent.emailDomain);     
            }  
            return true;    
        }),
    businessName: Yup.string()
        .required("businessName is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    billingEmail: Yup.string()
        .email('Invalid email')
        .required("billingEmail is required"),
    address1: Yup.string()
        .required("address1 is required"),
    taxID: Yup.string()
        .required("taxID is required"),
    tenantIndustryID: Yup.number()
        .required("tenantIndustryID is required"),
    tenantIndustryLevelID: Yup.number()
        .required("tenantIndustryLevelID is required"),
    // levelSubTypeID: Yup.number()
    //     .required("levelSubTypeID is required"),
    countryID: Yup.number()
        .required("countryID is required"),
    // stateID: Yup.number()
    //     .required("stateID is required"),
    city: Yup.string()
        .required("City is required"),
    zipCode: Yup.string()
        .matches(/^[0-9]*$/)
        .required("zipCode is required"),
});

const FormValidationsEdit = Yup.object().shape({
    tenantName: Yup.string()
        .required("Tenant Name is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    subscriptionID: Yup.string()
        .required("subscriptionID is required"),
    allowAccount: Yup.number()
        .required("allowAccount is required"),
    // superFirstName: Yup.string()
    //     .required("First Name is required")
    //     .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    // superLastName: Yup.string()
    //     .required("Last Name is required")
    //     .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    // superPhoneNo: Yup.string()
    //     .required("Phone No. is required"),
    // superEmail: Yup.string()
    //     .email('Invalid email')
    //     .required("Email is required"),
    // sponsarFirstName: Yup.string()
    //     .required("First Name is required")
    //     .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    // sponsarLastName: Yup.string()
    //     .required("Last Name is required")
    //     .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    // sponsarPhoneNo: Yup.string()
    //     .required("Phone No. is required"),
    // sponsarEmail: Yup.string()
    //     .email('Invalid email')
    //     .required("Email is required"),
    businessName: Yup.string()
        .required("businessName is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    billingEmail: Yup.string()
        .email('Invalid email')
        .required("billingEmail is required"),
    address1: Yup.string()
        .required("address1 is required"),
    taxID: Yup.string()
        .required("taxID is required"),
    tenantIndustryID: Yup.number()
        .required("tenantIndustryID is required"),
    tenantIndustryLevelID: Yup.number()
        .required("tenantIndustryLevelID is required"),
    // levelSubTypeID: Yup.number()
    //     .required("levelSubTypeID is required"),
    countryID: Yup.number()
        .required("countryID is required"),
    // stateID: Yup.number()
    //     .required("stateID is required"),
    city: Yup.string()
        .required("City is required"),
    zipCode: Yup.string()
        .matches(/^[0-9]*$/)
        .required("zipCode is required"),
});

export {
    FormValidationsAdd,
    FormValidationsEdit
}