import * as types from './types';

/****SnapShot Actions****/

const fetchConfigAll = () => ({
    type: types.FETCH_CONFIG,
})


const fetchConfigSuccess = ({items}) => ({
    type: types.FETCH_CONFIG_SUCCESS,
    payload: { 
        items
    }
})

const fetchConfigError = (error) => ({
    type: types.FETCH_CONFIG_FAILURE,
    payload: error
});

const createConfigRequest = (data) => ({
    type: types.CREATE_CONFIG_REQUEST,
    payload: data
});

const createConfigSuccess = ({ items }) => ({
    type: types.ADD_CONFIG_SUCCESS,
    payload: {
        items
    }
})

const updateConfigRequest = (data) => ({
    type: types.UPDATE_CONFIG_REQUEST,
    payload: data,
});

const updateConfigSuccess = ({ items }) => ({
    type: types.UPDATE_CONFIG_SUCCESS,
    payload: {
        items
    }
})

const deleteUserRequest = (userId) => ({
    type: types.DELETE_CONFIG_REQUEST,
    payload: {
        userId
    }
});

const deleteUserSuccess = ({ items }) => ({
    type: types.DELETE_CONFIG_SUCCESS,
    payload: {
        items
    }
})

const configError = ({error}) => ({
    type: types.CONFIG_ERROR,
    payload: {
        error
    }
});

/****File Operations****/

const getAllFileOperations = () => ({
    type: types.FETCH_FILE_OPERATIONS
})

const fetchFileOperationsSuccess = ({items}) => ({
    type: types.FETCH_FILE_OPERATIONS_SUCCESS,
    payload: { 
        items
    }
})

const createFileOperationsRequest = (data) => ({
    type: types.CREATE_FILE_OPERATIONS_REQUEST,
    payload: data
});

const createFileOperationsSuccess = ({ items }) => ({
    type: types.CREATE_FILE_OPERATIONS_SUCCESS,
    payload: {
        items
    }
})

const updateFileOperationsRequest = (data) => ({
    type: types.UPDATE_FILE_OPERATIONS_REQUEST,
    payload: data,
});

const updateFileOperationsSuccess = ({ items }) => ({
    type: types.UPDATE_FILE_OPERATIONS_SUCCESS,
    payload: {
        items
    }
})

const deleteFileOperationsRequest = (userId) => ({
    type: types.DELETE_FILE_OPERATIONS_REQUEST,
    payload: {
        userId
    }
});

const deleteFileOperationsSuccess = ({ items }) => ({
    type: types.DELETE_FILE_OPERATIONS_SUCCESS,
    payload: {
        items
    }
})

/**** Automated Cleansing ****/

const fetchAutoCleansing = () => ({
    type: types.FETCH_AUTO_CLEANSING,
})


const fetchAutoCleansingSuccess = ({items}) => ({
    type: types.FETCH_AUTO_CLEANSING_SUCCESS,
    payload: { 
        items
    }
})

/**** Automated Cleansing ****/

const fetchBasedCleansing = () => ({
    type: types.FETCH_BASED_CLEANSING,
})


const fetchBasedCleansingSuccess = ({items}) => ({
    type: types.FETCH_BASED_CLEANSING_SUCCESS,
    payload: { 
        items
    }
})

/**** Automated Cleansing ****/

const fetchCustomer = () => ({
    type: types.FETCH_CUSTOMERS,
})


const fetchCustomerSuccess = ({items}) => ({
    type: types.FETCH_CUSTOMERS_SUCCESS,
    payload: { 
        items
    }
})

/**** Staging ****/

const fetchStage = () => ({
    type: types.FETCH_STAGE,
})


const fetchStageSuccess = ({items}) => ({
    type: types.FETCH_STAGE_SUCCESS,
    payload: { 
        items
    }
})

export {
    fetchConfigAll,
    createConfigRequest,
    fetchConfigSuccess,
    fetchConfigError,
    deleteUserRequest,
    updateConfigRequest,
    updateConfigSuccess,
    configError,
    createConfigSuccess,
    deleteUserSuccess,
    getAllFileOperations,
    fetchFileOperationsSuccess,
    createFileOperationsRequest,
    createFileOperationsSuccess,
    updateFileOperationsRequest,
    updateFileOperationsSuccess,
    deleteFileOperationsRequest,
    deleteFileOperationsSuccess,
    fetchAutoCleansing,
    fetchAutoCleansingSuccess,
    fetchBasedCleansing,
    fetchBasedCleansingSuccess,
    fetchCustomer,
    fetchCustomerSuccess,
    fetchStage,
    fetchStageSuccess
};