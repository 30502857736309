import { takeEvery, call, fork, put } from 'redux-saga/effects';// takeLatest, take,
import * as types from '../actions/types';
import * as action from '../actions/configAction';
import { fetchModules } from '../../crud/modules.crud';

function* getModules(){
    try{
        const result = yield call(fetchModules);
        yield put({
            type: types.FETCH_MODULES_SUCCESS,
            payload: result.data.result
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getModulesRequest(){
    yield takeEvery(types.FETCH_MODULES, getModules);
}

const modulesSaga = [
    fork(getModulesRequest)
];

export default modulesSaga;