import { takeEvery, call, fork, put } from 'redux-saga/effects';// takeLatest, take,
import * as types from '../actions/types';
import {
    getPowerBi,
    fetchPoewrBiByID,
    exportReport
} from "../../crud/powerbi.crud";
import * as action from '../actions/configAction';

function* getReports({payload}){
    try{
        const result = yield call(getPowerBi, payload);
        yield put({
            type: types.FETCH_REPORTS_SUCCESS,
            payload: {
                items: result.data
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getReportsRequest(){
    yield takeEvery(types.FETCH_REPORTS, getReports);
}


function* getReportByReportId({payload}){
    try{
        const res = yield call(fetchPoewrBiByID, payload);
        yield put({
            type: types.FETCH_REPORT_BY_REPORTID_SUCCESS,
            payload: res.data.result,
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getReportByReportIdRequest(){
    yield takeEvery(types.FETCH_REPORT_BY_REPORTID, getReportByReportId);
}

function* exportReportHandler({payload}){
    try{
        const result = yield call(exportReport, payload);

        yield put({
            type: types.EXPORT_REPORT_SUCCESS,
            payload: result,
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to export report'
        }));
    }
}

function* exportReportRequest(){
    yield takeEvery(types.EXPORT_REPORT, exportReportHandler);
}
const reportsSaga = [
    fork(getReportsRequest),
    fork(getReportByReportIdRequest),
    fork(exportReportRequest)
];

export default reportsSaga;