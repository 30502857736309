import { Subject } from 'rxjs';
export const subject = new Subject();
export const subject$ = subject.asObservable()
export const tableName = new Subject();
export const tableName$ = tableName.asObservable()
export const columnName = new Subject();
export const columnName$ = columnName.asObservable()
export const CefitStatusCssClasses = ["success", "info", ""];
export const CefitStatusTitles = ["Selling", "Sold"];
export const CefitConditionCssClasses = ["success", "danger", ""];
export const CefitConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
    { text: "3", value: 3 },
    { text: "5", value: 5 },
    { text: "10", value: 10 }
];
export const setXlsxKey = (data) => {
    subject.next(data)
}
export const setTableName = (data) => {
    tableName.next(data)
}
export const setColumnName = (data) => {
    columnName.next(data)
}
export const initialFilter = {
  filter: {
    model: "",
    manufacture: "",
    VINCode: ""
  },
  sortOrder: "asc", // asc||desc
  sortField: "VINCode",
  pageNumber: 1,
  pageSize: 10
};
export const AVAILABLE_COLORS = [
  "Red",
  "CadetBlue",
  "Eagle",
  "Gold",
  "LightSlateGrey",
  "RoyalBlue",
  "Crimson",
  "Blue",
  "Sienna",
  "Indigo",
  "Green",
  "Violet",
  "GoldenRod",
  "OrangeRed",
  "Khaki",
  "Teal",
  "Purple",
  "Orange",
  "Pink",
  "Black",
  "DarkTurquoise"
];

export const AVAILABLE_MANUFACTURES = [
  "Pontiac",
  "Kia",
  "Lotus",
  "Subaru",
  "Jeep",
  "Isuzu",
  "Mitsubishi",
  "Oldsmobile",
  "Chevrolet",
  "Chrysler",
  "Audi",
  "Suzuki",
  "GMC",
  "Cadillac",
  "Infinity",
  "Mercury",
  "Dodge",
  "Ram",
  "Lexus",
  "Lamborghini",
  "Honda",
  "Nissan",
  "Ford",
  "Hyundai",
  "Saab",
  "Toyota"
];

export const FILE_DATA_TYPE = [
    "Enrollment",
    "Payment",
    "Claim"
]

export const FILE_FORMAT = [
    'CSV',
    'Excel'
]
