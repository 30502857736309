import React, { useState } from 'react';//, useEffect
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import * as Yup from "yup";
import {
    Table,
    TableBody,
    Button
} from '@material-ui/core';
import {
    getComparator,
    stableSort,
    EnhancedTableHead
} from '../../../../crud/SortGrid';
import { Form } from 'react-bootstrap'
import * as actions from '../../../../store/actions/configureWorkspacesAction'
import ConfigureWorkspacesItem from './ConfigureWorkspacesItem'

export default function ConfigureWorkspacesPage(props){
    const classes = useStyles()
    const dispatch = useDispatch()
    const { match, tenant, items } = props
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [workspaceValue, setWorkspacesValue] = useState(null)
    const [dataSource, setDataSource] = useState([items])
    const [loadeSelect, setLoadSelect] = useState(false)
    const loader = document.getElementById('splash-screen');
    const handleRequestSort = (event, property) => {
        if(orderBy === ''){
            setOrder('desc');
            setOrderBy(property);
            setDataSource(stableSort(dataSource, getComparator('asc', property)))
        } else{
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
            setDataSource(stableSort(dataSource, getComparator(order, orderBy)))
        }
    };
    function setCheckBox(values){
        setDataSource(values)
    }
    let allItemRows = [];
    items && items.forEach((item, i) => {
        const perItemRows = <ConfigureWorkspacesItem 
            key={i} 
            items={items} 
            item={item} 
            index={i} 
            setworkspaceConfiguration={setCheckBox}
            col={headCells.length, headCells} 
        />;
        allItemRows = allItemRows.concat(perItemRows);
    });
    function updateConfig(){
        const params = {
            TenantID: match.params.id,
            TenantWorkspace: items
        }
        loader.classList.remove('hidden')
        dispatch(actions.updateConfigureWorkspaces(params))
    }
    return(
        <>
            <Table className={classes.table}>
                <EnhancedTableHead
                    classes={classes}
                    order={order}
                    headCells={headCells}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={dataSource ? dataSource.length : 0}
                />

                <TableBody>
                    {allItemRows}
                </TableBody>
            </Table>
            <div className="row">
                <div className="col-sm-6 text-left mt-3">
                    <div style={{display: 'flex', alignItems: 'center', }}>
                        <Form.Control as="select" disabled={loadeSelect}>
                            <option value="">Please Select...</option>
                            {items && items.map((item, i) => (
                                (i !== 0) && <option key={i} value={i}>{item.workspaceName}</option>
                            ))}
                        </Form.Control>
                        <Button 
                            variant='contained' 
                            color="primary"
                            className={classes.btn}
                            disabled={workspaceValue ? false : true}
                        >Load More Workspace</Button>
                    </div>
                </div>
                <div className="col-sm-6 text-right mt-3">
                    <Button 
                        variant='contained' 
                        className={classes.btnRight}
                        color="primary"
                        onClick={updateConfig}
                    >Save</Button>
                </div>
            </div>
        </>
    )
}

const headCells = [
    { id: 'workspace', numeric: false, disablePadding: false, align: 'left',  label: 'Workspace' },
    { id: 'reports', numeric: false, disablePadding: false, align: 'left', label: 'Reports' },
    { id: 'actions', numeric: false, disablePadding: false, align: 'right', label: 'Show/Hide' }
];

const useStyles = makeStyles({
    container: {
        padding: '0 10px',
        //borderTop: 'solid 1px #dddddd'
        minHeight: 400
    },
    list: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '8px 0px'
    },
    commonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '10px 0px'
    },
    basicLable: {
        minWidth: 195,
    },
    profileContainer: {
        minWidth: 240,
    },
    profileImage: {
        width: 120,
        height: 120
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%'
    },
    uploadContainer: {
        width: 'calc(100% - 435px)',
    },
    inputContainer: {
        width: 'calc(100% - 195px)'
    },
    head: {
        padding: '20px 0px' 
    },
    title:{
        fontsize: '16px',
        color: '#0078d4',
        letterspacing: 'normal',
        fontweight: 'bold',
        margin: 0,
        fontFamily: 'Roboto, sans-serif'
    },
    btn: {
        whiteSpace: 'nowrap',
        padding: 12,
        borderRadius: 0,
        flex: '0 0 170px',
        marginLeft: 10
    },
    btnRight: {
        whiteSpace: 'nowrap',
        padding: 12,
        borderRadius: 0,
    }
})