import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import { Multiselect } from 'multiselect-react-dropdown';
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../../partials/content/Portlet";
import { TablePaginationActions } from '../../../../partials/layout/Pagination';
import TextField from '@material-ui/core/TextField';
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { 
    getComparator,
    stableSort,
    EnhancedTableHead 
} from '../../../../crud/SortGrid';
import { Modal } from "react-bootstrap";
import PackageItems from './PackageItems';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const headCells = [
    { id: 'SnapShotName', numeric: false, disablePadding: true, align: 'left', label: 'SnapShot Name' },
    { id: 'CreatedOn', numeric: false, disablePadding: false, align: 'left', label: 'CreatedOn' },
    { id: 'ItemCount', numeric: false, disablePadding: false, align: 'left', label: 'Item Count' },
    { id: 'SnapShotTableName', numeric: true, disablePadding: false, align: 'left', label: 'SnapShot Table Name' },
    { id: 'TagTableName', numeric: false, disablePadding: false, align: 'left', label: 'Tag Table Name' },
    { id: 'Status', numeric: false, disablePadding: false, align: 'right', label: 'Status' },
];

class PackageList extends Component {
    constructor(props){
        super(props);
        this.style = {
            chips: {
              background: "#0078d4",
              marginBottom: 0
            },
            searchBox: {
              border: '1px solid #e2e5ec'
            },
            multiselectContainer: {
              color: "#0078d4",
              width: 'auto',
              zIndex: '9999',
              minWidth: 300,
              marginRight: 10
            }
        };
        this.state = {
            packageItem: [],
            updatedRow: [],
            page: 0,
            rowsPerPage: 25,
            order: 'asc',
            orderBy: 'CreatedOn',
            show: false,
            modelText: '',
            limit: '40',
            personName: [],
            filterNum: [],
            except: false,
            selectedValue: '',
            ieBrowser: false,
            addPackage: {
                ID: undefined,
                SnapShotName: "",
                CreatedOn: "",
                ItemCount: undefined,
                SnapShotTableName: "",
                TagTableName: "",
                Status: undefined,
                ItemIds: null,
            },
            packageStatus: [
                //{enum: 'UNKNOWN', num: 0},
                {name: 'CREATED', enum: 'CREATED', num: 1},
                {name: 'CHOSEN_FOR_PROCESSING', enum: 'CHOSEN_FOR_PROCESSING', num: 2},
                {name: 'FAILED_AUTOMATED_CLEANSING', enum: 'FAILED_AUTOMATED_CLEANSING', num: 3},
                {name: 'FINISHED_AUTOMATED_CLEANSING', num: 4},
                {name: 'FINISHED_AUTOMATED_CLEANSING', enum: 'NO_AUTO_CLEANING_REQUIRED', num: 5},
                {name: 'FAILED_RULE_BASED_CLEANSING', enum: 'FAILED_RULE_BASED_CLEANSING', num: 6},
                {name: 'FINISHED_RULE_BASED_CLEANSING', enum: 'FINISHED_RULE_BASED_CLEANSING', num: 7},
                {name: 'NO_RULEBASED_CLEANING_REQUIRED', enum: 'NO_RULEBASED_CLEANING_REQUIRED', num: 8},
                {name: 'NEED_MANUAL_CLEANING', enum: 'NEED_MANUAL_CLEANING', num: 9},
                {name: 'MANUALLY_CLEANED', enum: 'MANUALLY_CLEANED', num: 10},
                {name: 'SENT_TO_CUSTOMERES', enum: 'SENT_TO_CUSTOMERES', num: 11},
                {name: 'RECEIVED_FROM_CUSTOMER', enum: 'RECEIVED_FROM_CUSTOMER', num: 12},
                {name: 'FAILED_IN_STAGING', enum: 'FAILED_IN_STAGING', num: 13},
                {name: 'COMPLETE', enum: 'COMPLETE', num: 14}
            ]
        }
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onFilderByDate = this.onFilderByDate.bind(this);
        this.setRowsPerPage = this.setRowsPerPage.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    componentDidMount(){
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        
        this.setState({
            packageItem: this.props.data,
            ieBrowser: isIE ? true : false
        }) 
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.data){
            if(nextProps.data !== this.state.packageItem){
                this.setState({
                    packageItem: nextProps.data,
                });
            }
        }
    }

    heandleChange(e, type){
        const { addPackage } = this.state;
        this.setState({
            addPackage: {
                ...addPackage,
                [type]: e.target.value
            }
        })
    }

    handleChangePage(event, newPage) {
        this.setPage(newPage)
        return this.state.page;
    }

    setPage(newPage){
      this.setState({
          page: newPage
      })
    }
    
    handleChangeRowsPerPage(event) {
        const data = parseInt(event.target.value, 10);
        this.setRowsPerPage(data);
        return this.state.rowsPerPage;
    }

    setRowsPerPage(data){
        this.setState({rowsPerPage: data})
      }


    heandleUpdate(item, rowId){
        const { updatedRow, addPackage } = this.state
        setTimeout(() => {
            updatedRow.length > 0 && updatedRow.splice(0 , 1);
            const currentExpandedRows = updatedRow;
            const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
            const newExpandedRows = isRowCurrentlyExpanded ? 
                currentExpandedRows.filter((id) => id !== rowId) : 
                (
                    currentExpandedRows.concat(rowId)
                );
                rowId === 0 || rowId > 0 ?
            this.setState({updatedRow : newExpandedRows, addPackage : item}) :
            this.setState({updatedRow : newExpandedRows, addPackage: addPackage})

        }, 500)
    }

    closeRow(){
        const { addPackage } = this.state
        this.setState({updatedRow : [], addPackage : addPackage})
    }

    heandlePopup(event, item){
        this.setState({
            show: true,
            modelText: item
        })
    }

    handleClose() {
        this.setState({ show: false });
    }

    renderItem(item, i){
        const { classes } = this.props;
        const { updatedRow, addPackage, limit } = this.state;
        const itemReturn = [
            <TableRow className='row-color' key={"row-data-" + i}>
                <TableCell className={classes.cell}>
                    {updatedRow.includes(i) ? <TextField 
                        id="standard-basic3" 
                        label="Edit SnapShotName" 
                        value={addPackage.SnapShotName} 
                        onChange={(e) => this.heandleChange(e, 'SnapShotName')}
                    /> 
                    :
                    item.SnapShotName}
                </TableCell>
                <TableCell className={classes.cell}>
                    {updatedRow.includes(i) ? <TextField 
                        id="standard-basic4" 
                        label="Edit CreatedOn" 
                        value={addPackage.CreatedOn} 
                        onChange={(e) => this.heandleChange(e, 'CreatedOn')}
                    /> 
                    :
                    new Date(item.CreatedOn).getTime()}
                </TableCell>
                <TableCell className={classes.cell}>
                    {updatedRow.includes(i) ? <TextField 
                        id="standard-basic5" 
                        label="Edit ItemCount" 
                        value={addPackage.ItemCount} 
                        onChange={(e) => this.heandleChange(e, 'ItemCount')}
                    /> 
                    :
                    item.ItemCount}
                </TableCell>
                <TableCell className={classes.cell} align="left">
                    {updatedRow.includes(i) ? <TextField 
                        id="standard-basic6" 
                        label="Edit SnapShotTableName" 
                        value={addPackage.SnapShotTableName} 
                        onChange={(e) => this.heandleChange(e, 'SnapShotTableName')}
                    /> 
                    :
                    <Fragment>
                        <Link 
                            to={{ 
                                pathname: '/runtime/tableData', 
                                state: { 
                                    items: item.SnapShotName,
                                } 
                            }}
                        >
                            {item.SnapShotTableName}
                        </Link>
                    </Fragment>}
                </TableCell>
                <Tooltip title={item.TagTableName} disableHoverListener={item.open}>
                    <TableCell
                        ref={item.rowref}
                        className={classes.cell}
                    >
                        {item.TagTableName}
                    </TableCell>
                  </Tooltip>
                <TableCell align='right' className={classes.cell}>
                    {updatedRow.includes(i) ? <TextField 
                        id="standard-basic7" 
                        label="Edit Status" 
                        value={addPackage.Status} 
                        onChange={(e) => this.heandleChange(e, 'Status')}
                    /> 
                    :
                    this.getStatus(item.Status)}
                </TableCell>
            </TableRow>
        ]
        return itemReturn;
    }

    getStatus(item){
        const { packageStatus } = this.state;
        const status = packageStatus.filter(res => {
            if(res.num === item){
                return res
            }
        })
        return status[0].enum;
    }

    handleRequestSort(event, property) {
        const { orderBy, order } = this.state;
        // const isAsc = orderBy === property && order === 'asc';
        // this.setState({
        //     order: isAsc ? 'desc' : 'asc',
        //     orderBy: property
        // })
        if(orderBy === ''){
            const isAsc = true;
            this.setState({
                order: 'desc',
                orderBy: property,
            })
        } else{
            const isAsc = orderBy === property && order === 'asc';
            this.setState({
                order: isAsc ? 'desc' : 'asc',
                orderBy: property,
            })
        }
    };

    handleChange(event, type){
        const value = event.target.value;
        this.setState({
            personName:  value
        })
    }

    onFilderByDate(){
        const { onFilter } = this.props;
        const { personName, packageStatus, except } = this.state;
        const data = personName.map(item => {
            for(let key in packageStatus){
                if(packageStatus[key].enum === item){
                    return packageStatus[key].num 
                }
            }
        })
        const params ={
            "except": except,
            "filter": data, //["1", "2", "3", "14"]
        }
        onFilter(params, true);
    }

    handleChangeSwitch(event){
        this.setState(prevState => ({
            except: !prevState.except
        }))
    }

    

    onSelect(selectedList, selectedItem) {
        const name = selectedList.map(item => {
            return item.name
        })
        this.setState({
            personName: name
        });
    }
     
    onRemove(selectedList, removedItem) {
        const name = selectedList.map(item => {
            return item.name
        })
        this.setState({
            personName: name
        });
    }

    render(){
        const { classes } = this.props;
        const { packageItem, ieBrowser, order, orderBy, packageStatus, except, rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, packageItem.length - page * rowsPerPage);
        let allItemRows = [];
        packageItem && stableSort(packageItem, getComparator(order, orderBy)).forEach((item, i) => {
            const perItemRows = this.renderItem(item, i);
            allItemRows = allItemRows.concat(perItemRows);
        });
        
        let dataslice = packageItem.length> 0 ? stableSort(packageItem, getComparator(order, orderBy)) : packageItem;
        let items = dataslice.length> 0 ? dataslice.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)  : dataslice;
        
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };
        return(
            <Paper className={classes.root}>
                <Portlet className={classes.portlet}>
                    <PortletHeader 
                        title='Package'
                        headerClassName={classes.portletHead}
                    />
                        <PortletBody style={{position: 'relative', padding: 10, maxHeight: 'calc( 100vh - 108px )', width: '100%'}} data-ktmenu-vertical="1">
                            <div style={{padding: '0 0 15px 0', display: 'flex', alignItems: 'center', zIndex: 9999}}>
                                <div style={{paddingRight: 10}}>
                                    <FormControlLabel
                                        label="Except"
                                        labelPlacement="start"
                                        control={
                                            <Switch
                                                checked={except}
                                                onChange={(e) => this.handleChangeSwitch(e)}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                    />
                                </div>
                                {/* <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="select-multiple-checkbox">Please Select Status</InputLabel>
                                    <Select
                                        multiple
                                        value={personName}
                                        onChange={(e) => this.handleChange(e)}
                                        input={<Input id="select-multiple-checkbox" />}
                                        renderValue={selected => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {packageStatus.map((name, i) => (
                                            <MenuItem key={i} value={name.enum}>
                                                <Checkbox checked={personName.indexOf(name.enum) > -1} />
                                                <ListItemText primary={name.enum} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> */}
                                <Multiselect 
                                    options={packageStatus} 
                                    selectedValues={this.state.selectedValue} 
                                    onSelect={this.onSelect} 
                                    onRemove={this.onRemove} 
                                    displayValue="name"
                                    style={this.style}
                                    placeholder="Select Column"
                                />
                                <Button variant="contained" color="primary" onClick={this.onFilderByDate} className={classes.button}>
                                    Filter
                                </Button>
                            </div>
                            
                            <PerfectScrollbar
                                options={{ 
                                    wheelSpeed: 2, 
                                    wheelPropagation: false 
                                }}
                                style={{padding: '0px 0 0', maxHeight: ieBrowser && 'calc( 100vh - 180px )',}}
                            >
                            <Table stickyHeader aria-label="sticky table" style={{height: '100%'}}>
                                <EnhancedTableHead
                                    classes={classes}
                                    headCells={headCells}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={packageItem.length}
                                />
                                <TableBody>
                                    {packageItem.length > 0 && <PackageItems 
                                        packageItem={items}
                                        classes={classes}
                                    />}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 31 * emptyRows }}>
                                            <TableCell colSpan={7} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
                                            colSpan={7}
                                            count={packageItem.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                            </PerfectScrollbar>
                        </PortletBody>
                </Portlet>
                <Modal show={this.state.show} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title className={classes.modalTitle}>{this.state.modelText}</Modal.Title>
                    </Modal.Header>
                </Modal>
            </Paper>
        )
    }
}

const useStyles = theme => ({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 300,
        width: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        maxWidth: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    modalTitle: {
        fontSize: '1rem !important'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
        maxWidth: '100%',
    },
})

PackageList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(PackageList);