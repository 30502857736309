import axios from "axios";
export const ALL_CONFIG_LIST = "api/config/all";
export const CREATE_CONFIG_REQUEST = "api/config/add";
export const DELETE_CONFIG_REQUEST = "api/config/delete";
export const UPDATE_CONFIG_REQUEST = "api/config/update";
export const All_CONFIG_ADD = 'configurations?code=Dq2oj2R11WnznXmL/3R7Oha8c7CpCytm/JnMZKs8RQl73gTZ0AW3ug==';

export const FILE_OPERATIONS_API = 'configurations?code=Dq2oj2R11WnznXmL/3R7Oha8c7CpCytm/JnMZKs8RQl73gTZ0AW3ug==&id=1';
export const All_CONFIG_API = 'configurations?code=Dq2oj2R11WnznXmL/3R7Oha8c7CpCytm/JnMZKs8RQl73gTZ0AW3ug==&id=2';
export const AUTO_CLEANSING_API = 'configurations?code=Dq2oj2R11WnznXmL/3R7Oha8c7CpCytm/JnMZKs8RQl73gTZ0AW3ug==&id=3';
export const AUTO_BASED_CLEANSING_API = 'configurations?code=Dq2oj2R11WnznXmL/3R7Oha8c7CpCytm/JnMZKs8RQl73gTZ0AW3ug==&id=4';
export const AUTO_CUSTOMER_API = 'configurations?code=Dq2oj2R11WnznXmL/3R7Oha8c7CpCytm/JnMZKs8RQl73gTZ0AW3ug==&id=6';
export const AUTO_STAGE_API = 'configurations?code=Dq2oj2R11WnznXmL/3R7Oha8c7CpCytm/JnMZKs8RQl73gTZ0AW3ug==&id=5';

export const getConfigAll = () => {
    return axios.get(All_CONFIG_API);
    // return fetch(All_CONFIG_API, { 
    //     method: 'GET'
    // })
    // .then((response) => {
    //     if(response.ok) {
    //       return response.json();
    //     } else {
    //     }
    // })
    // .catch((ex) => {
    //     throw new Error('fetch failed' + ex)
    // });
};

export const createConfig = (params) => {
    return axios.post(All_CONFIG_ADD, params);
//    return fetch(All_CONFIG_ADD, {
//         method: 'POST',
//         body: JSON.stringify(params)
//     })
//     .then((response) => response.json())
//     .then((items) => {
//         return({items, success: 'Added Successfully'});
//     })
//     .catch((error) => {
//         return(error);
//     })
}

export const updateConfig = (params) => {
    return axios.post(All_CONFIG_ADD, params);
//     return fetch(All_CONFIG_ADD, {
//         method: 'POST',
//         body: JSON.stringify(params)
//    })
//    .then((response) => response.json())
//     .then((items) => {
//         return({items, success: 'Update has been Successfully'});
//     })
//     .catch((error) => {
//         return(error);
//     })
}

export const deleteConfig = (params) => {
    return axios.post(All_CONFIG_ADD, params);
    // return fetch(All_CONFIG_ADD, {
    //     method: 'POST',
    //     body: JSON.stringify(params)
    // })
    // .then((response) => response.json())
    // .then((items) => {
    //     return({items, success: 'Delete has been Successfully'});
    // })
    // .catch((error) => {
    //     console.error(error);
    // })
}

export const getAllFileOperations = () => {
    return axios.get(FILE_OPERATIONS_API);
    // return fetch(FILE_OPERATIONS_API, { 
    //     method: 'GET'
    // })
    // .then((response) => {
    //     if(response.ok) {
    //       return response.json();
    //     } else {
    //     }
    // })
    // .catch((ex) => {
    //     throw new Error('fetch failed' + ex)
    // });
}

export const getAllAutoCleansing = () => {
    return axios.get(AUTO_CLEANSING_API);
    // return fetch(AUTO_CLEANSING_API, { 
    //     method: 'GET'
    // })
    // .then((response) => {
    //     if(response.ok) {
    //       return response.json();
    //     } else {
    //     }
    // })
    // .catch((ex) => {
    //     throw new Error('fetch failed' + ex)
    // });
}

export const gatAllBasedCleansing = () => {
    return axios.get(AUTO_BASED_CLEANSING_API);
    // return fetch(AUTO_BASED_CLEANSING_API, { 
    //     method: 'GET'
    // })
    // .then((response) => {
    //     if(response.ok) {
    //       return response.json();
    //     } else {
    //     }
    // })
    // .catch((ex) => {
    //     throw new Error('fetch failed' + ex)
    // });
}

export const gatAllCustomers = () => {
    return axios.get(AUTO_CUSTOMER_API);
    // return fetch(AUTO_CUSTOMER_API, { 
    //     method: 'GET'
    // })
    // .then((response) => {
    //     if(response.ok) {
    //       return response.json();
    //     } else {
    //     }
    // })
    // .catch((ex) => {
    //     throw new Error('fetch failed' + ex)
    // });
}

export const gatAllStage = () => {
    return axios.get(AUTO_STAGE_API);
    // return fetch(AUTO_STAGE_API, { 
    //     method: 'GET'
    // })
    // .then((response) => {
    //     if(response.ok) {
    //       return response.json();
    //     } else {
    //     }
    // })
    // .catch((ex) => {
    //     throw new Error('fetch failed' + ex)
    // });
}