import React, {useState, useEffect} from "react";
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { Input, Select } from "../../../partials/controls";
import {
    FILE_DATA_TYPE,
    FILE_FORMAT,
    CefitStatusTitles,
    CefitConditionTitles,
} from "../CefitUiHelpers";
import { useMemo } from "react";

// Validation schema
const CefitEditSchema = Yup.object().shape({
    tenantID: Yup.number()
        .required('Tenant Type is required'),
    fileDataType: Yup.string()
        .required("File Data Type is required"),
    fileFormat: Yup.string()
        .required("File Type is required"),
    rowsToSkip: Yup.number()
        .min(0, 'Min value 0.')
        .required("Rows To Skip is required"),
});

export function CefitsEditForm(props) {
    const {user} = useSelector((state)=>({
        user: state.auth.user
    }), shallowEqual);
    //const [userHasCreatePermission, setUserHasCreatePermission] = useState(false);
    const [userHasUpdatePermission, setUserHasUpdatePermission] = useState(false);
    // const [userHasDeletePermission, setUserHasDeletePermission] = useState(false);
    useEffect(()=>{
      if(user){
          if(user.vhUser){
            //   setUserHasCreatePermission(true);  
              setUserHasUpdatePermission(true);  
            //   setUserHasDeletePermission(true);
          }else{
              var fileMappingModule = user.modules?.find(m=>m.moduleName==='FileMapping');
              if(fileMappingModule){
                  var hasCreatePermission = !!fileMappingModule.permissions.some(p=>p.permissionName === 'Create');
                  var hasUpdatePermission = !!fileMappingModule.permissions.some(p=>p.permissionName === 'Update');
                //   var hasDeletePermission = !!fileMappingModule.permissions.some(p=>p.permissionName === 'Delete');
                //   setUserHasCreatePermission(hasCreatePermission);  
                  setUserHasUpdatePermission(hasCreatePermission || hasUpdatePermission);  
                //   setUserHasDeletePermission(hasDeletePermission);  
                  console.log(hasCreatePermission,hasUpdatePermission);
              }else{            
                //   setUserHasCreatePermission(false);  
                  setUserHasUpdatePermission(false);  
                //   setUserHasDeletePermission(false);  
              }             
          }
      }
    },[user]); 
    function onSubmitHeandle(values) {
        // debugger
        props.saveCefit(values)
    }
    return (
        <>
            {props.cefit && <Formik
                enableReinitialize={true}
                initialValues={props.cefit}
                validationSchema={CefitEditSchema}
                onSubmit={(values) => {
                    onSubmitHeandle(values);
                }}
            >
            {({ handleSubmit, values, errors, touched, setValues }) => (
                <>
                    <Form className="form form-label-right">
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <Field name="tenantID">
                                    {({field})=>(
                                        props.tenantList && <select label="Select Tenant" disabled={!userHasUpdatePermission}
                                        {...field}
                                        className={'form-control' + (errors.fileDataType && touched.fileDataType ? ' is-invalid' : ' is-valid')}
                                            onChange={(e)=>{
                                                console.log(e.target.options[e.target.selectedIndex].text);
                                                setValues(prevValues => ({
                                                    ...prevValues,
                                                    tenantName: e.target.options[e.target.selectedIndex].text,
                                                    [e.target.name]: e.target.value
                                                }));
                                            }}
                                        >
                                            <option value="">Please Select Tenant</option>
                                            {props.tenantList.map((item, index) => (
                                                <option key={item.tenantName} value={item.tenantID}>
                                                    {item.tenantName}
                                                </option>
                                            ))}
                                        </select>
                                    )}

                                </Field> 
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-3">
                                <Field name="fileDataType">
                                    {({ field }) => (
                                        FILE_DATA_TYPE && <select disabled={!userHasUpdatePermission}
                                            {...field}
                                            className={'form-control' + (errors.fileDataType && touched.fileDataType ? ' is-invalid' : ' is-valid')}
                                            onChange={(e) => {
                                                setValues(prevValues => ({
                                                    ...prevValues,
                                                    ['fileFormat']: '',
                                                    [e.target.name]: e.target.value
                                                }))
                                                props.selectDataType(e)
                                            }}
                                        >
                                            <option value="">Please File Data Type</option>
                                            {FILE_DATA_TYPE.map((data) => (
                                                <option key={data} value={data}>
                                                    {data}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </Field>
                            </div>
                            <div className="col-lg-3">
                                <Field name="fileFormat">
                                    {({ field }) => (
                                        FILE_FORMAT && <select disabled={!userHasUpdatePermission}
                                            {...field}
                                            className={'form-control' + (errors.fileFormat && touched.fileFormat ? ' is-invalid' : ' is-valid')}
                                            onChange={(e) => {
                                                setValues(prevValues => ({
                                                    ...prevValues,
                                                    ['file']: '',
                                                    [e.target.name]: e.target.value
                                                }))
                                                props.setAcceptType(e.target.value)
                                            }}
                                        >
                                            <option value="">Please Select File Type</option>
                                            {FILE_FORMAT.map((data) => (
                                                <option key={data} value={data}>
                                                    {data}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </Field>
                            </div>
                            {<>
                                <div className="col-lg-3">
                                    <Field name="sheetName.sheetNSel" >
                                        {({ field }) => (
                                            <select disabled={!userHasUpdatePermission || props.accept === '.csv'}
                                                {...field}
                                                className={'form-control'}
                                                onChange={(e) => {
                                                    setValues(prevValues => ({
                                                        ...prevValues,
                                                        [e.target.name]: e.target.value
                                                    }))
                                                    props.onChangedSheetName(e.target.value)
                                                }}
                                            >
                                                <option value="">Please Select Sheet Name</option>
                                                {props.cefit.sheetName && props.cefit.sheetName.sheetNames && props.cefit.sheetName.sheetNames.map((data) => (
                                                    <option key={data} value={data} >
                                                        {data}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-lg-3">
                                    <Field disabled={!userHasUpdatePermission}
                                        name="rowsToSkip"
                                        component={Input}
                                        type="number"
                                        placeholder="Rows to Skip"
                                        label="Rows to Skip"
                                        onChange={(e) => {
                                            setValues(prevValues => ({
                                                ...prevValues,
                                                [e.target.name]: e.target.value
                                            }))
                                            props.onChangedRowsToSkip(e.target.value)
                                        }}
                                    />
                                    {/* <Field name="rowsToSkip">
                                        {({ field }) => (
                                            <input 
                                                {...field}
                                                type="number"
                                                className={'form-control'}
                                                onChange={(e) => {
                                                    setValues(prevValues => ({
                                                        ...prevValues,
                                                        [e.target.name]: e.target.value
                                                    }))
                                                    props.onChangedRowsToSkip(e.target.value)
                                                }}
                                            />
                                        )} 
                                    </Field> */}
                                    
                                </div>
                            </>}
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-11">
                               
                                <Field name="file">
                                    {({ field }) => (
                                        <div className="custom-file">
                                            <label className="custom-file-label" htmlFor="inputGroupFile01">{props.fileName}</label>
                                            <input  disabled={!userHasUpdatePermission || !values.fileFormat}
                                                {...field}
                                                id="inputGroupFile01" 
                                                type="file" 
                                                accept={props.accept}
                                                className="custom-file-input" 
                                                onChange={(e) => props.handleFileInput(e, setValues)}
                                            />
                                        </div>
                                    )} 
                                </Field>
                            </div>
                            <div className="col-lg-1">
                                <Button disabled={!userHasUpdatePermission}
                                    variant="primary" 
                                    style={{width: '100%'}}
                                    onClick={() => handleSubmit()}
                                >
                                    Upload
                                </Button>
                            </div>
                        </div>
                    </Form>
                </>
            )}
            </Formik>}
        </>
    );
}
