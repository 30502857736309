import * as types from '../actions/types';


const INITIAL_STATE={
    data: [],
    roledata: null,
    selectRow: null,
    id: undefined,
    success: ''
}

export default (state=INITIAL_STATE, action) => {
    switch(action.type)
    {
        case types.FETCH_Role_SUCCESS:
            return {
                ...state,
                data: action.payload,
                selectRow: 0
            }
        case types.FETCH_Role_BY_ID_SUCCESS:
            return {
                ...state,
                roledata: action.payload,
                selectRow: action.payload.roleID
            }
        case types.ADD_Role_create_SUCCESS: 
            return {
                ...state,
                roledata: action.payload,
                selectRow: null
            }
        case types.FETCH_Role_BY_tenantID_SUCCESS: 
            return {
                ...state,
                roledata: action.payload,
                selectRow: null
            }
        case types.ADD_Role_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Role has been successfully added...'
            };
        case types.DELETE_Role_SUCCESS: 
            return {
                ...state,
                userdata: action.payload,
                success: true,
                message: 'Role has been successfully deleted..'
            } 
        case types.Role_Error: 
            return {
                ...state,
                error: action.payload.ErrorMessage ? action.payload.ErrorMessage : 'Please Try again...'
            }
        case types.RESET_STORE: 
            return {
                ...state,
                success: false,
                message: '',
                error: null
            }                       
        default:
        return state;
    }  
};