import React, {useState} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import TextEditor from './TextEditor'
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Button,
} from '@material-ui/core';
import SVG from "react-inlinesvg";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar,
    PortletHeaderTitle
} from "../../../../partials/content/Portlet";
import { green, purple, red } from '@material-ui/core/colors';
import * as actions from '../../../../store/actions/companyPolicyAction'
import Toast from '../../../../crud/Toast'
import * as tenantActions from "../../../../store/actions/tenantAction";
import PerfectScrollbar from "react-perfect-scrollbar";
const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export default function CompanyPolicyEdit(props){
    const dispatch = useDispatch()
    const loader = document.getElementById('splash-screen');
    const history = useHistory()
    const classes = useStyles();
    const [level, setLevel] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [toastVisible, setToastVisible] = React.useState(false)
    const [content, setContent] = React.useState({});
    const initialValue = {
        companyPolicyID: 0,
        createdBy: "",
        createdOn: "",
        isDeleted: false,
        policyContent: "",
        publishDate: "",
        published: true,
        tenantID: null,
    }
    const [item, setItem] = React.useState(props.location.state || initialValue)
    console.log('history', history)
    const { addData, user, tenentSuccess, tenantMassage, error } = useSelector((state) => ({
        user: state.auth.user,
        addData: state.companyPolicy.addData,
        tenentSuccess: state.companyPolicy.success,
        tenantMassage: state.companyPolicy.message,
        error: state.companyPolicy.error
    }), shallowEqual);
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    const [ieBrowser] = useState(isIE ? true : false);//, setIeBrowser
    React.useEffect(()=>{
        if(user){
            dispatch(tenantActions.setActiveStep(user.vhUser? 6 : 5));
        }
    },[dispatch, user]);
    React.useEffect(() => {
        if(!history.location.state){
            const data = addData;
            data['createdBy'] = user ? user.email : ''
            setItem(addData)
        }
    }, [addData])
    React.useEffect(() => {
        if(!history.location.state){
            if(history.location.pathname.indexOf('tenant') > 0){
                dispatch(actions.createTenantCompanyPolicy(props.match.params.id))
                loader.classList.remove('hidden')
            } else {
                dispatch(actions.addCompanyPolicy())
                loader.classList.remove('hidden')
            }
        }
    }, [dispatch])
    function hendleCancle(){
        history.goBack();
    }
    const resetTest = (content) => {
        setContent(content)
    }
    function hendleSubmit(){
        const params = item
        content && content.blocks && (params['policyContent'] = content.blocks[0].text)
        dispatch(actions.updateCompanyPolicy(params))
        loader.classList.remove('hidden')
    }
    function hendlePublish(){
        dispatch(actions.companyPolicyPublish(item.companyPolicyID))
        loader.classList.remove('hidden')
    }
    React.useEffect(() => {
        if(tenentSuccess){
            if(tenantMassage){                
                setLevel('success')
                setMessage(tenantMassage)
                setToastVisible(tenentSuccess)
            }
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.reset())
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 5000)
        }else { 
            setLevel('')
            setMessage('')
            setToastVisible(false)
        }
    }, [tenentSuccess, tenantMassage])
    React.useEffect(() => {
        if(error){
            setLevel('danger')
            setMessage(error)
            setToastVisible(true)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.reset())
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 5000)
        }
    }, [error])
    return(
        <Paper className={classes.root}>
            <Portlet className={classes.portlet}>
                <PortletHeader
                    title={
                        <PortletHeaderTitle>
                            <SVG 
                                src={toAbsoluteUrl('/media/svg/icons/Layout/hourglass_full-24px.svg')} 
                            />
                            <span 
                                style={{color: '#000000', marginLeft: 10, fontWeight: 700}}
                            >Policy Content</span>
                        </PortletHeaderTitle>
                    }
                    headerClassName={classes.portletHead}
                />
            </Portlet>
            <PortletBody 
                style={{
                    position: 'relative', 
                    padding: '0px 10px 10px', 
                    maxHeight: 'calc( 100vh - 92px )', 
                    minHeight: 200, width: '100%'
                }} 
                data-ktmenu-vertical="1"
            >
                        <PerfectScrollbar
                            options={{ 
                                wheelSpeed: 2, 
                                wheelPropagation: false 
                            }}
                            style={{padding: 0, maxHeight: 'calc( 100vh - 100px )'}}>
                            <div style={{
                                border: 'solid 1px #ddd',
                                borderRadius: 2,
                                minHeight: 'calc( 100vh - 150px )',
                                marginTop: 10
                            }}>
                                <TextEditor item={item} resetTest={resetTest}/>  
                            </div>  
                            <div className="row mt-4">
                                <div className="col-sm-12 text-right">
                                    {/* <ColorButton 
                                        variant="contained" 
                                        color="primary" 
                                        style={{marginLeft: 10}}
                                        onClick={hendleCancle}
                                    >
                                        Cancel
                                    </ColorButton> */}
                                    <Button 
                                        variant="contained"
                                        color="secondary"
                                        style={{marginLeft: 10}}
                                        onClick={hendleCancle}
                                    >
                                        Cancel
                                    </Button>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        style={{marginLeft: 10}}
                                        onClick={hendleSubmit}
                                    >
                                        Save
                                    </Button>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        style={{marginLeft: 10}}
                                        onClick={hendlePublish}
                                        disabled={item.companyPolicyID == 0}
                                    >
                                        Publish
                                    </Button>
                                </div>
                            </div>
                        </PerfectScrollbar>

            
            </PortletBody>
            <Toast
                level={level}
                message={message}
                visible={toastVisible}
            />
        </Paper>
    )
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        width: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    }
})