import React, {useState,useEffect, Fragment} from 'react';
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import * as actions from '../../../../store/actions/organisationAction'
import {
    TableCell,
    TableRow,
    IconButton,
    TextField,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'

export default function OrganisationAdd(props){
    const classes = useStyles();
    const dispatch = useDispatch()
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [initialValue, setInitialValue] = useState({
        Name:"",
        levelID:"",
        ParentOrganisationID:props.organizationID,
        TenantID:props.tenantID,
        Active:true
    })

    const submit = () => {
        dispatch(actions.fetchAddRow(initialValue))
        setTimeout(function(){ 
            props.onAddRow();
            dispatch(actions.getOrganisation(props.tenantID))
        }, 2000);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInitialValue({ ...initialValue, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(initialValue));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};
        if (!values.Name) {
            errors.Name = "Cannot be blank";
        } 
        if (!values.levelID) {
            errors.levelID = "Cannot be blank";
        } 
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submit();
        }
    }, [formErrors]);
    return(
        <Fragment>
            <TableRow className ='row-color'>
                <TableCell className = { classes.cellBtn } >
                    
                </TableCell>
                <TableCell className = { classes.cellBtn } > 
                    <TextField
                        id = "standard-basic3"
                        label = "Edit Name"
                        error={formErrors.Name ? true : false}
                        name="Name"
                        value = {initialValue.Name}
                        onChange = {
                            (e) => handleChange(e) 
                        }
                    /> 
                </TableCell>
                <TableCell 
                    className = { classes.cellBtn }
                > 
                    <TextField
                        id = "standard-basic3"
                        error={formErrors.levelID ? true : false}
                        label = "Edit levelID"
                        name="levelID"
                        value = {initialValue.levelID}
                        onChange = {
                            (e) => handleChange(e) 
                        }
                    />
                </TableCell>
                
                <TableCell 
                    className = { classes.cell }
                    align = "right"
                    style = {{ paddingRight: 0 }} 
                > 
                    <Fragment>
                        <IconButton
                            color = "primary"
                            className = { classes.button }
                            component = "span"
                            size = 'small'
                            onClick={handleSubmit}
                        >
                            <DoneIcon color = "primary" />
                        </IconButton> 
                        <IconButton
                            color = "default"
                            className = {classes.button}
                            component = "span"
                            size = 'small'
                            onClick={props.onAddRow}
                        >
                            <ClearIcon color = "error" />
                        </IconButton> 
                    </Fragment>
                </TableCell>			
            </TableRow> 
        </Fragment>
    )
}


const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
        padding: 0
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    title: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 500,
        lineHeight: '2.5rem'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    }
})