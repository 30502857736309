import * as types from './types';

const getAllTenantIndustries = () => ({
    type: types.GET_ALL_TENANTINDUSTRIES,
});


const getActiveTenantIndustries = () => ({
    type: types.GET_ACTIVE_TENANTINDUSTRIES,
});


const fetchTenantIndustryLevelsById = (id) => ({
    type: types.FETCH_TENANTINDUSTRY_BYID_LEVELS,
    payload: id
});

const updateTenantIndustryRequest = (data) => ({
    type: types.UPDATE_TENANTINDUSTRY_REQUEST,
    payload: data,
});

const updateTenantIndustrySuccess = ({ items }) => ({
    type: types.UPDATE_TENANTINDUSTRY_SUCCESS,
    payload: {
        items
    }
})

const addTenantIndustryRequest = (data) => ({
    type: types.ADD_TENANTINDUSTRY_REQUEST,
    payload: data,
});

const addTenantIndustrySuccess = ({ items }) => ({
    type: types.ADD_TENANTINDUSTRY_SUCCESS,
    payload: {
        items
    }
})

const deleteTenantIndustryRequest = (data) => ({
    type: types.DELETE_TENANTINDUSTRY_REQUEST,
    payload: data,
});

const deleteTenantIndustrySuccess = ({ items }) => ({
    type: types.DELETE_TENANTINDUSTRY_SUCCESS,
    payload: {
        items
    }
})

const fetchTenantIndustryLevelsAll = () => ({
    type: types.FETCH_TENANTINDUSTRY_LEVELS,
})

const fetchTenantIndustryLevelsSuccess = ({items}) => ({
    type: types.FETCH_TENANTINDUSTRY_LEVELS_SUCCESS,
    payload: { 
        items
    }
})

const updateTenantIndustryLevelsRequest = (data) => ({
    type: types.UPDATE_TENANTINDUSTRY_LEVELS_REQUEST,
    payload: data,
});

const updateTenantIndustryLevelsSuccess = ({ items }) => ({
    type: types.UPDATE_TENANTINDUSTRY_LEVELS_SUCCESS,
    payload: {
        items
    }
})

const addTenantIndustryLevelsRequest = (data) => ({
    type: types.ADD_TENANTINDUSTRY_LEVELS_REQUEST,
    payload: data,
});

const addTenantIndustryLevelsSuccess = ({ items }) => ({
    type: types.ADD_TENANTINDUSTRY_LEVELS_SUCCESS,
    payload: {
        items
    }
})

const deleteTenantIndustryLevelsRequest = (data) => ({
    type: types.DELETE_TENANTINDUSTRY_LEVELS_REQUEST,
    payload: data,
});

const deleteTenantIndustryLevelsSuccess = ({ items }) => ({
    type: types.DELETE_TENANTINDUSTRY_LEVELS_SUCCESS,
    payload: {
        items
    }
})

const fetchLevelsTypeAll = (data) => ({
    type: types.FETCH_LEVELS_TYPE,
    payload: data
})

const fetchLevelsTypeSuccess = ({items}) => ({
    type: types.FETCH_LEVELS_TYPE_SUCCESS,
    payload: { 
        items
    }
})

const updateLevelsTypeRequest = (data) => ({
    type: types.UPDATE_LEVELS_TYPE_REQUEST,
    payload: data,
});

const updateLevelsTypeSuccess = ({ items }) => ({
    type: types.UPDATE_LEVELS_TYPE_SUCCESS,
    payload: {
        items
    }
})

const addLevelsTypeRequest = (data) => ({
    type: types.ADD_LEVELS_TYPE_REQUEST,
    payload: data,
});

const addLevelsTypeSuccess = ({ items }) => ({
    type: types.ADD_LEVELS_TYPE_REQUEST,
    payload: {
        items
    }
})

const deleteLevelsTypeRequest = (data) => ({
    type: types.DELETE_LEVELS_TYPE_REQUEST,
    payload: data,
});

const deleteLevelsTypeSuccess = ({ items }) => ({
    type: types.DELETE_LEVELS_TYPE_SUCCESS,
    payload: {
        items
    }
})

const error = ({error}) => ({
    type: types.TENANTINDUSTRY_ERROR,
    payload: error
});

const reset = () => ({
    type: types.RESET_STORE
})

export {
    getAllTenantIndustries,
    getActiveTenantIndustries,
    updateTenantIndustryRequest,
    updateTenantIndustrySuccess,
    addTenantIndustryRequest,
    addTenantIndustrySuccess,
    deleteTenantIndustryRequest,
    deleteTenantIndustrySuccess,
    fetchTenantIndustryLevelsAll,
    fetchTenantIndustryLevelsSuccess,
    updateTenantIndustryLevelsRequest,
    updateTenantIndustryLevelsSuccess,
    addTenantIndustryLevelsRequest,
    addTenantIndustryLevelsSuccess,
    deleteTenantIndustryLevelsRequest,
    deleteTenantIndustryLevelsSuccess,
    fetchLevelsTypeAll,
    fetchLevelsTypeSuccess,
    updateLevelsTypeRequest,
    updateLevelsTypeSuccess,
    addLevelsTypeRequest,
    addLevelsTypeSuccess,
    deleteLevelsTypeRequest,
    deleteLevelsTypeSuccess,
    fetchTenantIndustryLevelsById,
    error,
    reset
}