import { takeEvery, takeLatest, take, call, fork, put } from 'redux-saga/effects';
import * as types from '../actions/types';
import * as action from '../actions/globalConfigAction';

import { getGlobalConfigAll, getAllGlobalConfig, updateGlobalConfig, addGlobalConfig, deleteGlobalConfig } from '../../crud/globalConfig.crud';

function* getGlobalConfig() {
    try {
        const res = yield call(getGlobalConfigAll);
        yield put({
            type: types.FETCH_GLOBAL_CONFIG_SUCCESS,
            payload: {
                items: res.data.result
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getGlobalRequest() {
    yield takeEvery(types.FETCH_GLOBAL_CONFIG, getGlobalConfig);
}


function* getAllGlobalConfigs() {
    try {
        const result = yield call(getAllGlobalConfig);
        yield put({
            type: types.FETCH_GLOBAL_CONFIG_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getAllGlobalConfigRequest() {
    yield takeEvery(types.FETCH_ALL_GLOBAL_CONFIG, getAllGlobalConfigs);
}


function* updateGlobalConfigItem({ payload }) {
    try {

        const items = yield call(updateGlobalConfig, payload);
        yield put({
            type: types.UPDATE_GLOBAL_CONFIG_SUCCESS,
            payload: {
                items: items.data
            },
        });
        yield call(getGlobalRequest);
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* watchUpdateGlobalConfigRequest() {
    yield takeLatest(types.UPDATE_GLOBAL_CONFIG_REQUEST, updateGlobalConfigItem);
}

function* addGlobalConfigItem({ payload }) {
    try {

        const items = yield call(addGlobalConfig, payload);
        yield put({
            type: types.ADD_GLOBAL_CONFIG_SUCCESS,
            payload: {
                items: items.data
            },
        });
        yield call(getGlobalRequest);
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* watchAddGlobalConfigRequest() {
    yield takeLatest(types.ADD_GLOBAL_CONFIG_REQUEST, addGlobalConfigItem);
}

function* deleteGlobalConfigItem({ payload }) {
    try {

        const items = yield call(deleteGlobalConfig, payload);
        yield put({
            type: types.DELETE_GLOBAL_CONFIG_SUCCESS,
            payload: {
                items: items.data
            },
        });
        yield call(getGlobalRequest);
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* watchDeleteGlobalConfigRequest() {
    yield takeLatest(types.DELETE_GLOBAL_CONFIG_REQUEST, deleteGlobalConfigItem);
}

const globalConfigSaga = [
    fork(getGlobalRequest),
    fork(getAllGlobalConfigRequest),
    fork(watchUpdateGlobalConfigRequest),
    fork(watchAddGlobalConfigRequest),
    fork(watchDeleteGlobalConfigRequest)
];

export default globalConfigSaga;
