import axios from "axios";

const url = 'workspace';

export const getConfigureWorkspaces = (id) => {
    return axios.get(`${url}/TenantWorkspaceReport/${id}`);
};

export const updateConfigureWorkspaces = (params) => {
    return axios.post(`${url}/TenantWorkspaceReport`, params);
};