import * as types from './types';

const getManageUser = (parmas) => ({
    type: types.FETCH_MANAGEUSER_REQUEST,
    payload: parmas
})

const getManageUserSuccess = ({ items }) => ({
    type: types.FETCH_MANAGEUSER_SUCCESS,
    payload: {
        items
    }
})

const getManageUserTenantId = (parmas) => ({
    type: types.FETCH_MANAGEUSER_TENANTID_REQUEST,
    payload: parmas
})

const getManageUserTenantIdSuccess = ({ items }) => ({
    type: types.FETCH_MANAGEUSER_TENANTID_SUCCESS,
    payload: {
        items
    }
})

const getManageUserByUserId = (data) => ({
    type: types.FETCH_MANAGEUSER_BYUSERID_REQUEST,
    payload: data
})

const getManageUserByUserIdSuccess = ({ items }) => ({
    type: types.FETCH_MANAGEUSER_BYUSERID_SUCCESS,
    payload: {
        items
    }
})

const createManageUserByTenantId = (data) => ({
    type: types.CREATE_MANAGEUSER_BYTENANTID_REQUEST,
    payload: data
})

const createManageUserByTenantIdSuccess = ({ items }) => ({
    type: types.CREATE_MANAGEUSER_BYTENANTID_SUCCESS,
    payload: {
        items
    }
})

const createManageUser = () => ({
    type: types.CREATE_MANAGEUSER_REQUEST,
})

const createManageUserSuccess = ({ items }) => ({
    type: types.CREATE_MANAGEUSER_SUCCESS,
    payload: {
        items
    }
})

const addManageUser = (data) => ({
    type: types.ADD_MANAGEUSER_REQUEST,
    payload: data
})

const addManageUserSuccess = ({ items }) => ({
    type: types.ADD_MANAGEUSER_SUCCESS,
    payload: {
        items
    }
})

const updateManageUser = (data) => ({
    type: types.UPDATE_MANAGEUSER_REQUEST,
    payload: data
})

const updateManageUserSuccess = ({ items }) => ({
    type: types.UPDATE_MANAGEUSER_SUCCESS,
    payload: {
        items
    }
})

const deleteManageUserRequest = (data) => ({
    type: types.DELETE_MANAGEUSER_REQUEST,
    payload: data,
});

const deleteManageUserSuccess = ({ items }) => ({
    type: types.DELETE_MANAGEUSER_SUCCESS,
    payload: {
        items
    }
});
const error = ({error}) => ({
    type: types.MANAGEUSER_ERROR,
    payload: error
});

const reset = () => ({
    type: types.RESET_STORE
});
const resetData = () => ({
    type: types.RESET_MANAGEUSER_STORE_DATA
});

export {
    getManageUser,
    getManageUserSuccess,
    getManageUserTenantId,
    getManageUserTenantIdSuccess,
    getManageUserByUserId,
    getManageUserByUserIdSuccess,
    createManageUserByTenantId,
    createManageUserByTenantIdSuccess,
    createManageUser,
    createManageUserSuccess,
    addManageUser,
    addManageUserSuccess,
    updateManageUser,
    updateManageUserSuccess,
    deleteManageUserRequest,
    deleteManageUserSuccess,
    error,
    reset,
    resetData
}