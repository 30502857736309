import React, { useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from "formik";
import { get, merge } from "lodash";
import { FormHelperText, Switch, Tab, Tabs } from "@material-ui/core";
import clsx from "clsx";
import { metronic, initLayoutConfig, LayoutConfig } from "../../../_metronic";
import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader,
  PortletHeaderToolbar
} from "../../partials/content/Portlet";
// import { CodeBlock } from "../../partials/content/CodeExample";
import Notice from "../../partials/content/Notice";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
}));

const localStorageActiveTabKey = "builderActiveTab";
export default function Builder() {
  const classes = useStyles();
  const activeTab = localStorage.getItem(localStorageActiveTabKey);
  const [tab, setTab] = useState(activeTab ? +activeTab : 0);
  const dispatch = useDispatch();
  const { layoutConfig } = useSelector(
    ({ builder }) => ({ layoutConfig: builder.layoutConfig }),
    shallowEqual
  );
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingButtonPreviewStyle, setLoadingButtonPreviewStyle] = useState({
    paddingRight: "2.5rem"
  });
  const [loadingReset, setLoadingReset] = useState(false);
  const [loadingButtonResetStyle, setLoadingButtonResetStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoadingPreview = () => {
    setLoadingPreview(true);
    setLoadingButtonPreviewStyle({ paddingRight: "3.5rem" });
  };
  const enableLoadingReset = () => {
    setLoadingReset(true);
    setLoadingButtonResetStyle({ paddingRight: "3.5rem" });
  };
  const updateLayoutConfig = _config => {
    dispatch(metronic.builder.actions.setLayoutConfigs(_config));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const initialValues = useMemo(
    () =>
      merge(
        // Fulfill changeable fields.
        LayoutConfig,
        layoutConfig
      ),
    [layoutConfig]
  );

  return (
    <>
      {/* <Notice icon="flaticon-warning kt-font-primary">
        The layout builder helps to configure the layout with preferred options
        and preview it in real time. The configured layout options will be saved
        until you change or reset them. To use the layout builder choose the
        layout options and click the <code>Preview</code> button to preview the
        changes.
      </Notice> */}
      {/*Formic off site: https://jaredpalmer.com/formik/docs/overview*/}
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          enableLoadingPreview();
          updateLayoutConfig(values);
        }}
        onReset={() => {
          enableLoadingReset();
          updateLayoutConfig(initLayoutConfig);
        }}
      >
        {({ values, handleReset, handleSubmit, handleChange, handleBlur }) => (
          <div className="kt-form kt-form--label-right" style={{padding: '0px 10px'}}>
            <Portlet className={classes.root}>
              <PortletHeader
                  title='Select Theme'
                // toolbar={
                //   <PortletHeaderToolbar>
                //     <Tabs
                //       component="div"
                //       className="builder-tabs"
                //       value={tab}
                //       onChange={(_, nextTab) => {
                //         setTab(nextTab);
                //         localStorage.setItem(localStorageActiveTabKey, nextTab);
                //       }}
                //     >
                //       <Tab label="Skins" />
                //       {/* <Tab label="Page" /> */}
                //       <Tab label="Header" />
                //       <Tab label="Subheader" />
                //       <Tab label="Content" />
                //       <Tab label="Aside" />
                //       <Tab label="Footer" />
                //     </Tabs>
                //   </PortletHeaderToolbar>
                // }
              />

                <PortletBody>
                  <div className="kt-section kt-margin-t-30">
                    <div className="kt-section__body">
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Header Skin:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control"
                            name="header.self.skin"
                            onBlur={handleBlur}
                            value={get(values, "header.self.skin")}
                            onChange={handleChange}
                          >
                            <option value="light">Light (Default)</option>
                            <option value="dark">Dark</option>
                          </select>
                          <FormHelperText>Select header skin</FormHelperText>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Header Menu Skin:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control"
                            name="header.menu.desktop.submenu.skin"
                            onBlur={handleBlur}
                            value={get(
                              values,
                              "header.menu.desktop.submenu.skin"
                            )}
                            onChange={handleChange}
                          >
                            <option value="light">Light (Default)</option>
                            <option value="dark">Dark</option>
                          </select>
                          <FormHelperText>
                            Select header menu skin
                          </FormHelperText>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Logo Bar Skin:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control"
                            name="brand.self.skin"
                            onBlur={handleBlur}
                            value={get(values, "brand.self.skin")}
                            onChange={handleChange}
                          >
                            <option value="dark">Dark (Default)</option>
                            <option value="light">Light</option>
                          </select>
                          <FormHelperText>Select logo bar skin</FormHelperText>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Aside Skin:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                            <select
                                className="form-control"
                                name="aside.self.skin"
                                onBlur={handleBlur}
                                value={get(values, "aside.self.skin")}
                                onChange={handleChange}
                            >
                                <option value="dark">Dark (Default)</option>
                                <option value="light">Light</option>
                            </select>
                            <FormHelperText>Select logo bar skin</FormHelperText>
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletBody>

              <PortletFooter>
                <div className="kt-padding-30 text-center">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    style={loadingButtonPreviewStyle}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loadingPreview
                      }
                    )}`}
                  >
                    <i className="la la-eye" /> Apply
                  </button>{" "}
                  <button
                    type="button"
                    onClick={handleReset}
                    style={loadingButtonResetStyle}
                    className={`btn btn-secondary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--dark": loadingReset
                      }
                    )}`}
                  >
                    <i className="la la-recycle" /> Reset
                  </button>
                </div>
              </PortletFooter>
            </Portlet>

            {/* <Portlet>
              <PortletHeader
                title={
                  <>
                    Generated Config{" "}
                    <small>
                      can be used for layout config in{" "}
                      <code>/src/_metronic/layouts/demo1/LayoutConfig.js</code>
                    </small>
                  </>
                }
              />

              <PortletBody>
                <CodeBlock
                  disableCopy="true"
                  language="json"
                  code={JSON.stringify(values, null, 2)}
                />
              </PortletBody>
            </Portlet> */}
          </div>
        )}
      </Formik>
    </>
  );
}
