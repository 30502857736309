import * as types from '../actions/types';


const INITIAL_STATE={
    users: [],
    userdata: null,
    selectRow: null,
    id: undefined,
    success: false,
    message: '',
    error: null
}

export default (state=INITIAL_STATE, action) => {
    // console.log("ManageUserAction", action, state);
    switch(action.type){
        case types.FETCH_MANAGEUSER_SUCCESS:
            return {
                ...state,
                users: action.payload,
                selectRow: 0
            }
        case types.FETCH_MANAGEUSER_BYUSERID_SUCCESS:
            return {
                ...state,
                userdata: action.payload,
                selectRow: action.payload.userID
            }
        case types.CREATE_MANAGEUSER_SUCCESS: 
            return {
                ...state,
                userdata: action.payload,
                selectRow: null,
                // success: true,
                // message: 'User has been successfully created..'
            }
        case types.CREATE_MANAGEUSER_BYTENANTID_SUCCESS: 
            return {
                ...state,
                userdata: action.payload,
                selectRow: null,
                // success: true,
                // message: 'Tenent user has been successfully created..'
            }
        case types.ADD_MANAGEUSER_SUCCESS: 
            return {
                ...state,
                userdata: action.payload,
                selectRow: action.payload?.userID,
                success: true,
                message: 'User has been successfully added..'
            }
        case types.UPDATE_MANAGEUSER_SUCCESS: 
            return {
                ...state,
                userdata: action.payload,
                selectRow: action.payload.userID,
                success: true,
                message: 'user has been successfully updated..'
            }
        case types.DELETE_MANAGEUSER_SUCCESS: 
            return {
                ...state,
                userdata: action.payload,
                success: true,
                message: 'user has been successfully deleted..'
            }
        case types.MANAGEUSER_ERROR: 
            return {
                ...state,
                error: action.payload.ErrorMessage ? action.payload.ErrorMessage : 'Please Try again...'
            }
        case types.RESET_STORE: 
            return {
                ...state,
                success: false,
                message: '',
                error: null
            }
        case types.RESET_MANAGEUSER_STORE_DATA:
            return {
                ...INITIAL_STATE
            };
        default:
        return state;
    }  
};