import React, { useState,useEffect, Fragment, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import * as actions from "../../../../../store/actions/roleAction";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as AngleDoubleLeftIcon } from "../../../../../../_metronic/layout/assets/layout-svg-icons/Angle-double-left.svg"
import { ReactComponent as AngleDoubleRightIcon } from "../../../../../../_metronic/layout/assets/layout-svg-icons/Angle-double-right.svg";
import { DeleteConfirmation } from '../../../../../crud/LayoutUtilsService'

import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Checkbox
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Done'
import {
    getComparator,    
    stableSort,
    EnhancedTableHead
} from '../../../../../crud/SortGrid';
import RoleAdd from '../Role-add/RoleAdd';
import { Form } from 'react-bootstrap'
import Toast from '../../../../../crud/Toast';

function  RoleList(props){
    const { match , user} = props
    const loader = document.getElementById('splash-screen');
    const [isSending, setIsSending] = useState(true)
    const[updateItem, setUpdateItem] = useState(null)
    const[deleteItem, setDelete] = useState(null)
    const [roleToDelete, setRoleToDelete] = useState(null);
    const classes = useStyles();
    const [addItem, setAddItem] = useState(null);
    const [minMenu, setMinMenu] = useState(false);
    const [userHasEditTenantPermission, setUserHasEditTenantPermission] = useState(false);
    useEffect(()=>{
        var hasPermission = !!user?.modules?.find(m=>m.moduleName==='Tenants' && m.permissions?.some(p=>p.permissionName === 'Create'||p.permissionName === 'Update'));
        setUserHasEditTenantPermission(hasPermission);
    },[]);
    const history = useHistory()
    const params = {
        "sortField":"",
        "sortDirection":"",
        "pageSize":10,
        "pageNumber":1,
        "searchText":""
    }
    const initialRole = {
        roleID: undefined,
        roleName: "",
        description: "",
        systemDefined: false,
        active: false,
        user: null,
        rolePermissions: null
    }
    const dispatch = useDispatch();
    const[dataSource, setDataSource] = useState([])
    useEffect(() => {
        loader.classList.remove('hidden')
        if(match.params.id){
            const data = {
                params: params,
                id: match.params.id
            }
            dispatch(actions.getAllTenantRoles(data));
        } else {
            dispatch(actions.getAllRoles(params));  
        }
        setTimeout(() => {
            loader.classList.add('hidden')
        }, 1000)
    }, [match.params]);
    const { currentState, row, success, roleMessage, error } = useSelector(
        (state) => ({ 
            currentState: state.roles.data, 
            row: state.roles.selectRow,
            success: state.roles.success,
            roleMessage: state.roles.message,
            error: state.roles.error
        }),
        shallowEqual
    );
    const [level, setLevel] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [toastVisible, setToastVisible] = React.useState(false)  ;
    useEffect(() => {
        console.log('success/message changed', success, roleMessage);
        if(success){
            setExpandedRows([]);
            setLevel('success')
            setMessage(roleMessage)
            setToastVisible(success)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.reset());
                if(match.params.id){
                    const data = {
                        params: params,
                        id: match.params.id
                    }
                    dispatch(actions.getAllTenantRoles(data));
                } else {
                    dispatch(actions.getAllRoles(params));  
                }
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 1000);
        }else { 
            setLevel('')
            setMessage('')
            setToastVisible(false)
        }
    }, [success, roleMessage]);    
    useEffect(() => {
        if(error){
            setLevel('danger')
            setMessage(error)
            setToastVisible(true)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.reset());
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 100)
        }
    }, [error]);
    useEffect(() => {
        const currentExpandedRows = [];
        const isRowExpanded = currentExpandedRows.includes(row);
        let obj = {};
        isRowExpanded ? (obj[row] = false) :  (obj[row] = true);
        setExpandState(obj);
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== row) :
            currentExpandedRows.concat(row);
        setExpandedRows(newExpandedRows);
        setAddItem(row == 0 ? currentState && currentState.items && currentState.items[0] : null);
    }, [row])
    useEffect(() => {
        setDataSource(currentState && currentState.items);
        setAddItem(row == 0 ? currentState && currentState.items && currentState.items.length > 0 ? currentState.items[0] : null : null)
        if(currentState && currentState.items && currentState.items.length > 0){
            const currentExpandedRows = [];
            const isRowExpanded = currentExpandedRows.includes(currentState.items[0].roleID);
            let obj = {};
            isRowExpanded ? (obj[currentState.items[0].roleID] = false) :  (obj[currentState.items[0].roleID] = true);
            setExpandState(obj);
            const newExpandedRows = isRowExpanded ?
                currentExpandedRows.filter(id => id !== currentState.items[0].roleID) :
                currentExpandedRows.concat(currentState.items[0].roleID);
            setExpandedRows(newExpandedRows);
            console.log('getRoleRequestById called from items changes');
            dispatch(actions.getRoleRequestById(currentState.items[0].roleID))
        }
    },[currentState])
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const handleRequestSort = (event, property) => {
        if(orderBy === ''){
            setOrder('desc');
            setOrderBy(property);
            setDataSource(stableSort(dataSource, getComparator('asc', property)))
        } else{
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
            setDataSource(stableSort(dataSource, getComparator(order, orderBy)))
        }
    };
    useEffect(() => {
        if (updateItem){
            setIsSending(false)
            setDataSource([]);
            dispatch(actions.updateRoleRequest(updateItem));
            setTimeout(() => {
                setUpdateItem(null);
                setIsSending(true)
            }, 500)
        }
    }, [dispatch, updateItem]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandState, setExpandState] = useState({});

    const handleEpandRow = (event, item) => {
        console.log(event.target?.type);
        
        console.log('getRoleRequestById called from handleExpandRow',item.roleID, roleToDelete?.roleID);
        if(item.roleID === roleToDelete?.roleID){
            setExpandedRows([]);
            return;
        }

        setAddItem(null);
        const currentExpandedRows = [];
        const isRowExpanded = currentExpandedRows.includes(item.roleID);
        let obj = {};
        isRowExpanded ? (obj[item.roleID] = false) :  (obj[item.roleID] = true);
        setExpandState(obj);
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== item.roleID) :
            currentExpandedRows.concat(item.roleID);
        setExpandedRows(newExpandedRows);
        setAddItem(item);
        dispatch(actions.getRoleRequestById(item.roleID));
    }

    const hendleMinMenu = () => {
        setMinMenu(!minMenu);
    }
    const hendleChange = (e) => {
        params.searchText = e.target.value;
        dispatch(actions.getAllRoles());
    }
    const deleteConfirm = (item) => {
        setRoleToDelete(item);
        setDelete(true);
    }
    const deleteClose = (rowIndex, action) => {
        if (action === 'delete') {
            const loader = document.getElementById('splash-screen');
            loader.classList.remove('hidden');
            console.log('Delete Role', roleToDelete);
            setExpandedRows([]);
            dispatch(actions.deleteRoleRequest(roleToDelete));
            //props.onDelete(item);
            setTimeout(()=>{
                setDelete(false);
                loader.classList.add('hidden');
            }, 100);
            return;
            //setDelete(false);
        } else if (action === 'close') {
            setDelete(false);
        }
    }
    let allItemRows = [];
    console.log(expandedRows);
    dataSource && dataSource.forEach((item, i) => {
        const perItemRows = <TableRow 
            onClick={event => handleEpandRow(event, item)}
            className={expandedRows.includes(item.roleID) ? clsx('row-color role-row ', classes.selected) : 'row-color role-row'}
            key={"row-data-" + i}
        >
            <TableCell className={classes.cellBtn}>
                {item.roleName}
            </TableCell>
            <TableCell className={classes.cellBtn}>
                {item.description}
            </TableCell>
            <TableCell className={classes.cell}>
                {item.systemDefined ? 'true' : 'false'}
            </TableCell>  
            <TableCell className={classes.cell}
                align="right"
                style={{paddingRight: 0}} 
            > 
            
                {userHasEditTenantPermission &&  !item.systemDefined  && (<Fragment>
                    <IconButton  
                        color="default"
                        className={classes.button}
                        aria-label="Delete"
                        component="span"
                        onClick={(e)=>{e.preventDefault(); deleteConfirm(item,e.preven);}}>
                        <DeleteIcon color="error" />
                    </IconButton>
                    {deleteItem && <DeleteConfirmation
                        _openDialog={deleteItem}
                        index={i}
                        handleDelete={deleteClose}
                    />}
                </Fragment>)}
            </TableCell>
        </TableRow>;
        //<RoleItem key={i} data={dataSource} item={item} index={i} onSave={onUpdateRow} onDelete={onDeleteRow} />;
        allItemRows = allItemRows.concat(perItemRows);
    });
    return (
        <div className={classes.container}>
            <div>
                <div className={clsx("panel panel-default ", minMenu ? classes.tenantContentSidebar : classes.pannelList)}>
                    <div className={clsx('tenant-menu', classes.tenantMenu, minMenu ? `${classes.minMenu} minMenu` : '')}>
                        <div className="menu__search">
                            <div className="search-tools">
                                <button
                                    className="search-toggler"
                                    id="search_toggler"
                                    onClick={() => hendleMinMenu()}
                                >
                                    <span>
                                        <AngleDoubleLeftIcon />
                                    </span>
                                    <span>
                                        <AngleDoubleRightIcon />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="menu-container">
                            <Table className={classes.table}>
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    headCells={headCells}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={dataSource ? dataSource.length : 0}
                                />
                                <TableBody>
                                    {props.openAddRow && <TableRow>
                                        <TableCell className={classes.cellBtn}>
                                            <Form.Control 
                                                placeholder="Role Name"
                                                value={initialRole.roleName}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.cellBtn}>
                                            <Form.Control 
                                                placeholder="Description"
                                                checked={initialRole.description}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Checkbox 
                                                checked={initialRole.systemDefined}
                                                //onChange={(e) => handleChange(e, index, i)} 
                                                name="systemDefined" 
                                                color="primary"
                                            />
                                        </TableCell>  
                                        <TableCell className={classes.cell}
                                            align="right"
                                            style={{paddingRight: 0}} 
                                        > 
                                            <Fragment>
                                                <IconButton
                                                    color="primary"
                                                    className={classes.button}
                                                    component="span"
                                                    size='small'
                                                >
                                                    <DoneIcon color = "primary" />
                                                </IconButton>
                                            </Fragment>
                                        </TableCell>
                                    </TableRow>}
                                    {allItemRows}
                                </TableBody>
                            </Table>
                            <Toast
                                level={level}
                                message={message}
                                visible={toastVisible}
                            />
                        </div>
                    </div>
                </div>
                
                <div className={clsx(minMenu ? classes.tenantMinContent : classes.tenantdetailrightcontent)}>
                    <>
                        <RoleAdd 
                            close={props.closeAdd} 
                            itemData={row == 0 ? addItem : null} 
                            match={match ? match : null}
                        />
                    </>
                </div>
            </div>
        </div>     
    )
}

const headCells = [
    { id: 'roleName', numeric: false, disablePadding: false, align: 'left', label: 'Role Name' },
    { id: 'description', numeric: false, disablePadding: false, align: 'left', label: 'Description' },
    { id: 'systemDefined', numeric: false, disablePadding: false, align: 'left', label: 'SystemDefined' },
    { id: 'actions', numeric: false, disablePadding: false, align: 'right', label: 'Actions' },

];

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    pannelList: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: 550,
        background: '#fff',
        transition: 'all .1s ease-out',
        paddingRight: 10
    },
    tenantContentSidebar : {
        position: 'absolute',
        top: '0',
        left: '0',
        width: 49,
        background: '#fff',
        transition: 'all .1s ease-out',
        paddingRight: 10
    },
    tenantdetailrightcontent: {
        marginLeft: 540,
        transition: 'all .1s ease-out',
        paddingLeft: 0
    },
    tenantMinContent: {
        marginLeft: 49,
        transition: 'all .1s ease-out',
        paddingLeft: 0
    },
    button: {
        margin: '0 8px 0 0',
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        width: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '30%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    },
    tenantMenu: {
        width: '100%',
        height: '100vh',
        background:'#ffffff',
        zIndex: '1',
        borderRight: 'solid 1px #ddd'
    },
    minMenu: {
        width:50,
        overflow: 'hidden',
    },
    addbtn: {
        padding: '4px 10px'
    },
    searchControl:{
        width:30
    },
    rolesearchcontainer:{
        padding:10,
        width:35
    },
    hidden: {
        display:'none' 
    },
    selected: {
        '& > td': {
            borderTop: 'solid 1px #004d88 !important',
            borderBottom: 'solid 1px #004d88 !important',
            background: '#0078d4',
            color: '#ffffff'
        }
    }
})

const mapStateToProps = store => ({
    user: store.auth.user
  });
  
export default connect(mapStateToProps)(RoleList);