import * as types from '../actions/types';


const INITIAL_STATE={
    types: [],
    id: undefined,
    success: ''
}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case types.FETCH_LEVELS_TYPE_SUCCESS:
            return {
                types: action.payload,
            }
        default:
        return state;
    }  
};