import * as types from '../actions/types';

const INITIAL_STATE = {
    data: null,
    item: null,
    addItem: null,
    countries: [],
    cuntryState: [],
    id: undefined,
    success: false,
    message: '',
    error: null,
    activeStep: 0,
    E2ERan: false
}




export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.FETCH_TENANT_SUCCESS:
            return {
                ...state,
                data: action.payload,
            }
        case types.FETCH_TENANT_BY_ID_SUCCESS:
            return {
                ...state,
                item: action.payload.items,
                addItem: null,
            }
        case types.FATCH_COUNTRY_SUCCESS:
            return {
                ...state,
                countries: action.payload,
            }
        case types.FATCH_STATE_SUCCESS:
            return {
                ...state,
                cuntryState: action.payload,
            }
        case types.ADD_TENANT_SUCCESS: 
            return {
                ...state,
                addItem: action.payload.items,
                success: true,
                message: 'Tenant has been successfully added..'
            }
        case types.UPDATE_TENANT_SUCCESS: 
            return {
                ...state,
                item: action.payload,
                success: true,
                message: 'Tenant has been successfully updated..'
            }
        case types.RUN_E2E_SUCCESS:
            console.log(action.payload);
            return {
                ...state,
                E2ERan: action.payload.E2ERan,
            };
        case types.DELETE_TENANT_SUCCESS: 
            return {
                ...state,
                success: true,
                message: 'Tenant has been successfully deleted..'
            }
        case types.TENANT_ERROR: 
            return {
                ...state,
                error: action.payload.ErrorMessage ? action.payload.ErrorMessage : 'Please Try again...'
            }
        case types.RESET_STORE: 
            return {
                ...state,
                success: false,
                message: '',
                error: null
            }
        case types.TENANT_ACTIVE_STEP_SET:
            return {
                ...state,
                activeStep:action.payload
            }        
        default:
            return state;
    }
};