import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core'
import Builder from "./Builder";
import Dashboard from "./Dashboard";
// import DocsPage from "./docs/DocsPage";
import { LastRequestDateTime_Key, LayoutSplashScreen } from "../../../_metronic";
import RunTimePage from './runtime';
import Reports from './reports'
import Tenants from '../tenants/index';
import EditTenantComponent from '../tenants/tenant-edit'
import CompanyPolicyEdit from '../home/admin/company-policy/CompanyPolicyEdit'
import Modal from '@material-ui/core/Modal';
import AcceptPolicy from './admin/AcceptPolicy'
import { CefitRouter } from '../cefit/CefitRouter';

import { shallowEqual, useSelector } from "react-redux";
import { getStorage, setStorage, removeStorage } from "../../../_metronic";
import {actions} from "../../store/ducks/auth.duck";

// const GoogleMaterialPage = lazy(() =>
//     import("./google-material/GoogleMaterialPage")
// );
const AdminPage = lazy(() =>
    import("./admin/AdminPage")
);
class HomePage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            open: true
        }
        this.hendleClose = this.hendleClose.bind(this);
        const user = props.user;
        setInterval(()=>{
            if(user && user.accessToken){
              let lastRequestDateTime = getStorage(LastRequestDateTime_Key);
              if(!lastRequestDateTime){
                lastRequestDateTime = Date.now();
                setStorage(LastRequestDateTime_Key, lastRequestDateTime);
                // console.log(`Home: session didn't have lastRequestDateTime, added new ${new Date(lastRequestDateTime)}`);
              }else{
                const sessionTimeout = parseInt(user.sessionTimeout)||1;
                // console.log("Home: sessionTimeout", user.sessionTimeout, sessionTimeout);
                const currentRequestDateTime = Date.now();
                var duration = parseInt((currentRequestDateTime-lastRequestDateTime)/(60000)); // in minutes
                // if DateTime.now() - lastRequestDateTime >= sessionTimeout
                // console.log(`Home: Duration ${duration}`,new Date(parseInt(lastRequestDateTime)), new Date(currentRequestDateTime));
                if(duration >= sessionTimeout){
                  // logout user and redirect to login screen
                  removeStorage(LastRequestDateTime_Key);
                    //   dispatch(actions.logout());
                    props.logout();        
                }
              }
            }
        },30*1000);
    }
    
    hendleClose(){        
        this.setState({
            open: false
        })
    }
    render() {
        const { classes } = this.props;
        const { user } = this.props;

        return (
            <>
                {!user.policyAccepted && <Modal
                    open={this.state.open}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.modal}
                >
                    <div  className={classes.paper}>
                        <div>
                            <h2 id="simple-modal-title" className={classes.modelTitle}>Accept Policy</h2>
                            {/* <button className={classes.closeButton} onClick={this.hendleClose}>
                                <i aria-hidden="true" className="fas fa-times"></i>
                            </button> */}
                        </div>
                        <div className={classes.peparContainer}>
                            <AcceptPolicy hendleClose={this.hendleClose} user={user} />
                        </div>
                    </div>
                </Modal>} 
                <Suspense fallback={<LayoutSplashScreen />}>
                    <Switch>                        
                        <Redirect exact from="/" to="/dashboard" />                        
                        <Route path="/builder" component={Builder} />
                        <Route path="/dashboard" component={Dashboard} />
                        {/* <Route path="/google-material" component={GoogleMaterialPage} /> */}
                        <Route path="/admin" component={AdminPage} />
                        <Route path="/runtime" component={RunTimePage} />
                        <Route path='/reports' component={Reports} />
                        {user.vhUser && <Route path='/tenant/:id/edit' exact render={(props) => <EditTenantComponent {...props} />} />}
                        <Route path='/tenant/:id/edit/:policyId' render={(props) => <CompanyPolicyEdit {...props} />} />
                        <Route path='/tenant/:id/edit/add' render={(props) => <CompanyPolicyEdit {...props} />} />
                        {user.vhUser && <Route path='/tenant/add' render={(props) => <EditTenantComponent {...props} />} />}
                        {user.vhUser && <Route path='/tenant' exact component={Tenants} />}
                        {!user.vhUser && <Redirect  from="/tenant" to="/dashboard" />}
                        {/* <Route path="/docs" component={DocsPage} /> */}
                        <Route path="/cefit" component={CefitRouter} />
                        {/* <Redirect to="/error/error-v1" /> */}
                    </Switch>
                </Suspense>
            </>
        );
    }
}


const useStyles = theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 700,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #dddddd',
        boxShadow: theme.shadows[5],
        padding: 0,
    },
    modelTitle: {
        marginBottom: 0,
        display: 'block',
        fontWeight: '700',
        color: '#646c9a',
        fontSize: '13px',
        padding: 10,
        borderBottom: 'solid 1px #ddd',
    },
    closeButton: {
        position: 'absolute',
        right: '-40px',
        top: '-36px',
        background: 'none',
        boxShadow: 'none',
        padding: '0px !important',
        margin: '0px !important',
        width: '35px',
        height: '35px',
        border: '2px solid #fff',
        borderRadius: '50%',
        display: 'inline-block',
        color: '#fff',
        lineHeight: '30px',
    },
    peparContainer: {
        padding: 10,
    },
    modelSubmitBtn: {
        padding: 10
    },
    formGroup: {
        alignItems: 'center'
    },
    formLabel: {
        marginRight: 5
    },
    formlLabelName: {
        marginRight: 15,
        marginBottom: 0
    }
})

const mapStateToProps = (state) => ({
    user: state.auth.user
})
const {logout} = actions;
export default connect(mapStateToProps, {logout})(withStyles(useStyles)(HomePage));
