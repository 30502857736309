import * as types from '../actions/types';

const INITIAL_STATE = {
    data: [],
    capacitiesData:[],
    success: false,
    message: '',
    error: null
}

export default(state= INITIAL_STATE, action)=>{
    switch(action.type){
        case types.GET_ALL_POWERBI_CAPACITY_MANAGEMENT_SUCCESS:
            return{
                ...state,
                data: action.payload.items
            };
        case types.GET_ALL_CAPACITIES_SUCCESS:
            return {
                ...state,
                capacitiesData: action.payload.items
            };
        case types.UPDATE_POWERBI_CAPACITY_MANAGEMENT_SUCCESS:
            return {
                ...state,
                success: true,
                message: "Data has been successfully updated..."
            };
        case types.POWERBI_CAPACITY_MANAGEMENT_ERROR:
            return {
                ...state,
                error: action.payload.ErrorMessage ? action.payload.ErrorMessage : 'Please Try again...'
            };
        case types.POWERBI_CAPACITY_MANAGEMENT_RESET:
            return {
                ...state,
                success: false,
                message: '',
                error: null
            };
        default:
            return state;
    }
}