import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { ContentRoute } from '../../partials/content/ContentRoute';
import { CefitPages } from './CefitPages'
import { CefitsEdit } from './cefits-edit/CefitsEdit'

export function CefitRouter(){
    return(
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    <Redirect 
                        exact={true} 
                        from="/cefit" 
                        to="/cefit/list" 
                    />
                }
                <ContentRoute exact path="/cefit/list" component={CefitPages} />
                <ContentRoute path="/cefit/list/:id/edit" render={(props) => <CefitsEdit {...props} />} />
                <ContentRoute path="/cefit/list/add" render={(props) => <CefitsEdit {...props} />} />
                <Redirect  
                    exact={true} 
                    to="/cefit" 
                />
            </Switch>
        </Suspense>
    )
}