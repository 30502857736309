
import axios from "axios";
const getUrl = 'Configuration'
const updateUrl = 'Configuration/UpdateGlobalConfig'
const addUrl = 'Configuration/CreateGlobalConfig'
const deleteUrl = 'Configuration/DeleteGlobalConfig'

export function getGlobalConfigAll() {
    return axios.get(getUrl);
}

export function updateGlobalConfig(params) {
    return axios.post(updateUrl, params);
}

export function addGlobalConfig(params) {
    return axios.post(addUrl, params);
}

export function deleteGlobalConfig(params) {
    return axios.delete(`${deleteUrl}/${params.globalConfigID}`, params);
}
export function getAllGlobalConfig() {
    return axios.get(getUrl);
}
