import * as types from './types'


const getFilesOperation = (id) => ({
    type: types.FETCH_FILEOPERATION,
    payload: id
})
const getFilesOperationSuccess = ({ items }) => ({
    type: types.FETCH_FILEOPERATION_SUCCESS,
    payload: items
})

const saveFilesOperation = (data) => ({
    type: types.SAVE_FILEOPERATION,
    payload: data
})

const saveFilesOperationSuccess = ({ items }) => ({
    type: types.SAVE_FILEOPERATION_SUCCESS,
    payload: items
})

const addFilesOperation = (data) => ({
    type: types.ADD_FILEOPERATION,
    payload: data
})

const addFilesOperationSuccess = ({ items }) => ({
    type: types.ADD_FILEOPERATION_SUCCESS,
    payload: items
})

export {
    getFilesOperation,
    getFilesOperationSuccess,
    saveFilesOperation,
    saveFilesOperationSuccess,
    addFilesOperation,
    addFilesOperationSuccess
}