import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PowerbiEmbedded from "react-powerbi";
import * as actions from '../../../store/actions/reportsAction';
import {IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default function ReportsPages(props){
    const [data, setData] = React.useState(props.data)
    useEffect(() => {
        setData(props.data);
    }, [props.data])
    const dispatch = useDispatch();
    const {user, reportItem, reportFile, embedData } = useSelector((state) => ({
        user: state.auth.user,
        reportItem: state.reports.reportItem,
        reportFile: state.reports.reportFile,
        embedData: state.reports.token
    }), shallowEqual);
    const [reportFileName, setReportFileName] = useState('');
    const [exportReportInProgress, setExportReportInProgress] = useState(false);
    useEffect(() => {
        if(data){
            console.log(data);
            dispatch(actions.fetchReportByReportId(data.reportId))
        }
    }, [data,dispatch]);
    const [userHasExportPermission, setUserHasExportPermisssion] = useState(false);
    useEffect(()=>{
        console.log(user, reportItem,data);
        if(user && reportItem && data && reportItem.reportID===data.reportId){
            const params = {
                reportID: reportItem.reportGUID,
                userName: user.email
            }
            dispatch(actions.fetchReports(params));
            if(user.vhUser){
                setUserHasExportPermisssion(true);  
            }else{
                var currentReportModule = user.modules?.find(m=>m.moduleName===reportItem.name);
                if(currentReportModule){
                    var hasExportPermisssion = !!currentReportModule.permissions.some(p=>p.permissionName === "Export");
                    setUserHasExportPermisssion(hasExportPermisssion);   
                }else{            
                    setUserHasExportPermisssion(false); 
                }             
            }
        }
    },[user,reportItem,data, dispatch]);

    useEffect(()=>{
        setExportReportInProgress(false);
        if(reportFile){
            const url = window.URL.createObjectURL(reportFile);
            const link = document.createElement('a');
            link.href = url;
            // link.setAttribute('target',"_blank");
            link.setAttribute('download', reportFileName);
            document.body.appendChild(link);            
            link.click();   
            setTimeout(dispatch(actions.resetReportFile()),100);
        }
    },[reportFile]);
    const downloadPDF = (e)=>{
        const params = {
            ReportId: reportItem.reportID,
            Format: "PDF"
        };
        setReportFileName(`${reportItem.name||'report'}.pdf`);
        setExportReportInProgress(true);
        dispatch(actions.exportReport(params));
    }
    // const downloadExcel = (e)=>{
    //     const params = {
    //         ReportId: reportItem.reportID,
    //         Format: "XLSX"
    //     };
    //     dispatch(actions.exportReport(params));
    // }
    const downloadPowerPoint = (e)=>{
        const params = {
            ReportId: reportItem.reportID,
            Format: "PPTX"
        };
        setReportFileName(`${reportItem.name||'report'}.pptx`);
        setExportReportInProgress(true);
        dispatch(actions.exportReport(params));
    }
    return(<>
        {userHasExportPermission && <div style={{ position: 'absolute'}}>
            <Tooltip title="download report in PDF">
                <IconButton style={{marginRight: 5, color: 'red'}} onClick={downloadPDF} disabled={exportReportInProgress}>
                    <i className="fas fa-file-pdf"></i>
                </IconButton>                
            </Tooltip>
            <Tooltip title="download report in Power Point">
                <IconButton style={{marginRight: 5, color: 'tomato'}} onClick={downloadPowerPoint} disabled={exportReportInProgress}>
                    <i className="fas fa-file-powerpoint"></i>
                </IconButton>                
            </Tooltip>
            {/* <IconButton style={{marginRight: 5, color: 'green'}} onClick={downloadExcel}>
                <i className="fas fa-file-excel"></i>
            </IconButton> */}
        </div>}
        {exportReportInProgress && <div style={{position: 'absolute', marginTop: 20, color: '#f00'}}>
            <span>Download in Progress</span>
        </div>}
        <div style={{width: '100%', padding: 0, height: 'calc( 100vh - 60px )'}}>
            {embedData && <PowerbiEmbedded
                id={`${reportItem.reportGUID}`}
                userId={ user.userID }
                embedUrl={embedData.embedUrl}
                accessToken={embedData.accessToken}
                filterPaneEnabled={true}
                navContentPaneEnabled={true}
                tokenType='Embed'
                width="100%"
                height="100%"
                //onEmbedded={this.onEmbedded}
            />}
        </div>
    </>)
}