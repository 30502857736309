import React, { Component, Fragment } from 'react';
import { Prompt } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../partials/content/Portlet";
// import clsx from "clsx";
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { 
    getComparator,
    stableSort,
    EnhancedTableHead 
} from '../../../../crud/SortGrid';

class TableDataList extends Component {
//     asideMenuRef = React.createRef();
//     insideTm;
//     outsideTm;
    constructor(props){
        super(props);
        this.state = {
            expandedRows: [],
            headCells: [],
            order: 'asc',
            orderBy: [],
            tableDataList: [],
            saveMode: false,
            diraction: 'ascending',
        }
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.renderTableHeader.bind(this);
    }

    componentWillMount(){
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        this.setState(state => {
            state.orderBy = this.props.data.length > 0 ? Object.keys(this.props.data[0])[0] : [];
            state.tableDataList = this.props.data;
            state.ieBrowser = isIE ? true : false
        });
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.data){
            if(nextProps.data !== this.state.tableDataList){
                this.setState(state => {
                    state.orderBy = nextProps.data.length > 0 ? Object.keys(nextProps.data[0])[0] : [];
                    state.tableDataList = nextProps.data
                });
            }
        }
    }

    closeRow(){
        this.setState({
            expandedRows : []
        })
    }

    heandleChange(evt, type, idx) {
        const value = evt.target.value
        const { tableDataList } = this.state;
        tableDataList[idx][type] = value;
        this.setState({
            tableDataList: tableDataList,
            saveMode: true,
        })
        // this.setState(state => {
        //     state.saveMode = true;
        //     const tableDataList = state.tableDataList.slice();
        //     for (let i = idx; i <= idx; i++) {
        //         tableDataList[i] = { ...tableDataList[i], [type]: value};
        //     }
        //     return { tableDataList };
        // });
    };

    renderItem(item, i){
        let key = Object.keys(item)
        const itemReturn = [
            <TableRow key={i}>
                {key.map((x, idx) => (
                    <TableCell key={x+idx}>
                        {/* {item[x]} */}
                        <TextField 
                            value={item[x]  || ''} 
                            onChange={(e) => this.heandleChange(e, x, i)}
                        /> 
                    </TableCell> 
                ))}
            </TableRow>
        ]

        return itemReturn;
    }

    saveTableData(){
        const { onSave, title } = this.props;
        const { tableDataList } = this.state
        let TableDt = JSON.stringify(tableDataList);
        const data = {
            TableName: title,
            TableData: TableDt
        }
        let objSerialized={
            "table": JSON.stringify(data)
        }
        onSave(objSerialized);
        setTimeout(() => {
            this.setState({
                saveMode: false,
                tableDataList: this.props.data
            })
        }, 2000)
    }

    discardTableData(){
        this.setState({
            tableDataList: this.props.data
        })
    }

    renderTableHeader(){
        const { classes } = this.props;
        const { tableDataList, order, orderBy } = this.state; 
        let header = tableDataList.length > 0 && Object.keys(tableDataList[0]);
        // this.setState(state => {
        //     state.orderBy = header[0];
        // })
        const headCells = []
        header && header.map(item => {
            const headCellsList = {};
            headCellsList['label'] = item;
            headCellsList['align'] = 'left';
            headCellsList['disablePadding'] = false;
            headCellsList['numeric'] = false;
            headCellsList['id'] = item
            headCells.push(headCellsList);
            return item;
        })
        return(
            tableDataList.length > 0 ? <EnhancedTableHead
                classes={classes}
                order={order}
                headCells={headCells}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={tableDataList.length}
            />
            : null
        )
    }

    handleRequestSort(event, property) {
        const { orderBy, order } = this.state;
        const isAsc = orderBy === property && order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };

    render(){
        const { classes } = this.props;
        const { tableDataList, ieBrowser, saveMode, order, orderBy } = this.state;
        let allItemRows = [];
        tableDataList.length > 0 && stableSort(tableDataList, getComparator(order, orderBy)).forEach((item, i) => {
            const perItemRows = this.renderItem(item, i);
            allItemRows = allItemRows.concat(perItemRows);
        });
        return(
            <Paper className={classes.root}>
                <Portlet 
                    className={classes.portlet}
                >
                    
                    <Prompt
                        when={!!this.state.saveMode}
                        message={location => `Unsaved changes will be lost. Do you want to proceed ?`}
                    />
                    <PortletHeader 
                        title={this.props.title.replace(/([A-Z])/g, ' $1').trim()}
                        toolbar={
                            <PortletHeaderToolbar>
                                <Link
                                    className="btn btn-primary btn-sm m-0" 
                                    to={{ 
                                        pathname: '/runtime/package', 
                                    }}
                                    style={{maxHeight: 26, lineHeight: 1, marginLeft: 8, backgroundColor: '#0078d4', boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'}}
                                >
                                    Back
                                </Link>
                                {saveMode && <Fragment>
                                    <Button variant="contained" color="primary" onClick={() => this.saveTableData()} className={classes.button}>
                                        Save
                                    </Button>
                                    <Button variant="contained" color="secondary" onClick={() => this.discardTableData()} className={classes.button}>
                                        Discard
                                    </Button>
                                </Fragment>}
                            </PortletHeaderToolbar>
                        }
                        headerClassName={classes.portletHead}
                    />
                        <PortletBody style={{padding: 10, maxHeight: 'calc( 100vh - 92px )', width: '100%'}} data-ktmenu-vertical="1">
                            
                            <PerfectScrollbar
                                options={{ 
                                    wheelSpeed: 2, 
                                    wheelPropagation: false 
                                }}
                                style={{padding: '0px 0 0', maxHeight: ieBrowser && 'calc( 100vh - 113px )',}}
                            >
                                <Table 
                                    stickyHeader 
                                    aria-label="sticky table" 
                                >
                                    {this.renderTableHeader()}
                                    <TableBody>
                                        {allItemRows}
                                    </TableBody>
                                </Table>
                            </PerfectScrollbar>
                        </PortletBody>
                </Portlet>
            </Paper>
        )
    }
}

const useStyles = theme => ({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 0 0 8px',
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        //maxWidth: 125,
        //minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
})

export default withStyles(useStyles)(TableDataList);