import React, {useState, useEffect} from 'react';
import {
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import clsx from 'clsx';
import Pagination from '@material-ui/lab/Pagination';
import * as actions from "../../../../store/actions/tenantAction";
import * as oAction from '../../../../store/actions/organisationAction'
import { /*Link,*/ useHistory } from 'react-router-dom';
import { ReactComponent as AngleDoubleLeftIcon } from "../../../../../_metronic/layout/assets/layout-svg-icons/Angle-double-left.svg";
import { ReactComponent as AngleDoubleRightIcon } from "../../../../../_metronic/layout/assets/layout-svg-icons/Angle-double-right.svg";
import PerfectScrollbar from "react-perfect-scrollbar";

/*export default*/ function TenantMenu(props) {
    const classes = useStyle();
    const [minMenu, setMinMenu] = useState(false)
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [page, setPage] = React.useState(1);
    const dispatch = useDispatch();
    const loader = document.getElementById('splash-screen');
    const { currentState } = useSelector(
        (state) => ({ currentState: state.tenant.data }),
        shallowEqual
    );
    const [params, setParams] = useState({
        "sortField": "tenantName",
        "sortDirection": "asc",
        "pageSize": 15,
        "pageNumber": 1,
        "searchText": ""
    })
    const items = currentState && currentState.items ? currentState.items : undefined;
    useEffect(() => {
        dispatch(actions.getAllTenant(params));
    }, [dispatch, params]);
    
    const onOpenEditPage = (tenant) => {
        history.push(`/tenant/${tenant.tenantID}/edit`);
        loader.classList.remove('hidden')
        dispatch(actions.getTenantById(tenant.tenantID))
        dispatch(oAction.getOrganisation(tenant.tenantID))
        setTimeout(() => {
            loader.classList.add('hidden')
        }, 400)
    }
    const hendleMinMenu = () => {
        setMinMenu(!minMenu);
    }
    const hendleChange = (e) => {
        params.searchText = e.target.value;
        dispatch(actions.getAllTenant(params));
    }
    const openAddScreen = () => {
        history.push(`/tenant/add`);
    }

    const pageNumbers = [];
    if(currentState && currentState.totalItems){
        for (let i = 1; i <= Math.ceil(currentState.totalItems / params.pageSize); i++) {
            pageNumbers.push(i);
        }
    }
    useEffect(() => {
        setCount(Math.ceil(currentState && currentState.totalItems / params.pageSize));
    }, [pageNumbers])

    function pageChange(event, value) {
        const pageNumber = value
        setPage(value)
        setParams(prev=>({...params, pageNumber}));
    }

    return(
        <div className={clsx('tenant-menu', classes.tenantMenu, minMenu ? `${classes.minMenu} minMenu` : '')}>
            <div className="menu__search">
                <div className="search-tools">
                    <button
                        className="search-toggler"
                        id="search_toggler"
                        onClick={() => hendleMinMenu()}
                    >
                        <span>
                            <AngleDoubleLeftIcon />
                        </span>
                        <span>
                            <AngleDoubleRightIcon />
                        </span>
                    </button>
                </div>
            </div>
                {props.user.vhUser && <div className={clsx('addbtn', classes.addbtn)}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={() => openAddScreen()}>+ Create New Tenant</Button>
                </div>}
                <div className="menu-search-container">
                    <input onChange={(e) => hendleChange(e)} className="form-control" type="text" placeholder="Search"/>
                </div>
            <PerfectScrollbar
                    options={{ wheelSpeed: 2, wheelPropagation: false }}
                    style={{padding: 0, maxHeight: 'calc( 100vh - 240px )'}}
                >
                <div className="menu-container">
                    {items && items.map((item) => (
                        <div className={clsx('menu-item', item.tenantID === props.match.params.id ? 'selected' : '')} key={item.tenantID}>
                            <a onClick={() => onOpenEditPage(item)}>
                                <i className="fab fa-bandcamp mr-2"></i>
                                {item.tenantName}
                            </a>
                        </div>
                    ))}
                </div>
            </PerfectScrollbar>
                <div className={classes.pagination}>
                {currentState && currentState.totalItems > params.pageSize && <Pagination 
                    count={count} 
                    page={page} 
                    onChange={pageChange} 
                    size="small" 
                    color="primary"
                />}
                </div>
        </div>
    )
}

const useStyle = makeStyles({
    tenantMenu: {
        width: 220,
        height: '100vh',
        background:'#ffffff',
        zIndex: '1',
        borderRight: 'solid 1px #ddd',
        ['@media (max-width:1540px)']: { 
            width: 180,
        },
        ['@media (max-width:1024px)']: { 
            width: '100%',
            flex: '0 0 100%',
            height: 'auto'
        }
    },
    minMenu: {
        width:50,
        overflow: 'hidden',
    },
    addbtn: {
        padding: '4px 10px'
    },
    pagination: {
        marginTop: 10,
        alignItems: 'center',
        display: 'flex'
    }
})


const mapStateToProps = store => ({    
    user: store.auth.user
  });
  
export default connect(mapStateToProps)(TenantMenu);