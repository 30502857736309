import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { useDispatch, useSelector, shallowEqual, connect} from 'react-redux'
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@material-ui/core/styles';
import { Input } from "../../../../partials/controls";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as action from "../../../../store/actions/fileOperationsAction";
import { Button } from '@material-ui/core';
import TenantEditHead from '../../TenantEditHead';


function StyledRadio(props) {
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.radioRoot}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.radioIcon, classes.checkedIcon)} />}
        icon={<span className={classes.radioIcon} />}
        {...props}
      />
    );
}

const FileOperationsSchemaSftp = Yup.object().shape({
    server: Yup.string()
        .required("Server is required"),
    userName: Yup.string()
        .required("User Name is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    password: Yup.string()
        .required("Password is required")
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"),
    folder: Yup.string()
        .required("Folder is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    failedFilesFolder: Yup.string()
        .required("Failed Files Folder is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
})

const FileOperationsSchema = Yup.object().shape({
    
    azureConnString: Yup.string()
        .required("Azure Connection String is required"),
    azureFolder: Yup.string()
        .required("Azure Folder is required")
        .matches(/^[a-zA-Z0-9\/]+$/, "Only alphabets are allowed for this field "),
    azureFailedFilesFolder: Yup.string()
        .required("Azure Failed Files Folder is required")
        .matches(/^[aA-zZ\sS]+$/, "Only alphabets are allowed for this field "),
});

function FileOperations({
    match,
    tenant,
    fileoperations,
    user
}) {
    const history = useHistory()
    const loader = document.getElementById('splash-screen');
    const dispatch = useDispatch()
    const initialValue = {
        tenantID: match.params.id,
        server: '',
        userName: '',
        password: '',
        folder: '',
        failedFilesFolder: '',
        azureConnString: "",
        azureFolder: '',
        azureFailedFilesFolder: ''
    }
    const [items, setItems] = useState(initialValue)
    const { filesValue } = useSelector(
        (state) => ({
            filesValue: state.fileOperation.data
        }),
        shallowEqual
    )
    const [userHasEditTenantPermission, setUserHasEditTenantPermission] = useState(false);
    useEffect(()=>{
        var hasPermission = !!user?.modules?.find(m=>m.moduleName==='Tenants' && m.permissions?.some(p=>p.permissionName === 'Create'||p.permissionName === 'Update'));
        setUserHasEditTenantPermission(hasPermission);
    },[]);
    useEffect(() => {
        setItems(filesValue)
    }, [filesValue])
    useEffect(() => {
        loader.classList.remove('hidden')
        dispatch(action.getFilesOperation(match.params.id))
        setTimeout(() => {
            loader.classList.add('hidden')
        }, 1000)
    }, [match.params.id]);
    //  console.log("getfileinfo",filesValue);
    const classes = useStyles();
    const [value, setValue] = React.useState('blob');
    const handleChange = (event) => {
        setValue(event.target.value);
    };
  
   function  onhandleChange(e){
        const {name, value} = e.target;
        setItems({
            ...items,
            [name]: value
        })
   }
   function onSubmitFile(values){
        dispatch(action.addFilesOperation(values))
        setTimeout(() => {
            dispatch(action.getFilesOperation(tenant.tenantID))
        }, 2000)
    }
    function handleCopyToClipboard(){
        console.log(arguments);
        console.log("copy to clipboard clicked");
        var connectionString = document.getElementsByName("azureConnString")[0].value;
        navigator.clipboard.writeText(connectionString);
    }
    return (
        <>
            {tenant && <TenantEditHead tenant={tenant} />}
            {items && items.azureConnString && <Formik
                enableReinitialize
                initialValues={items}
                validationSchema={value === 'sftp' ? FileOperationsSchemaSftp : FileOperationsSchema}
                onSubmit={(values) => {
                    onSubmitFile(values)
                }}
            >
                {({handleSubmit, errors, touched}) => (
                    <Form>
                        <div className="content-detail-main" style={{ padding: 10 }}>
                            <div className="tenant-detail tenant-fw-bold">
                                <div className="tenant-content">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className={classes.commonContainer}>
                                                <div className={classes.basicLable}>
                                                    <h6 className={classes.title}>Source</h6>
                                                </div>
                                                <RadioGroup 
                                                    row  
                                                    aria-label="gender" 
                                                    name="gender1" 
                                                    value={value} 
                                                    onChange={handleChange}
                                                >
                                                    <FormControlLabel value="sftp" control={<StyledRadio disabled />} label="SFTP" />
                                                    <FormControlLabel value="blob" control={<StyledRadio />} label="Blob Storage" />
                                                </RadioGroup>
                                            </div>
                                        </div>
                                    </div>
                                    {value === "sftp" ? <div className="row align-items-center">
                                        <div className="col-sm-6">
                                            <div className="row align-items-center">
                                                <div className="col-sm-4">
                                                    <div className="form-group1">Server :
                                                </div>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="form-group1" style={{ paddingBottom: 10 }}>
                                                        <Field
                                                            name="server"
                                                            component={Input}
                                                            placeholder="server"
                                                            label="server"
                                                            disabled={!userHasEditTenantPermission}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group1">
                                                        <p className="pb-0 mb-0 fw-500">UserName:</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="form-group1" style={{ paddingBottom: 10 }}>
                                                        <Field
                                                            name="userName"
                                                            component={Input}
                                                            placeholder="userName"
                                                            label="userName"
                                                            disabled={!userHasEditTenantPermission}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group1">Password:
                                                </div>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="form-group1" style={{ paddingBottom: 10 }}>
                                                        <Field name="password">
                                                            {({ field }) => (
                                                                <input {...field}
                                                                    className={"form-control" + (errors.password && touched.password ? ' is-invalid' : ' is-valid')}
                                                                    type="password" 
                                                                    disabled={!userHasEditTenantPermission}
                                                                />            
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 pull-right">
                                                    <div className="form-group1">
                                                        <p className="pb-0 mb-0 fw-500">Folder:</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-8" style={{ paddingBottom: 10 }}>
                                                    <div className="form-group1">
                                                        <Field
                                                            name="folder"
                                                            component={Input}
                                                            placeholder="Folder"
                                                            label="Folder"
                                                            disabled={!userHasEditTenantPermission}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 pull-right">
                                                    <div className="form-group1">
                                                        <p className="pb-0 mb-0 fw-500">Failed Files Folder:</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="form-group1" style={{ paddingBottom: 10 }}>
                                                        <Field
                                                            name="failedFilesFolder"
                                                            component={Input}
                                                            placeholder="Failed Files Folder"
                                                            label="Failed Files Folder"
                                                            disabled={!userHasEditTenantPermission}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-sm-6"></div>

                                    </div> :
                                        <div className="col-sm-12">
                                            <div className="row align-items-center">
                                                <div className="col-sm-2">
                                                    <div className="form-group1">Azure Connection String:
                                                </div>
                                                </div>
                                                <div className="col-sm-10">
                                                    <div className="form-group input-with-icon">
                                                            <span className="fas fa-copy form-control-icon" onClick={() => handleCopyToClipboard()}></span>
                                                            <Field name="azureConnString">
                                                                {({ field }) => (
                                                                    <input {...field}
                                                                        className={"form-control" + (errors.azureConnString && touched.azureConnString ? ' is-invalid' : ' is-valid')}
                                                                        type="read-only"
                                                                        readOnly
                                                                        disabled
                                                                    />            
                                                                )}
                                                            </Field>
                                                            <small id="emailHelp" className="form-text text-muted">Copy above connection link and open in <a href="https://go.microsoft.com/fwlink/?LinkId=708343&clcid=0x409"><i className="fas fa-download"></i> Azure Storage Explorer</a></small>
                                                    </div>
                                                    
                                                    
                                                </div>
  
                                                <div className="col-sm-2">
                                                    <div className="form-group1">
                                                        <p className="pb-0 mb-0 fw-500">Folder:</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group1" style={{ paddingBottom: 10 }}>
                                                        <Field
                                                            name="azureFolder"
                                                            component={Input}
                                                            placeholder="Folder"
                                                            label="Folder"
                                                            disabled={!userHasEditTenantPermission}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6"></div>

                                                <div className="col-sm-2">
                                                    <div className="form-group1">Failed Files Folder:
                                                </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group1" style={{ paddingBottom: 10 }}>
                                                        <Field
                                                            name="azureFailedFilesFolder"
                                                            component={Input}
                                                            placeholder="Failed Files Folder"
                                                            label="FailedFilesFolder" 
                                                            disabled={!userHasEditTenantPermission}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6"></div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {userHasEditTenantPermission && (<div className="row">
                                    <div className="col-md-12 text-right">                                        
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleSubmit()}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>}
        </>
    )
}

const useStyles = makeStyles({
    container: {
        padding: 10,
        borderTop: 'solid 1px #dddddd'
    },
    list: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '8px 0px'
    },
    commonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '10px 0px'
    },
    basicLable: {
        minWidth: 195,
    },
    profileContainer: {
        minWidth: 240,
    },
    profileImage: {
        width: 120,
        height: 120
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%'
    },
    uploadContainer: {
        width: 'calc(100% - 435px)',
    },
    inputContainer: {
        width: 'calc(100% - 195px)'
    },
    head: {
        padding: '10px 0px'
    },
    title: {
        fontSize: 16,
        color: '#0078d4',
        letterSpacing: 'normal',
        fontWeight: 'bold',

    },
    subtitle: {
        fontSize: 12,
        letterSpacing: 'normal',
        fontWeight: 'bold',

    },
    radioRoot: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
    },
    radioIcon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
});

const mapStateToProps = store => ({
    user: store.auth.user
  });
  
export default connect(mapStateToProps)(FileOperations);