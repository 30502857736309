import React, { useState, forwardRef, useEffect, useImperativeHandle, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/organisationAction";
import { makeStyles } from '@material-ui/core/styles';

import { 
    Table,
    TableBody,
} from '@material-ui/core';

import { 
    getComparator,
    stableSort,
    EnhancedTableHead 
} from '../../../../crud/SortGrid';
import OrganisationItem from './OrganisationItem';
import OrganisationAdd from './OrganisationAdd';
import Toast from '../../../../crud/Toast';

const OrganisationList = (props, ref) => {
    const loader = document.getElementById('splash-screen');
    const [isSending, setIsSending] = useState(true)
    const [updateItem, setUpdateItem] = useState(null)
    const [addItem, setAddItem] = useState(null)
    const [deleteItem, setDeleteItem] = useState(null)
    const [add, setAdd] = useState(false)
    const classes = useStyles();
    const dispatch = useDispatch();
    const { currentState, organizationSuccess, organizationMassage, error } = useSelector(
        (state) => ({ 
            currentState: state.organisation.data,
            organizationSuccess: state.organisation.success,
            organizationMassage: state.organisation.message,
            error: state.organisation.error
        }),
        shallowEqual
    );      
    const [level, setLevel] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [toastVisible, setToastVisible] = React.useState(false)  
    useEffect(() => {
        if(organizationSuccess){
            setLevel('success')
            setMessage(organizationMassage)
            setToastVisible(organizationSuccess)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.getOrganisation(props.tenant.tenantID));
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 1);
        }else { 
            setLevel('')
            setMessage('')
            setToastVisible(false)
        }
    }, [organizationSuccess, organizationMassage]);
    useEffect(() => {
        if(error){
            setLevel('danger')
            setMessage(error)
            setToastVisible(true)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.reset())
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 1)
        }
    }, [error]);
    const [dataSource, setDataSource] = useState(props.dataSource);
    const [updatedRow, setUpdatedRow] = useState([]);
    const [expandedRows, getExpandedRows] = useState([]);

    React.useEffect(() => {
        setDataSource(props.dataSource);
    }, [props.dataSource])
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const handleRequestSort = (event, property) => {
        if(orderBy === ''){
            setOrder('desc');
            setOrderBy(property);
            setDataSource(stableSort(dataSource, getComparator('asc', property)))
        } else{
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
            setDataSource(stableSort(dataSource, getComparator(order, orderBy)))
        }
    };
    const onDelete = (item)=>{
        
        if(item.organizationID){
            dispatch(actions.deleteOrganizationRequest(item.organizationID));
            loader.classList.remove('hidden')
        }
    }
    const onUpdateRow = (data) => {
        dispatch(actions.fetchUpdateRow(data))
    }
    useImperativeHandle(ref, () => ({
        getParentAdd() {
            setAdd(!add)
        }
    }));
    function onAddRow() {
        setAdd(!add)
    }
    let allItemRows = [];
    dataSource && dataSource.length > 0 && dataSource.forEach((item, i) => {
        const perItemRows = <OrganisationItem 
            key={i} dataSource={dataSource} 
            item={item} onAddRow={onAddRow}  
            updatedRow={updatedRow} 
            setUpdatedRow={setUpdatedRow}
            expandedRows={expandedRows}
            getExpandedRows={getExpandedRows}
            maxLevel={props.maxLevel} 
            index={i} 
            tenant={props.tenant} 
            onSave={onUpdateRow}
            onDelete={onDelete}
        />;
        allItemRows = allItemRows.concat(perItemRows);
    });
    const headCells = [
        { id: 'inner_actions', numeric: false, disablePadding: false, align: 'left',  label: '' },
        { id: 'name', numeric: false, disablePadding: false, align: 'left',  label: 'Name' },
        { id: 'levelid', numeric: false, disablePadding: false, align: 'left',  label: `${props.tenant.tenantLevels[props.tenant.tenantLevel]} ID` },
        { id: 'actions', numeric: false, disablePadding: false, align: 'right', label: 'Actions' }
    ];
    return (
        <> 
            {dataSource && <Table className={classes.table}>
                <EnhancedTableHead
                    classes={classes}
                    order={order}
                    headCells={headCells}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={dataSource ? dataSource.length : 0}
                /> 
                
                <TableBody>
                    {add && <OrganisationAdd 
                        key='add' 
                        organizationID={props.organizationID} 
                        tenantID={props.tenant.tenantID} 
                        onAddRow={onAddRow}
                    />}
                    {allItemRows}
                </TableBody>
            </Table>}
            <Toast
                level={level}
                message={message}
                visible={toastVisible}
            />
        </>
    )
}



const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    table: {
        minWidth: '100%',
        borderTop: 'solid 1px #ddd',
        borderRight: 'solid 1px #ddd',
        borderLeft: 'solid 2px #0078d4'
    },
    button: {
        margin: '0 8px 0 0',
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        width: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    }
})

export default forwardRef(OrganisationList);