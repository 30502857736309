import axios from 'axios'
const url = 'tenant';
const COUNTRY_URL = 'country'
const STATE = 'state/getstatebycountryid'

export function getTenantAllList(params) {
    return axios.post(`${url}/getalltenant`, params);
}

export function getTenantById(id) {
    return axios.get(`${url}/${id}`);
}

export function getCountry() {
    return axios.get(COUNTRY_URL);
}

export function getState(id) {
    return axios.get(`${STATE}/${id}`);
}

export function createInformation(params){
    console.log('in createInformation', params);
    return axios.post(`${url}/createtenant`, params);
}

export function updateInformation(params){
    console.log('in updateInformation', params);
    return axios.post(`${url}/updatetenant`, params);
}

export function deleteInformation(id){
    return axios.delete(`${url}/${id}`);
}

export function DoesDomainExists(domainName, exceptDomainId){
    return axios.get(`${url}/DoesDomainExists/${domainName}/${exceptDomainId}`);
}

export function runE2E(params){
    return axios.post(`${url}/runE2E`, params);
}
export function runAddNewUsersToReports(params){
    return axios.post(`${url}/runPowerBIRefreshUserRoles`, params);
}