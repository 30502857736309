import React from 'react';//, { useEffect, useState } 
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';// withStyles,
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import EditTenantBase from './EditTenantBase';
import TenantMenu from './tenantMenu/tenantMenu';
import Toast from '../../../crud/Toast'
import * as actions from "../../../store/actions/tenantAction";

export default function EditTenant(props) {
    const dispatch = useDispatch()
    const [level, setLevel] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [toastVisible, setToastVisible] = React.useState(false)
    const loader = document.getElementById('splash-screen');
    const {tenentSuccess, tenantMassage, error} = useSelector((state) => ({
        tenentSuccess: state.tenant.success,
        tenantMassage: state.tenant.message,
        error: state.tenant.error
    }), shallowEqual)
    React.useEffect(() => {
        if(tenentSuccess){
            setLevel('success')
            setMessage(tenantMassage)
            setToastVisible(tenentSuccess)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.reset())
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 5000)
        }else { 
            setLevel('')
            setMessage('')
            setToastVisible(false)
        }
    }, [tenentSuccess, tenantMassage])
    React.useEffect(() => {
        if(error){
            setLevel('danger')
            setMessage(error)
            setToastVisible(true)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.reset())
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 5000)
        }
    }, [error])
    return(
        <ThemeProvider theme={theme}>
            <div style={{display: 'flex', justifyContent: 'space-beetwin'}}>
                <TenantMenu match={props.match}/>
                <EditTenantBase match={props.match}/>
                <Toast
                    level={level}
                    message={message}
                    visible={toastVisible}
                />
            </div>
        </ThemeProvider>
    )
}

const theme = createMuiTheme({
    overrides: {
        MuiStep: {
            completed: {
                MuiStepLabel: {
                    iconContainer: {
                        backgroundColor: '#ffffff',
                        border: 'solid 2px #0078d4',
                        color: '#0078d4'
                    }
                }
            }
        },
        MuiExpansionPanelSummary: {
            content: {
                padding: 0,
                margin: '0px !important'
            }
        },
        MuiButtonBase: {
            root: {
                minHeight: '25px !important',
                lineHeight: '1.2 !important'
            },
            expandIcon: {
                padding: 5
            },
        },
        MuiButton: {
            text: {
                color: '#333333',
            },
            containedPrimary: {
                backgroundColor: '#0078d4',
                '&:hover': {
                    backgroundColor: '#0078d4',
                }
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: '0px !important',
                backgroundColor: 'rgba(0, 0, 0, 0.0) !important'
            }
        },
        MuiTableCell: {
            root: {
                padding: 5,
                fontSize: '1rem'
            },
            head: {
                lineHeight: '2.5rem'
            },
            sizeSmall: {
                padding: 5,
            },
            stickyHeader: {
                backgroundColor: '#ffffff'
            }
        },
        MuiFormControl: {
            root: {
                width: 'auto'
            }
        },
        MuiFormControlLabel: {
            root: {
                marginBottom: 0,
                marginLeft: 0
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiListItem: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14
            }
        },
        MuiInputLabel: {
            formControl: {
                transform: 'translate(0, 7px) scale(1)'
            },
            shrink: {
                transform: 'translate(0, 31px) scale(0.75)'
            }
        },
        MuiInput: {
            formControl: {
                marginTop: '0 !important'
            }
        }
    },
});