import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider, withStyles, createMuiTheme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress';
import AuditTrailList from './AuditTrailList';
import { fetchAuditTrail, fetchAuditTrailFilter } from '../../../../store/actions/runtimeAction';

class AuditTrail extends Component {
    constructor(props){
        super(props);
        this.props.fetchAuditTrail();
        this.state = {
            auditTrail: [],
            pageLoader: true
        }
    }

    componentDidMount(){
        const { auditTrail } = this.state
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.auditTrail){
            if(nextProps.auditTrail !== this.state.auditTrail){
                if(nextProps.auditTrail){
                    nextProps.auditTrail.map(res => {
                        res['open'] = true;
                        res['rowref'] = React.createRef()
                    })
                }
                this.setState(state => {
                    state.auditTrail = nextProps.auditTrail;
                    state.pageLoader = false; //state.auditTrail.length > 0 ? false : true
                });
            }
        }
    }

    heandleFilter(params){
        params.startdate ? this.props.fetchAuditTrailFilter(params) : this.props.fetchAuditTrail();
    }

    render(){
        const { classes } = this.props;
        const { auditTrail, pageLoader } = this.state;
        return(
            <ThemeProvider theme={theme}>
                <AuditTrailList data={auditTrail} onFilter={(e) => this.heandleFilter(e)}/>
                {pageLoader &&
                    <div className={classes.progressBar}>
                        <CircularProgress />
                    </div>
                }
            </ThemeProvider>
        )
    }
}

const theme = createMuiTheme({
    overrides: {
        MuiExpansionPanelSummary: {
            content: {
                padding: 0,
                margin: '0px !important'
            }
        },
        MuiButtonBase: {
            root: {
                minHeight: '25px !important',
                lineHeight: '1.2 !important'
            },
            expandIcon: {
                padding: 5
            },
        },
        MuiButton: {
            text: {
                color: '#333333',
            },
            containedPrimary: {
                backgroundColor: '#0078d4',
                '&:hover': {
                    backgroundColor: '#0078d4',
                }
            }
        },
        MuiIconButton: {
            root: {
                padding: '0px !important',
                minHeight: '20px !important',
                minWidth: '20px !important',
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: '0px !important',
                backgroundColor: 'rgba(0, 0, 0, 0.0) !important'
            }
        },
        MuiTableCell: {
            root: {
                padding: 5,
                fontSize: '1rem'
            },
            head: {
                lineHeight: '2.5rem'
            },
            sizeSmall: {
                padding: 5,
            },
            stickyHeader: {
                backgroundColor: '#ffffff'
            }
        },
        MuiFormControl: {
            root: {
                width: '100%',
            },
            marginNormal: {
                marginRight: '15px !important'
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiListItem: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiFormControl: {
            marginNormal: {
                marginTop: 0,
                marginBottom: 0
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14
            }
        },
        MuiInputLabel: {
            formControl: {
                transform: 'translate(0, 7px) scale(1)'
            },
            shrink: {
                transform: 'translate(0, 31px) scale(0.75)'
            }
        },
        MuiInput: {
            formControl: {
                marginTop: '0 !important'
            }
        }
    },
});

const mapStateToProps = state => ({
    auditTrail: state.runtime.auditTrail
})

const useStyles = theme => ({
    progressBar: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
        background: 'rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 99999
    }
});

export default connect(mapStateToProps, {
    fetchAuditTrail,
    fetchAuditTrailFilter
})(withStyles(useStyles)(AuditTrail));