import { takeEvery,  call, fork, put } from 'redux-saga/effects';//takeLatest, take,
import * as types from '../actions/types';
import * as action from '../actions/configAction';
import { 
    getworkspacereport
} from '../../crud/tenantconfiguration.crud'
function* getWorkspacer({payload}){
    try{
        const result = yield call(getworkspacereport, payload);
        yield put({
            type: types.FETCH_WORKSPACE_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    }catch(e){
        yield put(action.configError({
            error: 'An error occurred when trying to get the user'
        }));
    }
}

function* getWorkspacerRequest(){
    yield takeEvery(types.FETCH_WORKSPACE, getWorkspacer);
}

const tenantWorkspacerSaga = [
    fork(getWorkspacerRequest)
];

export default tenantWorkspacerSaga;