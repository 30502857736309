import { takeEvery,  call, fork, put } from 'redux-saga/effects';//takeLatest, take,
import * as types from '../actions/types';
import * as action from '../actions/companyPolicyAction';

import { 
    getCompanyPolicyList,
    getCompanyPolicyTenantList,
    companyPolicyUpdate,
    companyPolicyCreate,
    companyPolicyAdd,
    companyPolicyLatest,
    companyPolicyLatestTenant,
    companyPolicyAccept,
    companyPolicyPublish
} from '../../crud/companyPolicy.crud';

function* getCompanyPolicy() {
    try {
        const res = yield call(getCompanyPolicyList);
        yield put({
            type: types.FETCH_COMPANYPOLICY_SUCCESS,
            payload:  res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getCompanyPolicyRequest() {
    yield takeEvery(types.FETCH_COMPANYPOLICY, getCompanyPolicy);
}

function* getCompanyPolicyTenant({ payload }) {
    try {
        const res = yield call(getCompanyPolicyTenantList, payload);
        yield put({
            type: types.FETCH_COMPANYPOLICY_SUCCESS,
            payload:  res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getCompanyPolicyTenantRequest() {
    yield takeEvery(types.FETCH_COMPANYPOLICY_TENANT, getCompanyPolicyTenant);
}

function* updateCompanyPolicy({ payload }) {
    try {
        const res = yield call(companyPolicyUpdate, payload);
        yield put({
            type: types.SAVE_COMPANYPOLICY_SUCCESS,
            payload:  res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* updateCompanyPolicyRequest() {
    yield takeEvery(types.SAVE_COMPANYPOLICY, updateCompanyPolicy);
}

function* addCompanyPolicy() {
    try {
        const res = yield call(companyPolicyCreate);
        yield put({
            type: types.ADD_COMPANYPOLICY_SUCCESS,
            payload:  res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* addCompanyPolicyRequest() {
    yield takeEvery(types.ADD_COMPANYPOLICY, addCompanyPolicy);
}

function* createTenantCompanyPolicy({ payload }) {
    try {
        const res = yield call(companyPolicyAdd, payload);
        yield put({
            type: types.ADD_COMPANYPOLICY_SUCCESS,
            payload:  res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* createTenantCompanyPolicyRequest() {
    yield takeEvery(types.CREATE_COMPANYPOLICY, createTenantCompanyPolicy);
}

function* getCompanyPolicyLatest() {
    try {
        const res = yield call(companyPolicyLatest);
        yield put({
            type: types.COMPANYPOLICY_LATEST_SUCCESS,
            payload:  res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* companyPolicyLatestRequest() {
    yield takeEvery(types.COMPANYPOLICY_LATEST, getCompanyPolicyLatest);
}

function* getCompanyPolicyLatestTenant({ payload }) {
    try {
        const res = yield call(companyPolicyLatestTenant, payload);
        yield put({
            type: types.COMPANYPOLICY_LATEST_TENANT_SUCCESS,
            payload:  res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* companyPolicyLatestTenantRequest() {
    yield takeEvery(types.COMPANYPOLICY_LATEST_TENANT, getCompanyPolicyLatestTenant);
}

function* getCompanyPolicyAccept({ payload }) {
    try {
        const res = yield call(companyPolicyAccept, payload);
        yield put({
            type: types.COMPANYPOLICY_ACCEPT_SUCCESS,
            payload:  res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* companyPolicyAcceptRequest() {
    yield takeEvery(types.COMPANYPOLICY_ACCEPT, getCompanyPolicyAccept);
}

function* getCompanyPolicyPublish({ payload }) {
    try {
        const res = yield call(companyPolicyPublish, payload);
        yield put({
            type: types.COMPANYPOLICY_PUBLISH_SUCCESS,
            payload:  res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* companyPolicyPublishRequest() {
    yield takeEvery(types.COMPANYPOLICY_PUBLISH, getCompanyPolicyPublish);
}

const companyPolicySaga = [
    fork(getCompanyPolicyRequest),
    fork(getCompanyPolicyTenantRequest),
    fork(updateCompanyPolicyRequest),
    fork(addCompanyPolicyRequest),
    fork(createTenantCompanyPolicyRequest),
    fork(companyPolicyLatestRequest),
    fork(companyPolicyLatestTenantRequest),
    fork(companyPolicyAcceptRequest),
    fork(companyPolicyPublishRequest)
];

export default companyPolicySaga;