import * as types from './types';


const fetchReports = (params) => ({
    type: types.FETCH_REPORTS,
    payload: params
})


const fetchReportsSuccess = ({items}) => ({
    type: types.FETCH_REPORTS_SUCCESS,
    payload: { 
        items
    }
})

const fetchReportByReportId = (params) => ({
    type: types.FETCH_REPORT_BY_REPORTID,
    payload: params
})


const fetchReportByReportIdSuccess = ({items}) => ({
    type: types.FETCH_REPORT_BY_REPORTID_SUCCESS,
    payload: { 
        items
    }
})

const exportReport = (params)=>({
    type: types.EXPORT_REPORT,
    payload: params
});


const resetReportFile = () => ({
    type: types.RESET_REPORT_FILE
});

export {
    fetchReports,
    fetchReportsSuccess,
    fetchReportByReportId,
    fetchReportByReportIdSuccess,
    exportReport,
    resetReportFile
}