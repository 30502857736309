import axios from 'axios';

export const fetchOrganisationById = (id) => {
    return axios.get(`organization/GetOrganizationByTenantId/${id}`);
}

export const fetchOrganisationAdd = (params) => {
    return axios.post(`organization/createOrganization`, params);
}

export const fetchOrganisationUpdate = (params) => {
    return axios.post(`organization/updateOrganization`, params);
}

// export const importFile = (data) => {
//     return axios.post(`organization/importhierarchy/tenant/${data.id}`, data.params);
// }

export function deleteOrganization(id){
    return axios.delete(`organization/${id}`);
}

export const importHierarchyFile = ({file, tenantID})=>{
    var formData = new FormData();
    formData.append("tenantID", tenantID);
    formData.append("file", file);
    return axios.post( 'organization/importhierarchy', formData, {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
    });
}