import * as types from '../actions/types';


const INITIAL_STATE={
    token: '',
    reportItem: null,
    reportFile: null   
}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case types.FETCH_REPORTS_SUCCESS:
            return {
                ...state,
                token: action.payload.items
            }
        case types.FETCH_REPORT_BY_REPORTID_SUCCESS:
            return {
                ...state,
                reportItem: action.payload
            }
        case types.EXPORT_REPORT_SUCCESS:
            return {
                ...state,  
                reportFile: new Blob([action.payload.data], {type: action.payload.headers["content-type"]})              
            }
        case types.RESET_REPORT_FILE:
            return {
                ...state,
                reportFile: null
            }
        default:
        return state;
    }  
};