import * as types from '../actions/types';


const INITIAL_STATE = {
    data: [],
    id: undefined,
    success: ''
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case types.FETCH_MANAGE_REPORT_SUCCESS:
            return {
                data: action.payload,
            }
        default:
            return state;
    }
};