import axios from 'axios';

export const fetchFileOperationsById = (id) => {
    return axios.get(`tenant/getfileOperations/${id}`);
}

export const fetchFileOperationsAdd = (params) => {
    return axios.post(`tenant/fileOperations`, params);
}

