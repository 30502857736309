// import * as types from './types';

// const getAllGlobalConfig = () => ({
//     type: types.FETCH_GLOBAL_CONFIG,
// })

// export {
//     getAllGlobalConfig
// }

import * as types from './types';

const getAllGlobalConfig = (data) => ({
    type: types.FETCH_GLOBAL_CONFIG,
    payload: data
})

const fetchGlobalConfigAllList = () => ({
    type: types.FETCH_ALL_GLOBAL_CONFIG
})

const fetchGlobalConfigSuccess = ({ items }) => ({
    type: types.FETCH_GLOBAL_CONFIG_SUCCESS,
    payload: {
        items
    }
})

const updateGlobalConfigRequest = (data) => ({
    type: types.UPDATE_GLOBAL_CONFIG_REQUEST,
    payload: data,
});

const updateGlobalConfigSuccess = ({ items }) => ({
    type: types.UPDATE_GLOBAL_CONFIG_SUCCESS,
    payload: {
        items
    }
})

const addGlobalConfigRequest = (data) => ({
    type: types.ADD_GLOBAL_CONFIG_REQUEST,
    payload: data,
});

const addGlobalConfigSuccess = ({ items }) => ({
    type: types.ADD_GLOBAL_CONFIG_SUCCESS,
    payload: {
        items
    }
})

const deleteGlobalConfigRequest = (data) => ({
    type: types.DELETE_GLOBAL_CONFIG_REQUEST,
    payload: data,
});

const deleteGlobalConfigSuccess = ({ items }) => ({
    type: types.DELETE_GLOBAL_CONFIG_SUCCESS,
    payload: {
        items
    }
})

const error = ({error}) => ({
    type: types.GLOBAL_CONFIG_ERROR,
    payload: error
});

const reset = () => ({
    type: types.RESET_STORE
});


export {
    getAllGlobalConfig,
    fetchGlobalConfigAllList,
    fetchGlobalConfigSuccess,
    updateGlobalConfigRequest,
    updateGlobalConfigSuccess,
    addGlobalConfigRequest,
    addGlobalConfigSuccess,
    deleteGlobalConfigRequest,
    deleteGlobalConfigSuccess,
    error,
    reset
}
