import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import XLSX from 'xlsx';
import { make_cols } from './MakeColumns';
import { SheetJSFT } from './types';
import * as actions from '../../../../../store/actions/organisationAction'

export default function ImportHirarchy(props) {
    const { id, organizationID, setOnShow } = props
    const classes = useStyles();
    const dispatch = useDispatch()
    const [file, setFile] = React.useState(null);
    const [fileName, setFileName] = React.useState('Click to upload file');
    const [data, setData] = React.useState([]);
    const [cols, setCols] = React.useState([]);
    const [checkFile, setCheckFile] = useState(true)
    const initialKey = [
        "Name",
        "Code",
        "ParentOrganization"
    ]
    function handleChange(e) {
        const files = e.target.files;
        if (files && files[0]) { setFile(files[0]); setFileName(files[0].name) };
    };
    function handleFile() {
        dispatch(actions.importHierarchyFile({file: file,tenantID: id}));
        setTimeout(() => {
            dispatch(actions.getOrganisation(id))
            setOnShow(false)
        }, 3000);
        // const reader = new FileReader();
        // const rABS = !!reader.readAsBinaryString;

        // reader.onload = (e) => {
        //     const bstr = e.target.result;
        //     const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
        //     const wsname = wb.SheetNames[0];
        //     const ws = wb.Sheets[wsname];
        //     const data = XLSX.utils.sheet_to_json(ws);
        //     setData(data);
        //     setCols(make_cols(ws['!ref']));
        //     console.log('resultsss', JSON.stringify(data, null, 2));

        //     if (data && data.length > 0) {
        //         let keys = [];
        //         data.map(d=>{                    
        //             keys = [...new Set([...keys, ...Object.keys(d)])];
        //         });

        //         console.log(keys);
        //         const result = compareKeys(keys, initialKey)
        //         const value = {
        //             params: data,
        //             id: id
        //         }
        //         if (result) {
        //             dispatch(actions.importOrganisationRow(value));
        //             setTimeout(() => {
        //                 dispatch(actions.getOrganisation(id))
        //                 setOnShow(false)
        //             }, 3000)
        //         }
        //     } else {
        //         alert('Invalid or corrupt excel file, please inform administrator');
        //     }
        // };
        // if (rABS) {
        //     reader.readAsBinaryString(file);
        // } else {
        //     reader.readAsArrayBuffer(file);
        // };
    }

    function compareKeys(a, b) {
        var aKeys = Object.keys(a).sort();
        var bKeys = Object.keys(b).sort();
        console.log("companreKeys", a, b);
        const result = JSON.stringify(aKeys) === JSON.stringify(bKeys);
        console.log('result', result)
        setCheckFile(result)
        return result;
    }
    return (
        <div className={classes.container}>
            {!checkFile && (
                <div role="alert" className="alert alert-danger">
                    <div className="alert-text">Please add the correct file ...</div>
                </div>
            )}
            <p>Note: You can download and use <a download href={process.env.REACT_APP_DOWNLOAD_SampleOrganizationHierarchyFile} className={classes.link}>this</a> template for importing users.</p>
            <div className={classes.uploadContainer}>
                <div className={classes.text}><label htmlFor="fileupload">{fileName}</label></div>
                <input id='fileupload' type="file" className={classes.fileupload} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv' onChange={handleChange} />
                <input type='submit' className={classes.submitBtn} value="Save" disabled={file == null} onClick={handleFile} />
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        minWidth: 800
    },
    uploadContainer: {
        display: 'grid',
        gridTemplate: '[row]1fr / [col] 1fr'
    },
    text: {
        gridRow: 'row 1',
        gridColumn: 'col 1',
        textAlign: 'left',
        border: '1px solid #dddddd',
        padding: 4,
        //width: 'calc(100% - 100px)'
    },
    fileupload: {
        gridRow: 'row 1',
        gridColumn: 'col 1',
        background: 'red',
        opacity: '0',
        cursor: 'pointer'
    },
    link: {
        color: '#0078d4'
    },
    submitBtn: {
        gridRow: 'row 1',
        gridColumn: 'auto',
        textAlign: 'center',
        border: '1px solid #dddddd',
        padding: 4,
        width: 150
    }
})
