import { takeEvery, takeLatest, take, call, fork, put } from 'redux-saga/effects';
import * as types from '../actions/types';
import * as action from '../actions/powerBICapacityManagementAction';

import { getAllPowerBICapacityManagement, GetAllCapacities, updatePowerBICapacityManagement } from '../../crud/powerBICapacityManagement.crud';

function* getAllPowerBICapacityManagementHandler() {
    try {
        const res = yield call(getAllPowerBICapacityManagement);
        yield put({
            type: types.GET_ALL_POWERBI_CAPACITY_MANAGEMENT_SUCCESS,
            payload: {
                items: res.data
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getAllPowerBICapacityManagementRequest() {
    yield takeEvery(types.GET_ALL_POWERBI_CAPACITY_MANAGEMENT, getAllPowerBICapacityManagementHandler);
}
function* GetAllCapacitiesHandler() {
    try {
        const res = yield call(GetAllCapacities);
        yield put({
            type: types.GET_ALL_CAPACITIES_SUCCESS,
            payload: {
                items: res.data
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* GetAllCapacitiesRequest() {
    yield takeEvery(types.GET_ALL_CAPACITIES, GetAllCapacitiesHandler);
}

function* updatePowerBICapacityManagementHandler({ payload }) {
    try {

        const items = yield call(updatePowerBICapacityManagement, payload);
        yield put({
            type: types.UPDATE_POWERBI_CAPACITY_MANAGEMENT_SUCCESS,
            payload: {
                items: items.data
            },
        });
        yield call(getAllPowerBICapacityManagement);
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* updatePowerBICapacityManagementRequest() {
    yield takeLatest(types.UPDATE_POWERBI_CAPACITY_MANAGEMENT, updatePowerBICapacityManagementHandler);
}


const powerBICapacityManagementSaga = [
    fork(getAllPowerBICapacityManagementRequest),
    fork(GetAllCapacitiesRequest),
    fork(updatePowerBICapacityManagementRequest),
];

export default powerBICapacityManagementSaga;
