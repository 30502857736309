import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { ThemeProvider, withStyles, createMuiTheme } from '@material-ui/core/styles';
import TenantEditHead from '../../../tenants/TenantEditHead';
import ManageUserBase from './ManageUserBase'
import Toast from '../../../../crud/Toast'
import * as actions from "../../../../store/actions/manageUserAction";

export default function ManageUser(props){
    const { match, tenant } = props;
    const dispatch = useDispatch()
    const loader = document.getElementById('splash-screen');
    const [level, setLevel] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [toastVisible, setToastVisible] = React.useState(false)
    const {tenentSuccess, tenantMassage, error} = useSelector((state) => ({
        tenentSuccess: state.manageUser.success,
        tenantMassage: state.manageUser.message,
        error: state.manageUser.error
    }), shallowEqual)
    React.useEffect(() => {
        if(tenentSuccess){
            setLevel('success')
            setMessage(tenantMassage)
            setToastVisible(tenentSuccess)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.reset())
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 5000)
        }else { 
            setLevel('')
            setMessage('')
            setToastVisible(false)
        }
    }, [tenentSuccess, tenantMassage])
    React.useEffect(() => {
        if(error){
            setLevel('danger')
            setMessage(error)
            setToastVisible(true)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(actions.reset())
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 5000)
        }
    }, [error])
    return(
        <ThemeProvider theme={theme}>
            {tenant && <TenantEditHead tenant={tenant} />}
            <div style={{display: 'flex', justifyContent: 'space-beetwin'}}>
                <ManageUserBase match={match ? match : undefined} tenant={tenant}/>
            </div>
            <Toast
                level={level}
                message={message}
                visible={toastVisible}
            />
        </ThemeProvider>
    )
}

const theme = createMuiTheme({
    overrides: {
        MuiExpansionPanelSummary: {
            content: {
                padding: 0,
                margin: '0px !important'
            }
        },
        MuiButtonBase: {
            root: {
                minHeight: '25px !important',
                lineHeight: '1.2 !important'
            },
            expandIcon: {
                padding: 5
            },
        },
        MuiIconButton: {
            root: {
                padding: 0
            },
            colorPrimary: {
                padding: 0
            }
        },
        MuiButton: {
            root: {
                borderRadius: 0
            },
            text: {
                color: '#333333',
            },
            containedPrimary: {
                backgroundColor: '#0078d4',
                '&:hover': {
                    backgroundColor: '#0078d4',
                }
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: '0px !important',
                backgroundColor: 'rgba(0, 0, 0, 0.0) !important'
            }
        },
        MuiTableCell: {
            root: {
                padding: 5,
                fontSize: '1rem'
            },
            head: {
                lineHeight: '2.5rem'
            },
            sizeSmall: {
                padding: 5,
            },
            stickyHeader: {
                backgroundColor: '#ffffff'
            }
        },
        MuiTableHead: {
            root: {
                borderTop: 'solid 1px #ddd'
            }
        },
        MuiFormControl: {
            root: {
                width: 'auto'
            }
        },
        MuiFormControlLabel: {
            root: {
                marginBottom: 0,
                marginLeft: 0,
                marginRight: 8
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiListItem: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14
            }
        },
        MuiInputLabel: {
            formControl: {
                transform: 'translate(0, 7px) scale(1)'
            },
            shrink: {
                transform: 'translate(0, 31px) scale(0.75)'
            }
        },
        MuiInput: {
            formControl: {
                marginTop: '0 !important'
            }
        },
        PrivateSwitchBase: {
            root: {
                padding: 0
            }
        }
    },
});