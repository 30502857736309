import * as types from './types'


const getconfigureWorkspaces = (id) => ({
    type: types.FETCH_ConfigureWorkspaces,
    payload: id
})
const getconfigureWorkspacesSuccess = ({ items }) => ({
    type: types.FETCH_ConfigureWorkspaces_Success,
    payload: items
})

const updateConfigureWorkspaces = (params) => ({
    type: types.UPDATE_ConfigureWorkspaces,
    payload: params
})
const updateConfigureWorkspacesSuccess = ({ items }) => ({
    type: types.UPDATE_ConfigureWorkspaces_Success,
    payload: items
})
const error = ({error}) => ({
    type: types.ConfigureWorkspaces_Error,
    payload: error
});

const reset = () => ({
    type: types.RESET_STORE
})

export {
    getconfigureWorkspaces,
    getconfigureWorkspacesSuccess,
    updateConfigureWorkspaces,
    updateConfigureWorkspacesSuccess,
    error,
    reset
}