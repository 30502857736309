import React from "react";//, { useEffect }
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import * as actions from "../../store/actions/tenantAction";
// import { useLocation } from 'react-router-dom'
// import {
//     getTenantAllList
// } from '../../crud/tenant.crud';


export default function TenantInitializer({
    children,
    params,
}) {

    return ( 
        <> { children } </>
    )
}