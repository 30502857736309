import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CefitUiHelpers";

const CefitUiContext = createContext();

export function useCefitUiContext() {
  return useContext(CefitUiContext);
}

export const CefitUIConsumer = CefitUiContext.Consumer;

export function CefitUIProvider({ cefitUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newCefitButtonClick: cefitUIEvents.newCefitButtonClick,
    openEditCefitPage: cefitUIEvents.openEditCefitPage,
    openDeleteCefitDialog: cefitUIEvents.openDeleteCefitDialog,
    openDeleteCefitDialog: cefitUIEvents.openDeleteCefitDialog,
    openFetchCefitDialog: cefitUIEvents.openFetchCefitDialog,
    openUpdateCefitStatusDialog: cefitUIEvents.openUpdateCefitStatusDialog,
  };

  return (
    <CefitUiContext.Provider value={value}>
      {children}
    </CefitUiContext.Provider>
  );
}