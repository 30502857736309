import * as types from '../actions/types';


const INITIAL_STATE={
    package: [],
    auditTrail: [],
    TableData: [],
    TableName: '',
    id: undefined,
    success: false
}


export default (state=INITIAL_STATE, action) => {
    switch(action.type)
    {
        /****Package****/
        case types.FETCH_RUNTIME_SUCCESS:
            return {
                package: action.payload.items
            }
        case types.FETCH_AUDIT_SUCCESS:
            return {
                auditTrail: action.payload.items
            }
        case types.FETCH_TABLEDATA_SUCCESS:
            const data = JSON.parse(action.payload.items.TableData);
            return {
                tableData: data,
                TableName: action.payload.items.TableName
            }
        case types.UPDATE_TABLEDATA_SUCCESS: 
            return {
                success: action.payload.items.success
            }
        case types.FETCH_AUDIT_FILTER_SUCCESS:
            return {
                auditTrail: action.payload.items
            }
        default:
        return state;
    }  
};