import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ThemeProvider, withStyles, createMuiTheme } from '@material-ui/core/styles'
import { fetchTableData, updateTableDataRequest } from '../../../../store/actions/runtimeAction'
import TableDataList from './TableDataList'
import Toast from '../../../../crud/Toast';
import CircularProgress from '@material-ui/core/CircularProgress';

class TableData extends Component {
    constructor(props){
        super(props);
        const itemsdata= props.location.state.title;
        this.props.fetchTableData(itemsdata)
        this.state = {
            title: itemsdata,
            tableDataList: [],
            success: false,
            toastVisible: false,
            level: '',
            message: '',
            pageLoading: true
        }
    }

    componentDidMount(){
        const { tableDataList } = this.state
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.tableData){
            if(nextProps.tableData !== this.state.tableDataList){
                this.setState(state => {
                    state.tableDataList = nextProps.tableData;
                    state.pageLoading = false;
                });
            }
        }
    }
    
    heandleUpdateTableData(item){
        const { title, success } = this.state;
        this.props.updateTableDataRequest(item, title);
        this.setState({
            toastVisible: true,
            level: 'success',
            message: 'List has been update successfully'
        })
        setTimeout(() => {
            this.setState({
                toastVisible: false,
                level: '',
                message: ''
            })
        }, 1000)
        // setTimeout(() => {
        //     this.setState({
        //         success: this.props.success
        //     })
        //     if(success){
        //         this.setState({
        //             toastVisible: true,
        //             level: 'success',
        //             message: 'List has been added successfully'
        //         })
        //         setTimeout(() => {
        //             this.setState({
        //                 toastVisible: false,
        //                 level: '',
        //                 message: ''
        //             })
        //         }, 3000)
        //     } else {
        //         this.setState({
        //             toastVisible: true,
        //             level: 'danger',
        //             message: 'Some thing is wrong..'
        //         })
        //         setTimeout(() => {
        //             this.setState({
        //                 toastVisible: false,
        //                 level: '',
        //                 message: ''
        //             })
        //         }, 3000)
        //     }
        // }, 500)
    }

    render(){
        const { classes } = this.props;
        const { tableDataList, title, toastVisible, message, level, pageLoading } = this.state;
        return(
            <ThemeProvider theme={theme}>
                <TableDataList title={title} data={tableDataList} onSave={(e) => this.heandleUpdateTableData(e)} />
                <Toast
                    level={level}
                    message={message}
                    visible={toastVisible}
                />
                {pageLoading &&
                    <div className={classes.progressBar}>
                        <CircularProgress />
                    </div>
                }
            </ThemeProvider>
        )
    }
}

const theme = createMuiTheme({
    overrides: {
        MuiExpansionPanelSummary: {
            content: {
                padding: 0,
                margin: '0px !important'
            }
        },
        MuiButtonBase: {
            root: {
                minHeight: '25px !important',
                lineHeight: '1.2 !important'
            },
            expandIcon: {
                padding: 5
            },
        },
        MuiButton: {
            text: {
                color: '#333333',
            },
            containedPrimary: {
                backgroundColor: '#0078d4',
                '&:hover': {
                    backgroundColor: '#0078d4',
                }
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: '0px !important',
                backgroundColor: 'rgba(0, 0, 0, 0.0) !important'
            }
        },
        MuiTableCell: {
            root: {
                borderRight:' solid 1px #ddd',
                padding: '1px 5px',
                fontSize: '1rem'
            }
        },
        MuiFormControl: {
            root: {
                width: '100%'
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiListItem: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: 'none'
                },
                '&:after': {
                    borderBottom: 'none'
                },
                '&:hover:not(.Mui-disabled):before': {
                    borderBottom: 'none'
                }
            }
        }
    },
});

const useStyles = theme => ({
    progressBar: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
        background: 'rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

const mapStateToProps = state => ({
    tableData: state.runtime.tableData,
    success: state.runtime.success
})

export default connect(mapStateToProps, {
    fetchTableData,
    updateTableDataRequest
})(withStyles(useStyles)(TableData));