import * as types from './types';

const getAllTenant = (data) => ({
    type: types.FETCH_TENANT,
    payload: data
})

const fetchTenantSuccess = ({ items }) => ({
    type: types.FETCH_TENANT_SUCCESS,
    payload: {
        items
    }
})

const getTenantById = (id) => ({
    type: types.FETCH_TENANT_BY_ID,
    payload: id
})

const fetchTenantByIdSuccess = ({ items }) => ({
    type: types.FETCH_TENANT_BY_ID_SUCCESS,
    payload: {
        items
    }
})

const getCountry = () => ({
    type: types.FATCH_COUNTRY,
})

const fetchCountrySuccess = ({ items }) => ({
    type: types.FATCH_COUNTRY_SUCCESS,
    payload: items
})

const getState = (data) => ({
    type: types.FATCH_STATE,
    payload: data
})

const fetchStateSuccess = ({ items }) => ({
    type: types.FATCH_STATE_SUCCESS,
    payload: items
})

const updateTenantRequest = (data) => ({
    type: types.UPDATE_TENANT_REQUEST,
    payload: data,
});

const updateTenantSuccess = ({ items }) => ({
    type: types.UPDATE_TENANT_SUCCESS,
    payload: {
        items
    }
});
const runE2ERequest = ( data ) => ({
    type: types.RUN_E2E_REQUEST, 
    payload: data
});
const runAddNewUsersToReportsRequest = ( data ) => ({
    type: types.RUN_ADD_NEW_USERS_TO_REPORTS_REQUEST, 
    payload: data
});
const runE2ESuccess = ({ items }) => ({
    type: types.RUN_E2E_SUCCESS,
    payload: {
        items
    }
});
const runAddNewUsersToReportsSuccess = ({ items }) => ({
    type: types.RUN_ADD_NEW_USERS_TO_REPORTS_SUCCESS,
    payload: {
        items
    }
});
const deleteTenantRequest = (data) => ({
    type: types.DELETE_TENANT_REQUEST,
    payload: data,
});

const deleteTenantSuccess = ({ items }) => ({
    type: types.DELETE_TENANT_SUCCESS,
    payload: {
        items
    }
})

const addTenantRequest = (data, history) => ({
    type: types.ADD_TENANT_REQUEST,
    payload: {data, history},
});


const addTenantSuccess = ({ items }) => ({
    type: types.ADD_TENANT_SUCCESS,
    payload: {
        items
    }
})

const error = ({error}) => ({
    type: types.TENANT_ERROR,
    payload: error
});

const reset = () => ({
    type: types.RESET_STORE
})

const setActiveStep = (stepNumber)=>({
    type: types.TENANT_ACTIVE_STEP_SET,
    payload: stepNumber
})


export {
    getAllTenant,
    fetchTenantSuccess,
    getTenantById,
    fetchTenantByIdSuccess,
    fetchCountrySuccess,
    getCountry,
    getState,
    fetchStateSuccess,
    updateTenantRequest,
    updateTenantSuccess,
    deleteTenantRequest,
    deleteTenantSuccess,
    addTenantRequest,
    addTenantSuccess,
    runE2ERequest,
    runE2ESuccess,
    runAddNewUsersToReportsRequest,
    runAddNewUsersToReportsSuccess,
    error,
    reset,
    setActiveStep
}