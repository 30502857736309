import * as types from './types'


const getPermission = () => ({
    type: types.FETCH_PERMISSION
})

const getPermissionSuccess = ({ items }) => ({
    type: types.FETCH_PERMISSION_SUCCESS,
    payload: items
})

const savePermission = (data) => ({
    type: types.SAVE_PERMISSION,
    payload: data
})

const savePermissionSuccess = ({ items }) => ({
    type: types.SAVE_PERMISSION_SUCCESS,
    payload: items
})

const addPermission = (data) => ({
    type: types.ADD_PERMISSION,
    payload: data
})

const addPermissionSuccess = ({ items }) => ({
    type: types.ADD_PERMISSION_SUCCESS,
    payload: items
})

const error = ({error}) => ({
    type: types.PERMISSION_ERROR,
    payload: error
});

const reset = () => ({
    type: types.RESET_STORE
})

export {
    getPermission,
    getPermissionSuccess,
    savePermission,
    savePermissionSuccess,
    addPermission,
    addPermissionSuccess,
    error,
    reset
}