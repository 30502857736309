import React, { Component, Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { 
    getComparator,
    stableSort,
    EnhancedTableHead 
} from '../../../../crud/SortGrid';

class PackageItems extends Component {
    constructor(props){
        super(props);
        this.state = {
            packageItem: this.props.packageItem,
            packageStatus: [
                {enum: 'UNKNOWN', num: 0},
                {enum: 'CREATED', num: 1},
                {enum: 'CHOSEN_FOR_PROCESSING', num: 2},
                {enum: 'FAILED_AUTOMATED_CLEANSING', num: 3},
                {enum: 'FINISHED_AUTOMATED_CLEANSING', num: 4},
                {enum: 'NO_AUTO_CLEANING_REQUIRED', num: 5},
                {enum: 'FAILED_RULE_BASED_CLEANSING', num: 6},
                {enum: 'FINISHED_RULE_BASED_CLEANSING', num: 7},
                {enum: 'NO_RULEBASED_CLEANING_REQUIRED', num: 8},
                {enum: 'NEED_MANUAL_CLEANING', num: 9},
                {enum: 'MANUALLY_CLEANED', num: 10},
                {enum: 'SENT_TO_CUSTOMERES', num: 11},
                {enum: 'RECEIVED_FROM_CUSTOMER', num: 12},
                {enum: 'FAILED_IN_STAGING', num: 13},
                {enum: 'COMPLETE', num: 14}
            ]
        }
    }

    componentDidMount() {
        this.bindToolTip()
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.packageItem){
            if(nextProps.packageItem !== this.state.packageItem){
                this.setState({
                    packageItem: nextProps.packageItem,
                });
            }
        }
    }

    bindToolTip(){
        const { packageItem } = this.state;
        const packeageRow = packageItem.slice();
        const newRows = packeageRow.map(val => {
            if (val.rowref.current.scrollWidth > val.rowref.current.clientWidth) {
              val.open = false;
            }
            return val;
        });
        this.setState({ packageItem: newRows });

    }

    findDate(item){
        const findDate = `${new Date(item).getFullYear()}/${new Date(item).getMonth() + 1}/${new Date(item).getDate()} ${new Date(item).getHours()}:${new Date(item).getMinutes()}:${new Date(item).getSeconds()}:${new Date(item).getMilliseconds()}`;
        return findDate;
    }

    renderItem(item, i){
        const { classes } = this.props;
        const itemReturn = [
            <TableRow className='row-color' key={"row-data-" + i}>
                <TableCell className={classes.cell}>
                    {item.SnapShotName}
                </TableCell>
                <TableCell className={classes.cell}>
                    {this.findDate(item.CreatedOn)}
                </TableCell>
                <TableCell className={classes.cell}>
                    {item.ItemCount}
                </TableCell>
                <TableCell className={classes.cell} align="left">
                    {<Fragment>
                        <Link 
                            to={{ 
                                pathname: '/runtime/tableData', 
                                state: { 
                                    items: item.SnapShotName,
                                    title: item.SnapShotTableName
                                } 
                            }}
                        >
                            {item.SnapShotTableName}
                        </Link>
                    </Fragment>}
                </TableCell>
                <Tooltip title={item.TagTableName} disableHoverListener={item.open}>
                    <TableCell
                        ref={item.rowref}
                        className={classes.cell}
                    >
                        {item.TagTableName}
                    </TableCell>
                </Tooltip>
                <TableCell align='right' className={classes.cell}>
                    {this.getStatus(item.Status)}
                </TableCell>
            </TableRow>
        ]
        return itemReturn;
    }

    getStatus(item){
        const { packageStatus } = this.state;
        const status = packageStatus.filter(res => {
            if(res.num === item){
                return res
            }
        })
        return status[0].enum;
    }

    render(){
        const { packageItem, order, orderBy,} = this.state;
        let allItemRows = [];
        packageItem && packageItem.forEach((item, i) => {
            const perItemRows = this.renderItem(item, i);
            allItemRows = allItemRows.concat(perItemRows);
        });
        return(
            <Fragment>
                {allItemRows}
            </Fragment>
        )
    }
}

export default PackageItems;