import React, { useState, useEffect } from 'react';
import {
    Paper,
    Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual, connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
  PortletHeaderTitle
} from "../../../../partials/content/Portlet";
import SVG from "react-inlinesvg";
import CompanyPolicyList from './CompanyPolicyList'
import * as actions from '../../../../store/actions/companyPolicyAction'
import PerfectScrollbar from "react-perfect-scrollbar";

const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;
function CompanyPolicyBase(props){
    const { match,user  } = props
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { companyPolicy } = useSelector((state) => ({
        companyPolicy: state.companyPolicy.data
    }), shallowEqual);
    React.useEffect(() => {
        if(match.params.id){
            dispatch(actions.getCompanyPolicyTenantList(match.params.id))
            return
        } else {
            dispatch(actions.getCompanyPolicyList())
        }
    }, [match.params]);
    const [userHasEditTenantPermission, setUserHasEditTenantPermission] = useState(false);
    useEffect(()=>{
        var hasPermission = !!user?.modules?.find(m=>m.moduleName==='Tenants' && m.permissions?.some(p=>p.permissionName === 'Create'||p.permissionName === 'Update'));
        setUserHasEditTenantPermission(hasPermission);
    },[]);
    const hendleOpen = () => {
        history.push(`${history.location.pathname}/add`)
    }
    return(
        <Paper className={classes.root}>
            <Portlet className={classes.portlet}>
                <PortletHeader
                    title={
                        <PortletHeaderTitle>
                            <SVG 
                                src={toAbsoluteUrl('/media/svg/icons/Layout/hourglass_full-24px.svg')} 
                            />
                            <span 
                                style={{color: '#000000', marginLeft: 10, fontWeight: 700}}
                            >Company Policy</span>
                        </PortletHeaderTitle>
                    }
                    toolbar={
                        <PortletHeaderToolbar>
                            {userHasEditTenantPermission && (<Button variant="contained" color="primary" className={classes.button} onClick={hendleOpen}>
                                + Add Company Policy
                            </Button>)}
                        </PortletHeaderToolbar>
                    }
                    headerClassName={classes.portletHead}
                />
            </Portlet>
            <PortletBody 
                style={{
                    position: 'relative', 
                    padding: '0px 10px 10px', 
                    maxHeight: 'calc( 100vh - 92px )', 
                    minHeight: 200, width: '100%'
                }} 
                data-ktmenu-vertical="1"
            >
                {companyPolicy.length > 0 && 
                <PerfectScrollbar
                options={{ 
                    wheelSpeed: 2, 
                    wheelPropagation: false 
                }}
                style={{padding: 0, maxHeight: 'calc( 100vh - 100px )'}}>
                    <CompanyPolicyList 
                    companyPolicy={companyPolicy} 
                    match={match ? match : undefined}
                />
                </PerfectScrollbar>}           
            </PortletBody>
        </Paper>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        width: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #dddddd',
        boxShadow: theme.shadows[5],
        padding: 0,
    },
    modelTitle: {
        marginBottom: 0,
        display: 'block',
        fontWeight: '700',
        color: '#646c9a',
        fontSize: '13px',
        padding: 10,
        borderBottom: 'solid 1px #ddd',
    },
    closeButton: {
        position: 'absolute',
        right: '-40px',
        top: '-36px',
        background: 'none',
        boxShadow: 'none',
        padding: '0px !important',
        margin: '0px !important',
        width: '35px',
        height: '35px',
        border: '2px solid #fff',
        borderRadius: '50%',
        display: 'inline-block',
        color: '#fff',
        lineHeight: '30px',
    },
    peparContainer: {
        padding: 10,
    },
    modelSubmitBtn: {
        padding: 10
    },
    formGroup: {
        alignItems: 'center'
    },
    formLabel: {
        marginRight: 5
    },
    formlLabelName: {
        marginRight: 15,
        marginBottom: 0
    }
}))


const mapStateToProps = store => ({
    user: store.auth.user
  });
  
export default connect(mapStateToProps)(CompanyPolicyBase);