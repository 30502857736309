import * as types from '../actions/types';

const INITIAL_STATE = {
    items: [],
    success: false,
    message: '',
    error: null
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.FETCH_PERMISSION_SUCCESS:
            return {
                ...state, 
                items: action.payload
            }
        case types.ADD_PERMISSION_SUCCESS:
            return {
                ...state, 
                success: true,
                message: 'Permission has been successfully added..'
            }
        case types.SAVE_PERMISSION_SUCCESS:
            return {
                ...state, 
                success: true,
                message: 'Permission has been successfully updated..'
            }
        case types.MANAGEUSER_ERROR: 
            return {
                ...state,
                error: action.payload.ErrorMessage ? action.payload.ErrorMessage : 'Please Try again...'
            }
        case types.RESET_STORE: 
            return {
                ...state,
                success: false,
                message: '',
                error: null
            }
        default:
            return state;
    }
};