import { takeEvery, takeLatest, take, call, fork, put } from 'redux-saga/effects';
import * as types from '../actions/types';
import {} from "../../crud/config.crud";
import * as action from '../actions/subscriptionsAction';
import {getAllSubscriptions, getActiveSubscriptions, updateSubscription, addSubscription, deleteSubscription } from '../../crud/subcriptions.crud'

function* getAllSubscriptionsRequest() {
    yield takeEvery(types.FETCH_ALL_SUBSCRIPTIONS, function*() {
        try {
            const result = yield call(getAllSubscriptions);
            yield put({
                type: types.FETCH_ALL_SUBSCRIPTIONS_SUCCESS,
                payload: {
                    items: result.data.result
                },
            });
        } catch (e) {
            yield put(action.error({
                error: e
            }));
        }
    });
}

function* getActiveSubscriptionsRequest() {
    yield takeEvery(types.FETCH_ACTIVE_SUBSCRIPTIONS, function*() {
        try {
            const result = yield call(getActiveSubscriptions);
            yield put({
                type: types.FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS,
                payload: {
                    items: result.data.result
                },
            });
        } catch (e) {
            yield put(action.error({
                error: e
            }));
        }
    });
}

function* updateSubItem({ payload }) {
    try {

        const items = yield call(updateSubscription, payload);
        yield put({
            type: types.UPDATE_SUBSCRIPTIONS_SUCCESS,
            payload: {
                items: items.data
            },
        });
    }  catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* watchUpdateSubRequest() {
    yield takeLatest(types.UPDATE_SUBSCRIPTIONS_REQUEST, updateSubItem);
}



function* addSubItem({ payload }) {
    try {

        const items = yield call(addSubscription, payload);
        yield put({
            type: types.ADD_SUBSCRIPTIONS_SUCCESS,
            payload: {
                items: items.data
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* watchAddSubRequest() {
    yield takeLatest(types.ADD_SUBSCRIPTIONS_REQUEST, addSubItem);
}

function* deleteSubItem({ payload }) {
    try {

        const items = yield call(deleteSubscription, payload);
        yield put({
            type: types.DELETE_SUBSCRIPTIONS_SUCCESS,
            payload: {
                items: items.data
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* watchDeleteSubRequest() {
    yield takeLatest(types.DELETE_SUBSCRIPTIONS_REQUEST, deleteSubItem);
}

const sunbscritionsSaga = [    
    fork(getAllSubscriptionsRequest),
    fork(getActiveSubscriptionsRequest),
    fork(watchUpdateSubRequest),
    fork(watchAddSubRequest),
    fork(watchDeleteSubRequest)
];

export default sunbscritionsSaga;