import * as types from '../actions/types';


const INITIAL_STATE={
    data: []
}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case types.FETCH_MODULES_SUCCESS:
            return {
                ...state,
                data: action.payload,
            }
        default:
        return state;
    }
}