import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import objectPath from "object-path";
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import Header from "./header/Header";
import SubHeader from "./sub-header/SubHeader";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
// import TenantMenu from './tenantMenu/TenantMenu'
// import Footer from "./footer/Footer";
import ScrollTop from "../../app/partials/layout/ScrollTop";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
// import { getMenuItem } from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import QuickPanel from "../../app/partials/layout/QuickPanel";
// import RulesQuickPanel from '../../app/pages/home/admin/configurations/basedCleansing/RulesQuickPanel'
import KtContent from "./KtContent";
import Brand from './brand/Brand'
import "./assets/Base.scss";
import * as actions from "../../app/store/actions/modulesAction";
import { ThemeProvider, withStyles, createMuiTheme } from '@material-ui/core/styles';
const htmlClassService = new HTMLClassService();

const Layout = ({
  children,
  asideDisplay,
  subheaderDisplay,
  selfLayout,
  layoutConfig,
  contentContainerClasses
}) => {
  htmlClassService.setConfig(layoutConfig);
  const classes = useStyles()
  const dispatch = useDispatch()
  useEffect(() => {
      dispatch(actions.getModuls())
  }, [dispatch])
  // scroll to top after location changes
  // window.scrollTo(0, 0);

  const contentCssClasses = htmlClassService.classes.content.join(" ");
  const acceptCookies = localStorage.getItem('accept-cookies')
  const [cookies, setCookies] = useState(acceptCookies)
  const hendleAcceptcookies = () =>{
    localStorage.setItem('accept-cookies', true)
    setCookies(true)
  }
  //const menuConfig = getMenuItem().then(res => {return res})
  return selfLayout !== "blank" ? (
    <ThemeProvider 
            theme={theme}
        >
      <LayoutInitializer
        //menuConfig={menuConfig}
        layoutConfig={LayoutConfig}
        htmlClassService={htmlClassService}
      >
        {/* <!-- begin:: Header Mobile --> */}
        <HeaderMobile />
        {/* <!-- end:: Header Mobile --> */}

        <div className="kt-grid kt-grid--hor kt-grid--root">
          {/* <!-- begin::Body --> */}<div>
              <Brand />
              <Header />
            </div>
            
            {subheaderDisplay && <SubHeader /> }
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            {/* <!-- begin:: Aside Left --> */}
            
            {asideDisplay && (
              <>
                <AsideLeft />
              </>
            )}
            {/* <!-- end:: Aside Left --> */}
            {/* start:: tenant Menu */}
                {/* <TenantMenu /> */}
            {/* ens:: tenant Menu */}
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              {/* <!-- begin:: Header READY --> */}

              {/* <!-- end:: Header --> */}

              {/* <!-- begin:: Content --> */}
              {/* <PerfectScrollbar 
                  options={{ 
                      wheelSpeed: 2, 
                      wheelPropagation: false 
                  }}
              > */}
                  <div
                      id="kt_content"
                      className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
                  >
                    {/* <!-- begin:: Content Head --> */}
                    {/* <!-- end:: Content Head --> */}

                    {/* <!-- begin:: Content Body --> */}
                    {/* TODO: add class to animate  kt-grid--animateContent-finished */}
                    <KtContent>
                      {children}
                    </KtContent>
                    {/*<!-- end:: Content Body -->*/}
                  </div>
              {/* </PerfectScrollbar> */}
              {/* <!-- end:: Content --> */}
              {/* <Footer /> */}
              {cookies === 'false' && <div className="accept-cookies">
                  <div>
                    <p style={{display: 'inline-block'}}>This website uses cookies to ensure you get the best experience on our website. What is cookie? Our Cookie Policy</p>
                    <Button 
                        variant="contained" color="primary"
                        className={classes.btn}
                        onClick={hendleAcceptcookies}
                    >Understand
                    </Button>
                  </div>
              </div>}
            </div>
          </div>
        </div>
        <QuickPanel />
        <ScrollTop />
      </LayoutInitializer></ThemeProvider>
  ) : (
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <KtContent>
        {children}
      </KtContent>
    </div>
  );
}

const useStyles = makeStyles({
  btn: {
      whiteSpace: 'nowrap',
      padding: 12,
      borderRadius: 0,
      flex: '0 0 170px',
      marginLeft: 10
  }
})

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
    layoutConfig,
    selfLayout: objectPath.get(layoutConfig, "self.layout"),
    asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
    tenantDisplay: objectPath.get(layoutConfig, "tenant.self.display"),
    subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
    desktopHeaderDisplay: objectPath.get(
      layoutConfig,
      "header.self.fixed.desktop"
    ),
    contentContainerClasses: ""
});

export default connect(mapStateToProps)(Layout);
const theme = createMuiTheme({
  overrides: {
      MuiExpansionPanelSummary: {
          content: {
              padding: 0,
              margin: '0px !important'
          }
      },
      MuiButtonBase: {
          root: {
              minHeight: '25px !important',
              lineHeight: '1.2 !important'
          },
          expandIcon: {
              padding: 5
          },
      },
      MuiIconButton: {
          root: {
              padding: 0
          },
          colorPrimary: {
              padding: 0
          }
      },
      MuiButton: {
          root: {
              borderRadius: 0
          },
          text: {
              color: '#333333',
          },
          containedPrimary: {
              backgroundColor: '#0078d4',
              '&:hover': {
                  backgroundColor: '#0078d4',
              }
          }
      },
      MuiExpansionPanelDetails: {
          root: {
              padding: '0px !important',
              backgroundColor: 'rgba(0, 0, 0, 0.0) !important'
          }
      },
      MuiTableCell: {
          root: {
              padding: 5,
              fontSize: '1rem'
          },
          head: {
              lineHeight: '2.5rem'
          },
          sizeSmall: {
              padding: 5,
          },
          stickyHeader: {
              backgroundColor: '#ffffff'
          }
      },
      MuiTableHead: {
          root: {
              borderTop: 'solid 1px #ddd'
          }
      },
      MuiFormControl: {
          root: {
              width: 'auto'
          }
      },
      MuiFormControlLabel: {
          root: {
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 8
          }
      },
      MuiList: {
          padding: {
              paddingTop: 0,
              paddingBottom: 0,
          }
      },
      MuiListItem: {
          root: {
              paddingTop: 0,
              paddingBottom: 0,
          }
      },
      MuiTooltip: {
          tooltip: {
              fontSize: 14
          }
      },
      MuiInputLabel: {
          formControl: {
              transform: 'translate(0, 7px) scale(1)'
          },
          shrink: {
              transform: 'translate(0, 31px) scale(0.75)'
          }
      },
      MuiInput: {
          formControl: {
              marginTop: '0 !important'
          }
      },
      PrivateSwitchBase: {
          root: {
              padding: 0
          }
      }
  },
});
