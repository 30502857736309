import React from "react";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";
import Brand from "../brand/Brand";
import HeaderSearch from "../headerSearch/HeaderSearch";
import PerfectScrollbar from "react-perfect-scrollbar";
import Menu from "./Menu";
import KTOffcanvas from "../../_assets/js/offcanvas";

class AsideLeft extends React.Component {
  constructor(props){
      super(props);
      this.state = {
          menuConfig: this.props.menuConfig
      }
      this.filterMenuHeandle = this.filterMenuHeandle.bind(this)
  }
  asideOffCanvasRef = React.createRef();

  componentDidMount() {
    // eslint-disable-next-line
    
    const ktoffConvas = new KTOffcanvas(
      this.asideOffCanvasRef.current,
      this.props.menuCanvasOptions
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
        menuConfig: nextProps.menuConfig
    })
  }

  filterMenuHeandle(value){
      const { menuConfig } = this.props;
      // if(!value){
      //   this.setState({
      //     menuConfig: this.props.menuConfig
      //   })
      // } else{
      //     const filterData = menuConfig
      //     const data = menuConfig.aside.items.filter(name => name.title.includes(value))
      //     filterData.aside.items = data;
      //     this.setState({
      //       menuConfig: filterData
      //     })
      // }
  }

  render() {
    const { layoutConfig, asideMenuAttr, disableAsideSelfDisplay } = this.props
    const { menuConfig } = this.state;
    return (
      <>
        {this.props.user && <><button className="kt-aside-close" id="kt_aside_close_btn">
          <i className="la la-close" />
        </button>
        <div
          id="kt_aside"
          ref={this.asideOffCanvasRef}
          className={`kt-aside ${this.props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop`}
        >
          <HeaderSearch filterMenu={this.filterMenuHeandle} />
          <div
            id="kt_aside_menu_wrapper"
            className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
          >
            {this.props.disableScroll && (
              menuConfig.length > 0 && <Menu 
                  user={this.props.user}
                  menuConfig={menuConfig} 
                  htmlClassService={this.props.htmlClassService} 
                  layoutConfig={layoutConfig}
                  asideMenuAttr={asideMenuAttr}
                  disableAsideSelfDisplay={disableAsideSelfDisplay}
              />
            )}
            {!this.props.disableScroll && (
              <PerfectScrollbar
                options={{ wheelSpeed: 2, wheelPropagation: false }}
              >
                {menuConfig.length > 0 && <Menu 
                    user={this.props.user}
                    menuConfig={menuConfig} 
                    htmlClassService={this.props.htmlClassService} 
                    layoutConfig={layoutConfig}
                    asideMenuAttr={asideMenuAttr}
                    disableAsideSelfDisplay={disableAsideSelfDisplay} 
                />}
              </PerfectScrollbar>
            )}
          </div>
          <div className='copyRight' style={{padding: '5px 10px', fontSize: 12, minWidth: 200}}>&copy; VegaLytics</div>
        </div></> }
      </>
    );
  }
}

const mapStateToProps = store => ({
  menuConfig: store.builder.menuConfig,
  user: store.auth.user,
  layoutConfig: store.builder.layoutConfig,
  asideMenuAttr: builder.selectors.getAttributes(store, { path: "aside_menu" }),
  disableAsideSelfDisplay:
    builder.selectors.getConfig(store, "aside.self.display") === false,
  disableScroll:
    builder.selectors.getConfig(store, "aside.menu.dropdown") === "true" ||
    false,
  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: "aside",
    toString: true
  }),
  menuCanvasOptions: {
    baseClass: "kt-aside",
    overlay: true,
    closeBy: "kt_aside_close_btn",
    toggleBy: {
      target: "kt_aside_mobile_toggler",
      state: "kt-header-mobile__toolbar-toggler--active"
    }
  }
});

export default connect(mapStateToProps)(AsideLeft);
