import React,{ useState } from 'react';//, useEffect
import {
    Paper,
    Button,
    FormControl,
    InputBase
} from '@material-ui/core';// InputLabel,
import { useDispatch } from "react-redux";//, shallowEqual, useSelector
import { useHistory } from 'react-router-dom';// Link,
import { makeStyles } from '@material-ui/core/styles';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
  PortletHeaderTitle
} from "../../partials/content/Portlet";
import TenantInitializer from './TenantInitializer';
import SVG from "react-inlinesvg";
// import * as actions from "../../store/actions/tenantAction";
import TenantsPage from './TenantsPage';
import { withStyles } from '@material-ui/core/styles';
import PerfectScrollbar from "react-perfect-scrollbar";

const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 10,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
}))(InputBase);


export default function TenantBase() {
    const history = useHistory();
    // const loader = document.getElementById('splash-screen');
    // const dispatch = useDispatch();
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const searchValue = (e) => {
        setValue(e.target.value);
    }
    const onOpenAddPage = () => {
        history.push(`/tenant/add`)
    }
    const clearSearch = ()=>{
        setValue("");
        setSearchText("");
    }
    const onKeyUp = (e)=>{
        if (e.key === 'Enter') {
            e.preventDefault();
            getSearchResult();
        }
    }
    const getSearchResult = () => {
        setSearchText(value);
        setIsLoading(true);
    }
    return(
        <TenantInitializer>
            <Paper className={classes.root}>
                <Portlet className={classes.portlet}>
                    <PortletHeader
                        title={
                            <PortletHeaderTitle>
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Layout/hourglass_full-24px.svg')} />
                                <span style={{color: '#000000', marginLeft: 10, fontWeight: 700}}>Tenant</span>
                            </PortletHeaderTitle>
                        }
                        toolbar={
                            <PortletHeaderToolbar>
                                <Button variant="contained" color="primary" className={classes.button} onClick={() => onOpenAddPage()}>
                                    + Add New Tenant
                                </Button>
                            </PortletHeaderToolbar>
                        }
                        headerClassName={classes.portletHead}
                    />
                </Portlet>
                <PortletBody style={{position: 'relative', padding: '20px 10px 10px', maxHeight: 'calc( 100vh - 92px )', minHeight: 200, width: '100%'}}>
                   <div style={{padding: '0 0 15px 0', display: 'flex', alignItems: 'center', zIndex: 9999}}>
                        <FormControl className={classes.margin}>
                            <div align="center">
                                <BootstrapInput onChange={(e) => searchValue(e)} onKeyUp={onKeyUp} value={value} id="bootstrap-input"  placeholder="Search" className={classes.textField} />
                                <span>  
                                    <Button
                                        style={{ height: 34 }}
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={getSearchResult}
                                    >
                                        GO
                                    </Button>
                                </span>
                                {value.length>0 && <Button color="secondary" onClick={clearSearch}>Clear Search</Button>}
                            </div>
                        </FormControl>
                    </div>
                    <PerfectScrollbar
                        options={{ wheelSpeed: 2, wheelPropagation: false }}
                        style={{padding: 0, maxHeight: 'calc( 100vh - 163px )'}}>
                        <div><TenantsPage searchText={searchText} isLoading={isLoading} /></div>                    
                    </PerfectScrollbar>
                </PortletBody>
            </Paper>
        </TenantInitializer>
    )
}
const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
    },
    textField:{

    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        width: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    }
})
