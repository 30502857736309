import React, {useState, useEffect} from 'react';
import {
    Paper,
    Button,
    FormControl,
    InputLabel,
    InputBase
} from '@material-ui/core';  
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import clsx from 'clsx'
import SVG from "react-inlinesvg";
import { makeStyles } from '@material-ui/core/styles';
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
  PortletHeaderTitle
} from "../../../partials/content/Portlet";
import * as actions from "../../../store/actions/tenantAction";
import * as subActions from "../../../store/actions/subscriptionsAction";
import EditTanentPage from './EditTenantPage';
const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export default function EditTenantBase(props) {
    const dispatch = useDispatch()
    const classes = useStyles();
    //const activeStep = 0
    let initialValue = {
        active: true,
        address1: "",
        address2: "",
        allowAccount: 5,
        billingEmail: "",
        businessName: "",
        city: "",
        countryID: 0,
        domain: "xxxx",
        levelSubTypeID: 0,
        logoImage: "",
        selfServiceBIPurchased: false,
        sponsarEmail: "",
        sponsarFirstName: "",
        sponsarLastName: "",
        sponsarPhoneNo: "",
        // sponsarUserID: undefined,
        stateID: 0,
        subscriptionID: '1',
        superEmail: "",
        superFirstName: "",
        superLastName: "",
        superPhoneNo: "",
        // superUserID: null,
        taxID: "",
        tenantGUID: "",
        //tenantID: 0,
        tenantIndustryID: 0,
        tenantIndustryLevelID: 0,
        tenantName: '',
        zipCode: ''
    };
    const [item, setItem] = useState(initialValue);

    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    const [ieBrowser, setIeBrowser] = useState(isIE ? true : false);

    const { items, addItem, organisation, subscriptions, activeStep} = useSelector((state) => ({
        items: state.tenant,
        addItem: state.tenant.addItem,
        organisation: state.organisation.data && state.organisation.data.items,
        subscriptions: state.subscriptions.data,
        activeStep: state.tenant.activeStep
    }), 
    shallowEqual);

    // useEffect(()=>{        
    // },[items, addItem, organisation, subscriptions]);
    useEffect(() => {
        dispatch(subActions.fetchActiveSubscriptions());
    }, [dispatch]);    
    useEffect(() => {
        if(props.match.path === '/tenant/:id/edit'){
            setItem(items && items.item && items.item[0])
        // }else {
        //     setItem(initialValue);
        }
    }, [items, props.match.params]);

    useEffect(() => {
        if(props.match.path === '/tenant/add' && subscriptions?.items){
            const subscription = subscriptions.items?.find(s=>s.subscriptionID === parseInt(initialValue.subscriptionID));
            const allowAccount = subscription.allowAccount;
            const selfServiceBIPurchased = subscription.selfServiceBI;
            
            initialValue = {...initialValue, allowAccount, selfServiceBIPurchased };
            setItem(initialValue);
        }
    }, [props.match.path, subscriptions]);

    useEffect(() => {
        if(props.match.params.id){
            dispatch(actions.getTenantById(props.match.params.id));
        }
    }, [props.match.params.id]);
    
    return(
        <>
            {<Paper className={clsx('paper paperContainer')}>
                <Portlet className={classes.portlet}>
                    <PortletHeader
                        title={
                            <PortletHeaderTitle>
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Layout/hourglass_full-24px.svg')} />
                                <span style={{color: '#000000', marginLeft: 10, fontWeight: 700}}>{props.match.params.id ? 'Edit Tenant' : 'Add Tenant'}</span>
                            </PortletHeaderTitle>
                        }
                        headerClassName={classes.portletHead}/>
                    <PortletBody style={{position: 'relative', padding: '20px 0px 10px', maxHeight: 'calc( 100vh - 92px )', minHeight: 200, width: '100%'}}>       
                        <PerfectScrollbar
                            options={{ 
                                wheelSpeed: 2, 
                                wheelPropagation: false 
                            }}
                            style={{padding: 0, maxHeight: ieBrowser && 'calc( 100vh - 180px )'}}>
                            {props.match.path !== '/tenant/add' ? <EditTanentPage 
                                match={props.match} 
                                activeStep={activeStep}
                               item={item}
                                organisation={organisation}/> 
                            : <EditTanentPage 
                                match={props.match} 
                                activeStep={activeStep}
                                item={item}//{addItem ? addItem[0] : initialValue}
                                organisation={organisation}/>}
                        </PerfectScrollbar>
                    </PortletBody>
                </Portlet>
            </Paper>}
        </>
    )
}

const useStyles = makeStyles({
  root: {
      width: '100%',
      marginTop: 0,
      boxShadow: 'none'
  },
  button: {
      margin: '0 8px 0 0',
  },
  textField:{

  },
  filterButton: {
      marginTop: 20
  },
  portlet: {
      marginBottom: 0
  },
  cellHead: {
      maxWidth: 125,
      minWidth: 125,
      textOverflow: 'ellipsis',
      overflowX: 'hidden',
      whiteSpace: 'nowrap'
  },
  cell: {
      maxWidth: 125,
      minWidth: 125,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflowX: 'hidden'
  },
  cellBtn: {
      width: 35,
      minWidth: 35,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflowX: 'hidden'
  },
  portletHead: {
      position: 'fixed',
      width: '100%',
      background: '#ffffff',
      zIndex: '99999',
      right: 0,
      padding: '0px 10px !important',
      borderRadius: '0px !important',
      borderBottom: 'solid 1px #b6bbc2 !important',
      minHeight: '32px !important'
  },
  visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
  },
  formControl: {
      margin: '0 15px 0px 0px',
      minWidth: 180,
      maxWidth: '100%',
  },
  margin: {
      margin: '0 15px 0px 0px',
  },
  inputLabel: {
      //zIndex: 3,
      top: 12,
      left: 10
  },
  InputBase: {
      root: {
          marginTop: 0
      }
  }
})
