import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";

export const authProvider = new MsalAuthProvider(
    {
        auth: {
            authority: "https://login.microsoftonline.com/common",
            clientId: "33865b71-a07e-45a7-b5a4-842a6ec2b0aa",
            postLogoutRedirectUri: `${window.location.origin}/auth/login`,
            redirectUri: `${window.location.origin}/`,
            validateAuthority: true,
            navigateToLoginRequestUrl: true
        },
        system: {
            logger: new Logger(
              (logLevel, message, containsPii) => {
                //   console.log("[MSAL]", message);
              },
              {
                  level: LogLevel.Verbose,
                  piiLoggingEnabled: false
              }
            )
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: true
        }
    },
    {
        scopes: ["openid"]
    },
    {
        loginType: LoginType.Popup,
        tokenRefreshUri: window.location.origin + "/auth.html"
    }
);