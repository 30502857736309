import React from 'react'
import { InputGroup, FormControl } from 'react-bootstrap';
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
      classes.push("is-invalid");
    }
  
    if (touched && !errors) {
      classes.push("is-valid");
    }
  
    return classes.join(" ");
};

export function InputGroups({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type = "text",
    groupSuffix="*",
    ...props
}) {
    return (
        <>
            {/* {label && <label>Enter {label}</label>} */}
            <InputGroup className="mb-3">
                <FormControl
                    className={getFieldCSSClasses(touched[field.name], errors[field.name])}
                    type={type}
                    {...field}
                    {...props}
                />
                <InputGroup.Append>
                    <InputGroup.Text id="basic-addon2">{groupSuffix}</InputGroup.Text>
                    
                </InputGroup.Append>
                {withFeedbackLabel && (
                    <FieldFeedbackLabel
                        error={errors[field.name]}
                        touched={touched[field.name]}
                        label={label}
                        type={type}
                        customFeedbackLabel={customFeedbackLabel}
                    />
                )}
            </InputGroup>
        </>
    )
}