import React, { useState, useEffect } from 'react';// Fragment,
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import * as actions from '../../../../../store/actions/roleAction'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, Button } from '@material-ui/core';
import { Form } from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles'; 
//import PerfectScrollbar from "react-perfect-scrollbar";  

export default function RoleAdd(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { itemData, match } = props;
    const [items, setItem] = useState(null);
    const [SelectAll, setSelectAll] = useState(false);
    const [SelectAllReportsView, setSelectAllReportsView] = useState(true);
    const [SelectAllReportsExport, setSelectAllReportsExport] = useState(false);
    const { role } = useSelector(
        (state) => ({role: state.roles}),
        shallowEqual
    );
    const params = {
        "sortField":"",
        "sortDirection":"",
        "pageSize":60,
        "pageNumber":1,
        "searchText":""
    }
    useEffect(() => {
        setItem(role.roledata);        
    }, [role]);
    useEffect(() => { 
        if(items){  
            calculateSelectAllReportPermissionsCheckBox(items,'View');
            calculateSelectAllReportPermissionsCheckBox(items,'Export');
            calculateSelectAllPermissionsCheckBox(items);
        }     
    }, [items]);
    const calculateSelectAllReportPermissionsCheckBox = (items, permissionName)=>{
        for(var m=0;m<items.modules.length;m++){
            if(!['Reports'].includes(items.modules[m].moduleName))
                continue;
            if(items.modules[m].childModules){
                for(var cm=0;cm<items.modules[m].childModules.length;cm++){
                    for(var cp=0;cp<items.modules[m].childModules[cm].permissions.length;cp++){
                        if(items.modules[m].childModules[cm].permissions[cp].permissionName === permissionName && !items.modules[m].childModules[cm].permissions[cp].isSelected){
                            if(permissionName === 'View'){
                                setSelectAllReportsView(false);
                                return;
                            }
                            if(permissionName === 'Export'){
                                setSelectAllReportsExport(false);
                                return;
                            }
                        }
                    }
                }                
            }  
        }
        if(permissionName === 'View'){
            setSelectAllReportsView(true);
        }
        if(permissionName === 'Export'){
            setSelectAllReportsExport(true);
        }
    };
    const calculateSelectAllPermissionsCheckBox = (items)=>{
        for(var m=0;m<items.modules.length;m++){
            if(['Admin','RunTime'].includes(items.modules[m].moduleName))
                continue;
            for(var p=0;p<items.modules[m].permissions.length;p++){
                if(!items.modules[m].permissions[p].isSelected){
                    setSelectAll(false);
                    
                    return;
                }
            }
            if(items.modules[m].childModules){
                for(var cm=0;cm<items.modules[m].childModules.length;cm++){
                    for(var cp=0;cp<items.modules[m].childModules[cm].permissions.length;cp++){
                        if(!items.modules[m].childModules[cm].permissions[cp].isSelected){
                            setSelectAll(false);
                            return;
                        }
                    }
                }                
            }  
        }
        setSelectAll(true);
    };
    const item = React.useMemo(() => {
        return itemData
    }, [itemData]);
    const [addRole, setAddRole] = useState({
        roleName: "",
        description: "",
        systemDefined: true,
    })
    const closeRow = () => {
        props.close();
    }
    const onAddSubmit = () => {
        addRole.roleName && props.onAdd(addRole);
        addRole.roleName && props.close();
    }
    const selectAllReports = (e, permissionName) => {
        const {checked} = e.target;
        if(permissionName ==='View'){
            setSelectAllReportsView(checked);
        }else if(permissionName ==='Export'){
            setSelectAllReportsExport(checked);
        }
        for(var m=0;m<items.modules.length;m++){
            if(items.modules[m].moduleName !=='Reports')
                continue;
            console.log(items.modules[m]);
            if(items.modules[m].childModules){
                for(var cm=0;cm<items.modules[m].childModules.length;cm++){
                    for(var cp=0;cp<items.modules[m].childModules[cm].permissions.length;cp++){
                        console.log(items.modules[m].childModules[cm].permissions[cp].permissionName);
                        if(items.modules[m].childModules[cm].permissions[cp].permissionName === permissionName){
                            items.modules[m].childModules[cm].permissions[cp].isSelected = checked;
                            break;
                        }
                    }
                }                
            }
        }
        setItem({
            ...items
        });
    };
    useEffect(()=>{
        console.log("selectAll Changed",SelectAll);
    },[SelectAll]);
    const selectAllPermissions = (e)=>{
        const {checked} = e.target;
        setSelectAll(checked);
        console.log("SelectAll Permissions on Change event", checked);
        for(var m=0;m<items.modules.length;m++){
            if(['Admin','RunTime'].includes(items.modules[m].moduleName))
                continue;
            for(var p=0;p<items.modules[m].permissions.length;p++){
                items.modules[m].permissions[p].isSelected = checked;
            }
            if(items.modules[m].childModules){
                for(var cm=0;cm<items.modules[m].childModules.length;cm++){
                    for(var cp=0;cp<items.modules[m].childModules[cm].permissions.length;cp++){
                        items.modules[m].childModules[cm].permissions[cp].isSelected = checked;
                    }
                }                
            }
        }
        setItem({
            ...items
        });
    };
    const heandleChangeValue = (e, index, childIndex) => {
        const {name, checked} = e.target;
        items.modules[index].permissions[childIndex].isSelected = checked
        setItem({
            ...items
        })
    }
    function heandleChangeValueChild(e, index, parentIndex, childIndex){
        const {name, checked} = e.target;
        items.modules[index].childModules[parentIndex].permissions[childIndex].isSelected = checked
        setItem({
            ...items,
        })
    }

    const heandleChangeInput = (e) => {
        const {name, value} = e.target;
        setItem({
            ...items,
            [name]: value
        })
    }

    function onSubmitValue(){
        if(items.roleID > 0){
            dispatch(actions.updateRoleRequest(items))
        } else {
            dispatch(actions.addRoleRequest(items))
            setTimeout(() => {
                if(match.params.id){
                    const data = {
                        params: params,
                        id: match.params.id
                    }
                    dispatch(actions.getAllTenantRoles(data));
                } else {
                    dispatch(actions.getAllRoles(params));  
                }
            }, 2000)
        }
    }
    return (
        <>
            {items && 
            // <PerfectScrollbar
            //     options={{ 
            //         wheelSpeed: 2, 
            //         wheelPropagation: false 
            //     }}
            //     style={{padding: 0, maxHeight: 'calc( 100vh - 100px )'}}>
                <Form>
                    <div className={classes.head}>
                        <h5 className={classes.title}>Role Info</h5>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className={classes.commonContainer}>
                                
                                <div className={classes.inputContainer}>
                                    <Form.Control
                                        name="roleName"
                                        placeholder="Role Name"
                                        label="Role Name"
                                        value={items.roleName ? items.roleName : ''}
                                        onChange={(e) => heandleChangeInput(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className={classes.commonContainer}>
                                
                                <div className={classes.inputContainer}>
                                    <Form.Control
                                        name="description"
                                        placeholder="Description"
                                        label="Description"
                                        value={items.description ? items.description : ''}
                                        onChange={(e) => heandleChangeInput(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.head}>
                        <h5 className={classes.title} style={{display:'inline-block', marginRight: '3px'}}  >Permissions</h5>
                        <Checkbox color="primary" name="checkAll" onChange={selectAllPermissions} checked={SelectAll}  />
                            <label className={classes.formlLabelName} style={{marginLeft: '5px'}}>Select All</label>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                        {items && items.modules.length > 0 && <FormGroup className={classes.formGroup} row>
                            {items.modules.map((itm, i) => {
                                if(!["RunTime","Admin"].includes(itm.moduleName.trim()))
                                    return    <> 
                                        <div style={{width: '100%', padding: '10px 0'}}>
                                            <h5 
                                                style={{
                                                    margin: 0, 
                                                    fontSize: '1.1rem', 
                                                    fontWeight: '500', 
                                                    color: '#646c9a', 
                                                    fontFamily: 'Roboto, sans-serif',
                                                    display:'inline-block',
                                                    marginRight: '3px'
                                                }}
                                            >
                                                {itm.moduleName}
                                            </h5>
                                            {itm.moduleName === 'Reports' &&
                                                <>
                                                <Checkbox color="primary" name="selectAllViews" onChange={(e)=>selectAllReports(e,"View")} checked={SelectAllReportsView} />
                                                    <label className={classes.formlLabelName} style={{marginLeft: '5px'}}>All Views</label>
                                                <Checkbox color="primary" name="selectAllExports" onChange={(e)=>selectAllReports(e,"Export")} checked={SelectAllReportsExport} />
                                                    <label className={classes.formlLabelName} style={{marginLeft: '5px'}}>All Exports</label>
                                                </>
                                            }
                                        </div>
                                        {itm.permissions && itm.permissions.length > 0 && <div style={{width: '100%', display: 'flex'}}>
                                            <>
                                                {itm.permissions.map((pre, pi) => (
                                                    <>
                                                        <div style={{paddingRight: 15}}>
                                                            <FormControlLabel   
                                                                className={classes.formLabel}
                                                                key={pi}
                                                                control={
                                                                    <Checkbox 
                                                                        checked={pre.isSelected} 
                                                                        name="isSelected" 
                                                                        color="primary"
                                                                        onChange={(e) => heandleChangeValue(e, i, pi)}
                                                                    />
                                                                }
                                                            />
                                                            <label className={classes.formlLabelName}>{pre.permissionName}</label>
                                                        </div>
                                                    </>
                                                ))}
                                            </>
                                        </div>}
                                        
                                        {itm.childModules && itm.childModules.length > 0 && <div style={{padding: 10, border: 'solid 1px #ddd', width: '100%'}}>
                                            {itm.childModules.map((childItem, ci) => (
                                                <>
                                                    <div style={{width: '100%', padding: '10px 0'}}>
                                                        <h5 
                                                            style={{
                                                                margin: 0, 
                                                                fontSize: '1.1rem', 
                                                                fontWeight: '500', 
                                                                color: '#646c9a', 
                                                                fontFamily: 'Roboto, sans-serif'
                                                            }}
                                                        >
                                                            {childItem.moduleName}
                                                        </h5>
                                                    </div>
                                                    <div style={{width: '100%', display: 'flex'}}>
                                                        {childItem.permissions.map((childpre, cpi) => (
                                                            <>
                                                                <div style={{paddingRight: 15}}>
                                                                    <FormControlLabel   
                                                                        className={classes.formLabel}
                                                                        key={cpi}
                                                                        control={
                                                                            <Checkbox 
                                                                                checked={childpre.isSelected} 
                                                                                onChange={(e) => heandleChangeValueChild(e, i, ci, cpi)}
                                                                                name="isSelected" 
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                    />
                                                                    <label className={classes.formlLabelName}>{childpre.permissionName}</label>
                                                                </div>
                                                            </>
                                                        ))}
                                                    </div>
                                                </>
                                            ))}
                                        </div>}
                                    </>
                                else return <></>;
                            })}
                        </FormGroup>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-right">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => onSubmitValue()}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </Form>
            // </PerfectScrollbar>
            }
        </>
    )
 }
const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0 8px 0 0',
        padding: 0
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        //zIndex: 3,
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    },
    title:{
        color:'#0078d4',
        paddingBottom:10,
        paddingTop:10

    }
})
