import * as types from './types';


const fetchCefitList = (data) => ({
    type: types.FETCH_CEFIT_LIST,
    payload: data
})

const fetchCefitSuccess = ({ items }) => ({
    type: types.FETCH_CEFIT_LIST_SUCCESS,
    payload: {
        items
    }
})

const fetchCefitById = (data) => ({
    type: types.FETCH_CEFIT_BY_ID,
    payload: data
})

const fetchCefitByIdSuccess = ({ items }) => ({
    type: types.FETCH_CEFIT_BY_ID_SUCCESS,
    payload: {
        items
    }
})

const fetchCefitTableName = (data) => ({
    type: types.FETCH_CEFIT_TABLE_NAME,
    payload: data
})

const fetchCefitTableNameSuccess = ({ items }) => ({
    type: types.FETCH_CEFIT_TABLE_NAME_SUCCESS,
    payload: {
        items
    }
})

const fetchCefitColumnName = (data) => ({
    type: types.FETCH_CEFIT_COLUMN_NAME,
    payload: data
})


const fetchCefitColumnNameSuccess = ({ items }) => ({
    type: types.FETCH_CEFIT_COLUMN_NAME_SUCCESS,
    payload: {
        items
    }
})

const fetchUpdateExcel = (data) => ({
    type: types.FETCH_CEFIT_UPDATE_EXCEL,
    payload: data
})

const fetchUpdateExcelSuccess = ({ items }) => ({
    type: types.FETCH_CEFIT_UPDATE_EXCEL_SUCCESS,
    payload: {
        items
    }
})

const fetchUpdate = (data) => ({
    type: types.FETCH_CEFIT_UPDATE,
    payload: data
})

const fetchUpdateSuccess = ({ items }) => ({
    type: types.FETCH_CEFIT_UPDATE_SUCCESS,
    payload: {
        items
    }
})

const fetchCreate = (data) => ({
    type: types.FETCH_CEFIT_CREATE,
    payload: data
})

const fetchCreateSuccess = ({ items }) => ({
    type: types.FETCH_CEFIT_CREATE_SUCCESS,
    payload: {
        items
    }
})

const fetchDelete = (data) => ({
    type: types.FETCH_CEFIT_DELETE,
    payload: data
})

const fetchDeleteSuccess = ({ items }) => ({
    type: types.FETCH_CEFIT_DELETE_SUCCESS,
    payload: {
        items
    }
})

const fetchTestMapping = (data) => ({
    type: types.FETCH_CEFIT_TEST,
    payload: data
})

const fetchTestMappingSuccess = ({ items }) => ({
    type: types.FETCH_CEFIT_TEST_SUCCESS,
    payload: {
        items
    }
})

const fetchCefitApprove = (data) => ({
    type: types.FETCH_CEFIT_APPROVE,
    payload: data
})

const fetchCefitApproveSuccess = ({ items }) => ({
    type: types.FETCH_CEFIT_APPROVE_SUCCESS,
    payload: {
        items
    }
})

const fetchRefreshCifit = (data) => ({
    type: types.FETCH_CEFIT_REFRESH,
    payload: data
})

const fetchRefreshCifitSuccess = ({ items }) => ({
    type: types.FETCH_CEFIT_REFRESH_SUCCESS,
    payload: {
        items
    }
})

const error = ({ error }) => ({
    type: types.CEFIT_ERROR,
    payload: error
});

const reset = () => ({
    type: types.RESET_STORE
})

const resetUpdateValue = () => ({
    type: types.RESET_UPDATE_VALUE
})

export {
    fetchCefitList,
    fetchCefitSuccess,
    fetchCefitById,
    fetchCefitByIdSuccess,
    fetchCefitTableName,
    fetchCefitTableNameSuccess,
    fetchCefitColumnName,
    fetchCefitColumnNameSuccess,
    fetchUpdateExcel,
    fetchUpdateExcelSuccess,
    fetchUpdate,
    fetchUpdateSuccess,
    fetchCreate,
    fetchCreateSuccess,
    fetchDelete,
    fetchDeleteSuccess,
    fetchTestMapping,
    fetchTestMappingSuccess,
    fetchCefitApprove,
    fetchCefitApproveSuccess,
    resetUpdateValue,
    fetchRefreshCifit,
    fetchRefreshCifitSuccess,
    error,
    reset
}