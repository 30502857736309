import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import {
    TableCell,
    TableRow,
    IconButton,
    Button
} from '@material-ui/core';
import { DeleteConfirmation } from '../../../../crud/LayoutUtilsService'

export function CefitItem(props) {
    const classes = useStyles()
    const history = useHistory()
    const [item, setItem] = useState(props.item);
    const [index, setIndex] = useState(props.index)
    const [deleteItem, setDelete] = useState(false);
    useEffect(() => {
        setItem(props.item)
        setIndex(props.index)
    }, [props.item, props.index])
    const deleteConfirm = () => {
        setDelete(true)
    }
    const deteteClose = (rowIndex, action) => {
        if (action === 'delete') {
            props.onDelete(item);
            setDelete(false);
        } else if (action === 'close') {
            setDelete(false);
        }
    }
    return (
        <TableRow className = 'row-color' key = { "row-data-" + index } >
            <TableCell >{item.id}</TableCell>
            <TableCell className={classes.cell} >{item.tenantID}</TableCell>
            <TableCell className={classes.cell} >{item.tenantName}</TableCell>
            <TableCell className={classes.cell} >{item.fileFormat}</TableCell>
            <TableCell className={classes.cell} >{item.fileDataType}</TableCell>
            <TableCell className={classes.cell} >{item.fileName}</TableCell>
            <TableCell className={classes.cell} >{item.stagingTableName}</TableCell>
            <TableCell className={classes.cell} >{item.storedProcedureName}</TableCell>
            <TableCell className={classes.cell} >{item.submittedOn}</TableCell>
            <TableCell className={classes.cell} >{item.submittedBy}</TableCell>
            <TableCell className={classes.cell} >{item.deploymentStatus}</TableCell>
            <TableCell className={classes.cellRight} >
                <Button 
                    variant="contained" 
                    color="primary" 
                    className={classes.button}
                    onClick={() => props.openEditCefitPage(item.id)}
                >
                    View
                </Button>
            </TableCell>
            {props.userHasDeletePermission && <TableCell className={classes.cellRight} >
                <Button 
                    variant="contained" 
                    color="secondary" 
                    className={classes.button}
                    onClick={deleteConfirm}
                >
                    Decline
                </Button>
                {deleteItem && <DeleteConfirmation
                        _openDialog={deleteItem}
                        index={index}
                        handleDelete={deteteClose}
                    />
                }
            </TableCell>}
        </TableRow>
    )
} 

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 0,
        boxShadow: 'none'
    },
    button: {
        margin: '0',
        padding: 0
    },
    filterButton: {
        marginTop: 20
    },
    portlet: {
        marginBottom: 0
    },
    cellHead: {
        maxWidth: 125,
        minWidth: 125,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
    cell: {
        maxWidth: 125,
        minWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellBtn: {
        width: 35,
        minWidth: 35,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    cellRight: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        textAlign: 'right'
    },
    portletHead: {
        position: 'fixed',
        width: '100%',
        background: '#ffffff',
        zIndex: '99999',
        right: 0,
        padding: '0px 10px !important',
        borderRadius: '0px !important',
        borderBottom: 'solid 1px #b6bbc2 !important',
        minHeight: '32px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: '0 15px 0px 0px',
        minWidth: 180,
        maxWidth: '100%',
    },
    margin: {
        margin: '0 15px 0px 0px',
    },
    inputLabel: {
        top: 12,
        left: 10
    },
    InputBase: {
        root: {
            marginTop: 0
        }
    },
});