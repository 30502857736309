import axios from "axios";
import mockAuth from "../../_metronic/__mocks__/mockAuth";
import { toAbsoluteUrl } from "../../_metronic/utils/utils";
export const LOGIN_URL = "Users/authenticate";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "email/forgotpassword";
export const USER_DETAILS = 'users/VerifyUser?email='
export const SIGNUP_URL="users/VerifyUserSignUp";
export const ME_URL = "api/me";
export const USERBYID_URL="users/getuserbyid";
export const RESET_URL="email/resetpassword";

export function checkIfCorrectSubdomain(){
  return axios.get("Users/isCorrectSubdomain");
}

export function login(email, password) {
  return axios.post(LOGIN_URL, { userName: email, Password: password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function passwordUpdate(params) {
  return axios.put(SIGNUP_URL, params);
}

export function resetPassword(params){
      return axios.post(RESET_URL,params)
}
export function GetUsersByID(params) {
  return axios.get(`${USERBYID_URL}/${params.userId}`, params);
}
export function CheckResetPasswordLinkExpired(params){
  return axios.get('Email/CheckResetPasswordLinkExpired', {params:params});
}
export function CheckUserSignUpLinkExpired(params){
  return axios.get('users/CheckUserSignUpLinkExpired', {params:params});
}
export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(azureUser) {
  // const userdetails = azureUser
  const params = {
    "firstName": azureUser.name.split(" ")[0],
    "lastName": azureUser.name.split(" ")[1],
    "Email": azureUser.email,
    "phoneNumber":azureUser.phoneNumber
  }
  return axios(
    {
      method: 'post',
      url: `users/VerifyUser`,
      data: params
    }
  );
}

export function getUserByTokenOne(azureUser) {
  const params = {
    "firstName": azureUser.oneUser.firstName,
    "lastName": azureUser.oneUser.lastName,
    "Email": azureUser.oneUser.email,
    "phoneNumber":azureUser.oneUser.phoneNumber

  }
  return axios(
    {
      method: 'post',
      url: `users/VerifyUser`,
      data: params
    }
  );
}

// const fakeLoginRequest = username =>
//   new Promise((resolve, reject) =>
//     setTimeout(() => {
//       username === "foo" ? resolve(username) : reject("No such user");
//     }, 1000),
// );

// const data = {
//   id: 1,
//   username: "admin",
//   password: "demo",
//   email: "demo@jadebiz.com",
//   accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
//   refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
//   roles: [1], // Administrator
//   pic: toAbsoluteUrl("/media/users/300_25.jpg"),
//   fullname: "Demo Uder",
//   occupation: "CEO",
//   companyName: "Keenthemes",
//   phone: "456669067890",
//   address: {
//     addressLine: "L-12-20 Vertex, Cybersquare",
//     city: "San Francisco",
//     state: "California",
//     postCode: "45000"
//   },
//   socialNetworks: {
//     linkedIn: "https://linkedin.com/admin",
//     facebook: "https://facebook.com/admin",
//     twitter: "https://twitter.com/admin",
//     instagram: "https://instagram.com/admin"
//   }
// }
