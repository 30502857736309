import * as types from './types';



const fetchAllSubscriptions = () => ({
    type: types.FETCH_ALL_SUBSCRIPTIONS
});

const fetchActiveSubscriptions = () => ({
    type: types.FETCH_ACTIVE_SUBSCRIPTIONS
});

const fetchSubSuccess = ({items}) => ({
    type: types.FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS,
    payload: { 
        items
    }
})

const updateSubscriptionRequest = (data) => ({
    type: types.UPDATE_SUBSCRIPTIONS_REQUEST,
    payload: data,
});

const updateSubscriptionSuccess = ({ items }) => ({
    type: types.UPDATE_SUBSCRIPTIONS_SUCCESS,
    payload: {
        items
    }
})

const addSubscriptionRequest = (data) => ({
    type: types.ADD_SUBSCRIPTIONS_REQUEST,
    payload: data,
});

const addSubscriptionSuccess = ({ items }) => ({
    type: types.ADD_SUBSCRIPTIONS_SUCCESS,
    payload: {
        items
    }
})

const deleteSubscriptionRequest = (data) => ({
    type: types.DELETE_SUBSCRIPTIONS_REQUEST,
    payload: data,
});

const deleteSubscriptionSuccess = ({ items }) => ({
    type: types.DELETE_SUBSCRIPTIONS_SUCCESS,
    payload: {
        items
    }
})

const error = ({error}) => ({
    type: types.SUBSCRIPTIONS_ERROR,
    payload: error
});

const reset = () => ({
    type: types.RESET_STORE
})

export {
    fetchAllSubscriptions,
    fetchActiveSubscriptions,
    fetchSubSuccess,
    updateSubscriptionRequest,
    updateSubscriptionSuccess,
    addSubscriptionRequest,
    addSubscriptionSuccess,
    deleteSubscriptionRequest,
    deleteSubscriptionSuccess,
    error,
    reset
}