import axios from 'axios'
const url = 'Permission';

export function getPermissionsList(params) {
    return axios.get(`${url}/getPermissions`, params);
}

export function savePermissions(params) {
    return axios.post(`${url}/saveAllMapping`, params);
}

export function addPermissions(params) {
    return axios.post(`${url}/createPermission`, params);
}