/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector} from "react-redux";
import { useLastLocation } from "react-router-last-location";
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { LayoutSplashScreen } from "../../_metronic";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import CefitLayout from  '../../_metronic/layout/cefit/CefitLayout'
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import { authProvider } from '../pages/auth/authProvider';
import { CefitRouter } from '../pages/cefit/CefitRouter';
import { checkIfCorrectSubdomain } from "../crud/auth.crud";
import { ErrorPage1 } from "../pages/errors/ErrorPage1";
import { ErrorPage2 } from "../pages/errors/ErrorPage2";
import { ErrorPage3 } from "../pages/errors/ErrorPage3";
import { ErrorPage4 } from "../pages/errors/ErrorPage4";
import { ErrorPage5 } from "../pages/errors/ErrorPage5";
import { ErrorPage6 } from "../pages/errors/ErrorPage6";


export const Routes = withRouter(({ history }) => {
  const [isCorrectSubdomain, setIsCorrectSubdomain] =useState(null);
  const lastLocation = useLastLocation();
  //console.log('history', history);
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );
  useEffect(()=>{
    checkIfCorrectSubdomain()
    .then(({ data }) => {
      console.log(data);
      if(data.success) {
        setIsCorrectSubdomain(data.result);
      }
    })
    .catch(() => {
      setIsCorrectSubdomain(null);
    });
  },[]);
  return (
    <LayoutContextProvider history={history} menuConfig={menuConfig}>      
      <Switch>
        {!isAuthorized ? (
          (isCorrectSubdomain == null)?
            <LayoutSplashScreen visible="true" />
          : isCorrectSubdomain === true ? <AuthPage /> : <ErrorPage3 />  
        ) : (
          <Redirect from="/auth" to={userLastLocation} />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />
        {!isAuthorized ? (
            <Redirect to="/auth/login" />
        ) : (
            <Layout>
                <HomePage userLastLocation={userLastLocation} />
            </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
