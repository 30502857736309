import * as types from '../actions/types';


const INITIAL_STATE={
    allSubscriptions: [],
    data: [],
    id: undefined,
    success: false,
    message: '',
    error: null
}

export default (state=INITIAL_STATE, action) => {
    switch(action.type)
    {
        case types.FETCH_ALL_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                allSubscriptions: action.payload,
            }
        case types.FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                data: action.payload,
            }
        case types.ADD_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Subscriptions has been successfully added..'
            }
        case types.UPDATE_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Subscriptions has been successfully updated..'
            }
        case types.DELETE_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Subscriptions has been successfully deleted..'
            }
        case types.SUBSCRIPTIONS_ERROR: 
            return {
                ...state,
                error: action.payload.ErrorMessage ? action.payload.ErrorMessage : 'Please Try again...'
            }
        case types.RESET_STORE: 
            return {
                ...state,
                success: false,
                message: '',
                error: null
            }
        default:
        return state;
    }  
};