import * as types from '../actions/types';

const INITIAL_STATE={
    data: [],
    id: undefined,
    addData: {},
    latest: {},
    accept: {},
    success: false,
    message: '',
    error: null
}

export default (state=INITIAL_STATE, action) => {
    switch(action.type)
    {
        case types.FETCH_COMPANYPOLICY_SUCCESS:
            return {
                ...state,
                data: action.payload,
            }
        case types.ADD_COMPANYPOLICY_SUCCESS:
            return {
                ...state,
                addData: action.payload,
                 success: true,
                // message: 'Policy has been successfully added..'
            }
        case types.SAVE_COMPANYPOLICY_SUCCESS:
            return {
                ...state,
                addData: action.payload,
                success: true,
                message: 'Policy has been successfully saved..'
            }
        case types.COMPANYPOLICY_LATEST_SUCCESS:
            return {
                ...state,
                latest: action.payload,
            }
        case types.COMPANYPOLICY_LATEST_TENANT_SUCCESS:
            return {
                ...state,
                latest: action.payload,
            }
        case types.COMPANYPOLICY_ACCEPT_SUCCESS:
            return {
                ...state,
                accept: action.payload,
                success: true,
                message: 'Policy has been successfully accepted..'
            }
        case types.COMPANYPOLICY_PUBLISH_SUCCESS: 
            return {
                ...state,
                success: true,
                message: 'Policy has been successfully published..'
            }
        case types.COMPANYPOLICY_ERROR: 
            return {
                ...state,
                error: action.payload.ErrorMessage ? action.payload.ErrorMessage : 'Please Try again...'
            }
        case types.RESET_STORE: 
            return {
                ...state,
                success: false,
                message: '',
                error: null
            }
        default:
        return state;
    }  
};