
import * as types from './types';

const getAllPowerBICapacityManagement = ()=>({
    type: types.GET_ALL_POWERBI_CAPACITY_MANAGEMENT,
});

const getAllCapacities = ()=>({
    type: types.GET_ALL_CAPACITIES
});

const updatePowerBICapacityManagement = (data)=>({
    type: types.UPDATE_POWERBI_CAPACITY_MANAGEMENT,
    payload: data
})

const error = ({error})=>({
    type: types.POWERBI_CAPACITY_MANAGEMENT_ERROR,
    payload: error
});

const reset = ()=>({
    type: types.POWERBI_CAPACITY_MANAGEMENT_RESET
})


export {
    getAllPowerBICapacityManagement,
    getAllCapacities,
    updatePowerBICapacityManagement,
    error,
    reset
}