import * as types from './types';

const getCompanyPolicyList = () => ({
    type: types.FETCH_COMPANYPOLICY,
})

const getCompanyPolicyListSuccess = ({items}) => ({
    type: types.FETCH_COMPANYPOLICY_SUCCESS,
    payload: {
        items
    }
})

const getCompanyPolicyTenantList = (data) => ({
    type: types.FETCH_COMPANYPOLICY_TENANT,
    payload: data
})

const getCompanyPolicyTenantListSuccess = ({items}) => ({
    type: types.FETCH_COMPANYPOLICY_TENANT_SUCCESS,
    payload: {
        items
    }
})

const updateCompanyPolicy = (data) => ({
    type: types.SAVE_COMPANYPOLICY,
    payload: data
})

const updateCompanyPolicySuccess = ({items}) => ({
    type: types.SAVE_COMPANYPOLICY_SUCCESS,
    payload: {
        items
    }
})

const addCompanyPolicy = () => ({
    type: types.ADD_COMPANYPOLICY
})

const addCompanyPolicySuccess = ({items}) => ({
    type: types.ADD_COMPANYPOLICY_SUCCESS,
    payload: {
        items
    }
})

const createTenantCompanyPolicy = (data) => ({
    type: types.CREATE_COMPANYPOLICY,
    payload: data
})

const createTenantCompanyPolicySuccess = ({items}) => ({
    type: types.CREATE_COMPANYPOLICY_SUCCESS,
    payload: {
        items
    }
})

const companyPolicyLatest = () => ({
    type: types.COMPANYPOLICY_LATEST
})

const companyPolicyLatestSuccess = ({items}) => ({
    type: types.COMPANYPOLICY_LATEST_SUCCESS,
    payload: {
        items
    }
})

const companyPolicyLatestTenant = (data) => ({
    type: types.COMPANYPOLICY_LATEST_TENANT,
    payload: data
})

const companyPolicyLatestTenantSuccess = ({items}) => ({
    type: types.COMPANYPOLICY_LATEST_TENANT_SUCCESS,
    payload: {
        items
    }
})

const companyPolicyAccept = (data) => ({
    type: types.COMPANYPOLICY_ACCEPT,
    payload: data
})

const companyPolicyAcceptSuccess = ({items}) => ({
    type: types.COMPANYPOLICY_ACCEPT_SUCCESS,
    payload: {
        items
    }
})

const companyPolicyPublish = (data) => ({
    type: types.COMPANYPOLICY_PUBLISH,
    payload: data
})

const companyPolicyPublishSuccess = ({items}) => ({
    type: types.COMPANYPOLICY_PUBLISH_SUCCESS,
    payload: {
        items
    }
})

const error = ({error}) => ({
    type: types.COMPANYPOLICY_ERROR,
    payload: error
});

const reset = () => ({
    type: types.RESET_STORE
})


export {
    getCompanyPolicyList,
    getCompanyPolicyListSuccess,
    getCompanyPolicyTenantList,
    getCompanyPolicyTenantListSuccess,
    updateCompanyPolicy,
    updateCompanyPolicySuccess,
    addCompanyPolicy,
    addCompanyPolicySuccess,
    createTenantCompanyPolicy,
    createTenantCompanyPolicySuccess,
    companyPolicyLatest,
    companyPolicyLatestSuccess,
    companyPolicyLatestTenant,
    companyPolicyLatestTenantSuccess,
    companyPolicyAccept,
    companyPolicyAcceptSuccess,
    companyPolicyPublish,
    companyPolicyPublishSuccess,
    error,
    reset
}
