import React, { Component } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import Package from './package';
import AuditTrail from './auditTrail';
import TableData from './tableData'


class RunTimePage extends Component{ 
    render(){
        return(
            <Switch>
                {
                    <Redirect exact from="/runtime" to="/runtime/package" />
                }
                <Route
                    path="/runtime/package" 
                    component={Package} 
                />
                <Route
                    path="/runtime/auditTrail" 
                    component={AuditTrail} 
                />
                <Route
                    path="/runtime/tableData"
                    component={TableData}
                />
            </Switch> 
        )
    }
}

export default RunTimePage;