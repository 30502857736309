
import {actions} from "../../app/store/ducks/auth.duck";

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export const LastRequestDateTime_Key = "lrdt";
export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: {accessToken, user }
      } = store.getState();   
      // console.log("Utils", accessToken, user);
      // if(accessToken !== user?.accessToken)
      //   console.log("Utils accessToken is not same as that of user's accesstoken");
      if(accessToken && user ){  //               
        let lastRequestDateTime = getStorage(LastRequestDateTime_Key);
        if(!lastRequestDateTime){
          lastRequestDateTime = Date.now();
          setStorage(LastRequestDateTime_Key, lastRequestDateTime);
          // console.log(`util: session didn't have lastRequestDateTime, added new ${new Date(lastRequestDateTime)}`);
        }else{
          const sessionTimeout = parseInt(user.sessionTimeout)||1;
          // console.log("util: sessionTimeout", user.sessionTimeout, sessionTimeout);
          const currentRequestDateTime = Date.now();
          var duration = parseInt((currentRequestDateTime-lastRequestDateTime)/(60000)); // in minutes
          // console.log(`util: Duration ${duration}`,new Date(lastRequestDateTime), new Date(currentRequestDateTime));
          // if DateTime.now() - lastRequestDateTime >= sessionTimeout
          // console.log(`util: Duration ${duration}`,new Date(parseInt(lastRequestDateTime)), new Date(currentRequestDateTime));
          if(duration >= sessionTimeout){
            // logout user and redirect to login screen
            removeStorage(LastRequestDateTime_Key);
            store.dispatch(actions.logout());
            return config;

          }else{
            setStorage(LastRequestDateTime_Key, currentRequestDateTime);
            // console.log(`util: lastRequestDateTime has been reset to ${new Date(currentRequestDateTime)}`);
          }                

          // at main layout there should be timeInterval running every minute to check if user session timedout, log him out
        }          
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    err => Promise.reject(err)
  );
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    // console.log(
    //   "removeStorage: Error removing key [" +
    //   key +
    //   "] from localStorage: " +
    //   JSON.stringify(e)
    // );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now();
  let expiresIn = localStorage.getItem(key + "_expiresIn");
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      // console.log(
      //   "getStorage: Error reading key [" +
      //   key +
      //   "] from localStorage: " +
      //   JSON.stringify(e)
      // );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    // console.log(
    //   "setStorage: Error setting key [" +
    //   key +
    //   "] in localStorage: " +
    //   JSON.stringify(e)
    // );
    return false;
  }
  return true;
}
