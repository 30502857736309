import axios from "axios";
// import { toAbsoluteUrl } from "../../utils/utils";
const url = 'Modules/getAllModules';

export function getCefitMenuItem () {
    return axios.get(url).then(res => {
        const menuitem = res.data.result
        return menuitem;
    });
}

// const items = [
//     {
//         active: true,
//         displayModuleName: "Dashboard",
//         icon: "fas fa-home",
//         moduleID: 1,
//         moduleName: "Dashboard",
//         pageUrl: "/cefit/dashboard",
//         parentModuleID: null,
//         reportId: 0,
//         subModule: []
//     },
//     {
//         active: true,
//         displayModuleName: "Configration",
//         icon: "fas fa-home",
//         moduleID: 1,
//         moduleName: "Configration",
//         pageUrl: "/cefit/Configration",
//         parentModuleID: null,
//         reportId: 0,
//         subModule: []
//     }
// ]

const menu = {
    header: {
        self: {
            skin: "light"
        },
        items: []
    },
    aside: {
        self: {},
        items: []
    }
};

export default menu;