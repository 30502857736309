import axios from 'axios';
const getAllUrl = 'subscription/GetAllSubscription/true';
const getActiveUrl = 'subscription/GetAllSubscription/false';
const updateUrl = 'subscription/updatesubscription';
const addUrl = 'subscription/createsubscription';
const deleteUrl = 'subscription/deletesubscription';

export const getAllSubscriptions = () => axios.get(getAllUrl);

export const getActiveSubscriptions = () => axios.get(getActiveUrl);

export const addSubscription=(params) => axios.post(addUrl, params);

export const updateSubscription=(params) => axios.post(updateUrl, params);

export const deleteSubscription=(params) => axios.delete(`${deleteUrl}/${params.subscriptionID}`, params);