import React  from "react";//,{ useEffect }
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import * as actions from "../../../../store/actions/globalConfigAction";
// import { useLocation } from 'react-router-dom'


export default function RoleInitializer({
    children,
    params
}) {

    return (
       <> { children } </>
    )
}
