import React, { useState, useRef, useMemo, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector, shallowEqual, connect} from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//import { organisationServices, alertService, tenantServices } from '../../../../../_metronic/_services';
//import * as actions from "../../../../store/actions/tenantAction";
import * as oActions from "../../../../store/actions/organisationAction";
import OrganisationList from './OrganisationList'
import Button from '@material-ui/core/Button';
import ImportModel from './model';
import Toast from '../../../../crud/Toast';
import TenantEditHead from '../../TenantEditHead';

function Organisation({
    match,
    tenant,
    organisation,
    user
}) {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const childRef = useRef();
    const loader = document.getElementById('splash-screen');
    const classes = useStyles();
    const { id } = match.params;
    const [tenantItem, setTenantItem] = useState(undefined);
    const dataSource = useMemo(() => {
        return {organisation, tenant}
    }, [organisation, tenant])
    const [level, setLevel] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [toastVisible, setToastVisible] = React.useState(false)
    const {tenentSuccess, tenantMassage, error} = useSelector((state) => ({
        tenentSuccess: state.organisation.success,
        tenantMassage: state.organisation.message,
        error: state.organisation.error
    }), shallowEqual)
    const [userHasEditTenantPermission, setUserHasEditTenantPermission] = useState(false);
    useEffect(()=>{
        var hasPermission = !!user?.modules?.find(m=>m.moduleName==='Tenants' && m.permissions?.some(p=>p.permissionName === 'Create'||p.permissionName === 'Update'));
        setUserHasEditTenantPermission(hasPermission);
    },[]);
    useEffect(() => {
        loader.classList.remove('hidden')
        dispatch(oActions.getOrganisation(id)) 
        setTimeout(() => {
            loader.classList.add('hidden')
        }, 1000)
    }, [dispatch])

    const openImportModel = (id) => {
        setShow(true)
    }
    React.useEffect(() => {
        if(tenentSuccess){
            setLevel('success')
            setMessage(tenantMassage)
            setToastVisible(tenentSuccess)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(oActions.reset())
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 5000)
        }else { 
            setLevel('')
            setMessage('')
            setToastVisible(false)
        }
    }, [tenentSuccess, tenantMassage])
    React.useEffect(() => {
        if(error){
            setLevel('danger')
            setMessage(error)
            setToastVisible(true)
            loader.classList.add('hidden')
            setTimeout(() => {
                dispatch(oActions.reset())
                setLevel('')
                setMessage('')
                setToastVisible(false)
            }, 5000)
        }
    }, [error])
    return(
        <>
            {tenant && <TenantEditHead tenant={tenant} />}
            {/* {dataSource.tenant && <div className={classes.containerTop}>
                <div className="row">
                    <div className="col-sm-6">
                        <div className={classes.list}>
                            <div style={{paddingRight: 10, minWidth: 165}}>
                                <h6 style={{margin: 0}}>Subscription Type:</h6>
                            </div>
                            <div>
                                <p style={{margin: 0}}>{dataSource.tenant.subscriptionType}</p>
                            </div>
                        </div>
                        <div className={classes.list}>
                            <div style={{paddingRight: 10, minWidth: 165}}>
                                <h6 style={{margin: 0}}>Domain:</h6>
                            </div>
                            <div>
                                <p style={{margin: 0}}>{dataSource.tenant.domain}</p>
                            </div>
                        </div>
                        <div className={classes.list}>
                            <div style={{paddingRight: 10, minWidth: 165}}>
                                <h6 style={{margin: 0}}>Tenant ID:</h6>
                            </div>
                            <div>
                                <p style={{margin: 0}}>{dataSource.tenant.tenantGUID}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={classes.list}>
                            <div style={{paddingRight: 10, minWidth: 165}}>
                                <h6 style={{margin: 0}}>Tenant Industry Type:</h6>
                            </div>
                            <div>
                                <p style={{margin: 0}}>{dataSource.tenant.tenantIndustryType}</p>
                            </div>
                        </div>
                        <div className={classes.list}>
                            <div style={{paddingRight: 10, minWidth: 165}}>
                                <h6 style={{margin: 0}}>Tenant Type:</h6>
                            </div>
                            <div>
                                <p style={{margin: 0}}>{dataSource.tenant.tenantType}</p>
                            </div>
                        </div>
                        <div className={classes.list}>
                            <div style={{paddingRight: 10, minWidth: 165}}>
                                <h6 style={{margin: 0}}>Sub Type:</h6>
                            </div>
                            <div>
                                <p style={{margin: 0}}>{dataSource.tenant.subType}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>} */}
            {dataSource.tenant && <div className={classes.container}>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <>
                        {dataSource.organisation && dataSource.organisation.length > 0 &&
                        <ImportModel 
                            id={dataSource.tenant.tenantID} 
                            organizationID={dataSource.organisation[0].organizationID} 
                            show={show} 
                        />}
                    </>
                </div>
                {dataSource.organisation && dataSource.organisation.length > 0 && <TableContainer component={Paper} className={classes.paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>{`${dataSource.tenant.levelName} ID`}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {dataSource.organisation[0].name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {dataSource.organisation[0].levelID}
                            </TableCell>
                        </TableRow>
                        {dataSource.tenant.maxLevel > 0 && <tr key = { "row-expanded-1"} className="MuiTableRow-root" >
                            <td className = "MuiTableCell-root MuiTableCell-body TableDetailCell-active-225" style = {{ padding: 10 }} colSpan = "6" >
                                <div style={{display: 'flex', justifyContent: 'space-between',alignItems: 'center', padding:'0px 0px', marginBottom: 10}}>
                                    <div className={classes.title}>{dataSource.tenant.tenantLevels[dataSource.tenant.tenantLevel]}</div>
                                    {userHasEditTenantPermission && (<div>
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                childRef.current.getParentAdd(dataSource.organisation[0].organizationID)
                                            }}
                                        >
                                            {`+ Add New ${dataSource.tenant.tenantLevels[dataSource.tenant.tenantLevel]}`}
                                        </Button>
                                    </div>)}
                                </div>
                                {dataSource.organisation && dataSource.organisation.length > 0 && <OrganisationList 
                                    organizationID={dataSource.organisation[0].organizationID} 
                                    dataSource={dataSource.organisation[0].childOrganization} 
                                    tenant={dataSource.tenant}
                                    ref={childRef}
                                />}
                            </td> 
                        </tr>}
                        </TableBody>
                    </Table>
                </TableContainer>}
                <Toast
                    level={level}
                    message={message}
                    visible={toastVisible}
                />
            </div>}
        </>
    )
}

const useStyles = makeStyles({
    container: {
        width: '100%',
        padding: 10,
    },
    paper: {
        width: '100%',
        padding: 10
    },
    table: {
        minWidth: '100%',
        //borderLeft: 'solid 2px #0078d4'
    },
    title: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 500,
        lineHeight: '2.5rem'
    },
    containerTop: {
        padding: 10,
        marginBottom: 10,
        borderTop: 'solid 1px #dddddd',
        borderBottom: 'solid 1px #dddddd'
    },
    list: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '8px 0px'
    },
    commonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    basicLable: {
        minWidth: 165,
    },
    profileContainer: {
        minWidth: 240,
    },
    profileImage: {
        width: 120,
        height: 120
    },
    uploadContainer: {
        width: 'calc(100% - 405px)',
    },
    inputContainer: {
        width: 'calc(100% - 165px)'
    }
});


const mapStateToProps = store => ({
    user: store.auth.user
  });
  
export default connect(mapStateToProps)(Organisation);