import { takeEvery, call, fork, put } from 'redux-saga/effects';// takeLatest, take,
import * as types from '../actions/types';
import * as action from '../actions/tenantConfigurationAction';
import { getTenantConfigAllList, updateTenantConfiguration } from '../../crud/tenantconfiguration.crud'



function* getAllTenantConfig(){
    try{
        const result = yield call(getTenantConfigAllList);
        yield put({
            type: types.FETCH_TenantConfig_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getAllTenantConfigRequest(){
    yield takeEvery(types.FETCH_ALL_TenantConfig, getAllTenantConfig);
}

function* updateTenantConfig({payload}){
    try{
        const result = yield call(updateTenantConfiguration, payload);
        yield put({
            type: types.UPDATE_TenantConfig_success,
            payload: {
                items: result.data.result
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* updateTenantConfigRequest(){
    yield takeEvery(types.UPDATE_TenantConfig, updateTenantConfig);
}


const tenantconfigurationSaga = [
    fork(getAllTenantConfigRequest),
    fork(updateTenantConfigRequest)
];

export default tenantconfigurationSaga;