import axios from 'axios';
const url = 'users'
export const fetchManageUser = (params) => {
    return axios.post(`${url}/admin/getall`, params);
}
export const fetchManageTenantUser = (data) => {
    return axios.post(`${url}/tenant/getall/${data.id}`, data.params);
}
export const fetchManageUserByUserId = (id) => {
    return axios.get(`${url}/getuserbyid/${id}`);
}
export const fetchCreateManageUser = () => {
    return axios.get(`${url}/admin/create`);
}
export const fetchCreateManageUserByTenantId = (id) => {
    return axios.get(`${url}/tenant/create/${id}`);
}

export const fetchUpdateManageUser = (params) => {
    return axios.post(`${url}/updateuser`, params);
}

export const fetchAddManageUser = (params) => {
    return axios.post(`${url}/saveUser`, params);
}
export const deleteManageUser = (params) => {
   // console.log("manageUser crud delete", {params:params});
    return axios.delete(`${url}/tenant/deleteuser`, {params:params});
}

