import { takeEvery, takeLatest, take, call, fork, put } from 'redux-saga/effects';
import * as types from '../actions/types';
import * as action from '../actions/tenantIndustryAction';
import { 
    getAllTenantIndustries, 
    getActiveTenantIndustries,
    updateTenantIndustry, 
    addTenantIndustry, 
    deleteTenantIndustry,
    getTenantIndustryLevelsList, 
    updateTenantIndustryLevels, 
    addTenantIndustryLevels, 
    deleteTenantIndustryLevels,
    getLevelsTypeList, 
    updateLevelsType, 
    addLevelsType, 
    deleteLevelsType,
    fetchTenantIndustryLevelsById
} from '../../crud/tenantIndustry.crud'

function* getAllTenantIndustriesHandler(){
    try{
        const result = yield call(getAllTenantIndustries);
        yield put({
            type: types.GET_ALL_TENANTINDUSTRIES_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || e
        }));
    }
}

function* getAllTenantIndustriesRequest(){
    yield takeEvery(types.GET_ALL_TENANTINDUSTRIES, getAllTenantIndustriesHandler);
}
function* getActiveTenantIndustriesHandler(){
    try{
        const result = yield call(getActiveTenantIndustries);
        yield put({
            type: types.GET_ACTIVE_TENANTINDUSTRIES_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || e
        }));
    }
}

function* getActiveTenantIndustriesRequest(){
    yield takeEvery(types.GET_ACTIVE_TENANTINDUSTRIES, getActiveTenantIndustriesHandler);
}

function* updateTenantIdustryItem({payload}){
    try{
        
        const items = yield call(updateTenantIndustry, payload);
        yield put({
            type: types.UPDATE_TENANTINDUSTRY_SUCCESS,
            payload: {
                items: items.data
            },
        });
        yield call(getAllTenantIndustries);
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || e
        }));
    }
}

function* watchUpdateTenantIdustryRequest(){
    yield takeLatest(types.UPDATE_TENANTINDUSTRY_REQUEST, updateTenantIdustryItem);
}



function* addTenantIdustryItem({payload}){
    try{
        
        const items = yield call(addTenantIndustry, payload);
        yield put({
            type: types.ADD_TENANTINDUSTRY_SUCCESS,
            payload: {
                items: items.data
            },
        });
        yield call(getAllTenantIndustries);
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || e
        }));
    }
}

function* watchAddTenantIdustryRequest(){
    yield takeLatest(types.ADD_TENANTINDUSTRY_REQUEST, addTenantIdustryItem);
}

function* deleteTenantIdustryItem({payload}){
    try{
        
        const items = yield call(deleteTenantIndustry, payload);
        yield put({
            type: types.DELETE_TENANTINDUSTRY_SUCCESS,
            payload: {
                items: items.data
            },
        });
        yield call(getAllTenantIndustries);
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || e
        }));
    }
}

function* watchDeleteTenantIdustryRequest(){
    yield takeLatest(types.DELETE_TENANTINDUSTRY_REQUEST, deleteTenantIdustryItem);
}

function* getTenantIdustryLevels(){
    try{
        const result = yield call(getTenantIndustryLevelsList);
        yield put({
            type: types.FETCH_TENANTINDUSTRY_LEVELS_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || e
        }));
    }
}

function* getTenantIdustryLevelsRequest(){
    yield takeEvery(types.FETCH_TENANTINDUSTRY_BYID_LEVELS, getTenantIdustryLevels);
}

function* updateTenantIdustryLevelsItem({payload}){
    try{
        
        const items = yield call(updateTenantIndustryLevels, payload);
        yield put({
            type: types.UPDATE_TENANTINDUSTRY_LEVELS_SUCCESS,
            payload: {
                items: items.data
            },
        });
        yield call(getTenantIdustryLevels);
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || e
        }));
    }
}

function* watchUpdateTenantIdustryLevelsRequest(){
    yield takeLatest(types.UPDATE_TENANTINDUSTRY_LEVELS_REQUEST, updateTenantIdustryLevelsItem);
}



function* addTenantIdustryLevelsItem({payload}){
    try{
        
        const items = yield call(addTenantIndustryLevels, payload);
        yield put({
            type: types.ADD_TENANTINDUSTRY_LEVELS_SUCCESS,
            payload: {
                items: items.data
            },
        });
        yield call(getTenantIdustryLevels);
    } catch (e) {

        yield put(action.error({
            error: e.response?.data || e
        }));
    }
}

function* watchAddTenantIdustryLevelsRequest(){
    yield takeLatest(types.ADD_TENANTINDUSTRY_LEVELS_REQUEST, addTenantIdustryLevelsItem);
}

function* deleteTenantIdustryLevelsItem({payload}){
    try{
        
        const items = yield call(deleteTenantIndustryLevels, payload);
        yield put({
            type: types.DELETE_TENANTINDUSTRY_LEVELS_SUCCESS,
            payload: {
                items: items.data
            },
        });
        // yield call(getTenantIdustryLevels);
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || e
        }));
    }
}

function* watchDeleteTenantIdustryLevelsRequest(){
    yield takeLatest(types.DELETE_TENANTINDUSTRY_LEVELS_REQUEST, deleteTenantIdustryLevelsItem);
}


function* getLevelsType({ payload }){
    try{
        const result = yield call(getLevelsTypeList, payload);
        yield put({
            type: types.FETCH_LEVELS_TYPE_SUCCESS,
            payload: {
                items: result.data.result
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getLevelsTypeRequest(){
    yield takeEvery(types.FETCH_LEVELS_TYPE, getLevelsType);
}

function* updateLevelsTypeItem({payload}){
    try{
        
        const items = yield call(updateLevelsType, payload);
        yield put({
            type: types.UPDATE_LEVELS_TYPE_SUCCESS,
            payload: {
                items: items.data
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* watchUpdateLevelsTypeRequest(){
    yield takeLatest(types.UPDATE_LEVELS_TYPE_REQUEST, updateLevelsTypeItem);
}



function* addLevelsTypeItem({payload}){
    try{
        
        const items = yield call(addLevelsType, payload);
        yield put({
            type: types.ADD_LEVELS_TYPE_SUCCESS,
            payload: {
                items: items.data
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* watchAddLevelsTypeRequest(){
    yield takeLatest(types.ADD_LEVELS_TYPE_REQUEST, addLevelsTypeItem);
}

function* deleteLevelsTypeItem({payload}){
    try{
        
        const items = yield call(deleteLevelsType, payload);
        yield put({
            type: types.DELETE_LEVELS_TYPE_SUCCESS,
            payload: {
                items: items.data
            },
        });
    } catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* watchDeleteLevelsTypeRequest(){
    yield takeLatest(types.DELETE_LEVELS_TYPE_REQUEST, deleteLevelsTypeItem);
}

const tenantIdustrySaga = [
    fork(getAllTenantIndustriesRequest),
    fork(getActiveTenantIndustriesRequest),
    fork(watchUpdateTenantIdustryRequest),
    fork(watchAddTenantIdustryRequest),
    fork(watchDeleteTenantIdustryRequest),
    fork(getTenantIdustryLevelsRequest),
    fork(watchUpdateTenantIdustryLevelsRequest),
    fork(watchAddTenantIdustryLevelsRequest),
    fork(watchDeleteTenantIdustryLevelsRequest),
    fork(getLevelsTypeRequest),
    fork(watchUpdateLevelsTypeRequest),
    fork(watchAddLevelsTypeRequest),
    fork(watchDeleteLevelsTypeRequest)
];

export default tenantIdustrySaga;