import React from 'react'
import { Route } from 'react-router-dom'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { CefitUIProvider } from './CefitUiContext'
import { CefitLoadingDialog } from "./cefit-loading-dialog/CefitLoadingDialog";
import { CefitCard } from './CefitCard'

export function CefitPages({ history }) {
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    const [ieBrowser] = React.useState(isIE ? true : false);
    const cefitUIEvents = {
        newCefitButtonClick: () => {
            history.push("/cefit/list/add");
        },
        openEditCefitPage: (id) => {
            history.push(`/cefit/list/${id}/edit`);
        },
        openDeleteCefitDialog: (id) => {
            history.push(`/cefit/list/${id}/delete`);
        },
        openDeleteCefitDialog: () => {
            history.push(`/cefit/deleteCefit`);
        },
        openFetchCefitDialog: () => {
            history.push(`/cefit/fetch`);
        },
        openUpdateCefitStatusDialog: () => {
            history.push("/cefit/updateStatus");
        },
        ieBrowser: ieBrowser,
    };
    return (
        <ThemeProvider theme={theme}>
            <CefitUIProvider cefitUIEvents={cefitUIEvents}> 
                <CefitLoadingDialog />
                <CefitCard />
            </CefitUIProvider>
        </ThemeProvider>
    )
}

const theme = createMuiTheme({
    overrides: {
        MuiExpansionPanelSummary: {
            content: {
                padding: 0,
                margin: '0px !important'
            }
        },
        MuiPagination: {
            root: {
                marginTop: 10,
                alignItems: 'center'
            },
            ul: {
                marginTop: 10,
                alignItems: 'center'
            }
        },
        MuiButtonBase: {
            root: {
                minHeight: '25px !important',
                lineHeight: '1.2 !important'
            },
            expandIcon: {
                padding: 5
            },
        },
        MuiButton: {
            text: {
                color: '#333333',
            },
            containedPrimary: {
                backgroundColor: '#0078d4',
                '&:hover': {
                    backgroundColor: '#0078d4',
                }
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: '0px !important',
                backgroundColor: 'rgba(0, 0, 0, 0.0) !important'
            }
        },
        MuiTableCell: {
            root: {
                padding: 5,
                fontSize: '1rem'
            },
            head: {
                lineHeight: '2.5rem'
            },
            sizeSmall: {
                padding: 5,
            },
            stickyHeader: {
                backgroundColor: '#ffffff'
            }
        },
        MuiFormControl: {
            root: {
                width: 'auto'
            }
        },
        MuiFormControlLabel: {
            root: {
                marginBottom: 0,
                marginLeft: 0
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiListItem: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14
            }
        },
        MuiInputLabel: {
            formControl: {
                transform: 'translate(0, 7px) scale(1)'
            },
            shrink: {
                transform: 'translate(0, 31px) scale(0.75)'
            }
        },
        MuiInput: {
            formControl: {
                marginTop: '0 !important'
            }
        }
    },
});

const useStyles = theme => ({
    progressBar: {
        top: 31,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
        background: 'rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 99999
    }
});