import * as types from '../actions/types';


const INITIAL_STATE = {
    data: [],
    id: undefined,
    success: false,
    message: '',
    error: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.FETCH_GLOBAL_CONFIG_SUCCESS:
            return {
                ...state,
                data: action.payload,
            }
        case types.UPDATE_GLOBAL_CONFIG_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Item has been successfully updated..'
            }
        case types.ADD_GLOBAL_CONFIG_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Item has been successfully added..'
            }
        case types.DELETE_GLOBAL_CONFIG_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Item has been successfully deleted..'
            }
        
        case types.GLOBAL_CONFIG_ERROR: 
            return {
                ...state,
                error: action.payload.ErrorMessage ? action.payload.ErrorMessage : 'Please Try again...'
            }
        case types.RESET_STORE: 
            return {
                ...state,
                success: false,
                message: '',
                error: null
            }
        default:
            return state;
    }
};
