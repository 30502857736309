import axios from "axios";
export const RUNTIME_API = 'getPackageList?code=LM0AfvtmdBxf5HgngXbfxTbTjyAN6L9jTawBmaktQiPlZD38f5rE9w==';
export const AUDITE_API = 'getAuditTrail?code=HSt2A1fHbcWBz73Wt2rmlLmg2VXG8pyLZarzTXENypoM1XG6pr4rHA==';
export const TABLEDATA_API = 'getSnapShotData?code=iqBW3jRQlte59slVdSKE5DUPsnC3rlthhJOkl/WIlgLFtBLGY7jkpQ==&tablename='



export const getRunTimeFilter = (params) => {
    return axios.get(RUNTIME_API, params);
    // return fetch(`https://VegaLyticsapplication.azurewebsites.net/api/${RUNTIME_API}`, {
    //     method: 'POST',
    //     Body: params
    // })
    // .then((response) => {
    //   if(response.ok) {
    //       return(response.json());
    //   }  else {
    //       return({success: false});
    //   }
    // })
    // .catch((error) => {
    //     return({success: false});
    // })
};

export const getRunTimeAll = () => {
    const params ={
        "except":"false",
        "filter": ["1"]
    }
    return axios.get(RUNTIME_API, {params});
};

export const getAuditTrailAll = () => {
    const date = new Date().getDate()
    const year = new Date().getFullYear()
    const month = new Date().getMonth() + 1
    const full =  year + "/" + month + "/" + date; 
    const params = {
        'startdate': full,
        'enddate': full
    }
    return axios.get(AUDITE_API, {params});
};

export const getAuditTrailFilter = (params) => {
    return axios.get(AUDITE_API, params);
};

export const getTableDataAll = (items) => {
    return axios.get(TABLEDATA_API+items, items);
};

export const updateTableData = (items) => {
    return axios.post(TABLEDATA_API+items.type, items.data);
    // return fetch(TABLEDATA_API+items.type, {
    //     method: 'POST',
    //     body: JSON.stringify(items.data)
    // })
    // .then((response) => {
    //   if(response.ok) {
    //       return({success: true});
    //   }  else {
    //       return({success: false});
    //   }
    // })
    // .catch((error) => {
    //     return({success: false});
    // })
}