import { takeEvery, takeLatest, take, call, fork, put } from 'redux-saga/effects';
import * as types from '../actions/types';
import * as action from '../actions/manageUserAction';

import { 
    fetchManageUser,
    fetchManageUserByUserId,
    fetchCreateManageUser,
    fetchCreateManageUserByTenantId,
    fetchManageTenantUser,
    fetchUpdateManageUser,
    fetchAddManageUser,
    deleteManageUser
} from '../../crud/manageUser.curd';

function* getManageUser({ payload }){
    try{
        const result = yield call(fetchManageUser, payload);
        yield put({
            type: types.FETCH_MANAGEUSER_SUCCESS,
            payload: result.data.result
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getManageUserRequest(){
    yield takeEvery(types.FETCH_MANAGEUSER_REQUEST, getManageUser);
}

function* getManageUserTenantId({ payload }){
    try{
        const result = yield call(fetchManageTenantUser, payload);
        yield put({
            type: types.FETCH_MANAGEUSER_SUCCESS,
            payload: result.data.result
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getManageUserTenantIdRequest(){
    yield takeEvery(types.FETCH_MANAGEUSER_TENANTID_REQUEST, getManageUserTenantId);
}

function* getManageUserByUserId({ payload }){
    try{
        const result = yield call(fetchManageUserByUserId, payload);
        yield put({
            type: types.FETCH_MANAGEUSER_BYUSERID_SUCCESS,
            payload: result.data.result
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* getManageUserByUserIdRequest(){
    yield takeEvery(types.FETCH_MANAGEUSER_BYUSERID_REQUEST, getManageUserByUserId);
}

function* createManageUser(){
    try{
        const result = yield call(fetchCreateManageUser);
        yield put({
            type: types.CREATE_MANAGEUSER_SUCCESS,
            payload: result.data.result
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* creatManageUserRequest(){
    yield takeEvery(types.CREATE_MANAGEUSER_REQUEST, createManageUser);
}

function* createManageUserTenantId({ payload }){
    try{
        const result = yield call(fetchCreateManageUserByTenantId, payload);
        yield put({
            type: types.CREATE_MANAGEUSER_BYTENANTID_SUCCESS,
            payload: result.data.result
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* createManageUserTenantIdRequest(){
    yield takeEvery(types.CREATE_MANAGEUSER_BYTENANTID_REQUEST, createManageUserTenantId);
}

function* updateManageUser({ payload }){
    try{
        const result = yield call(fetchUpdateManageUser, payload);
        yield put({
            type: types.UPDATE_MANAGEUSER_SUCCESS,
            payload: result.data.result
        });
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* updateManageUserTenantIdRequest(){
    yield takeEvery(types.UPDATE_MANAGEUSER_REQUEST, updateManageUser);
}

function* addManageUser({ payload }){
    try{
        const result = yield call(fetchAddManageUser, payload);
        if(result.data.ErrorMessage){
            yield put({
                type: types.MANAGEUSER_ERROR,
                payload: {
                    ErrorMessage:  result.data.ErrorMessage,
                    error: result.data.ErrorMessage,
                    success: false
                }
                
            });
        }else{
            yield put({
                type: types.ADD_MANAGEUSER_SUCCESS,
                payload: result.data.result
            });
        }
    }catch (e) {
        yield put(action.error({
            error: e
        }));
    }
}

function* addManageUserTenantIdRequest(){
    yield takeEvery(types.ADD_MANAGEUSER_REQUEST, addManageUser);
}


function* deleteManageUserItem({payload}){
    try{
        // console.log("SagaManageUser deleteManageUserItem", payload);
        const items = yield call(deleteManageUser, payload);
        yield put({
            type: types.DELETE_MANAGEUSER_SUCCESS,
            payload: items.data.result
        });
      //  yield call(getManageUserTenantIdRequest);
    }catch(e){
        // console.log(e);
        yield put(action.error({
            error: e
        }));
    }
}

function* watchDeleteManageUserRequest(){
    yield takeLatest(types.DELETE_MANAGEUSER_REQUEST, deleteManageUserItem);
}



const manageUserSaga = [
    fork(getManageUserRequest),
    fork(getManageUserTenantIdRequest),
    fork(getManageUserByUserIdRequest),
    fork(creatManageUserRequest),
    fork(createManageUserTenantIdRequest),
    fork(updateManageUserTenantIdRequest),
    fork(addManageUserTenantIdRequest),
    fork(watchDeleteManageUserRequest),

];

export default manageUserSaga;