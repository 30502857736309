import { takeEvery,  call, fork, put } from 'redux-saga/effects';//takeLatest, take,
import * as types from '../actions/types';
import {
    getCefitList,
    getCefitById,
    getTableName,
    getColumnName,
    uploadExcel,
    getUpdateCefit,
    getCefitApprove,
    getCefitDelete,
    getCefitRefresh,
    getCefitTestMapping,
} from "../../crud/cefit.curd";
import * as action from '../actions/cefitAction';

function* getCefit({ payload }) {
    try {
        const res = yield call(getCefitList, payload);
        yield put({
            type: types.FETCH_CEFIT_LIST_SUCCESS,
            payload: res.data.result,
        });
    } catch (e) {
        console.log(e);
        yield put(action.error({
            error: e.response?.data || 'Something is wrong to fetch list..'
        }));
    }
}

function* getCefitRequest() {
    yield takeEvery(types.FETCH_CEFIT_LIST, getCefit);
}

function* getCefitByCId({ payload }) {
    try {
        const res = yield call(getCefitById, payload);
        yield put({
            type: types.FETCH_CEFIT_BY_ID_SUCCESS,
            payload: res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || 'Something is wrong to fetch by id'
        }));
    }
}

function* getCefitByIdRequest() {
    yield takeEvery(types.FETCH_CEFIT_BY_ID, getCefitByCId);
}

function* getCefitTableName({ payload }) {
    try {
        const res = yield call(getTableName, payload);
        yield put({
            type: types.FETCH_CEFIT_TABLE_NAME_SUCCESS,
            payload: res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || 'Something is wrong to fetch table name...'
        }));
    }
}

function* getCefitTableNameRequest() {
    yield takeEvery(types.FETCH_CEFIT_TABLE_NAME, getCefitTableName);
}

function* getCefitColumnName({ payload }) {
    try {
        const res = yield call(getColumnName, payload);
        yield put({
            type: types.FETCH_CEFIT_COLUMN_NAME_SUCCESS,
            payload: res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || 'Something is wrong to fetch column name...'
        }));
    }
}

function* getCefitColumnNameRequest() {
    yield takeEvery(types.FETCH_CEFIT_COLUMN_NAME, getCefitColumnName);
}

function* getCefitExcel({ payload }) {
    try {
        const res = yield call(uploadExcel, payload);
        yield put({
            type: types.FETCH_CEFIT_UPDATE_EXCEL_SUCCESS,
            payload: res.data.result,
        });
    } catch (e) {
        console.log(e)
        yield put(action.error({
            error: e.response?.data || 'Something is wrong to upload file...'
        }));
    }
}

function* getCefitExcelRequest() {
    yield takeEvery(types.FETCH_CEFIT_UPDATE_EXCEL, getCefitExcel);
}

function* getCefitUpdate({ payload }) {
    try {
        const res = yield call(getUpdateCefit, payload);
        yield put({
            type: types.FETCH_CEFIT_UPDATE_SUCCESS,
            payload: res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || 'Something is wrong to update cefit..'
        }));
    }
}

function* getCefitUpdateRequest() {
    yield takeEvery(types.FETCH_CEFIT_UPDATE, getCefitUpdate);
}

function* cefitCreate({ payload }) {
    try {
        const res = yield call(getUpdateCefit, payload);
        yield put({
            type: types.FETCH_CEFIT_CREATE_SUCCESS,
            payload: res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || 'Something is wrong to create cefit..'
        }));
    }
}

function* getCefitCreateRequest() {
    yield takeEvery(types.FETCH_CEFIT_CREATE, cefitCreate);
}

function* cefitTest({ payload }) {
    try {
        const res = yield call(getCefitTestMapping, payload);
        yield put({
            type: types.FETCH_CEFIT_TEST_SUCCESS,
            payload: res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || 'Something is wrong to test mapping..'
        }));
    }
}

function* getCefitTestRequest() {
    yield takeEvery(types.FETCH_CEFIT_TEST, cefitTest);
}

function* cefitApprove({ payload }) {
    try {
        const res = yield call(getCefitApprove, payload);
        yield put({
            type: types.FETCH_CEFIT_APPROVE_SUCCESS,
            payload: res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || 'Something is wrong to approving..'
        }));
    }
}

function* getCefitApproveRequest() {
    yield takeEvery(types.FETCH_CEFIT_APPROVE, cefitApprove);
}

function* cefitDelete({ payload }) {
    try {
        const res = yield call(getCefitDelete, payload);
        yield put({
            type: types.FETCH_CEFIT_DELETE_SUCCESS,
            payload: res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || 'Something is wrong to delete cefit..'
        }));
    }
}

function* getCefitDeleteRequest() {
    yield takeEvery(types.FETCH_CEFIT_DELETE, cefitDelete);
}

function* cefitRefresh({ payload }) {
    try {
        const res = yield call(getCefitRefresh, payload);
        yield put({
            type: types.FETCH_CEFIT_REFRESH_SUCCESS,
            payload: res.data.result,
        });
    } catch (e) {
        yield put(action.error({
            error: e.response?.data || 'Something is wrong to refresh cefit..'
        }));
    }
}

function* getCefitRefreshRequest() {
    yield takeEvery(types.FETCH_CEFIT_REFRESH, cefitRefresh);
}

const cefitSaga = [
    fork(getCefitRequest),
    fork(getCefitByIdRequest),
    fork(getCefitTableNameRequest),
    fork(getCefitColumnNameRequest),
    fork(getCefitExcelRequest),
    fork(getCefitUpdateRequest),
    fork(getCefitCreateRequest),
    fork(getCefitTestRequest),
    fork(getCefitApproveRequest),
    fork(getCefitDeleteRequest),
    fork(getCefitRefreshRequest)
];

export default cefitSaga;