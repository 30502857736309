import axios from "axios";
export const CEFIT_URL = process.env.REACT_APP_CEFIT_API;

export const getCefitList = (params) => {
    return axios.post(`${CEFIT_URL}/getViewConfiguration`, params);
}

export const getCefitById = (id) => {
    return axios.get(`${CEFIT_URL}/editConfiguration/${id}`);
}

export const getTableName = (dataType) => {
    return axios.get(`${CEFIT_URL}/getTableColumnName?fileDataType=${dataType}`)
        //return axios.get(`${CEFIT_URL}/getTableName?fileDataType=${dataType}`);
}

export const getColumnName = (columnName) => {
    return axios.get(`${CEFIT_URL}/getTableField?tableName=${columnName}`);
}

export const getCefitTestMapping = (id) => {
    return axios.get(`${CEFIT_URL}/testConfiguration?id=${id}`);
}

export const getCefitApprove = (id) => {
    return axios.get(`${CEFIT_URL}/approveDeploy?id=${id}`);
}

export const getCefitDelete = (id) => {
    return axios.delete(`${CEFIT_URL}/deleteConfiguration/${id}`);
}

export const getCefitRefresh = (params) => {
    return axios.post(`${CEFIT_URL}/refreshMapping`, params);
}



export const uploadExcel = (params) => {
    let f = new FormData();
    f.append("FileObject", params.JsonFile)
    f.append('FileDataType', params.FileDataType)
    f.append('RowsToSkip', params.rowsToSkip)
    f.append('Tenantid', params.tenantid)
    f.append('FileName', params.fileName)
    return axios.post(`${CEFIT_URL}/uploadFile`, f, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

export const getUpdateCefit = (params) => {
    return axios.post(`${CEFIT_URL}/saveField`, params)
}