import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { Table, Button, Form } from "react-bootstrap";
import { Select, MenuItem } from '@material-ui/core'
import { CustomTableItem } from './CustomTableItem'
import { useForm } from "react-hook-form";

export function CustomTable({
    keys,
    tableName,
    columnName,
    onSelectChangeTableName,
    onSubmiHendle,
    defaultValues,
    roles,
    match,
    onTestMapping,
    onCefitApprove,
    onRefreshMaping,
    addNewRow,
    onCancleButton,
    removeRow
}){ 
    const {user} = useSelector((state)=>({
        user: state.auth.user
    }), shallowEqual);
    //const [userHasCreatePermission, setUserHasCreatePermission] = useState(false);
    const [userHasUpdatePermission, setUserHasUpdatePermission] = useState(false);
    // const [userHasDeletePermission, setUserHasDeletePermission] = useState(false);
    useEffect(()=>{
      if(user){
          console.log(user.vhUser);
          if(user.vhUser){
            //   setUserHasCreatePermission(true);  
              setUserHasUpdatePermission(true);  
            //   setUserHasDeletePermission(true);
          }else{
              var fileMappingModule = user.modules?.find(m=>m.moduleName==='FileMapping');
              if(fileMappingModule){
                  var hasCreatePermission = !!fileMappingModule.permissions.some(p=>p.permissionName === 'Create');
                  var hasUpdatePermission = !!fileMappingModule.permissions.some(p=>p.permissionName === 'Update');
                //   var hasDeletePermission = !!fileMappingModule.permissions.some(p=>p.permissionName === 'Delete');
                //   setUserHasCreatePermission(hasCreatePermission);  
                  setUserHasUpdatePermission(hasCreatePermission || hasUpdatePermission);  
                //   setUserHasDeletePermission(hasDeletePermission);  
                  console.log(hasCreatePermission,hasUpdatePermission);
              }else{            
                //   setUserHasCreatePermission(false);  
                  setUserHasUpdatePermission(false);  
                //   setUserHasDeletePermission(false);  
              }             
          }
      }
    },[user]); 
    const history = useHistory()
    const id = match.params.id
    const {
        control,
        register,
        handleSubmit,
        getValues,
        errors,
        reset,
        setValue
      } = useForm({defaultValues: defaultValues});
    const [rowPerPage, setrowPerPage] = useState(3)
    React.useEffect(() => {
      reset(defaultValues)
    }, [defaultValues])
    function addField (argument) {
        setrowPerPage(rowPerPage + 1)
    }
    function removeField (argument) {
        setrowPerPage(rowPerPage > 1 ? rowPerPage - 1 : 1)
    }

    const onSubmit = (data) => {
        console.log("data", data)
        onSubmiHendle(data.test)
    };
    
    return(
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Table striped bordered condensed="true" hover className="table-custom" id="myTable">
                    <thead>
                        <tr>
                            <th>File Field Name</th>
                            <th>Table Name</th>
                            <th>Target Column Name</th>
                            <th>Rules</th>
                           <th> {userHasUpdatePermission &&  
                                <Button variant="primary" onClick={() => onRefreshMaping()}>Refresh</Button>
                            }</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableName && tableName.length > 0 && <CustomTableItem 
                            keys={keys}
                            tableName={tableName}
                            columnName={columnName}
                            addNewRow={addNewRow}
                            removeRow={removeRow}
                            addField={addField}
                            removeField={removeField}
                            onSelectChangeTableName={onSelectChangeTableName}
                            {...{ control, register, defaultValues, getValues, setValue, reset, errors }}
                        />}
                    </tbody>
                </Table>
                <div className="row mt-4">
                    <div className="col-sm-12 text-right">
                        {userHasUpdatePermission && <input type="submit" className="btn btn-primary" value='Save and Submit Mapping' />}
                        {' '}
                        {userHasUpdatePermission && <Button
                            disabled={id ? false : true}
                            variant="primary" 
                            onClick={onTestMapping} 
                        >Test Mapping</Button> }
                        {' '}
                        {/* && roles && roles.roleName === 'Tool Admin' */}
                        {(userHasUpdatePermission || (user.vhUser && roles?.roleName === 'Tool Admin'))  && <Button 
                            disabled={id ? false : true}
                            variant="primary" 
                            onClick={onCefitApprove} 
                        >Approve and Deploy</Button>}
                        {' '}
                        <Button 
                            variant="primary" 
                            onClick={() => onCancleButton()}
                        >Cancel</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}