import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";

export default class MenuList extends React.Component {
  render() {
      const { currentUrl, menuConfig, layoutConfig } = this.props;
      return menuConfig.length > 0 ? menuConfig.map((child, index) => {
          return (
              <React.Fragment key={`menuList${index}`}>
                {child.section && <MenuSection item={child} />}
                {child.separator && <MenuItemSeparator item={child} />}
                { (this.props.user.vhUser || (this.props.user.menuAccess && this.props.user.menuAccess.includes(child.moduleName))) && (
                    <MenuItem
                        user={this.props.user}
                        item={child}
                        currentUrl={currentUrl}
                        layoutConfig={layoutConfig}
                    />
                )}
              </React.Fragment>
          );
      }) : <div>Loading...</div>;
  }
}
